import React, { useEffect,useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import 'paper-css/paper.min.css'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import { red,yellow,green } from '@material-ui/core/colors'
import { observer } from 'mobx-react-lite'
import { CardMedia } from '@material-ui/core'
import DataStore from './stores/data'
import UiStore from './stores/ui'
import { spreadElements,spreadElements2, spreadElements3 } from 'shared/functions/helper'
import i18next from 'i18n.js'
import queryString from 'query-string'

import IMG_ARROWS from 'media/img/ampelflyer/pfeile.png'
import IMG_FALTANLEITUNG from 'media/img/ampelflyer/faltanleitung.png'
import IMG_TRAFFICLIGHT_GREEN from 'media/img/ampelflyer/green.png'
import IMG_TRAFFICLIGHT_YELLOW from 'media/img/ampelflyer/yellow.png'
import IMG_TRAFFICLIGHT_RED from 'media/img/ampelflyer/red.png'

const useStyles = makeStyles(theme => ({
  site:{
   width:(297 / 3)+'mm',
   display:'inline-block',
   verticalAlign:'top',
   position:'relative',
   height:'210mm'
 },
 inner:{
   margin:'7.5mm',
   borderTop:'2px solid',
   paddingTop:'5mm',
   position:'relative'
 },
 pageNumber:{
   position:'absolute',
   bottom:'3.5mm',
   left:0,
   right:0,
   width:'100%',
   margin:'0 auto',
   color:'#000',
   textAlign:'center',
   fontSize:'0.7rem'
 },
 cutline:{
   position:'absolute',
   right:-1,
   borderRight:'1px dashed #aaa',
   height:25
 },
 cutlineTop:{
   top:'3.5mm',
 },
 cutlineBottom:{
   bottom:'3.5mm',
 },
 coloredText:{
   color:red[500]
 },
 mainImg:{
   height:'5.77cm',
   marginTop:'15mm',
   marginBottom:'15mm'
 },
 trafficLight:{
   position:'relative',
   backgroundColor: '#333',
   width: 40,
   height: 88,
   borderRadius: 10,
   float:'left',
 },
 trafficLightElement:{
   position:'absolute',
   width:20,
   height:20,
   borderRadius:'50%',
   left:0,
   right:0,
   margin:'0 auto'
 },
 trafficLightRedPosition:{
   top:7
 },
 trafficLightRedColor:{
   background:red[500],
 },
 trafficLightYellowPosition:{
   top:27+7
 },
 trafficLightYellowColor:{
   background:yellow[500],
 },
 trafficLightGreenPosition:{
   top:27+27+7
 },
 trafficLightGreenColor:{
   background:green[500],
 },
 trafficLightInactive:{
   background:'#fff'
 },
 trafficLightRedActive:{
   boxShadow: '0 0 1em 0.1em ' + red[500],
 },
 trafficLightYellowActive:{
   boxShadow: '0 0 1em 0.1em ' + yellow[500],
 },
 trafficLightGreenActive:{
   boxShadow: '0 0 1em 0.1em ' + green[500],
 },
 trafficLightContainer:{
   marginTop:'10mm',
   clear:'left'
 },
 trafficLightText:{
   verticalAlign:'top',
   marginLeft:'20mm'
 },
 printBackground:{
   '-webkit-print-color-adjust': 'exact'
 },
 binaryMatrix:{
   position:'relative',
   overflow:'hidden',
   marginTop:55
 },
 binaryNumber:{
  color:'#ddd',
  fontSize:18,
  lineHeight:1
 },
 lupeContainer:{
  position:'absolute',
  width:'100%',
  height:'100%',
  top:0,
  left:0
 },
 usedServicesSpace:{
   height:190
 },
 magnifiyingGlass:{
   fontSize: '17em',
   display: 'inline-block',
   width: '0.5em',
   height: '0.5em',
   border: '5px solid #000',
   position: 'relative',
   borderRadius: '0.35em',
   marginLeft:71,
   marginTop:85,
   background:'#fff',
   textAlign:'center',
   "&::before": {
     content: "''",
     display: 'inline-block',
     position: 'absolute',
     left: '-0.25em',
     bottom: '-0.1em',
     borderWidth: 0,
     background: '#000',
     width: '0.35em',
     height: '0.08em',
     transform: 'rotate(138deg)',
     '-webkit-print-color-adjust': 'exact'
   }
 },
 magnifiedData:{
   fontSize:17,
   marginTop:23,
   letterSpacing:0,
   lineHeight:'0.9em',
   padding:7,
   fontWeight:600
 },
 arrow:{
   top: 4,
   right: 0,
   width: '44%',
   position: 'absolute'
 },
 arrowGreen:{
   transform:'rotate(17deg)',
   top:'1em'
 },
 arrowYellow:{
   transform:'rotate(45deg)',
   top:'8em'
 },
 arrowRed:{
   transform:'rotate(80deg)',
   top:'13em'
 },
 atOurSchoolContentSpace:{
   paddingTop:'4mm'
 },
 trafficLightPageSpacing:{
   marginBottom:'12mm'
 },
 maxHeightContainer:{
  height:(210 - 50) / 3 +'mm',
 },
 serviceImage:{
   height:30,
 },
 serviceIcon:{
   textAlign:'center'
 },
 serviceText:{
   fontSize:'0.6rem'
 },
 serviceTable:{
   width:'100%',
   height:'100%',
   borderSpacing:0
 },
 serviceTableCell:{
   width:'33.33333%',
   height:'33.33333%',
   verticalAlign:'top'
 },
 wrinkleImageContainer:{
  width:'297mm',
  height:'210mm',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
 },
 wrinkleImage:{
   width:1095,
   height:619
 }

}));

const Pdf = observer(() => {
  const { pdf } = useContext(DataStore)

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    if (params.lng && params.lng !== "undefined") {
      i18next.changeLanguage(params.lng)
    } else {
      i18next.changeLanguage('de')
    }

    document.body.classList.add('A4')
    document.body.classList.add('landscape')

    const style = document.createElement('style')
    style.innerHTML = `@page { size: A4 landscape } body { line-height: 1.3 }`
    document.head.appendChild(style)

    pdf.loadData(
      () => {},
      () => {}
    )

    return () => {
      document.body.classList.remove('A4')
      document.body.classList.remove('landscape')
      style.parentNode.removeChild(style)
    }
  },[])

  return (
    <>
     <section className="sheet">
      <Page6 flyer={pdf.flyer} />
      <Page3 flyer={pdf.flyer} pdf={pdf} />
      <Page1 flyer={pdf.flyer} />
     </section>
     <section className="sheet">
      <Page2 flyer={pdf.flyer} />
      <Page4 flyer={pdf.flyer} />
      <Page5 flyer={pdf.flyer} pdf={pdf} />
     </section>
     <section className="sheet">
      <WrinkleTutorialPage />
     </section>
    }
   </>
  )
})

export const SingleTrafficLight = (props) => {
  const classes = useStyles()
  let activeClass = null, boxShadowClass = null
  const { COLORS } = useContext(DataStore)

  if (props.active === COLORS.GREEN) {
    activeClass = classes.trafficLightGreenActive
    boxShadowClass = classes.trafficLightGreenColor
  }
  else if (props.active === COLORS.YELLOW) {
    activeClass = classes.trafficLightYellowActive
    boxShadowClass = classes.trafficLightYellowColor
  }
  else if (props.active === COLORS.RED) {
    activeClass = classes.trafficLightRedActive
    boxShadowClass = classes.trafficLightRedColor
  }

  return (
    <div className={`${classes.trafficLightElement} ${classes.printBackground} ${activeClass} ${boxShadowClass}`} />
  )
}

export const TrafficLight = (props) => {
  const classes = useStyles()
  const { COLORS } = useContext(DataStore)

  return (
   <div className={`${classes.trafficLight} ${classes.printBackground}`} {...props}>

    <div className={`
     ${classes.trafficLightElement}
     ${classes.trafficLightRedPosition}
     ${classes.printBackground}
     ${(props.active === COLORS.RED ? classes.trafficLightRedActive : classes.trafficLightInactive)}
     ${(props.active === COLORS.RED ? classes.trafficLightRedColor : '')}
     `}
    />
    <div className={`
     ${classes.trafficLightElement}
     ${classes.trafficLightYellowPosition}
     ${classes.printBackground}
     ${(props.active === COLORS.YELLOW ? classes.trafficLightYellowActive : classes.trafficLightInactive)}
     ${(props.active === COLORS.YELLOW ? classes.trafficLightYellowColor : '')}
     `}
    />
    <div className={`
     ${classes.trafficLightElement}
     ${classes.trafficLightGreenPosition}
     ${classes.printBackground}
     ${(props.active === COLORS.GREEN ? classes.trafficLightGreenActive : classes.trafficLightInactive)}
     ${(props.active === COLORS.GREEN ? classes.trafficLightGreenColor : '')}
     `}
    />

   </div>
 )
}

const BinaryMatrix = () => {
  const classes = useStyles()
  const n = 21*15
  return (
   <div className={classes.binaryMatrix}>
    {[...Array(n)].map((e, j) => <span key={'binary-number-'+j} className={classes.binaryNumber}> {Math.round(Math.random())} </span>)}
    <div className={classes.lupeContainer}>
     <div className={classes.magnifiyingGlass}>
      <div className={`${classes.magnifiedData}`}>
       {[...Array(8+9+8)].map((g,i) => {
         let color = green[500]
         if (i >= 12 && i < 12 + 5) color = yellow[500]
         if (i >= 12 + 5 && i < 12 + 5 + 3) color = red[500]
         return <span key={'binary-number-data'+i} style={{color}}> {Math.round(Math.random())} </span>
        }
       )}
      </div>
     </div>

      <img src={IMG_ARROWS} alt="arrows" className={`${classes.arrow}`} />

    </div>
   </div>
  )
}

const WrinkleTutorialPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.wrinkleImageContainer}>
     <img src={IMG_FALTANLEITUNG} className={classes.wrinkleImage} alt="wrinkle" />
    </div>
  )
}

const Page1 = (props) => {
 const { flyer } = props
 const classes = useStyles()
 const { t } = useTranslation()

 return (
  <div className={classes.site}>
   <div className={classes.inner}>
    <Box fontWeight="fontWeightBold" fontSize="h4.fontSize">{t('ampelflyer.main.title')}</Box>
    <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">{flyer.custom_schulhaus_name}</Box>
    <p className={classes.coloredText}>{t('ampelflyer.main.teaser')}</p>
    <CardMedia className={`${classes.mainImg} ${classes.printBackground}`} image={flyer.logo} />
    <p>{t('ampelflyer.main.content')}</p>
    <p>{t('ampelflyer.main.post')}</p>
   </div>
   <div className={classes.pageNumber}>1</div>
  </div>
 )
}

const Page2 = (props) => {
 const { flyer } = props
 const classes = useStyles()
 const { t } = useTranslation()

 return (
  <div className={classes.site}>
   <div className={`${classes.cutline} ${classes.cutlineTop}`} />
   <div className={classes.inner}>
    <Box className={classes.coloredText} fontSize="h5.fontSize">
     {t('ampelflyer.datamanagement.title')}
    </Box>
    <p>{t('ampelflyer.datamanagement.content',{name:flyer.custom_schulhaus_name})}</p>
    <BinaryMatrix />
    <p>{t('ampelflyer.datamanagement.point1')}<br/>{t('ampelflyer.datamanagement.point2')}</p>
    <ul style={{paddingLeft:'4mm'}}>
     <li>{t('ampelflyer.datamanagement.point3')}</li>
     <li>{t('ampelflyer.datamanagement.point4')}</li>
     <li>{t('ampelflyer.datamanagement.point5')}</li>
    </ul>
   </div>
   <div className={classes.pageNumber}>2</div>
   <div className={`${classes.cutline} ${classes.cutlineBottom}`} />
  </div>
 )
}

const Page6 = (props) => {
 const { flyer } = props
 const classes = useStyles()
 const { t } = useTranslation()
 const { COLORS } = useContext(DataStore)

 return (
  <div className={classes.site}>
   <div className={`${classes.cutline} ${classes.cutlineTop}`} />
   <div className={classes.inner}>
    <Box fontSize="h5.fontSize">
     {t('ampelflyer.categories.title')}
    </Box>

    <div className={classes.trafficLightContainer}>
     <div className={`${classes.trafficLight} ${classes.printBackground}`}>
      <img
       src={IMG_TRAFFICLIGHT_GREEN}
       className={`${classes.trafficLightGreenPosition} ${classes.printBackground}`}
       style={{width:'100%'}}
      />
     </div>
     <div className={classes.trafficLightText}>
      <div className={classes.coloredText}> {t('ampelflyer.categories.properData.title')}</div>
      <p>{t('ampelflyer.categories.properData.content')}</p>
     </div>
    </div>

    <div className={classes.trafficLightContainer}>
    <div className={`${classes.trafficLight} ${classes.printBackground}`}>
     <img
      src={IMG_TRAFFICLIGHT_YELLOW}
      className={`${classes.trafficLightYellowPosition} ${classes.printBackground}`}
      style={{width:'100%'}}
     />
    </div>
     <div className={classes.trafficLightText}>
      <div className={classes.coloredText}> {t('ampelflyer.categories.personalData.title')}</div>
      <p>{t('ampelflyer.categories.personalData.content')}</p>
     </div>
    </div>

    <div className={classes.trafficLightContainer}>
    <div className={`${classes.trafficLight} ${classes.printBackground}`}>
     <img
      src={IMG_TRAFFICLIGHT_RED}
      className={`${classes.trafficLightRedPosition} ${classes.printBackground}`}
      style={{width:'100%'}}
     />
    </div>
     <div className={classes.trafficLightText}>
      <div className={classes.coloredText}> {t('ampelflyer.categories.specialNeedData.title')}</div>
      <p>{t('ampelflyer.categories.specialNeedData.content')}</p>
      <ul style={{paddingLeft:'4mm'}}>
       <li>{t('ampelflyer.categories.specialNeedData.point1')}</li>
       <li>{t('ampelflyer.categories.specialNeedData.point2')}</li>
       <li>{t('ampelflyer.categories.specialNeedData.point3')}</li>
       <li>{t('ampelflyer.categories.specialNeedData.point4')}</li>
      </ul>
      <p>{t('ampelflyer.categories.specialNeedData.point5')}</p>
     </div>
    </div>

   </div>
   <div className={classes.pageNumber}>3</div>
   <div className={`${classes.cutline} ${classes.cutlineBottom}`} />
  </div>
 )
}

const ServiceIcon = (props) => {
  const classes = useStyles()
  const { service } = props
  return (
    <div className={classes.serviceIcon}>
     <img src={service.image} alt="" className={classes.serviceImage}/>
     <div className={classes.serviceText}> {service.name} </div>
    </div>
  )
}

const Page4 = observer((props) => {
 const { flyer } = props
 const classes = useStyles()
 const { t } = useTranslation()
 const { COLORS } = useContext(DataStore)
 const { pdf } = useContext(UiStore)

 return (
  <div className={classes.site}>
   <div className={`${classes.cutline} ${classes.cutlineTop}`} />
   <div className={classes.inner}>
    <Box fontSize="h5.fontSize">
     {t('ampelflyer.usedServices.title')}...
    </Box>
    <div style={{marginBottom:25}}>{t('ampelflyer.usedServices.content')}</div>
    <div className={`${classes.maxHeightContainer}`}>
     <div className={`${classes.trafficLight} ${classes.printBackground}`}>
      <img
       src={IMG_TRAFFICLIGHT_GREEN}
       className={`${classes.trafficLightGreenPosition} ${classes.printBackground}`}
       style={{width:'100%'}}
      />
     </div>
     <div id="pdf-services-green" className={classes.trafficLightText} style={{height:'100%',position:'relative'}}>
      <table className={classes.serviceTable}>
       <tbody>
        <tr>
         <td className={classes.serviceTableCell}>{flyer.services_green[0] ? <ServiceIcon service={flyer.services_green[0]} /> : null}</td>
         <td className={classes.serviceTableCell}>{flyer.services_green[1] ? <ServiceIcon service={flyer.services_green[1]} /> : null}</td>
         <td className={classes.serviceTableCell}>{flyer.services_green[2] ? <ServiceIcon service={flyer.services_green[2]} /> : null}</td>
        </tr>
        <tr>
         <td className={classes.serviceTableCell}>{flyer.services_green[3] ? <ServiceIcon service={flyer.services_green[3]} />  : null}</td>
         <td className={classes.serviceTableCell}>{flyer.services_green[4] ? <ServiceIcon service={flyer.services_green[4]} />  : null}</td>
         <td className={classes.serviceTableCell}>{flyer.services_green[5] ? <ServiceIcon service={flyer.services_green[5]} />  : null}</td>
        </tr>
        <tr>
         <td className={classes.serviceTableCell}>{flyer.services_green[6] ? <ServiceIcon service={flyer.services_green[6]} />  : null}</td>
         <td className={classes.serviceTableCell}>{flyer.services_green[7] ? <ServiceIcon service={flyer.services_green[7]} />  : null}</td>
         <td className={classes.serviceTableCell}>{flyer.services_green[8] ? <ServiceIcon service={flyer.services_green[8]} />  : null}</td>
        </tr>
       </tbody>
      </table>
     </div>
    </div>
    <div className={classes.maxHeightContainer}>
     <div className={`${classes.trafficLight} ${classes.printBackground}`}>
      <img
       src={IMG_TRAFFICLIGHT_YELLOW}
       className={`${classes.trafficLightYellowPosition} ${classes.printBackground}`}
       style={{width:'100%'}}
      />
     </div>
     <div id="pdf-services-yellow" className={classes.trafficLightText} style={{height:'100%',position:'relative'}}>
     <table className={classes.serviceTable}>
      <tbody>
       <tr>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[0] ? <ServiceIcon service={flyer.services_yellow[0]} /> : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[1] ? <ServiceIcon service={flyer.services_yellow[1]} /> : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[2] ? <ServiceIcon service={flyer.services_yellow[2]} /> : null}</td>
       </tr>
       <tr>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[3] ? <ServiceIcon service={flyer.services_yellow[3]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[4] ? <ServiceIcon service={flyer.services_yellow[4]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[5] ? <ServiceIcon service={flyer.services_yellow[5]} />  : null}</td>
       </tr>
       <tr>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[6] ? <ServiceIcon service={flyer.services_yellow[6]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[7] ? <ServiceIcon service={flyer.services_yellow[7]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_yellow[8] ? <ServiceIcon service={flyer.services_yellow[8]} />  : null}</td>
       </tr>
      </tbody>
     </table>
     </div>
    </div>
    <div className={classes.maxHeightContainer}>
     <div className={`${classes.trafficLight} ${classes.printBackground}`}>
      <img
       src={IMG_TRAFFICLIGHT_RED}
       className={`${classes.trafficLightRedPosition} ${classes.printBackground}`}
       style={{width:'100%'}}
      />
     </div>
     <div id="pdf-services-red" className={classes.trafficLightText} style={{height:'100%',position:'relative'}}>
     <table className={classes.serviceTable}>
      <tbody>
       <tr>
        <td className={classes.serviceTableCell}>{flyer.services_red[0] ? <ServiceIcon service={flyer.services_red[0]} /> : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_red[1] ? <ServiceIcon service={flyer.services_red[1]} /> : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_red[2] ? <ServiceIcon service={flyer.services_red[2]} /> : null}</td>
       </tr>
       <tr>
        <td className={classes.serviceTableCell}>{flyer.services_red[3] ? <ServiceIcon service={flyer.services_red[3]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_red[4] ? <ServiceIcon service={flyer.services_red[4]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_red[5] ? <ServiceIcon service={flyer.services_red[5]} />  : null}</td>
       </tr>
       <tr>
        <td className={classes.serviceTableCell}>{flyer.services_red[6] ? <ServiceIcon service={flyer.services_red[6]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_red[7] ? <ServiceIcon service={flyer.services_red[7]} />  : null}</td>
        <td className={classes.serviceTableCell}>{flyer.services_red[8] ? <ServiceIcon service={flyer.services_red[8]} />  : null}</td>
       </tr>
      </tbody>
     </table>
     </div>
    </div>
   </div>
   <div className={classes.pageNumber}>4</div>
   <div className={`${classes.cutline} ${classes.cutlineBottom}`} />
  </div>
 )
})


const Page5 = (props) => {
 const { flyer,pdf } = props
 const classes = useStyles()
 const { t } = useTranslation()

 return (
  <div className={classes.site}>
   <div className={classes.inner}>
    <Box fontSize="h5.fontSize">
     ...{t('ampelflyer.atOurSchool.title',{name:flyer.custom_schulhaus_name})}
    </Box>
    <div className={classes.atOurSchoolContentSpace}>

     <div className={classes.maxHeightContainer}>
      <div className={classes.coloredText}> {t('ampelflyer.atOurSchool.green.title')}</div>
      <div dangerouslySetInnerHTML={{__html:pdf.format(flyer.text_green_data)}} />
     </div>

      <div className={classes.maxHeightContainer}>
       <div className={classes.coloredText}> {t('ampelflyer.atOurSchool.yellow.title')}</div>
       <div dangerouslySetInnerHTML={{__html:pdf.format(flyer.text_yellow_data)}} />
      </div>

      <div className={classes.maxHeightContainer}>
       <div className={classes.coloredText}> {t('ampelflyer.atOurSchool.red.title')}</div>
       <div dangerouslySetInnerHTML={{__html:pdf.format(flyer.text_red_data)}} />
      </div>

    </div>
   </div>
   <div className={classes.pageNumber}>5</div>
  </div>
 )
}

const Page3 = (props) => {
 const { flyer,pdf } = props
 const classes = useStyles()
 const { t } = useTranslation()


 return (
  <div className={classes.site}>
   <div className={`${classes.cutline} ${classes.cutlineTop}`} />
   <div className={classes.inner}>
    <Box className={classes.coloredText} fontSize="h5.fontSize">
     {t('ampelflyer.moreInformation.title')}
    </Box>
    <p>{t('ampelflyer.moreInformation.content')}</p>
    <Box className={classes.coloredText} fontSize="h5.fontSize">
     {t('ampelflyer.impress.title')}
    </Box>
    <p dangerouslySetInnerHTML={{__html:pdf.format(flyer.impress)}} />
   </div>
   <div className={classes.pageNumber}>6</div>
   <div className={`${classes.cutline} ${classes.cutlineBottom}`} />
  </div>
 )
}

export default Pdf
