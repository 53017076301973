import React, { useContext,useEffect } from 'react'
import {deburr,debounce} from 'lodash'
import { FixedSizeList as List } from 'react-window'
import { getSchoolShortname } from 'shared/functions/helper'

import {
  Grid,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  Paper,
  IconButton,
  ListItem,
  ListItemText
} from '@material-ui/core'
import { Search } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop:25
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 320,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
}));


const Location = observer(() => {
  const { location } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={location.open} onClose={location.handleClose} maxWidth={"md"}>
      {location.selected.length > 0 && <JoinButton />}
      <Container component="main" maxWidth="md">
       <SearchBar />
       {location.suggestions.length > 0  && <LocationList />}
      </Container>
    </Dialog>
  )
})

const getLocations = debounce((location,cb) => { location.getLocations(cb) },300)

const SearchBar = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { location } = useContext(UiStore)

  return (
    <Paper component="form" className={classes.searchRoot} elevation={0}>
      <InputBase
        autoFocus
        value={location.searchTerm}
        onChange={location.setSearchTerm}
        onKeyUp={getLocations.bind(this,location)}
        className={classes.searchInput}
        placeholder={t('core.location.searchPlaceholder')}
        inputProps={{ 'aria-label': t('core.location.searchPlaceholder') }}
      />
      <IconButton className={classes.searchIconButton} aria-label="search" onClick={getLocations.bind(this,location)}>
        <Search />
      </IconButton>
    </Paper>
  );
})

const JoinButton = observer(() => {
 const { location,notifier } = useContext(UiStore)
 const { user } = useContext(DataStore)
 const { t } = useTranslation()

 const requestJoinLocations = () => {
   location.requestJoinLocations(
     () => {
       user.getPendingRequests(
         () => { notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)  }
       )
     },
     () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
   )
   location.handleClose()
 }

 return (
    <Button
     variant="contained"
     color="primary"
     onClick={requestJoinLocations}>{t('core.location.requestJoin')}</Button>
 )
})

const LocationList = observer(() => {
  const { location } = useContext(UiStore)

  return (
     <List height={350} width={320} itemSize={66} itemCount={location.suggestions.length} itemData={location.suggestions}>
      {Row}
     </List>
  )
})

const Row = ({ data, index, style }) => (
  <LocationListItem  data={data[index]} style={style} />
)


const LocationListItem = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data,style } = props
  const { location,notifier } = useContext(UiStore)
  const { user } = useContext(DataStore)
  const shorten = getSchoolShortname(data.name)

  const address = data.strasse + ', ' + data.plz + ' ' + data.standort

  const toggleItem = () => {
    location.toggleItem(data.erz_key)
  }

  let styles = { ...style, background:(location.isSelected(data.erz_key) ? '#cfcfcf ' : '') }

  return (
   <ListItem button style={styles} onClick={toggleItem}>
    <ListItemText primary={data.name} secondary={address} />
   </ListItem>
  )
})

export const IamNotWorkingInASchoolOrKindergardenAndWantToParticipate = observer(() => {
  const { location,notifier } = useContext(UiStore)
  const { user } = useContext(DataStore)
  const { t } = useTranslation()
  const classes = useStyles()

  const requestJoinKibs = () => {
    location.requestJoinKibs(
      () => {
        user.getPendingRequests(
          () => { notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)  }
        )
      },
      () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
    )
    location.handleCloseForeignerDialog()
  }

  return (
    <Dialog open={location.openForeignerDialog} onClose={location.handleCloseForeignerDialog} maxWidth={"md"}>
     <DialogTitle id="alert-dialog-title">{t('core.location.kibs')}</DialogTitle>
      <DialogContent>
        <DialogContentText id={"fictional-school"}>
          {t('core.location.fictionalSchool')}<br />
          {t('core.location.fullUsage')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={location.handleCloseForeignerDialog} color="secondary">
         {t('core.general.cancel')}
        </Button>
        <Button onClick={requestJoinKibs} color="primary" autoFocus>
          {t('core.location.requestJoin')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})


export default Location
