import React, {useEffect,useState} from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import Ajax from 'api/ajax'

import IMG_NETWORK_BERN from 'media/img/networkbern.png'


import {
  Button,
  useMediaQuery,
  Typography,
  Divider
} from '@material-ui/core'
import { HowToReg,ArrowRight } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  networkBern:{
    position:'absolute',
    zIndex:-1
  },
  regBtn:{
   marginRight:theme.spacing(2),
  },
  btnColor:{

  },
  btnContainer:{
    display:'flex',
    justifyContent:'center',
  },
  btn:{
    width:75,
    height:75,
    borderRadius:'50%',
    color:'white',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'1.5rem',
    margin:theme.spacing(4),
    [theme.breakpoints.down('sm')]:{
      width:50,
      height:50,
      fontSize:'1rem'
    }
  },
  colorSMI:{
    backgroundImage: 'linear-gradient(to right, #990e9d, #9f0d9a, #a50c97, #aa0d94, #af0f91)'
  },
  colorSL:{
    backgroundImage: 'linear-gradient(to right, #c21185, #c81082, #ce107f, #d4117b, #d91378)'
  },
  colorLP:{
    backgroundImage: 'linear-gradient(to right, #f1156a, #f41568, #f81566, #fb1564, #fe1662)'
  },
  divider:{
    marginTop:theme.spacing(4),
    marginBottom:theme.spacing(4)
  },
  gotobtncontainer:{
    marginTop:theme.spacing(6)
  },
  registerText:{
    marginTop:theme.spacing(4)
  }
}));

const Banner = observer(() => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [team,setTeam] = useState([])

  const toSignup = () => history.push('/signup')
  const toServices = () => history.push('/dashboard')

  return (
    <section id="main" className="subscribe-area ptb-100" style={{background:'none'}}>
      <div className="container-fluid">
        <div className="subscribe-content">
        <h1>{t('core.landingpage.networking')}</h1>
        <Typography variant="h6" component="p">{t('core.landingpage.kibs')}</Typography>
        <Typography variant="h6" component="p">{t('core.landingpage.mission')}</Typography>
        <Divider className={classes.divider} />

        <div className="banner-btn" className={classes.gotobtncontainer}>
         <Button onClick={toSignup} variant="contained" className={`${classes.regBtn} ${classes.btnColor} phButton`}>
          {t('core.login.dontHaveAccount')}
         </Button>
         <Button onClick={toServices} variant="contained" className={`${classes.regBtn} ${classes.btnColor} phButton`}>
          {t('core.landingpage.goToServices')}
         </Button>
         <Typography variant="h6" className={classes.registerText} component="p">
          {t('core.landingpage.missionRegister')}
         </Typography>
        </div>
        </div>
      </div>
    </section>
  )
})



export default Banner
