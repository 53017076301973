import React, { useContext,useEffect } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from "react-router-dom"
import AnimateHeight from 'react-animate-height'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'
import { PendingsDialog } from './FreshStart'
import MemberList from './MemberList'
import Invite from './Invite'
import { ourAuthServices } from 'shared/functions/ourservices'


import IMG_ADD_PEOPLE from 'media/img/addPeople.png'
import IMG_DASHBOARD_SKELETON from 'media/img/dashboard.png'

import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  ButtonGroup,
  Button,
  Tooltip,
  Divider,
  Drawer,
  IconButton
} from '@material-ui/core'
import { AddCircleOutline,Home,Drafts,Traffic,MoreVert,Person,ExpandMore,ChevronLeftIcon,ChevronRight,ChevronLeft } from '@material-ui/icons'
import { amber,blue } from '@material-ui/core/colors'

const PANEL_COLOR = '#d2d2d2'
const DRAWER_WIDTH = 220

const useStyles = makeStyles(theme => ({
  root:{
   position:'relative',
   marginTop:theme.spacing(2),
  },
  card:{
   minHeight:140,
   cursor:'pointer',
   margin:'0 auto',
   margin:theme.spacing(2),
   display:'flex',
   textAlign:'center',
   [theme.breakpoints.down('sm')]: {
     width:'80%%',
   }
  },
  cardCenter:{
   display:'flex',
   flexDirection:'column',
   justifyContent:'center',
   alignItems:'center',
   textAlign:'center',
   height:'100%',
 },
 houseTitle:{
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
   height:'100%',
   whiteSpace:'nowrap',
   overflow:'hidden',
   textOverflow:'ellipsis',
   textAlign:'center',
   color:'white'
 },
 services:{
   position:'absolute',
   bottom:0,
   width:'100%',
   height:36,
   backgroundColor:'#e35633'
 },
 serviceButton:{
   width:'33%',
   color:'#fff'
 },
 schoolServicesPanel:{
   background:'inherit',
   marginTop:42,
   background:PANEL_COLOR
 },
 divider:{
   marginBottom:theme.spacing(2),
   marginTop:theme.spacing(2),
   color:'#ccc'
 },
 triangleContainer:{
   position:'absolute',
   width:'100%'
 },
 triangleUp:{
  width: 0,
	height: 0,
  margin:'0 auto',
	borderLeft: '10px solid transparent',
	borderRight: '10px solid transparent',
	borderBottom: '0px solid #ccc',
  position:'relative'
 },
 triangleEmpty:{
    position: 'absolute',
    top:4,
    left:-8,
    width:0,
    borderBottom:'solid 20px #fafafa',
    borderRight:'solid 8px transparent',
    borderLeft:'solid 8px transparent'
 },
 rel:{
   position:'relative'
 },
 media:{
   height:140
 },
 mediaSchooltech:{
   backgroundSize:'contain',
   objectFit:'contain',
 },
 addLocationBtn:{
   fontSize:'3rem',
   color:'#b2b2b2'
 },
 drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top:65,
    [theme.breakpoints.only('xs')]: {
      top:57
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
}));




const SchoolServices = observer(() => {
  const classes = useStyles()
  const { user,schoolPage,dashboard } = useContext(DataStore)
  const { schoolPageUI,dashboardUI } = useContext(UiStore)

  const isXs = useMediaQuery(theme => theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme => theme.breakpoints.only('md'))
  const isLg = useMediaQuery(theme => theme.breakpoints.only('lg'))

  const xs = 6
  const sm = 4
  const md = 2
  const lg = 2

  const panelInfos = (elementNumber,maxElements) => {
    let index = elementNumber
    elementNumber = elementNumber + 2
    const maxNumbersPerRow = 12
    let divider = 0
    if (isXs) { divider = xs }
    if (isSm) { divider = sm }
    if (isMd) { divider = md }
    if (isLg) { divider = lg }
    const elementsPerRow = maxNumbersPerRow / divider
    const isLastElement = elementNumber - 1 === maxElements

    const rowNumber = index + 1 < elementsPerRow ? 0 : Math.round(index / elementsPerRow)

    return {
      put:isLastElement ? true : elementNumber % elementsPerRow === 0,
      elementNumber,
      rowNumber
    }
  }

  useEffect(() => {
    return () => {
      dashboardUI.handleCloseMemberList()
    }
  })

  return (
   <div className={classes.root}>
    <Grid container>

    {user.pendings.length > 0 &&
     <Grid item xs={xs} sm={sm} md={md} lg={lg} >
       <PendingSchool bgColor={amber[500]}/>
       <PendingsDialog />
     </Grid>
    }

    {user.schools.length === 0 &&
     <Grid item xs={xs} sm={sm} md={md} lg={lg} key={'add-school'}>
      <AddSchool />
     </Grid>}

     {user.schools.map((s,i) => {
      const { put,elementNumber,rowNumber } = panelInfos(i,user.schools.length)
      return <>
       <Grid item xs={xs} sm={sm} md={md} lg={lg} key={'school-invited-'+i} className={classes.rel}>
        <JoinedSchool school={s} elementNumber={elementNumber} rowNumber={rowNumber}/>
       </Grid>

       {i + 1 === user.schools.length &&
         <Grid item xs={xs} sm={sm} md={md} lg={lg} key={'add-school'}>
          <AddSchool />
         </Grid>
       }

       {put &&
           <Grid item xs={12} sm={12} md={12} lg={12} key={'school-invited-panel-'+i}>
            <LocationDashboard rowNumber={rowNumber} school={s} />
           </Grid>
         }
        </>
     })}

    </Grid>
    <DrawerRight />
   </div>
  )
})

const DrawerRight = observer(() => {
  const classes = useStyles()
  const { schoolPage } = useContext(DataStore)
  const { school } = schoolPage
  const { dashboardUI } = useContext(UiStore)


  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={dashboardUI.showMemberlist}
      classes={{
        paper: classes.drawerPaper,
      }}
     >
     <div className={classes.drawerHeader}>
      <IconButton onClick={dashboardUI.handleCloseMemberList}>
       {dashboardUI.showMemberlist ? <ChevronRight /> : <ChevronLeft/>}
      </IconButton>
     </div>
     {dashboardUI.showMemberlist &&
      <>
       <Divider />
       <Invite />
       <MemberList member={school.member} requests={school.requests} />
      </>
     }
    </Drawer>
  )
})

const LocationDashboard = observer((props) => {
  const { schoolPageUI } = useContext(UiStore)
  const { dashboard } = useContext(DataStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const { rowNumber } = props
  const history = useHistory()
  const height = schoolPageUI.amIOpen(rowNumber)

  const { currSchool } = dashboard
  let services = ourAuthServices().map(s => {
    s.additionalClass = classes.mediaSchooltech
    return s
  })

  if (currSchool) {
    services[0].onClick = () => {
      schoolPageUI.setCurrentService(schoolPageUI.services.SCHOOL_TECH)
      history.push('/dashboard/schoolpage/'+currSchool.key)
    }
    services[1].onClick = () => {
      schoolPageUI.setCurrentService(schoolPageUI.services.AMPELFLYER)
      history.push('/dashboard/schoolpage/'+currSchool.key+'/ampelflyer')
    }
    services[2].onClick = () => {
      schoolPageUI.setCurrentService(schoolPageUI.services.SMICALC)
      history.push('/dashboard/schoolpage/'+currSchool.key+'/smicalc')
    }
  }


  return (
   <div className={`${classes.schoolServicesPanel} animated fadeIn`}>
    <AnimateHeight duration={ 250 } height={height}>
     <Grid container>
      {services.map((s,j) => {
        return <Grid item xs={12} sm={4} md={3} lg={3} key={'school-specific-service-'+j}>
         <Service
          media={s.img}
          title={s.title}
          text={s.text}
          onClick={s.onClick}
          additionalClass={s.additionalClass}
         />
        </Grid>
      })}
     </Grid>
    </AnimateHeight>
   </div>
  )
})

export const ServiceSmall = observer((props) => {
  let { img,onClick,styles,style,title,additionalClass,selected } = props
  const { schoolPageUI } = useContext(UiStore)
  const classes = useStyles()
  const { t } = useTranslation()


  return (
  <Tooltip title={t(title)} placement="right">
   <div onClick={onClick} className={styles} style={style}>
    <CardActionArea style={{background:selected?'#ddd':''}}>
      <CardMedia
        image={img}
        style={{height:60}}
        className={additionalClass ? additionalClass : ''}
      />
     </CardActionArea>
    </div>
   </Tooltip>
  )
})

const Service = observer((props) => {
  const { media,title,onClick,additionalClass } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
   <Card onClick={onClick} className={classes.card}>
    <CardActionArea>
      <CardMedia
        className={`${classes.media} ${additionalClass}`}
        image={media}
        title={t(title)}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {t(title)}
        </Typography>
      </CardContent>
    </CardActionArea>
   </Card>
  )
})

const AddSchool = observer(() => {
  const classes = useStyles()
  const { location } = useContext(UiStore)
  const { t } = useTranslation()

  return (
    <CustomCard
     onClick={location.handleOpen}
     noShadow={true}
     bgColor={'#fafafa'}
     component={
       <div className={classes.cardCenter}>
        <AddCircleOutline className={classes.addLocationBtn}/>
       </div>
     }
    />
  )
})



const JoinedSchool = observer((props) => {
  const { school,elementNumber,rowNumber } = props
  const { schoolPageUI,dashboardUI } = useContext(UiStore)
  const { schoolPage } = useContext(DataStore)
  const { dashboard } = useContext(DataStore)
  const classes = useStyles()
  const theme = useTheme()

  const onClick = () => {
    dashboard.setCurrentSchool(school)
    schoolPage.setId(school.key)
    const open = schoolPageUI.currentElementNumber === elementNumber && schoolPageUI.openSchoolServicesPanel === schoolPageUI.PANEL_HEIGHT
    if (!open) {
      schoolPage.getData(
         () => {
           dashboardUI.handleOpenMemberList()
           schoolPageUI.handleOpenSchoolServicesPanel(rowNumber,elementNumber)
         },
         () => {}
       )
    } else {
      dashboardUI.handleCloseMemberList()
      schoolPageUI.handleOpenSchoolServicesPanel(rowNumber,elementNumber)
    }
  }
  const iamSelected = schoolPageUI.currentElementNumber === elementNumber && schoolPageUI.openSchoolServicesPanel === schoolPageUI.PANEL_HEIGHT


  return (
   <>
    <CustomCard
     bgColor={iamSelected ? PANEL_COLOR : 'inherit'}
     onClick={onClick}
     component={
       <>
        <Home />
        <Typography variand="body1" component="p">{school.name}</Typography>
       </>
     }
    />
    {iamSelected &&
      <div className={`${classes.triangleContainer} animated headShake`} style={{left:schoolPageUI.trianglePos +'px'}}>
       <div className={classes.triangleUp}>
        <div className={classes.triangleEmpty} style={{borderBottomColor:PANEL_COLOR}}/>
       </div>
      </div>
    }
   </>
  )
})

const PendingSchool = observer((props) => {
  const classes = useStyles()
  const { location } = useContext(UiStore)
  const { bgColor } = props
  const { t } = useTranslation()

  return (
    <CustomCard
     bgColor={bgColor}
     onClick={location.handleOpenPendingDialog}
     component={
       <div className={classes.cardCenter}>
        <Drafts />
        <Typography variand="body1" component="p">{t('core.dashboard.freshStart.pendings')}</Typography>

       </div>
     }
    />
  )
})

const CustomCard  = observer((props) => {
  const { component,onClick,bgColor,noShadow} = props
  const { schoolPageUI } = useContext(UiStore)
  const classes = useStyles()


  return (
    <Card
     className={classes.card}
     onClick={onClick}
     style={{backgroundColor:bgColor}}
     elevation={noShadow ? 0 : 1}
    >
     <CardActionArea>
       <CardContent>
         {component}
       </CardContent>
     </CardActionArea>
   </Card>
  )
})


export default SchoolServices
