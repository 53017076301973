import React, {useEffect,useState} from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import Ajax from 'api/ajax'

import IMG_NETWORK_BERN from 'media/img/networkbern.png'
import IMG_BG from "./assets/img/main-banner-bg-1.jpg"


import {
  Button,
  useMediaQuery,
  Typography,
  Divider
} from '@material-ui/core'
import { HowToReg,ArrowRight } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  networkBern:{
    position:'absolute',
    zIndex:-1
  },
  regBtn:{
   marginRight:theme.spacing(2),
  },
  btnColor:{
    background:'linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656) !important',
  },
  btnContainer:{
    display:'flex',
    justifyContent:'center',
  },
  btn:{
    width:75,
    height:75,
    borderRadius:'50%',
    color:'white',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'1.5rem',
    margin:theme.spacing(4),
    [theme.breakpoints.down('sm')]:{
      width:50,
      height:50,
      fontSize:'1rem'
    }
  },
  colorSMI:{
    backgroundImage: 'linear-gradient(to right, #990e9d, #9f0d9a, #a50c97, #aa0d94, #af0f91)'
  },
  colorSL:{
    backgroundImage: 'linear-gradient(to right, #c21185, #c81082, #ce107f, #d4117b, #d91378)'
  },
  colorLP:{
    backgroundImage: 'linear-gradient(to right, #f1156a, #f41568, #f81566, #fb1564, #fe1662)'
  },
  divider:{
    marginTop:theme.spacing(4),
    marginBottom:theme.spacing(4)
  },
  gotobtncontainer:{
    marginTop:theme.spacing(6)
  }
}));



const Banner = observer(() => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [team,setTeam] = useState([])

  const toSignup = () => history.push('/signup')
  const toServices = () => history.push('/dashboard')

  return (
   <div className="main-banner">
    <div className="main-banner-item" style={{ backgroundImage:"url("+IMG_BG+")"}}>
     <div className="d-table">
      <div className="d-table-cell">
       <div className="container-fluid">
        <div className="row align-items-center" style={{marginLeft:0,marginRight:0,padding:5}}>
         <img src={IMG_NETWORK_BERN} className={classes.networkBern}/>
          <div className="main-banner-content">
            <h1>{t('core.landingpage.networking')}</h1>
            <Typography variant="h6" component="p">{t('core.landingpage.kibs')}</Typography>
            <Divider className={classes.divider} />

            <div className={classes.btnContainer}>
             <div className={`${classes.btn} ${classes.colorSMI}`}>{t('core.landingpage.SMI')}</div>
             <div className={`${classes.btn} ${classes.colorSL}`}>{t('core.landingpage.SL')}</div>
             <div className={`${classes.btn} ${classes.colorLP}`}>{t('core.landingpage.LP')}</div>
            </div>

            <Typography variant="body1" component="p">{t('core.landingpage.mission')}</Typography>

            <div className="banner-btn" className={classes.gotobtncontainer}>
             <Button onClick={toSignup} variant="contained" color="primary" className={`${classes.regBtn} phButton`}>
              <HowToReg />
              {t('core.login.dontHaveAccount')}
             </Button>
             <Button onClick={toServices} variant="contained" color="primary" className={`${classes.regBtn} phButton`}>
              <ArrowRight />
              {t('core.landingpage.goToServices')}
             </Button>
            </div>

           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
    </div>
  )
})


const Banner2 = observer(() => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [team,setTeam] = useState([])

  const toSignup = () => history.push('/signup')
  const toServices = () => history.push('/dashboard')

  return (
    <div className="main-banner">
      <div className="main-banner-item">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6" style={{paddingLeft:0}}>
                  <div className="main-banner-content">
                    <h1>{t('core.landingpage.kibs')}</h1>
                    <p>{t('core.landingpage.mission')}</p>
                    <div className="banner-btn">
                     <Button onClick={toSignup} variant="contained" color="primary" className={`${classes.regBtn} ${classes.btnColor}`}>
                      <HowToReg />
                      {t('core.login.dontHaveAccount')}
                     </Button>
                     <Button onClick={toServices} variant="contained" color="primary" className={`${classes.regBtn} ${classes.btnColor}`}>
                      {t('core.landingpage.toServices')}
                      <ArrowRight />
                     </Button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" style={{paddingLeft:0}}>
                 <div className="main-banner-image"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Banner
