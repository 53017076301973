import React, { useContext,useEffect,useState } from "react"
import ReactDOM from "react-dom"

import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { observer,useObserver } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import UiStore from './stores/ui'
import DataStore from './stores/data'
import SchoolPageDataStore from 'core/stores/data'
import GlobaUI from 'core/stores/ui'
import history from 'history.js'
import TechData from './techdata'
import SchoolNumbers from 'services/schoolnumbers/index'
import SchoolDocuments from 'services/schooldocuments/index'


import {
 Button,
 TextField,
 Paper,
 Typography,
 ExpansionPanel,
 ExpansionPanelDetails,
 ExpansionPanelSummary,
 ClickAwayListener,
 Table,
 TableContainer,
 TableCell,
 TableRow,
 TableBody,
 IconButton,
 Tooltip,
 Grid,
 useMediaQuery
} from '@material-ui/core/'

import {
 ExpandMore,ExpandLess,Phone,DeleteOutline,AddCircleOutline
} from '@material-ui/icons'



const useStyles = makeStyles(theme => ({
 root:{
   position:'relative',
   width:'100%',
   maxWidth:900,
   display:'flex',
   alignItems:'center',
   flexDirection:'column',
   margin:'0 auto',
   minWidth:300,
   [theme.breakpoints.up('sm')]: {
     minWidth:500
   }
 },
 paper:{
   padding:theme.spacing(2),
   display:'flex',
   alignItems:'center',
   position:'relative',
   minWidth:300,
   minHeight:88,
   [theme.breakpoints.up('sm')]: {
     minWidth:500
   },
   cursor:'pointer',
   marginBottom:theme.spacing(1)
 },
 removeHighlights:{
   boxShadow:'none',
   borderBottom:'1px solid #ccc'
 },
 isSelected:{
   borderLeft:theme.spacing(0.75) + 'px solid #4285f4'
 },
 field:{
   width:'50%'
 },
 spaceRight:{
   paddingRight:theme.spacing(2)
 },
 columnIcon:{
   width:72
 },
 columnFields:{

 },
 columnRemove:{
   width:10
 },
 columnAdd:{
   width:10
 },
 saveBtn:{
   maxWidth:500,
   marginTop:theme.spacing(1)
 },
 headlineContainer:{
   marginTop:theme.spacing(4),
   marginBottom:theme.spacing(4),
   alignSelf:'flex-start'
 },
 description:{
   width:'100%',
   display:'flex',
   alignItems:'center'
 },
 heading: {
   fontSize: theme.typography.pxToRem(15),
   flexBasis: '55%',
   flexShrink: 0,
 },
 secondaryHeading: {
   fontSize: theme.typography.pxToRem(15),
   color: theme.palette.text.secondary,
 },
}))

const Headline = (props) => {
 const classes = useStyles()
 return (
   <div className={classes.headlineContainer}>
    <Typography variant="h4">{props.title}</Typography>
   </div>
  )
}

const SchoolTech = observer((props) => {
  const classes = useStyles()
  const data = useContext(DataStore)
  const { t } = useTranslation()

  useEffect(() => {
    const schoolId = props.schoolId
    if (schoolId) {
      data.getSchooltechData(
        schoolId,
        () => {},
        () => {}
      )
    }
  },[])

  return (
   <div className={classes.root}>
    <Headline title={t('schooltech.headlines.contact')} />
    <ChangeName />
    <ChangeStreet />
    <ChangePhoneNumbers />
    <SavePart />
    <Headline title={t('schooltech.internetConnection')} />
    <TechData />
    <SavePart />
    <Headline title={t('schooltech.headlines.schoolnumbers')} />
    <SchoolNumbers {...props} />
   </div>
  )
})

const SavePart = observer(() => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const { schoolPage } = useContext(SchoolPageDataStore)
  const { notifier } = useContext(GlobaUI)
  const classes = useStyles()

  const save = () => {
    data.update(
      (res) => {
        notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
        schoolPage.school.setName(data.name)
      },
      () => {
        notifier.push(t('core.notifier.5xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
    <Button color="primary" className={classes.saveBtn} fullWidth variant="outlined" onClick={save}> {t('core.general.save')} </Button>
  )
})

const ChangeName = observer(() => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  const onChange = (e) => { data.setName(e.target.value) }

  return (
    <ConfigItem
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.name')}</Typography>
        <Typography className={classes.secondaryHeading}>{data.name}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       placeholder={t('schooltech.name')}
       value={data.name}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const ChangeStreet = observer(() => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  const onChange = (e) => { data.setStreet(e.target.value) }

  return (
    <ConfigItem
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.street')}</Typography>
        <Typography className={classes.secondaryHeading}>{data.strasse}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       placeholder={t('schooltech.street')}
       value={data.strasse}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const ChangeLocation = observer(() => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  const onChange = (e) => { data.setLocation(e.target.value) }

  return (
    <ConfigItem
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.location')}</Typography>
        <Typography className={classes.secondaryHeading}>{data.standort}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       value={data.standort}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const ChangePhoneNumbers = observer(() => {
  const classes = useStyles()
  const data = useContext(DataStore)

  return (
    <ConfigItem
     expandFirst={true}
     view={
      <TableContainer>
       <Table size="small">
        <TableBody>

         {data.phones.map((d,i) => {
          return !d.mustBeDeleted
          ?
          <TableRow key={'school-phone-'+i}>
           <TableCell className={classes.columnIcon}><Phone /></TableCell>
           <TableCell>
            <Typography> {d.telefon} / {d.identifier}</Typography>
           </TableCell>
          </TableRow>
          :
          null
         })}

        </TableBody>
       </Table>
      </TableContainer>
     }
     edit={
       <ChangePhoneNumbersTable />
     }
    />
  )
})

const ChangePhoneNumbersTable = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const data = useContext(DataStore)

  return useObserver(() => (
   <TableContainer>
    <Table size="small">
     <TableBody>
      {data.phones.map((d,i) => {
        if (!d.mustBeDeleted) {
          return <ChangePhoneNumbersRow
                  index={i}
                  isLast={(i+1===data.phones.length)}
                  d={d}
                  key={'edit-phone-tablerow-'+i}
                  add={data.addPhone}
                  remove={data.removePhone}
                 />
        } else return null
       })
      }
     </TableBody>
    </Table>
   </TableContainer>
 ))
})

const ChangePhoneNumbersRow = observer((props) => {
  const classes = useStyles()
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.down('sm'))


  let { index,isLast,d,add,remove } = props
  const { t } = useTranslation()

  const onChangePhone = (e) => {
    d.changePhone(e.target.value)
  }
  const onChangeIdentifier = (e) => {
    d.changeIdentifier(e.target.value)
  }
  const onRemove = () => {
    remove(d.clientId)
  }

  return (
    !upSm
    ?
    <TableRow>
     <TableCell className={classes.columnIcon}><Phone /></TableCell>
     <TableCell className={classes.columnFields}>
       <TextField
        id={"edit-phone-"+index}
        label={t('core.user.phone')}
        value={d.telefon}
        className={`${classes.field} ${classes.spaceRight}`}
        onChange={onChangePhone}
       />
       <TextField
        id={"edit-phone-label-"+index}
        label={t('core.general.label')}
        value={d.identifier}
        className={classes.field}
        onChange={onChangeIdentifier}
       />
     </TableCell>
     <TableCell className={classes.columnRemove}>
       <IconButton aria-label="close" className={classes.action} onClick={onRemove}>
        <DeleteOutline />
       </IconButton>
     </TableCell>
     <TableCell className={classes.columnAdd}>
       {isLast &&
        <Tooltip title={t('core.general.add')} placement="bottom">
         <IconButton aria-label="add" className={classes.action} onClick={add}>
          <AddCircleOutline className={classes.add}/>
         </IconButton>
        </Tooltip>
       }
     </TableCell>
   </TableRow>
   :
   <Grid container>
    <Grid item xs={12}>
      <TextField
       id={"edit-phone-"+index}
       label={t('core.user.phone')}
       value={d.telefon}
       onChange={onChangePhone}
       fullWidth
      />
     </Grid>
     <Grid item xs={12}>
      <TextField
       id={"edit-phone-label-"+index}
       label={t('core.general.label')}
       value={d.identifier}
       onChange={onChangeIdentifier}
       fullWidth
      />
    </Grid>
    <Grid item xs={12}>
     <IconButton aria-label="close" className={classes.action} onClick={onRemove}>
      <DeleteOutline />
     </IconButton>
     {isLast &&
      <Tooltip title={t('core.general.add')} placement="bottom">
       <IconButton aria-label="add" className={classes.action} onClick={add}>
        <AddCircleOutline className={classes.add}/>
       </IconButton>
      </Tooltip>
     }
    </Grid>
   </Grid>
  )
})

export const ConfigItem = observer((props) => {
  const classes = useStyles()
  const { view,edit,dontHighlight,expandFirst } = props
  const [expanded,setExpanded] = useState(expandFirst?expandFirst:false)

  const open = () => { setExpanded(true) }
  const close = () => { setExpanded(false) }

  let classNames = classes.paper
  if (dontHighlight) classNames += ' ' + classes.removeHighlights
  if (expanded && !dontHighlight) classNames += ' ' + classes.isSelected

  return (
   <ClickAwayListener onClickAway={close}>
    <Paper onClick={open} className={classNames}>
     {expanded
      ?
      edit
      :
      view
     }
    </Paper>
   </ClickAwayListener>
 )
})



export default SchoolTech
