import React from 'react'
import './style.css'
import { useTranslation } from 'react-i18next'

export const ErrorPage404 = () => {
  return <Error code={404} />
}

export const ErrorPage403 = () => {
  return <Error code={403} />
}

const Error = (props) => {
  const { code } = props
  const { t } = useTranslation()
  return (
    <section className="wrapper">
        <div className="container">
          <div id="scene" className="scene" data-hover-only="false">
            <div className="circle" data-depth="1.2" />
            <div className="one" data-depth="0.9">
              <div className="content">
                <span className="piece" />
                <span className="piece" />
                <span className="piece" />
              </div>
            </div>
            <p className="p404" data-depth="0.50">{code}</p>
            <p className="p404" data-depth="0.10">{code}</p>
          </div>
          <div className="text">
            <article>
              <p>{t('core.errorpages.'+code)}</p>
            </article>
          </div>
        </div>
      </section>
  )
}
