import React,{ useEffect,useState,useContext,Component } from 'react'
import { Route,Redirect } from 'react-router'
import Ajax from 'api/ajax'
import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'
import { observer } from 'mobx-react-lite'

const PublicRoute = observer(({ component: Component, ...rest }) => {
  const ui = useContext(UiStore)
  useEffect(() => { ui.setLoading(false) },[])
  useEffect(() => { ui.setPageTitle(rest.title) })
  window.kibsportal.routes.setVisibility(window.kibsportal.routes.VISIBILITY_PUBLIC)
  ui.triggerLayout()
  return (
   <Route {...rest} render={(props) => (
     <Component {...props} />
    )}
   />
 )
})


export default PublicRoute
