import IMG_AMPELSYSTEM from 'media/img/ampelsystem.png'
import IMG_AVATARS from 'media/img/avatars.png'
import IMG_BINARYTRICK from 'media/img/binarytrick.png'
import IMG_CHATS from 'media/img/chats.png'
import IMG_FAKENEWS from 'media/img/fakenews.jpg'
import IMG_FAKEWEBSITES from 'media/img/fakewebsites.png'
import IMG_GAMEKULTUR from 'media/img/gamekulturinderschule.png'
import IMG_INTERNETANSCHLUSS from 'media/img/internetanschluss.png'
import IMG_LASERCUTTEN from 'media/img/lasercutteninderschule.png'
import IMG_MEDIENCOACHES from 'media/img/mediencoaches.jpg'
import IMG_DATENSCHUTZINDERSCHULE from 'media/img/datenschutzinderschule.jpeg'
import IMG_PLANUNGSWERKZEUG from 'media/img/planungswerkzeug.png'
import IMG_PLOTTEN from 'media/img/plotteninderschule.png'
import IMG_PORNOUNDSCHULE from 'media/img/pornoundschule.png'
import IMG_SCRATCH_WERKZEUGKASTEN from 'media/img/scratchwerkzeugkasten.png'
import IMG_SPIELEINDERSCHULE from 'media/img/spieleinderschule.png'
import IMG_STORIES360 from 'media/img/stories360.png'
import IMG_UNTERSTUETZUNG from 'media/img/unterstuetzung.png'
import IMG_CHROMEPROJEKT from 'media/img/chromeprojekt.png'
import IMG_STORIESINDERSCHULE from 'media/img/storiesinderschule.png'
import IMG_SMITAGUNG from 'media/img/tagungen/smitagung.png'
import IMG_SWISSCOM from 'media/img/swisscom-logo.png'
import IMG_DICE from 'media/img/dice.png'
import IMG_AMPELFLYER from 'media/img/ampelflyer.png'
import IMG_SMICALC from 'media/img/smicalc.png'
import IMG_SCHOOL from 'media/img/school.png'
import IMG_AUDIO_DRIVE from 'media/img/AudioDrive.png'
import IMG_SENSIBELLE from "media/img/sensibelle.png"
import IMG_REDIRECTER from 'media/img/redirecter.png'
import IMG_CCGAME from 'media/img/ccgame.png'
import IMG_NETSFETZ from 'media/img/netsfetz.png'
import IMG_KOMPASS from 'media/img/kompass.jpg'
import IMG_HANDLUNGSFELDER from 'media/img/handlungsfelder.png'
import IMG_GAMEREDESIGN from 'media/img/gameredesign.png'

import history from 'history.js'

const URLS = {
  AUDIODRIVE: '/audiodrive',
  AMPELSYSTEM: '/datenschutz/ampelsystem',
  AVATARS: 'https://avatars.kibs.ch',
  BINARYTRICK: 'https://binaertrick.kibs.ch',
  CHATS: '/chats',
  CHROMEPROJEKT: 'https://chromeprojekt.ch',
  DATENSCHUTZINDERSCHULE: '/catalog/datenschutzinderschule',
  FAKENEWS: '/catalog/fakenews',
  FAKEWEBSITES: '/fakewebsites',
  GAMEKULTUR: 'https://gamekulturinderschule.ch',
  GAMEREDESIGN: 'https://informatik4school.weebly.com/',
  HANDLUNGSFELDER: 'https://sites.google.com/view/prozessbegleitung/handlungsfelder-als-%C3%BCberblick',
  INTERNETANSCHLUSS: '/internetanschluss',
  LASERCUTTEN: 'https://lasercutteninderschule.ch',
  MEDIENCOACHES: '/catalog/mediencoaches',
  PLANUNGSWERKZEUG: 'https://planungswerkzeug.kibs.ch',
  PLOTTENINDERSCHULE: 'https://plotteninderschule.ch',
  PORNOUNDSCHULE: '/catalog/pornografieundschule',
  SCRATCHKURSE: 'https://scratch.kibs.ch',
  SENSIBELLE: "https://sensibelle.ch",
  SPIELEINDERSCHULE: 'https://spieleinderschule.org',
  STORIES360: 'https://stories360.org',
  UNTERSTUETZUNG: '/unterstützung',
  STORIESINDERSCHULE: 'https://storiesinderschule.ch',
  SMITAGUNG: '/tagungen',
  SAI: '/sai',
  DICE: 'https://dice.spieleinderschule.org',
  REDIRECTER: '/redirecter',
  CCGAME: 'https://www.ccspiel.ch',
  NETSFETZ: 'https://netsfetz.ch',
  KOMPASS: '/kompass'
}


function makeAGoto(url) {
  if (url.startsWith('/')) {
    return () => { history.push(url) }
  } else {
    return () => { window.open(url, "_blank") }
  }
}

export function ourAuthServices() {
  return [
    {
      img: IMG_SCHOOL,
      title: 'core.dashboard.coreData',
      text: 'core.landingpage.service_0.text',
    },
    {
      img: IMG_AMPELFLYER,
      title: 'ampelflyer.title',
      text: 'ampelflyer.heading1',
    },
    {
      img: IMG_SMICALC,
      title: 'smicalc.title',
      text: 'smicalc.content4',
    }
  ]
}
export function ourServices() {
  return [
    {
      img: IMG_SWISSCOM,
      title: 'core.landingpage.service_20.title',
      text: 'core.landingpage.service_20.text',
      url: URLS.SAI,
      goTo: makeAGoto(URLS.SAI)
    },

    {
      img: IMG_AMPELSYSTEM,
      title: 'core.landingpage.service_0.title',
      text: 'core.landingpage.service_0.text',
      url: URLS.AMPELSYSTEM,
      goTo: makeAGoto(URLS.AMPELSYSTEM)
    },
    {
      img: IMG_NETSFETZ,
      title: 'core.landingpage.service_28.title',
      text: 'core.landingpage.service_28.text',
      url: URLS.NETSFETZ,
      goTo: makeAGoto(URLS.NETSFETZ)
    },
    {
      img: IMG_KOMPASS,
      title: 'core.landingpage.service_29.title',
      text: 'core.landingpage.service_29.text',
      url: URLS.KOMPASS,
      goTo: makeAGoto(URLS.KOMPASS)
    },
    {
      img: IMG_GAMEREDESIGN,
      title: 'core.landingpage.service_31.title',
      text: 'core.landingpage.service_31.text',
      url: URLS.GAMEREDESIGN,
      goTo: makeAGoto(URLS.GAMEREDESIGN)
    },
    {
      img: IMG_HANDLUNGSFELDER,
      title: 'core.landingpage.service_30.title',
      text: 'core.landingpage.service_30.text',
      url: URLS.HANDLUNGSFELDER,
      goTo: makeAGoto(URLS.HANDLUNGSFELDER)
    },
    {
      img: IMG_CCGAME,
      title: 'core.landingpage.service_27.title',
      text: 'core.landingpage.service_27.text',
      url: URLS.CCGAME,
      goTo: makeAGoto(URLS.CCGAME)
    },
    {
      img: IMG_REDIRECTER,
      title: 'core.landingpage.service_26.title',
      text: 'core.landingpage.service_26.text',
      url: URLS.REDIRECTER,
      goTo: makeAGoto(URLS.REDIRECTER)
    },
    {
      img: IMG_AUDIO_DRIVE,
      title: 'core.landingpage.service_24.title',
      text: 'core.landingpage.service_24.text',
      url: URLS.AUDIODRIVE,
      goTo: makeAGoto(URLS.AUDIODRIVE)
    },
    {
      img: IMG_AVATARS,
      title: 'core.landingpage.service_1.title',
      text: 'core.landingpage.service_1.text',
      url: URLS.AVATARS,
      goTo: makeAGoto(URLS.AVATARS)
    },
    {
      img: IMG_BINARYTRICK,
      title: 'core.landingpage.service_18.title',
      text: 'core.landingpage.service_18.text',
      url: URLS.BINARYTRICK,
      goTo: makeAGoto(URLS.BINARYTRICK)
    },
    {
      img: IMG_CHATS,
      title: 'core.landingpage.service_2.title',
      text: 'core.landingpage.service_2.text',
      url: URLS.CHATS,
      goTo: makeAGoto(URLS.CHATS)
    },
    {
      img: IMG_CHROMEPROJEKT,
      title: 'core.landingpage.service_9.title',
      text: 'core.landingpage.service_9.text',
      url: URLS.CHROMEPROJEKT,
      goTo: makeAGoto(URLS.CHROMEPROJEKT)
    },
    {
      img: IMG_DATENSCHUTZINDERSCHULE,
      title: 'core.landingpage.service_21.title',
      text: 'core.landingpage.service_21.text',
      url: URLS.DATENSCHUTZINDERSCHULE,
      goTo: makeAGoto(URLS.DATENSCHUTZINDERSCHULE)
    },
    {
      img: IMG_SENSIBELLE,
      title: 'core.landingpage.service_25.title',
      text: 'core.landingpage.service_25.text',
      url: URLS.SENSIBELLE,
      goTo: makeAGoto(URLS.SENSIBELLE)
    },
    {
      img: IMG_STORIESINDERSCHULE,
      title: 'core.landingpage.service_6.title',
      text: 'core.landingpage.service_6.text',
      url: URLS.STORIESINDERSCHULE,
      goTo: makeAGoto(URLS.STORIESINDERSCHULE)
    },
    {
      img: IMG_UNTERSTUETZUNG,
      title: 'core.landingpage.service_10.title',
      text: 'core.landingpage.service_10.text',
      url: URLS.UNTERSTUETZUNG,
      goTo: makeAGoto(URLS.UNTERSTUETZUNG)
    },
    {
      img: IMG_SMITAGUNG,
      title: 'core.landingpage.service_19.title',
      text: 'core.landingpage.service_19.text',
      url: URLS.SMITAGUNG,
      goTo: makeAGoto(URLS.SMITAGUNG)
    },
    {
      img: IMG_FAKENEWS,
      title: 'core.landingpage.service_3.title',
      text: 'core.landingpage.service_3.text',
      url: URLS.FAKENEWS,
      goTo: makeAGoto(URLS.FAKENEWS)
    },
    {
      img: IMG_FAKEWEBSITES,
      title: 'core.landingpage.service_11.title',
      text: 'core.landingpage.service_11.text',
      url: URLS.FAKEWEBSITES,
      goTo: makeAGoto(URLS.FAKEWEBSITES)
    },
    {
      img: IMG_GAMEKULTUR,
      title: 'core.landingpage.service_7.title',
      text: 'core.landingpage.service_7.text',
      url: URLS.GAMEKULTUR,
      goTo: makeAGoto(URLS.GAMEKULTUR)
    },
    {
      img: IMG_INTERNETANSCHLUSS,
      title: 'core.landingpage.service_4.title',
      text: 'core.landingpage.service_4.text',
      url: URLS.INTERNETANSCHLUSS,
      goTo: makeAGoto(URLS.INTERNETANSCHLUSS)
    },
    {
      img: IMG_LASERCUTTEN,
      title: 'core.landingpage.service_12.title',
      text: 'core.landingpage.service_12.text',
      url: URLS.LASERCUTTEN,
      goTo: makeAGoto(URLS.LASERCUTTEN)
    },
    {
      img: IMG_MEDIENCOACHES,
      title: 'core.landingpage.service_13.title',
      text: 'core.landingpage.service_13.text',
      url: URLS.MEDIENCOACHES,
      goTo: makeAGoto(URLS.MEDIENCOACHES)
    },
    {
      img: IMG_PLANUNGSWERKZEUG,
      title: 'core.landingpage.service_5.title',
      text: 'core.landingpage.service_5.text',
      url: URLS.PLANUNGSWERKZEUG,
      goTo: makeAGoto(URLS.PLANUNGSWERKZEUG)
    },
    {
      img: IMG_PLOTTEN,
      title: 'core.landingpage.service_14.title',
      text: 'core.landingpage.service_14.text',
      url: URLS.PLOTTENINDERSCHULE,
      goTo: makeAGoto(URLS.PLOTTENINDERSCHULE)
    },
    {
      img: IMG_PORNOUNDSCHULE,
      title: 'core.landingpage.service_22.title',
      text: 'core.landingpage.service_22.text',
      url: URLS.PORNOUNDSCHULE,
      goTo: makeAGoto(URLS.PORNOUNDSCHULE)
    },
    {
      img: IMG_SCRATCH_WERKZEUGKASTEN,
      title: 'core.landingpage.service_15.title',
      text: 'core.landingpage.service_15.text',
      url: URLS.SCRATCHKURSE,
      goTo: makeAGoto(URLS.SCRATCHKURSE)
    },
    {
      img: IMG_SPIELEINDERSCHULE,
      title: 'core.landingpage.service_16.title',
      text: 'core.landingpage.service_16.text',
      url: URLS.SPIELEINDERSCHULE,
      goTo: makeAGoto(URLS.SPIELEINDERSCHULE)
    },
    {
      img: IMG_DICE,
      title: 'core.landingpage.service_23.title',
      text: 'core.landingpage.service_23.text',
      url: URLS.DICE,
      goTo: makeAGoto(URLS.DICE)
    }
  ]
}
