import React, { useCallback,useEffect,useContext,useRef } from 'react'

import Button from '@material-ui/core/Button'

import GoogleLogo from 'media/img/googleLogo.svg'
import OfficeLogo from 'media/img/office365Logo.png'
import { useTranslation } from 'react-i18next'
import history from 'history.js'

import { makeStyles } from '@material-ui/core/styles'
import { useObserver,observer } from 'mobx-react-lite'
import PopoutWindow from 'react-popout'
import queryString from 'query-string'

import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'
import './style.css'


const useStyles = makeStyles(theme => ({
  googleLogo: {
    width: 30,
    margin: theme.spacing(1),
  },
  officeLogo: {
    width: 30,
    margin: theme.spacing(1),
  },
  spacing:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  hrSpace: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  frame:{
    width:'100%',
    height:'auto',
    minHeight:'90vh'
  }
}))

const OAuthView = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { oauth2Dialog,notifier } = useContext(UiStore)
  const { user } = useContext(DataStore)
  const popoutWindow = useRef(null)
  const { callback } = props

  const openFrame = link => () => {
    link = 'https://' + window.location.host + link
    oauth2Dialog.handleClose()
    oauth2Dialog.setLink(link)
    oauth2Dialog.handleOpen()
  }
  const onError = () => {
    notifier.push(t('core.notifier.oauthError'),notifier.variants.ERROR)
    oauth2Dialog.handleClose()
  }
  const title = oauth2Dialog.isGoogle ? t('core.login.googleSignIn') : t('core.login.office365SignIn')
  const ref = useRef(false)

  useEffect(() => {
    if (!ref.current) {
      window.addEventListener('message',(e) => {
        if (e.data) {
          if (e.data.msg && e.data.msg === 'oauthRedirectSuccess') {
            user.set(e.data.user)
            //oauth2Dialog.handleClose()

            if (callback) {
              callback()
            } else {
              const params = queryString.parse(window.location.search)
              if (params && params.redirect_uri && params.redirect_uri !== '[object Object]') {
                history.push(params.redirect_uri)
              }
              else {
                history.push('/dashboard')
              }
            }
            notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
          }
        }
      })
      ref.current = true
    }
  },[])


  return useObserver(() => (
   <>
    <Button variant="outlined" fullWidth className={classes.spacing} onClick={openFrame('/login/google')}>
     <img src={GoogleLogo} className={classes.googleLogo} alt=""/>
     {t('core.login.googleSignIn')}
    </Button>
    <Button variant="outlined" fullWidth className={classes.spacing} onClick={openFrame('/login/o365')}>
     <img src={OfficeLogo} className={classes.officeLogo} alt=""/>
     {t('core.login.office365SignIn')}
    </Button>
    <hr className={`hr-text ${classes.hrSpace}`} data-content={t('core.login.or').toUpperCase()} />
    {oauth2Dialog.open &&
      <PopoutWindow
       ref={popoutWindow}
       url={oauth2Dialog.link}
       title={title}
       options={{height:500}}
       onClosing={oauth2Dialog.handleClose}
       onError={onError}
      />
    }

   </>
  ))
}

export const OAuthReceiver = () => {
  const { user } = useContext(DataStore)

  useEffect(() => {
    let u = new URL(window.location).searchParams.get('user')
    if (u) {
     window.opener.postMessage({msg:'oauthRedirectSuccess',user:JSON.parse(u)},window.opener.location)
    }
  },[user])
  return (
    <div> Success </div>
  )
}


export default OAuthView
