import 'date-fns'
import React, { useContext,useEffect,useState } from "react"
import ReactDOM from "react-dom"

import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { useObserver,observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  DateTimePicker
} from '@material-ui/pickers'
import deLocale from "date-fns/locale/de"
import moment from 'moment'
import Countdown from './countdown'

import 'ext/animate.min.css'

import GlobalUI from 'core/stores/ui'
import UiStore from './stores/ui'
import DataStore from './stores/data'
import {
  Typography,
  Container,
  Grid,
  Chip,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  ButtonGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core/'

import {
  Settings as SettingsIcon,VpnKey,Visibility,ExitToApp,Description
} from '@material-ui/icons'

import IMG_FAVICON from 'media/img/phishing/favicon.png'
import IMG_BACKGROUND from 'media/img/phishing/Phishing_Background_evil.png'
import IMG_FRAME_RIGHT from 'media/img/phishing/Phishing_Rahmen_vert.png'
import IMG_WELCOME from 'media/img/phishing/Phishing_Welcome.png'
import IMG_PREPERATION_1 from 'media/img/phishing/Phishing_Preparation1.png'
import IMG_PREPERATION_2 from 'media/img/phishing/Phishing_Preparation2.png'
import IMG_FINISHED from 'media/img/phishing/Phishing_Finished.png'
import IMG_IFRAME_LOADING from 'media/img/phishing/iframe-loading.gif'

const THEME_COLOR = 'rgb(240,255,255)'
const THEME_COLOR_SECOND = '#d88f8c'
const FONT_FAMILY = "'Nerko One', cursive;"
moment.locale('de')

const useStyles = makeStyles(theme => ({
 root:{
   backgroundImage:'url('+IMG_BACKGROUND+')',
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
   width:'100%',
   height:'100vh',
   overflowX:'hidden',
 },
 frameBottom:{
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
   width:'100%',
   height:'100vh',
   overflowX:'hidden',
   position:'absolute'
 },
 frameRight:{
   backgroundImage:'url('+IMG_FRAME_RIGHT+')',
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
   width:'100%',
   height:'100vh',
   overflowX:'hidden',
   position:'absolute'
 },
 frameAvatar:{
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
   width:'100%',
   height:'100vh',
   overflowX:'hidden',
   position:'absolute'
 },
 main:{
   position:'absolute',
   width:'100%',
   height:'100vh',
   zIndex:1
 },
 input:{
    width: '85%',
    display: 'flex',
    padding: 15,
    backgroundColor: THEME_COLOR,
    color: '#111',
    fontSize: 15,
    boxShadow: '3px 1px 0px 2px, 0px -1px 0px 2px rgba(0, 0, 0, 0.62)',
    height: 58,
    transform: 'skew(-3deg, 1deg)',
    letterSpacing: 2,
    outline:'none',
    margin:'0 auto'
  },
  content:{
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
    color:THEME_COLOR,
    position:'relative'
  },
  spacing:{
    marginTop:theme.spacing(4),
    marginBottom:theme.spacing(4)
  },
  shadow:{
    transition: 'all .5s ease',
    boxShadow: '20px 38px 34px -26px rgba(255, 255, 255, 0.5)',
    '&:hover':{
      boxShadow:'2px 8px 4px -6px rgba(255, 255, 255, 0.5)'
    }
  },
  button:{
    cursor:'pointer',
    alignSelf: 'center',
    background: THEME_COLOR,
    paddingLeft:'1rem',
    paddingRight:'1rem',
    margin: '0 1rem',
    transition: 'all .5s ease',
    color: '#41403E',
    fontSize: '2rem',
    letterSpacing: 1,
    outline: 'none',
    borderRadius: '255px 15px 225px 15px/15px 225px 15px 255px',
    fontFamily:FONT_FAMILY,
    position:'relative'
  },
  thick:{
    border: 'solid 7px rgba(255, 255, 255, 0.5)'
  },
  nextBtn:{
    position:'absolute',
    bottom:5,
    right:5,
    zIndex:10
  },
  browser:{
    width:'100%',
    minHeight:500,
    background: THEME_COLOR,
    border: '6px solid #373C49',
    borderRadius: 6,
    position:'relative'
  },
  nav:{
    width: '100%',
    height: 50,
    background: '#373C49',
    textAlign:'right',
    position:'relative'
  },
  favicon:{
    transition: 'all .5s ease',
    maxHeight:35,
    borderRadius:'50%',
  },
  url:{
    width: '100%',
    height:'100%',
    outline:'none',
    padding: 6,
    backgroundColor: '#454B5B',
    color: '#9299a0',
    fontSize:'1.8rem',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#373C49',
    '&:hover:':{
      color: '#C5D4D7'
    },
    '&focus:':{
      color: '#C5D4D7'
    },
    borderRadius:6,
    fontFamily:FONT_FAMILY
  },
  bookmarks:{
    width:'100%',
    height:35,
    background: '#373C49',
    boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
    textAlign:'left',
    display:'flex',
    alignItems:'center',
    fontFamily:FONT_FAMILY,
    position:'relative'
  },
  page:{
    width: '100%',
    height: '83%',
    background: THEME_COLOR,
    position:'relative',
    minHeight:440
  },
  chip:{
    marginLeft:theme.spacing(1),
    marginRight:theme.spacing(1),
    color:'white'
  },
  chipLastWebsite:{
    marginLeft:theme.spacing(1),
    marginRight:theme.spacing(1),
    color:'black'
  },
  google:{
    background:'#4285F4'
  },
  o365:{
    background:'#DC3E15'
  },
  avatar:{
    background:THEME_COLOR
  },
  avatarLastWebsite:{
    background:'inherit'
  },
  browserPage:{
    width:'100%',
    height:'100%',
    minHeight:440,
    border:'none',
    transition: 'all .5s ease',
    overflow:'auto',
    position:'relative',
    background:'url('+IMG_IFRAME_LOADING+') center center no-repeat'
  },
  font:{
    fontFamily:FONT_FAMILY
  },
  leftArrow:{
    verticalAlign:'middle',
    verticalAlign:'-webkit-baseline-middle',
    height:40
  },
  questBackground:{
    position:'absolute',
    width:'100%',
    height:'100%',
    top:0,
    left:0,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:theme.spacing(2),
    zIndex:10,
    background:'rgba(0,0,0,.6)',
    transition: 'all .5s ease',
    overflow:'auto'
  },
  blur:{
    transition: 'all .5s ease',
    filter:'blur(3px)',
  },
  lock:{
    position:'absolute',
    width:'100%',
    height:'100%',
    top:0,
    left:0,
    background:'rgba(0,0,0,.35)',
    zIndex:10
  },
  weAreLive:{
    position:'absolute',
    top:5,
    left:5,
    background:'#db2d1b',
    color:'white',
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(1),
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
    borderRadius:6
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  ribbon:{
   position:'absolute',
   right:0,
   cursor:'pointer',
   '&:hover':{
     transform:'scale(1.2)'
   }
  },
  ribbonToThePhishing:{
    top:52,
    background:'#44b1a8'
  },
  ribbonCancel:{
    top:5,
    background:'#db2d1b',
    paddingLeft:15,
    paddingRight:15,
    borderTopLeftRadius:6,
    borderBottomLeftRadius:6,
    '&::after':{
      borderLeft: '15px solid #db2d1b',
    }
  },
  ribbonHowTo:{
    top:15,
    background:'#44b1a8'
  },
  ribbonContent:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  close:{
    padding:theme.spacing(3),
    background:'red',
    position:'absolute',
    borderRadius:'50%',
    border:'4px solid white',
    color:'white',
    width:16,
    height:16,
    top:-24,
    right:-24,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize:'7rem',
    '&:hover':{
      background:'rgba(0,0,0,.2)'
    }
  },
  goLiveBtn:{
    float:'right',
    marginTop:theme.spacing(2)
  },
  cachedObject:{
    width:'90%',
    minHeight:100,
    margin:theme.spacing(2)
  },
  fontSizeRoboto:{
    fontSize:'1.125rem'
  },
  delay2s:{
   animationDelay: '.75s'
 },
  delay1s:{
   animationDelay: '.25s'
 },
 preventInteraction:{
   position:'absolute',
   zIndex:20,
   top:0,
   left:0,
   width:'100%',
   height:'100%'
 },
 goToInfo:{
  cursor:'pointer',
  textDecoration:'underline',
  '&:hover':{
    color:THEME_COLOR_SECOND
  }
 }
}))

const Phishing = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { code,currentStep,init,next,handleSelectedElement,initialChecks,injectLiveData,live } = useContext(DataStore)
  const { showQuest,hideQuest,showNextButton } = useContext(UiStore)

  useEffect(() => {
    const loadMonitoring = (liveData) => {
      showQuest()
      live(
        (success,liveData) => {
          next(5)
          injectLiveData(code,liveData.url,liveData.abortPin,liveData.static)
        },
        () => {}
      )
    }
    initialChecks(loadMonitoring)
  },[window.location.href])

  useEffect(() => {
    init()

    window.addEventListener('message',(e) => {
      const data = e.data
      if (typeof data === 'string') {
        try {
          const json = JSON.parse(data)
          if (json.message === 'selectedElement') {
            handleSelectedElement(
             json.data,
             () => { next();showQuest(); },
             () => { next();showQuest(); })
          }
        } catch (err) { console.error(err); }
      }
    })

  },[])

  let avatar = '', animation = '', delay = classes.delay1s
  if (currentStep === 0) {
    avatar = IMG_WELCOME
    animation = 'fadeIn'
    delay = classes.delay2s
  }
  else if (currentStep === 1) {
    avatar = IMG_WELCOME
  }
  else if (currentStep === 2) {
    avatar = IMG_PREPERATION_1
    animation = 'fadeIn'
    delay = classes.delay1s
  }
  else if (currentStep === 3) {
    avatar = IMG_PREPERATION_1
    animation = 'fadeIn'
    delay = classes.delay2s
  }
  else if (currentStep === 4) {
    avatar = IMG_PREPERATION_2
    animation = 'fadeIn'
    delay = classes.delay1s
  }
  else if (currentStep === 5) {
    avatar = IMG_FINISHED
    animation = 'fadeIn'
    delay = classes.delay2s
  }

  return (
    <div className={classes.root}>
     <div className={classes.frameRight} />
     <div className={`${classes.frameAvatar} animated ${animation} ${delay}`} style={{backgroundImage:'url('+avatar+')'}} />

     <link href="https://fonts.googleapis.com/css2?family=Nerko+One&display=swap" rel="stylesheet" />
     <div className={classes.main}>
      <Grid container direction="row" justify="flex-end" alignItems="center">
       <Grid item xs={12} sm={10} md={8} lg={8}>
        <Grid container direction="row" justify="center" alignItems="center" style={{height:'100vh'}}>
         <Grid item xs={10} sm={10} md={10} lg={10} className={classes.content}>
          {currentStep === 0 && <Welcome />}
          {currentStep > 0 &&
           <>
            <Browser />
            <NextButton />
           </>
          }
         </Grid>
        </Grid>
       </Grid>
      </Grid>
     </div>
    </div>
  )
})

const ComicButton = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { text, onClick, thick, additionalClasses } = props

  return (
   <button
    onClick={onClick?onClick:()=>{}}
    className={`${additionalClasses?additionalClasses:''} ${classes.button} ${classes.shadow} ${(thick ? classes.thick : '')}`}>
    {text} {props.children}
   </button>
  )
})

const AbortDialog = observer(() => {
  const { t } = useTranslation()
  const { abortOpen,handleCloseAbortDialog } = useContext(UiStore)
  const { abort } = useContext(DataStore)

  const _abort = () => {
    abort(() => {
     handleCloseAbortDialog()
    })
  }

  return (
    <Dialog
      open={abortOpen}
      onClose={handleCloseAbortDialog}
      aria-labelledby="abort-dialog-title"
      aria-describedby="abort-dialog-description"
    >
      <DialogTitle id="abort-dialog-title">{t('phishing.monitoring.abort')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="abort-dialog-description">
          {t('phishing.monitoring.abortContent')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAbortDialog} color="primary">
          {t('phishing.monitoring.abortLater')}
        </Button>
        <Button onClick={_abort} color="primary" autoFocus>
          {t('phishing.monitoring.abortNow')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const Welcome = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { cache,next,live,setCode,injectLiveData,removeFromCache } = useContext(DataStore)
  const { showQuest } = useContext(UiStore)
  const history = useHistory()

  const _next = (e) => {
    next()
    showQuest()
  }

  const loadMonitoring = (item) => {
    const { code, abortPin, staticPage } = item
    setCode(code)
    showQuest()
    live(
      (success,liveData) => {
        next(5)
        injectLiveData(code,liveData.url,abortPin,staticPage)
      },
      () => {}
    )
  }

  const gotoInfo = () => {
    history.push('/phishing/info')
  }

  return (
    <div style={{textAlign:'center'}}>
     <Typography className={classes.font} variant="h3">{t('phishing.title')}</Typography>
     <div className={classes.spacing}/>
     <Typography className={classes.fontSizeRoboto} variant="h4" component="p">{t('phishing.subtitle1')}</Typography>
     <div className={classes.spacing}/>
     <ComicButton text={t('phishing.go')} onClick={_next} thick />
     <div className={classes.spacing}/>
     <Typography onClick={gotoInfo} className={`${classes.fontSizeRoboto} ${classes.goToInfo}`} variant="h4" component="p">{t('phishing.goToInfo')}</Typography>
     <div className={classes.spacing}/>
     {cache && cache.length > 0 &&
      <>
       <div className={classes.spacing} />
       <Typography variant="h4" component="p" className={classes.font}>{t('phishing.lastPhishingSetups')}</Typography>
       <div className={classes.spacing} />
       <Grid container>
        {cache.map(item => {
         const name = item.name && item.name.trim() !== '' ? item.name : t('phishing.unnamed')
         return <Grid item xs={12} sm={6} md={4} lg={3} style={{position:'relative'}}>
          <ComicButton
           thick
           additionalClasses={classes.cachedObject}
           key={item.code}
           text={name}
           onClick={() => { loadMonitoring(item) }}>
          </ComicButton>
         </Grid>
        })}
       </Grid>
      </>
     }
    </div>
  )
})

const AnimatedText = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { title, subtitle, content } = props

  return (
   <>
    {title && <Typography style={{textAlign:'center'}} variant="h3" className={`${classes.font} animated bounceInRight`}>{title}</Typography>}
    <div className={classes.spacing}/>
    {subtitle && <Typography style={{textAlign:'center'}} variant="h4" component="p" className={`${classes.fontSizeRoboto} animated bounceInLeft`}>{subtitle}</Typography>}
    <div className={classes.spacing}/>
    {content && <>{content}</>}
   </>
  )
})

const Browser = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const select = (e) => e.target.select()
  const {
    url,
    setO365,
    setGoogle,
    setURL,
    loadWebsite,
    frameUrl,
    lastPhishingSite,
    loadlastWebsite,
    magic,
    currentStep
  } = useContext(DataStore)
  const { showQuest,hideQuest,showNextButton,showLoading,hideLoading,loading } = useContext(UiStore)

  const _setURL = (e) => { setURL(e.target.value) }

  const _setGoogle = (e) => {
    setGoogle()
    _next(3)
  }
  const _setO365 = (e) => {
    setO365()
    _next(3)
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      showLoading()
      loadWebsite(() => {
        hideLoading()
        _next()
      })
    }
  }

  const _next = () => {
    hideQuest()
    showNextButton()
  }

  return (
    <div className={`${classes.shadow} ${classes.browser}`}>
      <div className={classes.nav}>

       <Grid container justify="space-between">
        {currentStep > 1 && currentStep !== 5 && <div className={classes.lock} />}
        <Grid item xs={1} sm={1} md={1} lg={1} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
         <img src={IMG_FAVICON} alt="favicon" className={`${classes.favicon} ${(loading?'animated flip infinite':'')}`} />
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          <input
           onClick={select}
           type="text"
           value={url}
           onChange={_setURL}
           onPaste={_setURL}
           className={classes.url}
           placeholder={t('phishing.typeURL')}
           onKeyDown={onKeyDown}
           autoFocus
           disabled={currentStep===5}
          />
        </Grid>
       </Grid>
      </div>
      {currentStep !== 5 &&
       <div className={classes.bookmarks}>
         {currentStep > 1 && <div className={classes.lock} />}
         <Chip
          size="small"
          avatar={<Avatar className={classes.avatar}>G</Avatar>}
          label={t('phishing.google')}
          onClick={_setGoogle}
          className={`${classes.google} ${classes.chip}`}
         />
         <Chip
          size="small"
          avatar={<Avatar className={classes.avatar}>O</Avatar>}
          label={t('phishing.office365')}
          onClick={_setO365}
          className={`${classes.o365} ${classes.chip}`}
         />
         {lastPhishingSite &&
           <Chip
            size="small"
            avatar={<Avatar className={classes.avatarLastWebsite}>{lastPhishingSite.charAt(0).toUpperCase()}</Avatar>}
            label={lastPhishingSite}
            onClick={loadlastWebsite}
            className={`${classes.chipLastWebsite}`}
           />
         }
       </div>
      }
      <div className={classes.page}>
       <BrowserPage />
      </div>
    </div>
  )
})

const BrowserPage = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { frameUrl,currentStep,isGoogle,isMicrosoft } = useContext(DataStore)
  const { questFrame } = useContext(UiStore)

  return (
   <>
    {currentStep === 1 && (isGoogle || isMicrosoft) && <div className={classes.preventInteraction} />}
    {questFrame && <Quest />}
    {frameUrl &&
      <iframe
       id="crazy-quest-frame"
       src={frameUrl}
       sandbox="allow-scripts allow-forms allow-pointer-lock allow-same-origin"
       className={`${classes.browserPage} ${questFrame ? classes.blur : ''}`}></iframe>}
   </>
  )
})

const Quest = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { currentStep,postMessage,liveData } = useContext(DataStore)
  const { hideQuest } = useContext(UiStore)

  const findElement = () => {
    postMessage('startSelect')
    hideQuest()
  }

  return (
    <div className={classes.questBackground}>
     {currentStep === 1 &&
       <AnimatedText
        title={t('phishing.createCopy.title')}
        subtitle={t('phishing.createCopy.subtitle')}
       />
     }
     {currentStep === 2 &&
       <AnimatedText
        title={t('phishing.changeElements.title')}
        subtitle={t('phishing.changeElements.subtitle')}
        content={
          <ComicButton text={t('phishing.changeElements.showSubmit')} onClick={findElement} />
        }
       />
     }
     {currentStep === 3 &&
       <AnimatedText
        title={t('phishing.redirect.title')}
        subtitle={t('phishing.redirect.subtitle')}
        content={<RedirectChooser />}
       />
     }
     {currentStep === 4 &&
       <AnimatedText
        title={t('phishing.settings.title')}
        subtitle={t('phishing.settings.subtitle')}
        content={<Settings />}
       />
     }
     {currentStep === 5 && liveData && <Monitoring />}
    </div>
  )
})

const Monitoring = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { notifier } = useContext(GlobalUI)
  const { showLoading, hideLoading } = useContext(UiStore)
  const { live,LIVE_UPDATE_EVERY_SECONDS,liveData,staticPage,frameUrl } = useContext(DataStore)
  const { url, name, database } = liveData
  const loop = (cb) => {
    showLoading()
    live(
      () => {
        hideLoading()
        setTimeout(() => {
          loop(loop)
        },LIVE_UPDATE_EVERY_SECONDS * 1000)
      },
      () => {
        hideLoading()
      }
    )
  }
  useEffect(loop,[])

  const _howTo = () => {

  }
  const _trackedLink = () => {
    const el = document.createElement('input')
    el.value = staticPage
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    notifier.push(t('core.notifier.copiedToClipboard'),notifier.variants.SUCCESS,notifier.pos.TOP_RIGHT)
  }
  const _disabledTrackingLink = () => {
    const win = window.open(frameUrl, '_blank');
    win.focus();
  }

  return (
    liveData &&
    <Grid container alignItems="center" justifyContent="center" direction="column" style={{textAlign:'center'}}>
     <div className={`${classes.weAreLive} ${classes.font}`}>{t('phishing.live')}</div>
     <Grid item xs={12}>
      <Typography variant="h2" className={classes.font}>{name}</Typography>
      <div className={classes.spacing} />
      <Countdown
       timeTillDate={moment(database.end).format("MM DD YYYY, h:mm a")}
       timeFormat="MM DD YYYY, h:mm a"
      />
      <div>
       <Typography variant="body1" component="span">{database.visits.length + ' ' + t('phishing.monitoring.visits')}</Typography>
       <Typography variant="body1" component="span"> | </Typography>
       <Typography variant="body1" component="span">{database.logins.length + ' ' + t('phishing.monitoring.logins')}</Typography>
      </div>
     </Grid>
     <div className={classes.spacing} />

     <ButtonGroup variant="text">
      <Button style={{color:THEME_COLOR}} onClick={_howTo}>{t('phishing.monitoring.howTo')}</Button>
      <Button style={{color:THEME_COLOR}} onClick={_trackedLink}>{t('phishing.monitoring.trackingLink')}</Button>
      <Button style={{color:THEME_COLOR}} onClick={_disabledTrackingLink}><ExitToApp /> {t('phishing.monitoring.disabledTrackingLink')}</Button>
    </ButtonGroup>

     <MonitoringSettings />
     <AbortDialog />
    </Grid>
  )
})

const MonitoringSettings = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { handleOpenAbortDialog } = useContext(UiStore)

  return (
   <div className={`${classes.ribbon} ${classes.ribbonCancel} ${classes.shadow} ${classes.font}`}>
    <div className={classes.ribbonContent} onClick={handleOpenAbortDialog}>
     <Typography variant="h5" className={classes.font}>{t('phishing.monitoring.abort')}</Typography>
    </div>
   </div>
  )
})

const Settings = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { 
    datetimeOpen,
    showDateTime,
    hideDateTime,
    showNextButton,
    showMonitoring,
    showQuest,
    hideNextButton,
    showLoading,
    hideLoading,
    nextButton
  } = useContext(UiStore)
  const { end,setEnd,name,setName,next,setup } = useContext(DataStore)
  const select = (e) => e.target.select()

  useEffect(() => {
    showNextButton()
    setEnd(new Date())
  },[])

  const _setName = (e) => {
    setName(e.target.value)
  }
  const _setEnd = (date) => {
    setEnd(date)
  }

  const style = { width:'100%', height:'auto' }

  return (
   <div>

     <input
      onClick={select}
      type="text"
      value={name}
      onChange={_setName}
      onPaste={_setName}
      className={classes.url}
      style={style}
      placeholder={t('phishing.settings.name')}
      autoFocus
     />

     <input
      onClick={showDateTime}
      type="text"
      value={t('phishing.settings.end') + ': ' + moment(end).format('MMMM Do YYYY, h:mm:ss a')}
      className={classes.url}
      style={style}
      placeholder={t('phishing.settings.name')}
      autoFocus
     />

     <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <DateTimePicker
       open={datetimeOpen}
       onOpen={showDateTime}
       onClose={hideDateTime}
       value={end}
       disablePast
       onChange={_setEnd}
       showTodayButton
       style={{display:'none'}}
      />
     </MuiPickersUtilsProvider>

   </div>
  )
})

const RedirectChooser = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    magic,
    redirectOptions,
    setRedirectOption,
    selectedRedirectOption,
    redirectUrl ,
    setRedirectUrl
  } = useContext(DataStore)
  const { showNextButton,hideNextButton } = useContext(UiStore)


  useEffect(() => {
    showNextButton()
  },[])

  const select = (e) => e.target.select()
  const _setRedirectOption = (e) => { setRedirectOption(parseInt(e.target.value)) }
  const _setRedirectUrl = (e) => setRedirectUrl(e.target.value)
  const SAME_URL = magic && magic.url ? magic.url : ''

  return (
   <RadioGroup aria-label="redirect-options" name="redirect-options-name" value={selectedRedirectOption} onChange={_setRedirectOption}>
    <FormControlLabel
     value={redirectOptions.SAME_URL}
     control={<Radio />}
     label={t('phishing.redirect.option1',{url:SAME_URL})}
    />
    <FormControlLabel
     value={redirectOptions.CUSTOM_URL}
     label={t('phishing.redirect.option2')}
     control={<Radio />}
    />
    {selectedRedirectOption === redirectOptions.CUSTOM_URL &&
      <input
       onClick={select}
       type="text"
       value={redirectUrl}
       onChange={_setRedirectUrl}
       onPaste={_setRedirectUrl}
       className={classes.url}
       style={{height:'auto',width:'100%'}}
       placeholder={t('phishing.typeURL')}
       autoFocus
      />
    }
    <FormControlLabel
     value={redirectOptions.RESOLVE_PHISHING}
     control={<Radio />}
     label={t('phishing.redirect.option3')}
    />
   </RadioGroup>
  )
})

const NextButton = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { next,currentStep,addScripts,isGoogle,isMicrosoft,loadWebsite,setup } = useContext(DataStore)
  const { showQuest,nextButton,hideNextButton,showLoading,hideLoading } = useContext(UiStore)
  const { notifier } = useContext(GlobalUI)



  const _next = (e) => {
    if (currentStep === 3) {
      showLoading()
      addScripts(
        () => {
          hideLoading()
          next()
          showQuest()
          //hideNextButton()
        },
        () => {}
      )
    } else if (currentStep === 1 && (isGoogle || isMicrosoft)) {
      // skip changing elements, already done in replicas
      loadWebsite(() => {
        next(3)
        showQuest()
        hideNextButton()
      })
    } else if (currentStep === 4) {
        showLoading()
        setup((success) => {
            hideLoading()
            next()
            showQuest()
            hideNextButton()
            notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS,notifier.pos.TOP_RIGHT)
          },
          () => {
            hideLoading()
          }
        )
    } else {
      next()
      showQuest()
      hideNextButton()
    }

  }
  return ( nextButton && <ComicButton additionalClasses={classes.nextBtn} text={t('phishing.next')} onClick={_next} /> )
})



export default Phishing
