import React, {useEffect,useContext} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import IMG_DISCORD from 'media/img/discord.png'

import {
  Button,
  Typography
} from '@material-ui/core'


const Fellows = observer(() => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const { utilityText } = useContext(UiStore)
  const { kibsmeta } = data

  useEffect(utilityText.getTextCommunity,[])

  return (
   <section className="features-area pt-100 pb-70">
    <div className="container">
      <div className="section-title text-width">
       <h2>{t('core.landingpage.discord.title')}</h2>
        <div className="bar" />
      </div>
      <img src={IMG_DISCORD} alt="discord" style={{ display:"block", maxWidth:"90%", margin:"0 auto" }} />
      <div className="row">
       <div className="subscribe-content">
       <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html: utilityText.textCommunity.toString('html') }} />
       <Button variant="contained" href={kibsmeta.discord} className={` phButton`}>
       {t('core.landingpage.discord.takePart')}
       </Button>
      </div>
     </div>
    </div>
  </section>
 )
})

export default Fellows
