import React,{ useContext,useEffect } from 'react'

import { observer } from 'mobx-react-lite'
import Admin from './Admin'
import Catalog from './Catalog'



export const SimpleCatalog = observer(() => {
  useEffect(() => {
    document.body.style.fontFamily = "Mukta";
    return () => {
      document.body.style.fontFamily = "Roboto"
    }
  },[])
  return ( <Catalog /> )
})

export const SimpleCatalogAdministration = observer(() => {
  return ( <Admin /> )
})
