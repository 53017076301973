import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import Ajax from 'api/ajax'
import ls from 'local-storage'
import { hash,checkImage } from 'shared/functions/helper'
import i18next from 'i18next'
import queryString from 'query-string'
import IMG_PupilWork from 'media/img/ampelflyer/pupilwork.jpg'

class FlyerManager {
  flyer = []
  current = new Flyer({})
  services = []
  schulhaus = {}

  getSchulhausData(schulhausKey,cbSuccess) {
    Ajax.getSchulhausData(
      {
        scope:{
          name:1
        },
        keys:{
          schulhaus:schulhausKey
        }
      },
      (res) => {
        if (res && res.data && res.data.success && res.data.schulhaus) {
          this.schulhaus = res.data.schulhaus
          cbSuccess()
        }
      },
      () => {}
    )
  }

  getAllFlyer(fk_schulhaus_key,cbSuccess,cbFail) {
   Ajax.checkAmpelFlyer(
     fk_schulhaus_key,
     (res) => {
      if (res && res.data && res.data.success) {
        const data = res.data.ampelflyer
        this.addAllFlyers(data)
        this.setCurrentFlyer(this.flyer[0])
      }
      cbSuccess()
    },
    cbFail
   )
  }

  getAllAmpelflyerServices(fk_schulhaus_key,cbSuccess,cbFail) {
    Ajax.getAllAmpelflyerServices(
      fk_schulhaus_key,
      (res) => {
        if (res && res.data && res.data.success) {
          this.addServices(res.data.services)
          cbSuccess()
        } else cbFail()
      },
      cbFail
    )
  }
  createFlyer (cbSuccess,cbFail) {
    Ajax.createAmpelflyer(
      this.schulhaus.erz_key,
      (res) => {
        if (res && res.data && res.data.success) {
          const data = res.data.ampelflyer
          this.addFlyer(data)
          cbSuccess()
        }
      },
      cbFail
    )
  }
  deleteFlyer (cbSuccess,cbFail) {
    Ajax.deleteAmpelflyer(
      this.current.id,
      this.schulhaus.erz_key,
      () => {
        this.removeFlyer(this.current.id)
        cbSuccess()
      },
      cbFail
    )
  }
  addServices = (services) => {
    this.services = services
  }
  addAllFlyers = (data) => {
   for (let i = 0; i < data.length; i++) {
    this.addFlyer(data[i])
   }
  }
  addFlyer = (data) => {
    this.flyer.push(
      new Flyer(
        data,
        this.schulhaus.name,
        this.schulhaus.erz_key
      )
    )
  }
  removeFlyer = (id) => { this.flyer = this.flyer.filter(f => f.id !== id) }
  switchFlyer = (index) => { this.setCurrentFlyer(this.flyer[index]) }
  setSchulhaus = (data) => { this.schulhaus = data }
  setCurrentFlyer = (flyer) => { this.current = flyer }
  getServiceByName = (name) => { return this.services.filter(s => hash(name) === hash(s.name))[0] }
  getServiceById = (id) => { return this.services.filter(s => s.id === id)[0] }
}

class Flyer {
  id
  title
  custom_schulhaus_name
  logo
  text_green_data
  text_yellow_data
  text_red_data
  impress
  services_green
  services_yellow
  services_red

  from

  SERVICE_TEXT_MAX_CHARACTERS = 255
  DEBOUNCE_TIMER = 800

  constructor (data,schulhausname = '',schulhausKey = null) {
    const {
      id,
      title,
      custom_schulhaus_name,
      logo,
      text_green_data,
      text_yellow_data,
      text_red_data,
      impress,
      services_green,
      services_yellow,
      services_red,

      from
    } = data

    this.id = id
    this.title = title ? title : i18next.t('ampelflyer.unnamed')
    this.custom_schulhaus_name = custom_schulhaus_name ? custom_schulhaus_name : schulhausname
    this.logo = logo ? this.getUncachedLogo(logo) : IMG_PupilWork
    this.text_green_data = text_green_data ? text_green_data : i18next.t('ampelflyer.atOurSchool.green.point1')
    this.text_yellow_data = text_yellow_data ? text_yellow_data : i18next.t('ampelflyer.atOurSchool.yellow.point1') + ' ' + i18next.t('ampelflyer.atOurSchool.yellow.point2')
    this.text_red_data = text_red_data ? text_red_data : i18next.t('ampelflyer.atOurSchool.red.point1')
    this.impress = impress ? impress : i18next.t('ampelflyer.impress.content')
    this.services_green = services_green ? services_green : []
    this.services_yellow = services_yellow ? services_yellow : []
    this.services_red = services_red ? services_red : []
    this.from = from ? from : {}
  }

  setTitle = (title) => this.title = title
  setCustomSchoolname = (schoolname) => this.custom_schulhaus_name = schoolname
  setTextGreenData = (text) => this.text_green_data = text
  setTextYellowData = (text) => this.text_yellow_data = text
  setTextRedData = (text) => this.text_red_data = text
  setImpress = (impress) => this.impress = impress

  addService = (color,service) => {
   if (color === colors.GREEN) { this.services_green = [...this.services_green,service] }
   else if (color === colors.YELLOW) { this.services_yellow = [...this.services_yellow,service] }
   else if (color === colors.RED) { this.services_red = [...this.services_red,service] }
  }

  removeService = (color,service) => {
    if (color === colors.GREEN) { this.services_green = this.services_green.filter(s => s.id !== service.id) }
    if (color === colors.YELLOW) { this.services_yellow = this.services_yellow.filter(s => s.id !== service.id) }
    if (color === colors.RED) { this.services_red = this.services_red.filter(s => s.id !== service.id) }
  }

  isSelected = (color,service) => {
    if (color === colors.GREEN) { return this.services_green.filter(s => s.id === service.id).length > 0 }
    if (color === colors.YELLOW) { return this.services_yellow.filter(s => s.id === service.id).length > 0 }
    if (color === colors.RED) { return this.services_red.filter(s => s.id === service.id).length > 0 }
  }

  save = (schulhausKey,data = null) => {
   data = data ? data : this.serialized

   Ajax.updateAmpelflyer(
     data,
     schulhausKey,
     () => {},
     () => {}
   )
  }

  setDefaultLogo = (schulhausKey,ampelflyerId) => {
   this.logo = IMG_PupilWork
   this.saveLogo(null,schulhausKey,ampelflyerId)
  }

  getUncachedLogo = (logo) => {
    return logo +'?rand='+Math.random()
  }

  get isCustomLogo () {
    return this.logo !== IMG_PupilWork
  }
  get text_red_remaining_characters () {
    return this.SERVICE_TEXT_MAX_CHARACTERS - this.text_red_data.length
  }
  get text_yellow_remaining_characters () {
    return this.SERVICE_TEXT_MAX_CHARACTERS - this.text_yellow_data.length
  }
  get text_green_remaining_characters () {
    return this.SERVICE_TEXT_MAX_CHARACTERS - this.text_green_data.length
  }

  saveLogo = (logo,schulhausKey,ampelflyerId) => {
    const formData = new FormData()
    if (logo) {
     formData.append('logo',logo[0])
    }
    formData.append('schulhausKey',schulhausKey)
    formData.append('ampelflyerId',ampelflyerId)

    Ajax.updateAmpelflyerLogo(
      formData,
      (res) => {
        if (res && res.data && res.data.success) {
          this.logo = res.data.logo ? this.getUncachedLogo(res.data.logo) : IMG_PupilWork
        }
      },
      () => {}
    )
  }

  get serialized () {
   return {
     id:this.id,
     title:this.title,
     custom_schulhaus_name:this.custom_schulhaus_name,
     text_green_data:this.text_green_data,
     text_yellow_data:this.text_yellow_data,
     text_red_data:this.text_red_data,
     impress:this.impress,
     services_green:this.services_green.map(s => s.id),
     services_yellow:this.services_yellow.map(s => s.id),
     services_red:this.services_red.map(s => s.id)
   }
  }
}

class PDF {
  flyer = new Flyer({})

  format = (text) => {
    return text ? text.replace(new RegExp('\r?\n','g'), '<br />') : ''
  }

  loadData = (cbSuccess,cbFail) => {
    const params = queryString.parse(window.location.search)
    let fk_schulhaus_key = null
    let ampelflyerId = null
    if (params && params.schulhaus && params.ampelflyer) {
      fk_schulhaus_key = params.schulhaus
      ampelflyerId = params.ampelflyer

      Ajax.getAmpelFlyer(
        ampelflyerId,
        fk_schulhaus_key,
        (res) => {
          if (res && res.data && res.data.success) {
            this.flyer = new Flyer(res.data.ampelflyer,null,fk_schulhaus_key)
            cbSuccess()
          }
        },
        cbFail
      )
    }
  }


}

class NewService {
  constructor () {
    this.init()
  }

  get isSaveable () {
    return this.name && this.name !== ''
  }

  init = () => {
    this.name = ''
    this.isPublic = false
    this.image = null
  }

  create = (schulhausKey,cbSuccess,cbFail) => {
    const formData = new FormData()
    if (this.image) {
     formData.append('service_image',this.image[0])
     formData.append('schulhausKey',schulhausKey)
     formData.append('service',JSON.stringify(this.serialized))
    }
    Ajax.createNewAmpelflyerService(
      formData,
      (res) => {
        if (res && res.data && res.data.success) {
          this.init()
          cbSuccess(res.data.services)
        }
      },
      cbFail
    )
  }

  setName = (name) => this.name = name
  setPublic = (checked) => this.isPublic = checked
  setImage = (file) => {
   this.image = file
  }

  get serialized () {
    return {
      name:this.name,
      isPublic:this.isPublic
    }
  }
}


const colors = {
  GREEN:1,
  YELLOW:2,
  RED:3
}
class Data {
  COLORS = colors

  flyerManager
  pdf = new PDF()
  newService

  init = () => {
    this.flyerManager = new FlyerManager()
    this.pdf = new PDF()
    this.newService = new NewService()
  }
}

decorate(FlyerManager,{
  schulhaus:observable,
  flyer:observable,
  current:observable,
  services:observable
})

decorate(Flyer,{
  id:observable,
  title:observable,
  custom_schulhaus_name:observable,
  logo:observable,
  text_green_data:observable,
  text_yellow_data:observable,
  text_red_data:observable,
  impress:observable,
  services_green:observable,
  services_yellow:observable,
  services_red:observable,
  serialized:computed,
  isCustomLogo:computed,
  text_green_remaining_characters:computed,
  text_yellow_remaining_characters:computed,
  text_red_remaining_characters:computed,
  from:observable
})

decorate(NewService,{
  name:observable,
  isPublic:observable,
  image:observable,
  serialized:computed
})

decorate(PDF,{
 flyer:observable
})


const DataStore = createContext(new Data())
export default DataStore
