import React, { useContext,useEffect,useState } from 'react'
import {deburr,debounce} from 'lodash'
import { FixedSizeList as FSL } from 'react-window'
import moment from 'moment'

import {
  AppBar,
  Toolbar,
  Divider,
  Grid,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  Paper,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Tooltip,
  Chip,
  Slide,
  ListItemSecondaryAction,
  CircularProgress
} from '@material-ui/core'
import { Search,AccountCircle,Home,Mail,Phone,Close } from '@material-ui/icons'

import GoogleLogo from 'media/img/googleLogo.svg'
import OfficeLogo from 'media/img/office365Logo.png'
import IMG_KIBS_LOGO_RED from 'media/img/KibsLogo_red.png'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop:25
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  profile:{
    marginTop:theme.spacing(3)
  },
  authIcon:{
    height:40,
    width:'auto'
  },
  subheader:{
    fontStyle:'italic'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  theSchoolFrame:{
    width:'100%',
    height:'100vh',
    overflow:'auto'
  },
  progress:{
    display:'flex',
    width:'100%',
    height:'100vh',
    justifyContent:'center',
    alignItems:'center'
  }
}));

moment.locale('de')
const SchoolFind = observer(() => {
  const { utilitySchool } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="md" id="ref-parent">
     <SearchBar />
     {utilitySchool.suggestions.length > 0 && <div>{utilitySchool.suggestions.length} {t('core.admin.utility.hits')}</div>}
     {utilitySchool.suggestions.length > 0  && <SchoolList />}
     {utilitySchool.currentSchool && <TheSchoolFrame />}
    </Container>
  )
})


const getSchools = debounce((school,cb) => { school.get(cb) },300)

const SearchBar = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { utilitySchool } = useContext(UiStore)

  const _onKeyDown = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault()
    }
  }

  return (
    <Paper component="form" className={classes.searchRoot} elevation={0}>
      <InputBase
        autoFocus
        value={utilitySchool.searchTerm}
        onChange={utilitySchool.setSearchTerm}
        onKeyUp={getSchools.bind(this,utilitySchool)}
        onKeyDown={_onKeyDown}
        className={classes.searchInput}
        placeholder={t('core.admin.utility.schoolDetailsPlaceholder')}
        inputProps={{ 'aria-label': t('core.admin.utility.schoolDetailsPlaceholder') }}
      />
      <IconButton className={classes.searchIconButton} aria-label="search" onClick={getSchools.bind(this,utilitySchool)}>
        <Search />
      </IconButton>
    </Paper>
  );
})

const SchoolList = observer(() => {
  const { utilitySchool } = useContext(UiStore)
  const [width,setWidth] = useState(0)

  useEffect(() => {
    const d = document.getElementById('ref-parent')
    setWidth(d.offSetWidth)
  },[window.width])

  return (
     <FSL height={500} width={width} itemSize={66} itemCount={utilitySchool.suggestions.length} itemData={utilitySchool.suggestions}>
      {Row}
     </FSL>
  )
})

const Row = ({ data, index, style }) => (
  <SchoolListItem  data={data[index]} style={style} />
)


const SchoolListItem = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data,style } = props
  const { utilitySchool,notifier } = useContext(UiStore)

  const _onClick = () => {
   utilitySchool.setCurrentSchool(data)
   utilitySchool.join(utilitySchool.handleOpen)
  }

  let styles = { ...style }

  return (
   <ListItem button style={styles} onClick={_onClick}>
    <ListItemText
     primary={data.name + ', ' + data.standort}
     secondary={data.strasse + ', ' + data.plz}
    />
   </ListItem>
  )
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TheSchoolFrame = observer((props) => {
  const classes = useStyles()
  const { utilitySchool } = useContext(UiStore)
  const { open, handleClose } = utilitySchool

  const _handleClose = () => {
    utilitySchool.leave(() => {
     handleClose()
    })
  }

 useEffect(() => {
   return () => {
    utilitySchool.leave(() => {
      handleClose()
    })
   }
 },[])

  return (
    <Dialog fullScreen open={open} onClose={_handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={_handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {utilitySchool.currentSchool.name}
          </Typography>
        </Toolbar>
      </AppBar>
      {utilitySchool.loading
       ?
       <div className={classes.progress}>
        <CircularProgress />
       </div>
       :
       <iframe
        src={'/dashboard/schoolpage/'+utilitySchool.currentSchool.erz_key}
        className={classes.theSchoolFrame}
       ></iframe>
      }

    </Dialog>
  )
})


export default SchoolFind
