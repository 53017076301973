import React, { useEffect,useContext,useState } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import DataStore from 'core/stores/data'

import {
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import {
  ExpandMore
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
 heading: {
  fontSize: theme.typography.pxToRem(15),
  flexBasis: '66.66%',
  flexShrink: 0,
 },
 secondaryHeading: {
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.text.secondary,
 },
 table:{
  minWidth:320
 },
 panelDetails:{
  flexDirection:'column'
 },
 spacer:{
   marginTop:theme.spacing(1)
 },
 bigSpacer:{
   marginTop:theme.spacing(6),
   marginBottom:theme.spacing(6)
 },
}))




const Rechtliches = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
   <Container>
    <Typography variant="h4" className={classes.bigSpacer}>{t('rechtliches.dataprotection.title')}</Typography>

    <Container maxWidth={"md"}>
     <div className={classes.spacer} />
     <Datenschutzerklaerung />
     <div className={classes.spacer} />
     <AllgemeinerHinweis />
     <div className={classes.spacer} />
     <BearbeitungPersonenDaten />
     <div className={classes.spacer} />
     <Cookies />
     <div className={classes.spacer} />
     <SSLTLS />
     <div className={classes.spacer} />
     <ServerLog />
     <div className={classes.spacer} />
     <DiensteVonDritten />
     <div className={classes.spacer} />
     <YourRights />
     <div className={classes.spacer} />
     <WerbeMails />
     <div className={classes.spacer} />
     <GoogleAnalytics />
     <div className={classes.spacer} />
     <GoogleWebFonts />
     <div className={classes.spacer} />
     <YouTube />
     <div className={classes.spacer} />
     <Vimeo />
     <div className={classes.spacer} />
     <Copyright />
     <div className={classes.spacer} />
     <Disclaimer />
     <div className={classes.spacer} />
     <Updates />
     <div className={classes.spacer} />
     <Questions />
    </Container>

    <div className={classes.bigSpacer} />
   </Container>
  )
})

const Questions = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel defaultExpanded={true}>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.questions.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.questions.first')}</Typography></p>
      <p><Typography>{t('rechtliches.questions.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Updates = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.updates.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.updates.first')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Disclaimer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.disclaimer.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.disclaimer.first')}</Typography></p>
      <p><Typography>{t('rechtliches.disclaimer.second')}</Typography></p>
      <p><Typography>{t('rechtliches.disclaimer.third')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Copyright = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.copyright.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.copyright.first')}</Typography></p>
      <p><Typography>{t('rechtliches.copyright.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Vimeo = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.vimeo.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.vimeo.first')}</Typography></p>
      <p><Typography>{t('rechtliches.vimeo.second')}</Typography></p>
      <p><Typography>{t('rechtliches.vimeo.third')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const YouTube = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.youtube.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.youtube.first')}</Typography></p>
      <p><Typography>{t('rechtliches.youtube.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const GoogleWebFonts = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.googleWebFonts.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.googleWebFonts.first')}</Typography></p>
      <p><Typography>{t('rechtliches.googleWebFonts.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const GoogleAnalytics = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const gaProperty = 'UA-150375995-1'
  const disableStr = 'ga-disable-' + gaProperty
  const cookie = document.cookie.indexOf(disableStr + '=true') > -1 || window[disableStr] || false

  const [deactivated,setDeactivated] = useState(cookie)
  const handleChange = () => {

   setDeactivated(!deactivated)
   if (deactivated) {
      document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
      window[disableStr] = true
   } else {
     document.cookie = disableStr + '=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
     window[disableStr] = false
   }
  }
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.googleAnalytics.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.googleAnalytics.first')}</Typography></p>
      <p><Typography>{t('rechtliches.googleAnalytics.second')}</Typography></p>
      <p><Typography>{t('rechtliches.googleAnalytics.third')}</Typography></p>
      <p><Typography>{t('rechtliches.googleAnalytics.fourth')}</Typography></p>
      <p><Typography>{t('rechtliches.googleAnalytics.fifth')}</Typography></p>
      <p><Typography>{t('rechtliches.googleAnalytics.sixth')}</Typography></p>
      <p>
      <FormControlLabel
        control={
          <Switch
            checked={deactivated}
            onChange={handleChange}
            name="ga"
            color="secondary"
          />
        }
        label={!deactivated ? t('rechtliches.googleAnalytics.deactivate') : t('rechtliches.googleAnalytics.activate')}
      />
      </p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const WerbeMails = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.advertisingMails.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.advertisingMails.first')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const YourRights = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.yourRights.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.first.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.first.text')}</Typography>
      </p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.second.title')}</Typography>
        <Typography>{t('rechtliches.yourRights.second.point1.text')}</Typography>
        <List>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point1')}</ListItemText></ListItem>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point2')}</ListItemText></ListItem>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point3')}</ListItemText></ListItem>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point4')}</ListItemText></ListItem>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point5')}</ListItemText></ListItem>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point6')}</ListItemText></ListItem>
         <ListItem><ListItemText>{t('rechtliches.yourRights.second.point1.point7')}</ListItemText></ListItem>
        </List>
        <Typography>{t('rechtliches.yourRights.second.point1.text2')}</Typography>
        <Typography>{t('rechtliches.yourRights.second.point1.text3')}</Typography>
      </p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.third.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.third.first')}</Typography>
      </p>
      <p><Typography>{t('rechtliches.yourRights.third.second')}</Typography></p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.fourth.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.fourth.first.text')}</Typography>
       <List>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fourth.first.point1')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fourth.first.point2')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fourth.first.point3')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fourth.first.point4')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fourth.first.point5')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fourth.first.point6')}</ListItemText></ListItem>
       </List>
       <Typography>{t('rechtliches.yourRights.fourth.first.text2')}</Typography>
      </p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.fifth.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.fifth.first.text')}</Typography>
       <List>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fifth.first.point1')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fifth.first.point2')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fifth.first.point3')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.yourRights.fifth.first.point4')}</ListItemText></ListItem>
       </List>
       <Typography>{t('rechtliches.yourRights.fifth.first.text2')}</Typography>
      </p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.sixth.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.sixth.first')}</Typography>
      </p>
      <p><Typography>{t('rechtliches.yourRights.sixth.second')}</Typography></p>
      <p><Typography>{t('rechtliches.yourRights.sixth.third')}</Typography></p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.seventh.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.seventh.first')}</Typography>
      </p>
      <p><Typography>{t('rechtliches.yourRights.seventh.second')}</Typography></p>
      <p><Typography>{t('rechtliches.yourRights.seventh.third')}</Typography></p>

      <p>
       <Typography variant="h6">{t('rechtliches.yourRights.eigth.title')}</Typography>
       <Typography>{t('rechtliches.yourRights.eigth.first')}</Typography>
      </p>
      <p><Typography>{t('rechtliches.yourRights.eigth.second')}</Typography></p>

     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const DiensteVonDritten = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.thirdparty.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.thirdparty.first')}</Typography></p>
      <p><Typography>{t('rechtliches.thirdparty.second')}</Typography></p>
      <p><Typography>{t('rechtliches.thirdparty.third')}</Typography></p>
      <p><Typography>{t('rechtliches.thirdparty.fourth')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const ServerLog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.serverlog.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p>
       <Typography>{t('rechtliches.serverlog.first.title')}</Typography>
       <List>
        <ListItem><ListItemText>{t('rechtliches.serverlog.first.point1')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.serverlog.first.point2')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.serverlog.first.point3')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.serverlog.first.point4')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.serverlog.first.point5')}</ListItemText></ListItem>
       </List>
      </p>
      <p><Typography>{t('rechtliches.serverlog.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const SSLTLS = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.ssltls.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.ssltls.first')}</Typography></p>
      <p><Typography>{t('rechtliches.ssltls.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Cookies = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.cookies.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.cookies.first')}</Typography></p>
      <p><Typography>{t('rechtliches.cookies.second')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const BearbeitungPersonenDaten = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.personalData.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.personalData.first')}</Typography></p>
      <p>
       <Typography>{t('rechtliches.personalData.second.title')}</Typography>
       <List>
        <ListItem><ListItemText>{t('rechtliches.personalData.second.point1')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.personalData.second.point2')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.personalData.second.point3')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.personalData.second.point4')}</ListItemText></ListItem>
        <ListItem><ListItemText>{t('rechtliches.personalData.second.point5')}</ListItemText></ListItem>
       </List>
      </p>
      <p><Typography>{t('rechtliches.personalData.third')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const AllgemeinerHinweis = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.generalHints.title')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
      <p><Typography>{t('rechtliches.generalHints.first')}</Typography></p>
      <p><Typography>{t('rechtliches.generalHints.second')}</Typography></p>
      <p><Typography>{t('rechtliches.generalHints.third')}</Typography></p>
      <p><Typography>{t('rechtliches.generalHints.fourth')}</Typography></p>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Datenschutzerklaerung = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { kibsmeta } = useContext(DataStore)

  return (
    <ExpansionPanel>
     <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography className={classes.heading}> {t('rechtliches.dataprotection.responsibleIs')} </Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails className={classes.panelDetails}>
     <Table className={classes.table} aria-label="simple table">
       <TableBody>

        <TableRow>
         <TableCell>
           {kibsmeta.name}
         </TableCell>
         <TableCell align="right">{}</TableCell>
        </TableRow>

        <TableRow>
         <TableCell>
           {t('rechtliches.dataprotection.responsiblePerson')}
         </TableCell>
         <TableCell align="right">{}</TableCell>
        </TableRow>

        <TableRow>
         <TableCell>
           {kibsmeta.strasse}
         </TableCell>
         <TableCell align="right">{}</TableCell>
        </TableRow>

        <TableRow>
         <TableCell>
           {kibsmeta.plz} {kibsmeta.standort}
         </TableCell>
         <TableCell align="right">{}</TableCell>
        </TableRow>

        <TableRow>
         <TableCell>
           {t('core.footer.phone')}
         </TableCell>
         <TableCell align="right">{kibsmeta.telefon}</TableCell>
        </TableRow>

        <TableRow>
         <TableCell>
           {t('core.footer.mail')}
         </TableCell>
         <TableCell align="right">{kibsmeta.mail}</TableCell>
        </TableRow>

       </TableBody>
     </Table>
     </ExpansionPanelDetails>
    </ExpansionPanel>
  )
})

export default Rechtliches
