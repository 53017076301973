import React, { useContext,useEffect } from "react"
import ReactDOM from "react-dom"
import { useObserver,observer } from 'mobx-react-lite'



const PhishingSensibilisierung = observer(() => {
  return (
    <p>psens</p>
  )
})

export default PhishingSensibilisierung
