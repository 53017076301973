import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import Ajax from 'api/ajax'

class Data {
 countSuS = 0
 countTeacher = 0

 zyklus1 = false
 zyklus2 = false
 zyklus3 = false

 IOS_COUNT = 0
 ANDROID_COUNT = 0
 OSX_COUNT = 0
 WINDOWS_COUNT = 0
 CHROMEOS_COUNT = 0
 LINUX_COUNT = 0
 OTHERS_COUNT = 0

 setData = (data) => {
   const {
     sus,
     teacher,
     zyklus1,
     zyklus2,
     zyklus3,
     ios_count,
     android_count,
     osx_count,
     windows_count,
     chromeos_count,
     linux_count,
     others_count,
   } = data

   if (sus) this.countSuS = sus
   if (teacher) this.countTeacher = teacher
   this.zyklus1 = zyklus1
   this.zyklus2 = zyklus2
   this.zyklus3 = zyklus3
   this.IOS_COUNT = ios_count
   this.ANDROID_COUNT = android_count
   this.OSX_COUNT = osx_count
   this.WINDOWS_COUNT = windows_count
   this.CHROMEOS_COUNT = chromeos_count
   this.LINUX_COUNT = linux_count
   this.OTHERS_COUNT = others_count
 }

 setCountSus = (sus) => this.countSuS = sus
 setCountTeacher = (teacher) => this.countTeacher = teacher
 setZyklus1 = () => this.zyklus1 = !this.zyklus1
 setZyklus2 = () => this.zyklus2 = !this.zyklus2
 setZyklus3 = () => this.zyklus3 = !this.zyklus3

 get IOS () { return this.IOS_COUNT > 0 }
 get ANDROID () { return this.ANDROID_COUNT > 0 }
 get OSX () { return this.OSX_COUNT > 0 }
 get WINDOWS () { return this.WINDOWS_COUNT > 0 }
 get CHROMEOS () { return this.CHROMEOS_COUNT > 0 }
 get LINUX () { return this.LINUX_COUNT > 0 }
 get OTHERS () { return this.OTHERS_COUNT > 0 }

 setIosCount = (count) => { this.IOS_COUNT = count <= 0 ? 0 : count }
 setAndroidCount = (count) => { this.ANDROID_COUNT = count <= 0 ? 0 : count }
 setOsxCount = (count) => { this.OSX_COUNT = count <= 0 ? 0 : count }
 setWindowsCount = (count) => { this.WINDOWS_COUNT = count <= 0 ? 0 : count }
 setChromeosCount = (count) => { this.CHROMEOS_COUNT = count <= 0 ? 0 : count }
 setLinuxCount = (count) => { this.LINUX_COUNT = count <= 0 ? 0 : count }
 setOthersCount = (count) => { this.OTHERS_COUNT = count <= 0 ? 0 : count }

 getSchoolNumbers = (schoolKey,cbSuccess,cbFail) => {
   Ajax.getSchoolNumbers(
     { schoolKey },
     (res) => {
       if (res && res.data && res.data.success) {
         this.setData(res.data.schoolnumbers)
         cbSuccess()
       } else cbFail()
     },
     cbFail
   )
 }

 update = (schoolKey,cbSuccess,cbFail) => {
   Ajax.updateSchoolnumbers(
    { schoolKey, data:this.serialized },
    (res) => {
      if (res && res.data && res.data.success) {
        cbSuccess()
      } else cbFail()
    },
    cbFail
   )
 }

 get serialized () {
   return {
     sus:this.countSuS,
     teacher:this.countTeacher,
     zyklus1:this.zyklus1,
     zyklus2:this.zyklus2,
     zyklus3:this.zyklus3,
     iosCount:this.IOS_COUNT,
     androidCount:this.ANDROID_COUNT,
     osxCount:this.OSX_COUNT,
     windowsCount:this.WINDOWS_COUNT,
     chromeosCount:this.CHROMEOS_COUNT,
     linuxCount:this.LINUX_COUNT,
     othersCount:this.OTHERS_COUNT
   }
 }

}

decorate(Data,{
  countSuS:observable,
  countTeacher:observable,
  zyklus1:observable,
  zyklus2:observable,
  zyklus3:observable,
  IOS:computed,
  ANDROID:computed,
  OSX:computed,
  WINDOWS:computed,
  CHROMEOS:computed,
  LINUX:computed,
  OTHERS:computed,
  IOS_COUNT:observable,
  ANDROID_COUNT:observable,
  OSX_COUNT:observable,
  WINDOWS_COUNT:observable,
  CHROMEOS_COUNT:observable,
  LINUX_COUNT:observable,
  OTHERS_COUNT:observable,
  serialized:computed
})

const DataStore = createContext(new Data())
export default DataStore
