import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import Ajax from 'api/ajax'
import ls from 'local-storage'



class User {
  id
  name = ''
  foreName = ''
  password = ''
  photo = null
  mail = ''
  mails = []
  mailsWithoutOAuth = []
  deletedMails = []
  phones = []
  deletedPhones = []
  schools = []
  pendings = []
  soe = []
  isAuthenticated = false
  counter = 0
  forgotPwMail = ''

  getId = () => {
    return this.id
  }

  itsMe = (member) => {
    return member.id === this.id
  }
  get hasSchools () {
    return this.schools && this.schools.length > 0
  }

  isMember = (key) => {
    return this.schools.filter(s => s.key === key).length > 0
  }

  getNewCounter = () => {
    this.counter++
    return this.counter
  }

  set = (user) => {
    if (user.id) this.setId(user.id)
    if (user.name) this.setName(user.name)
    if (user.foreName) this.setForeName(user.foreName)
    if (user.mail) this.setMail(user.mail)
    if (user.photo && user.photo !== '') this.setPhoto(user.photo)
    if (user.token) {
      ls('token',user.token)
      this.setAuthenticated(true)
    } else {
      this.setAuthenticated(false)
    }
    if (user.soe) {
      this.soe = user.soe.map(soe => new SOE(soe))
    }
    if (user.schools) {
      this.schools = user.schools.map(s => new School(s))
    }
    if (user.mails) {
      this.mails = user.mails.map((m,i) => {
        m.clientId = i
        return new Mail(m)
      })
      this.mailsWithoutOAuth = this.mails.filter((m) => {
        if (!m.isGoogleOAuth && !m.isOfficeOAuth && !m.isKibsAuth) {
          return m
        }
      })
      if (this.mailsWithoutOAuth.length === 0) {
        this.mailsWithoutOAuth = [new Mail({clientId:this.getNewCounter()})]
      }
    }
    if (user.phones && user.phones.length > 0) {
      this.phones = user.phones.map((p,i) => {
        p.clientId = i
        return new Phone(p)
      })
    } else {
      this.phones = [new Phone({clientId:this.getNewCounter()})]
    }
  }
  setAuthenticated = (value) => {
    this.isAuthenticated = value
  }
  setId = (id) => {
    this.id = id
  }
  setName = (name) => {
    this.name = name
  }
  setForeName = (foreName) => {
    this.foreName = foreName
  }
  setPassword = (password) => {
    this.password = password
  }
  setMail = (mail) => {
    this.mail = mail
  }
  setForgotPwMail = (mail) => {
    this.forgotPwMail = mail
  }
  setPhoto = (photo) => {
    this.photo = photo+'?rand='+Math.random()
  }
  setPendings = (pendings) => {
    this.pendings = pendings
  }
  addMailWithoutOAuth = () => {
    this.mailsWithoutOAuth = [...this.mailsWithoutOAuth,new Mail({clientId:this.getNewCounter()})]
  }
  removeMailWithoutOAuth = (clientId) => {
    this.mailsWithoutOAuth.filter(m => {
      if (m.clientId === clientId) {
        if (m.id) {
          m.setOp(m.OPS.DELETE)
          this.deletedMails.push(m)
          this.mails = this.mails.filter(mail => mail.id !== m.id)
        }
      }
    })
    this.mailsWithoutOAuth = this.mailsWithoutOAuth.filter(m => {
      if (m.clientId !== clientId && !m.mustBeDeleted) {
        return m
      }
    })
    const hasOnlyDeletedmailsWithoutOAuthLeft = this.mailsWithoutOAuth.filter(m => m.mustBeDeleted).length === this.mailsWithoutOAuth.length
    // if it would be empty, add a new one so user has a field to insert more
    if (hasOnlyDeletedmailsWithoutOAuthLeft || this.mailsWithoutOAuth.length === 0) {
      this.addMailWithoutOAuth()
    }
  }
  addPhone = () => {
    this.phones = [...this.phones,new Phone({clientId:this.getNewCounter()})]
  }
  removePhone = (clientId) => {
   this.phones.filter(p => {
     if (p.clientId === clientId) {
       if (p.id) {
         p.setOp(p.OPS.DELETE)
         this.deletedPhones.push(p)
       }
     }
   })

   this.phones = this.phones.filter(p => {
     if (p.clientId !== clientId && !p.mustBeDeleted) {
       return p
     }
   })

   const hasOnlyDeletedPhonesLeft = this.phones.filter(p => p.mustBeDeleted).length === this.phones.length
   // if it would be empty, add a new one so user has a field to insert more
   if (hasOnlyDeletedPhonesLeft || this.phones.length === 0) {
     this.addPhone()
   }
  }

  removeLocation = (schulhausKey) => {
    this.schools = this.schools.filter(s => parseInt(s.key) !== parseInt(schulhausKey))
  }

  finalDeletion = (cbSuccess,cbFail) => {
    Ajax.finalDeletionAccount(
      (res) => {
        if (res && res.data && res.data.success) {
          this.signOut()
          cbSuccess()
        } else {
          cbFail()
        }
      },
      cbFail
    )
  }

  signIn = (cbSuccess,cbFail) => {
   Ajax.signIn(this.mail,this.password,
    (res) => {
      this.set(res.data)
      cbSuccess()
    },
    (err) => { cbFail() }
   )
  }

  signUp = (cbSuccess,cbFail) => {
    Ajax.signUp(this.name,this.foreName,this.mail,this.password,
     (res) => {
       if (res && res.data) {
       if (res.data.user) {
         this.set(res.data.user)
       }
       cbSuccess(res.data)
      } else cbFail()
     },
     (err) => {
       cbFail(err)
     }
    )
  }

  forgotPw = (cbSuccess,cbFail) => {
   if (this.forgotPwMail && this.forgotPwMail !== '') {
     Ajax.resetPw(
       this.forgotPwMail,
       (res) => {
         this.setForgotPwMail('')
         if (res && res.data && res.data.success) {
           cbSuccess(res.data)
         } else cbFail()
       },
       cbFail
     )
   }
  }

  signOut = (cbSuccess,cbFail) => {
    ls.remove('token')
    this.setAuthenticated(false)
  }

  check = (cbSuccess,cbFail) => {
    const hasToken = ls('token') ? true : false
    if (hasToken) {
      Ajax.checkUser(
        (res) => {
         if (res && res.data && res.data.success && res.data.user) {
           this.set(res.data.user)
           cbSuccess(this.schools.length > 0)
         }
        },
        cbFail
      )
    }
  }

  resetPw = (mail,cb) => {
   Ajax.resetPw(mail,
    (res) => {
     cb()
    },
    (err) => {
      console.log(err);
    }
   )
  }

  validateOAuthorizationCode = (code,cb) => {
    Ajax.validateOAuthorizationCode(code,
     (res) => {
       cb()
     },
     (err) => {
       console.log(err);
     }
    )
  }

  getSchoolsWhereUserIsMember = (cb) => {
    Ajax.getSchoolsWhereUserIsMember(
      (res) => {
        if (res && res.data && res.data.success) {
          this.schools = res.data.schools.map(s => new School(s))
          cb(this.schools)
        } else cb(null)
      },
      (err) => {
        cb([])
      }
    )
  }

  getPendingRequests = (cb) => {
    Ajax.getPendingRequests(
      (res) => {
        if (res && res.data &&res.data.success) {
          this.setPendings(res.data.requests)
        }
        if (cb) cb()
      }
    )
  }

  updateAvatar = (avatar,cbSuccess,cbFail) => {
    const formData = new FormData()
    if (avatar) {
     formData.append('avatar',avatar[0])
     Ajax.updateAvatar(
       formData,
       (res) => {
         res = res.data
         if (res.success) {
           this.setPhoto(res.photo)
           cbSuccess()
         } else cbFail()
       },
       cbFail
     )
    }
  }

  cancelInviteRequest = (erzKey,cbSuccess,cbFail) => {
    if (erzKey) {
      Ajax.cancelInviteRequest(
        erzKey,
        cbSuccess,
        cbFail
      )
    }
  }

  update = (cbSuccess,cbFail) => {
   Ajax.updateUser(
     this.serialized,
     cbSuccess,
     cbFail
   )
  }

  get serialized () {
    const p = this.phones.filter(p => {
      if (p.mustBeDeleted || p.mustBeUpdated || p.mustBeInserted) {
        return p.serialized
      }
    })
    const m = this.mailsWithoutOAuth.filter(m => {
      if (m.mustBeDeleted || m.mustBeUpdated || m.mustBeInserted) {
        return m.serialized
      }
    })
    const phones = [...p,...this.deletedPhones]
    const mails = [...m,...this.deletedMails]

    return {
     name:this.name,
     foreName:this.foreName,
     mails:mails,
     phones:phones
    }
  }

}

class Mail {
  mail = ''
  identifier = ''
  OPS = {
    DELETE:'delete',
    INSERT:'insert',
    UPDATE:'update'
  }
  op

  get serialized () {
    return {
      mail:this.mail,
      identifier:this.identifier,
      op:this.op,
      id:this.id
    }
  }

  constructor (d) {
    Object.assign(this,d)
    if (d.mail) this.mail = d.mail
    if (d.bezeichner) this.identifier = d.bezeichner
    if (d.id) this.op = this.OPS.UPDATE; else this.op = this.OPS.INSERT
  }

  setOp = (op) => {
    this.op = op
  }

  changeMail = (value) => {
    this.mail = value
    if (this.id && !this.isEmpty) this.op = this.OPS.UPDATE
    if (!this.id && !this.isEmpty) this.op = this.OPS.INSERT
  }
   changeIdentifier = (value) => {
    this.identifier = value
    if (this.id && !this.isEmpty) this.op = this.OPS.UPDATE
    if (!this.id && !this.isEmpty) this.op = this.OPS.INSERT
  }
  get isEmpty () {
    return this.mail.trim() === '' && this.identifier.trim() === ''
  }
  get mustBeInserted () {
    return this.op === this.OPS.INSERT && !this.id && !this.isEmpty
  }
  get mustBeDeleted () {
    return this.op === this.OPS.DELETE && this.id
  }
  get mustBeUpdated () {
    return this.op === this.OPS.UPDATE && this.id
  }

}

class Dashboard {
  currSchool

  setCurrentSchool = (school) => {
    this.currSchool = school
  }

}

class Phone {
  telefon = ''
  identifier = ''

  OPS = {
    DELETE:'delete',
    INSERT:'insert',
    UPDATE:'update'
  }
  op

  get serialized () {
    return {
      telefon:this.telefon,
      identifier:this.identifier,
      op:this.op,
      id:this.id
    }
  }

  constructor (d) {
    Object.assign(this,d)
    if (d.telefon) this.telefon = d.telefon
    if (d.bezeichner) this.identifier = d.bezeichner
    if (d.id) this.op = this.OPS.UPDATE; else this.op = this.OPS.INSERT
  }

  setOp = (op) => {
    this.op = op
  }

  changePhone = (value) => {
    this.telefon = value
    if (this.id && !this.isEmpty) this.op = this.OPS.UPDATE
    if (!this.id && !this.isEmpty) this.op = this.OPS.INSERT
  }
   changeIdentifier = (value) => {
    this.identifier = value
    if (this.id && !this.isEmpty) this.op = this.OPS.UPDATE
    if (!this.id && !this.isEmpty) this.op = this.OPS.INSERT
  }
  get isEmpty () {
    return this.telefon.trim() === '' && this.identifier.trim() === ''
  }
  get mustBeInserted () {
    return this.op === this.OPS.INSERT && !this.id && !this.isEmpty
  }
  get mustBeDeleted () {
    return this.op === this.OPS.DELETE && this.id
  }
  get mustBeUpdated () {
    return this.op === this.OPS.UPDATE && this.id
  }

}

class SchoolPage {
  id = null
  soe
  school
  currMember
  memberFunctions = []
  teachingLevels = []

  setId = (id) => {
    this.id = id
  }
  getData = (cbSuccess,cbFail) => {
   Ajax.getSchoolpage(
     this.id,
     (res) => {
       if (res.data) {
         res = res.data
         if (res.success) {
           this.setData(res.schoolpage)
           cbSuccess()
         }
       }
     },
     cbFail
   )
  }

  setData = (schoolPage) => {
    if (schoolPage) {
      if (schoolPage.soe) this.soe = new SOE(schoolPage.soe)
      if (schoolPage.school) this.school = new School(schoolPage.school)
      this.memberFunctions = schoolPage && schoolPage.school.memberFunctions ? schoolPage.school.memberFunctions : []
      this.teachingLevels = schoolPage && schoolPage.school.teachingLevels ? schoolPage.school.teachingLevels : []
    } else {
      this.soe = null
      this.school = null
      this.memberFunctions = []
      this.teachingLevels = []
    }
  }
  setCurrentMember = (m) => {
    this.currMember = new Member(m)
  }
  leaveLocation = (itsMe,cbSuccess,cbFail) => {
    if (this.currMember) {
      Ajax.leaveSchool(
        { userId:this.currMember.id, schulhausKey:this.id },
        (res) => {
          if (res && res.data) {
            if (res.data.success) {
              if (itsMe) {
                this.setCurrentMember(null)
              }
              cbSuccess()
            } else cbFail()
          }
        },
        cbFail
      )
    }
  }

}

class SOE {
  name
  strasse
  plz
  standort
  phones

  constructor (data) {
    if (data) {
      const {
        name, strasse, standort, plz, phones
      } = data

      this.name = name ? name : ''
      this.strasse = strasse ? strasse : ''
      this.plz = plz ? plz : ''
      this.standort = standort ? standort : ''
      this.phones = phones ? phones.map(p => new Phone(p)) : []
    }
  }
}

class Invite {
  expiresAfter
  maxNumberOfUses
  code
  expireDate
  schulhausKey
  userId
  recipients = ''

  expiresAfterPossibilities = {
    MINUTES_5:'5m',
    MINUTES_30:'30m',
    HOURS_1:'1h',
    HOURS_6:'6h',
    HOURS_12:'12h',
    DAY_1:'1d',
    M5:{value:5,unit:'m'},
    M30:{value:30,unit:'m'},
    H1:{value:1,unit:'h'},
    H6:{value:6,unit:'h'},
    H12:{value:12,unit:'h'},
    D1:{value:1,unit:'d'}
  }
  maxNumberOfUsesPossibilities = {
    TIMES_1:1,
    TIMES_5:5,
    TIMES_10:10,
    TIMES_25:25,
    TIMES_UNLIMITED:-1
  }

  constructor (data) {
    if (data) {
     const { code, expireDate, fk_schulhaus_key, userId, maxNumberOfUses } = data
     this.expiresAfter = this.expiresAfterPossibilities.MINUTES_5
     this.code = code ? code : ''
     this.schulhausKey = fk_schulhaus_key ? fk_schulhaus_key : null
     this.userId = userId ? userId : null
     this.maxNumberOfUses = maxNumberOfUses ? maxNumberOfUses : -1
    }
  }

  getExpiresAfter = (asString) => {
    if (asString === this.expiresAfterPossibilities.MINUTES_5) return this.expiresAfterPossibilities.M5
    if (asString === this.expiresAfterPossibilities.MINUTES_30) return this.expiresAfterPossibilities.M30
    if (asString === this.expiresAfterPossibilities.HOURS_1) return this.expiresAfterPossibilities.H1
    if (asString === this.expiresAfterPossibilities.HOURS_6) return this.expiresAfterPossibilities.H6
    if (asString === this.expiresAfterPossibilities.HOURS_12) return this.expiresAfterPossibilities.H12
    if (asString === this.expiresAfterPossibilities.DAY_1) return this.expiresAfterPossibilities.D1
  }

  setExpiresAfter = (expiresAfter) => { this.expiresAfter = expiresAfter }
  setMaxNumberOfUses = (maxNumberOfUses) => { this.maxNumberOfUses = maxNumberOfUses }

  get sharedInviteLink () {
    return 'https://kibs.ch/join/'+this.code
  }

  get serialized () {
    return {
     expiresAfter:this.getExpiresAfter(this.expiresAfter),
     maxNumberOfUses:this.maxNumberOfUses,
     schulhausKey:this.schulhausKey
    }
  }

  generateInviteLink (cbSuccess,cbFail) {
    Ajax.generateInviteLink(
      this.serialized,
      (res) => {
        if (res && res.data && res.data.success) {
          res = res.data
          if (res.inviteLink && res.inviteLink.code) {
            this.code = res.inviteLink.code
            cbSuccess()
          } else cbFail()
        }
      },
      cbFail
    )
  }

  setRecipients = (recipients) => {
    this.recipients = recipients
  }

  sendInviteLink (cbSuccess,cbFail) {
    if (this.recipients && this.recipients !== '') {
      const to = this.recipients.split(';')

      Ajax.sendInviteLink(
        {
          to,
          schulhausKey:this.schulhausKey
        },
        (res) => {
          if (res && res.data && res.data.success) {
            const info = res.data.info
            cbSuccess()
          }
        },
        cbFail
      )
    }
  }
}

class School {
  key = null
  name
  strasse
  plz
  phones
  member
  requests
  invite

  constructor(data) {
    if (data) {
      const {
        name,fk_schulhaus_key,erz_key,strasse,plz,phones,member,requests,inviteLink
      } = data

      this.name = name ? name : ''
      this.strasse = strasse ? strasse : ''
      this.plz = plz ? plz : ''
      this.phones = phones ? phones.map(p => new Phone(p)) : []
      this.key = fk_schulhaus_key ? fk_schulhaus_key : null
      if (!this.key) {
        this.key = erz_key ? erz_key : null
      }
      this.member = member ? member.map(m => new Member(m)) : []
      this.invite = inviteLink ? new Invite(inviteLink) : new Invite({})
      if (this.member && this.member.length > 0) {
        this.member = this.sortByForeName(this.member)
      }
      this.requests = requests ? requests.map(r => new Request(r,this.key)) : []
      if (this.requests && this.requests.length > 0) {
        this.requests = this.sortByForeName(this.requests)
      }
    }
  }

  setName = (name) => {
    this.name = name
  }

  addMember = (m) => {
    this.removeRequest(m)
    this.member.push(new Member(m,true))
    this.member = this.sortByForeName(this.member)
  }

  removeMember = (m) => {
    this.member = this.member.filter(member => member.id !== m.id)
  }

  removeRequest = (r) => {
    this.requests = this.requests.filter(req => req.id !== r.id)
  }

  sortByForeName = (arr) => {
    return arr = arr.sort((a, b) => {
     if (a.foreName < b.foreName) {
       return -1
     }
     if (a.foreName > b.foreName) {
      return 1
     }
     return 0
    })
  }

  get schoolPage () {
    return '/dashboard/schoolpage/'+this.key
  }


}

class Request {
  id
  name
  foreName
  photo
  schoolId

  constructor (data,schoolId) {
    if (data && schoolId) {
      const { id,name,foreName,photo } = data
      this.id = id ? id : null
      this.name = name ? name : ''
      this.foreName = foreName ? foreName : ''
      this.photo = photo ? photo : null
      this.schoolId = schoolId ? schoolId : null
    }
  }

  accept = (cbSuccess,cbFail) => {
   if (this.id && this.schoolId) {
     Ajax.acceptRequest(
       { schulhausKey:this.schoolId,userId:this.id },
       (res) => {
         if (res && res.data && res.data.success) {
          cbSuccess()
         }
       },
       cbFail
     )
   }
  }
  decline = (cbSuccess,cbFail) => {
    if (this.id && this.schoolId) {
      Ajax.declineRequest(
        { schulhausKey:this.schoolId,userId:this.id },
        (res) => {
          if (res && res.data && res.data.success) {
           cbSuccess()
          }
        },
        cbFail
      )
    }
  }
}

class Member {
 id
 name
 foreName
 photo
 functions
 teachingLevels
 mails
 phones

 justAdded

 constructor (data,justAdded = false) {
   if (data) {
     const {
       id,name,foreName,photo,functions,teachingLevels,mails,phones
     } = data
     this.id = id ? id : null
     this.name = name ? name : ''
     this.foreName = foreName ? foreName : ''
     this.photo = photo ? photo : null
     this.functions = functions ? functions : []
     this.teachingLevels = teachingLevels ? teachingLevels : []
     this.mails = mails ? mails.map(m => new Mail(m)) : []
     this.phones = phones ? phones.map(p => new Phone(p)) : []
   }
   this.justAdded = justAdded
 }

 get hasFunctionsOrTeachingLevels () {
   return this.functions.length > 0 || this.teachingLevels.length > 0
 }

 getDetails = (schulhausKey,cbSuccess,cbFail) => {
   Ajax.getMemberDetails(
     { id:this.id, schulhausKey },
     (res) => {
       if (res && res.data) {
         if (res.data.success) {
           cbSuccess(res.data.detailedMember)
         } else cbFail()
       }
     },
     cbFail
   )
 }

 addMemberFunction = (schulhausKey,memberFn,cbSuccess,cbFail) => {
   Ajax.addMemberFunction(
     { userId:this.id, schulhausKey, memberFn:memberFn.id },
     (res) => {
       if (res && res.data && res.data.success) {
         this.addLocalMemberFunction(memberFn)
         cbSuccess()
       } else cbFail()
     },
     cbFail
   )
 }
 deleteMemberFunction = (schulhausKey,memberFn,cbSuccess,cbFail) => {
   Ajax.deleteMemberFunction(
     { userId:this.id, schulhausKey, memberFn:memberFn.id },
     (res) => {
       if (res && res.data && res.data.success) {
         this.deleteLocalMemberFunction(memberFn)
         cbSuccess()
       } else cbFail()
     },
     cbFail
   )
 }
 addMemberTeachingLevel = (schulhausKey,teachingLevel,cbSuccess,cbFail) => {
   Ajax.addMemberTeachingLevel(
     { userId:this.id, schulhausKey, teachingLevel:teachingLevel.id },
     (res) => {
       if (res && res.data && res.data.success) {
         this.addLocalTeachingLevel(teachingLevel)
       }
       cbSuccess()
     },
     cbFail
   )
 }
 deleteMemberTeachingLevel = (schulhausKey,teachingLevel,cbSuccess,cbFail) => {
   Ajax.deleteMemberTeachingLevel(
     { userId:this.id, schulhausKey, teachingLevel:teachingLevel.id },
     (res) => {
       if (res && res.data && res.data.success) {
         this.deleteLocalTeachingLevel(teachingLevel)
         cbSuccess()
       } else cbFail()
     },
     cbFail
   )
 }
 addLocalMemberFunction = (fn) => { this.functions.push(fn) }
 deleteLocalMemberFunction = (fn) => { this.functions = this.functions.filter(f => f.id !== fn.id) }
 addLocalTeachingLevel = (tl) => { this.teachingLevels.push(tl) }
 deleteLocalTeachingLevel = (tl) => { this.teachingLevels = this.teachingLevels.filter(t => t.id !== tl.id) }
}

class Administration {
  currentRedirectRequest = null
  currentReleaseUser = null
  isFirstUser = true
  inviteRequestsWhereUserIsFirstAtLocation = []
  inviteRequestsWhereUserIsNotFirstAtLocation = []
  redirectRequests = []
  allRedirectRequests = []

  setAllRedirects = (requests) => {
    this.allRedirectRequests = requests
  }
  setCurrentRedirectRequest = (request) => {
    this.currentRedirectRequest = request
  }
  setInviteRequestsWhereUserIsFirstAtLocation = (requests) => {
    this.inviteRequestsWhereUserIsFirstAtLocation = requests
  }
  setInviteRequestsWhereUserIsNotFirstAtLocation = (requests) => {
    this.inviteRequestsWhereUserIsNotFirstAtLocation = requests
  }
  setCurrentReleaseUser = (user) => {
    this.currentReleaseUser = user
  }
  setRedirectRequests = (pendings) => {
    this.redirectRequests = pendings
  }
  // a flag that is needed to determine if we accept an invite from Kibs (because a User is a first user)
  // or if we accept an invite on a school, where people are pending and should be released by schoolmember
  // see "acceptInvite" for the isFirstUser-Flag
  setFirstUser = (value) => this.isFirstUser = value

  getAllRedirects = (cbSuccess,cbFail) => {
    Ajax.getAllRedirects(
      (res) => {
        this.setAllRedirects(res.data.redirects)
        cbSuccess()
      },
      cbFail
    )
  }

  getPendingRedirects = (cbSuccess,cbFail) => {
    Ajax.getPendingRedirects(
      (res) => {
        if (res && res.data && res.data.success) {
          this.setRedirectRequests(res.data.pendings)
          cbSuccess()
        }
      },
      cbFail
    )
  }
  acceptRedirectRequest = (cbSuccess,cbFail) => {
   if (this.currentRedirectRequest) {
     Ajax.acceptRedirect(
       this.currentRedirectRequest.id,
       (res) => {
        if (res && res.data && res.data.success) {
          this.redirectRequests = this.redirectRequests.filter(r => r.id !== this.currentRedirectRequest.id)
        }
        cbSuccess(res.data.success)
       },
       cbFail
     )
   }
  }
  declineRedirectRequest = (cbSuccess,cbFail) => {
   if (this.currentRedirectRequest) {
     Ajax.declineRedirect(
       this.currentRedirectRequest.id,
       (res) => {
         if (res && res.data && res.data.success) {
           this.redirectRequests = this.redirectRequests.filter(r => r.id !== this.currentRedirectRequest.id)
           cbSuccess()
         } else cbFail()
       },
       cbFail
     )
   }
  }

  getInviteRequestsWhereUserIsNotFirstAtLocation = (cbSuccess,cbFail) => {
    Ajax.getInviteRequestsWhereUserIsNotFirstAtLocation(
      (res) => {
        if (res.data) {
          res = res.data
          if (res.success) {
            this.setInviteRequestsWhereUserIsNotFirstAtLocation(res.requests)
          }
          cbSuccess()
        } else cbFail()
      }
    )
  }
  getInviteRequestsWhereUserIsFirstAtLocation = (cbSuccess,cbFail) => {
    Ajax.getInviteRequestsWhereUserIsFirstAtLocation(
      (res) => {
        if (res.data) {
          res = res.data
          if (res.success) {
            this.setInviteRequestsWhereUserIsFirstAtLocation(res.requests)
          }
          cbSuccess()
        } else cbFail()
      }
    )
  }
  checkLocations = (erzKey,userId) => {
    const numberLocationsLeft = this.removeLocation(erzKey)
    if (numberLocationsLeft === 0) {
      this.inviteRequestsWhereUserIsFirstAtLocation = this.inviteRequestsWhereUserIsFirstAtLocation.filter(u => u.id !== userId)
    }
    return numberLocationsLeft
  }
  checkLocationsNotFirst = (erzKey,userId) => {
    const numberLocationsLeft = this.removeLocation(erzKey)
    if (numberLocationsLeft === 0) {
      this.inviteRequestsWhereUserIsNotFirstAtLocation = this.inviteRequestsWhereUserIsNotFirstAtLocation.filter(u => u.id !== userId)
    }
    return numberLocationsLeft
  }
  removeLocation = (erzKey) => {
    this.currentReleaseUser.locations = this.currentReleaseUser.locations.filter(l => l.erz_key !== erzKey)
    return this.currentReleaseUser.locations.length
  }
  declineInvite = (erzKey,userId,cbSuccess,cbFail) => {
    if (erzKey && userId) {
      Ajax.declineInvite(
        { erzKey, userId },
        (res) => {
          if (res.data && res.data.success) {
            cbSuccess(res.data.success,this.checkLocations(erzKey,userId))
          } else cbFail()
        },
        cbFail
      )
    }
  }
  acceptInvite = (erzKey,userId,cbSuccess,cbFail) => {
    if (erzKey && userId) {
      if (this.isFirstUser) {
        Ajax.acceptInvite(
          { erzKey, userId },
          (res) => {
            if (res.data && res.data.success) {
              cbSuccess(res.data.success,this.checkLocations(erzKey,userId))
            } else cbFail()
          },
          cbFail
        )
      } else {
        Ajax.acceptInviteForNotFirstUsers(
          { erzKey, userId },
          (res) => {
            if (res.data && res.data.success) {
              cbSuccess(res.data.success,this.checkLocationsNotFirst(erzKey,userId))
            } else cbFail()
          },
          cbFail
        )
      }
    } else cbFail()
  }

}

class AdminSurveys {
  gFormsLink = ''

  createSurvey = (link,cbSuccess,cbFail) => {
    this.gFormsLink = link
    Ajax.createSurvey(
      {gFormsLink:this.gFormsLink},
      (res) => {

      },
      cbFail
    )
  }
}

class InviteRoutine {
  code
  school
  from
  expired
  valid
  alreadyJoined

  checkInvite = (code,userId,cbSuccess,cbFail) => {
    this.code = code
    userId = userId ? userId : -100

    Ajax.checkInvite(
      { code,userId },
      (res) => {
       if (res && res.data && res.data.success) {
         res = res.data.data
         this.setData(res)
         cbSuccess()
       } else cbFail()
      },
      cbFail
    )
  }

  join = (cbSuccess,cbFail) => {
    Ajax.joinSchoolByLink(
      {
        code:this.code,
        erzKey:this.school.key
      },
      (res) => {
        if (res && res.data && res.data.success) {
          cbSuccess()
        } else cbFail()
      },
      cbFail
    )
  }

  setData = (data) => {
    this.school = new School(data.school)
    this.from = data.from
    this.expired = data.expired
    this.valid = data.valid
    this.alreadyJoined = data.alreadyJoined
  }

  get schoolAcronym () {
    const splitted = this.school.name.split(' ')
    return splitted.length >= 2 ? splitted[0].charAt(0) + splitted[1].charAt(0) : splitted[0].charAt(0)
  }

}

class Tagungen {

}

class News {
 tweets = []

 getNews = () => {
   Ajax.getKibsNews(
     (res) => {
       if (res && res.data && res.data.success) {
         res.data.tweets.map(t => this.tweets.push(t))
       }
     },
     () => {}
   )
 }
}


class Data {
  user = new User()
  currSchool = new School()
  schoolPage = new SchoolPage()
  inviteRoutine = new InviteRoutine()
  dashboard = new Dashboard()
  tagungen = new Tagungen()
  kibsmeta = {}
  news = new News()

  administration = new Administration()
  adminSurveys = new AdminSurveys()

  setCurrentSchool = (school) => {
    this.currSchool = school
  }
  setKibsMeta = (cbSuccess,cbFail) => {
    if (!this.kibsmeta || Object.entries(this.kibsmeta).length === 0) {
      Ajax.getKibsMeta(
        (res) => {
          if (res && res.data && res.data.success) {
            this.kibsmeta = res.data.meta
          }
        },
        cbFail
      )
    }
  }
}

decorate(User, {
 id:observable,
 name: observable,
 foreName: observable,
 mail: observable,
 mails:observable,
 forgotPwMail:observable,
 mailsWithoutOAuth:observable,
 phones:observable,
 deletePhones:observable,
 password: observable,
 photo:observable,
 schools: observable,
 pendings:observable,
 soe:observable,
 isAuthenticated:observable,
 hasSchools:computed,
 counter:observable,
 serialized:computed
})
decorate(Member,{
  functions:observable,
  teachingLevels:observable,
  hasFunctionsOrTeachingLevels:computed,
  justAdded:observable
})
decorate(School,{
  name:observable,
  key:observable,
  strasse:observable,
  plz:observable,
  phones:observable,
  member:observable,
  requests:observable,
  invite:observable,
  schoolPage:computed
})
decorate(SOE,{
  name:observable,
  strasse:observable,
  plz:observable,
  standort:observable,
  phones:observable
})
decorate(SchoolPage,{
  id:observable,
  soe:observable,
  school:observable,
  currMember:observable,
  memberFunctions:observable,
  teachingLevels:observable
})
decorate(Mail,{
  mail:observable,
  identifier:observable,
  op:observable,
  isEmpty:computed,
  mustBeInserted:computed,
  mustBeDeleted:computed,
  mustBeUpdated:computed,
  serialized:computed
})
decorate(Phone,{
  telefon:observable,
  identifier:observable,
  op:observable,
  isEmpty:computed,
  mustBeInserted:computed,
  mustBeDeleted:computed,
  mustBeUpdated:computed,
  serialized:computed
})
decorate(Administration,{
  inviteRequestsWhereUserIsFirstAtLocation:observable,
  inviteRequestsWhereUserIsNotFirstAtLocation:observable,
  currentReleaseUser:observable,
  currentRedirectRequest:observable,
  allRedirectRequests:observable,
  isFirstUser:observable,
  redirectRequests:observable
})
decorate(Invite,{
  code:observable,
  maxNumberOfUses:observable,
  expiresAfter:observable,
  sharedInviteLink:computed,
  serialized:computed,
  recipients:observable
})
decorate(InviteRoutine,{
  code:observable,
  school:observable,
  from:observable,
  expired:observable,
  valid:observable,
  schoolAcronym:computed,
  alreadyJoined:observable
})
decorate(Tagungen,{

})
decorate(News,{
  tweets:observable
})
decorate(Dashboard,{
  currSchool:observable
})
decorate(AdminSurveys,{
  gFormsLink:observable
})
decorate(Data,{
  currSchool:observable,
  user:observable,
  schoolPage:observable,
  kibsmeta:observable
})

const DataStore = createContext(new Data())
export default DataStore
