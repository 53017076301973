import React, { useContext,useEffect } from "react"
import ReactDOM from "react-dom"

import ReactFullpage from "@fullpage/react-fullpage"
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { useObserver,observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import IMG_Byte from 'media/img/internetanschluss/byte.png'
import IMG_Dot from 'media/img/internetanschluss/dot.png'
import IMG_SSL_1 from 'media/img/internetanschluss/bild_01.png'
import IMG_SSL_2 from 'media/img/internetanschluss/bild_02.png'
import IMG_SSL_3 from 'media/img/internetanschluss/bild_03.png'
import IMG_SSL_4 from 'media/img/internetanschluss/bild_04.png'
import IMG_FS_DE from 'media/img/internetanschluss/filtertechnikundsensibilisierung_de.png'
import IMG_FS_FR from 'media/img/internetanschluss/filtertechnikundsensibilisierung_fr.png'
import IMG_BRUSH_STROKE1 from 'media/img/internetanschluss/brushstrokeYellow.png'
import IMG_BRUSH_STROKE2 from 'media/img/internetanschluss/brushstrokeYellow2.png'
import IMG_BRUSH_STROKE3 from 'media/img/internetanschluss/brushstrokeYellow3.png'
import IMG_REAL_FLYER from 'media/img/internetanschluss/realFlyer.png'

import ImageZoom from 'react-medium-image-zoom'

import 'ext/animate.min.css'

import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'
import {
  Typography,
  Container,
  Box,
  Divider,
  Button,
  IconButton,
  Icon,
  Tabs,
  Tab,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Fab
} from '@material-ui/core/'

import {
  TwitterTimelineEmbed
} from 'react-twitter-embed'

import {
  VolumeOff,
  VolumeUp,
  Menu,
  KeyboardArrowUp
} from '@material-ui/icons'



// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
  //require('ext/fullpage.scrollHorizontally.min')
};
const LK = '5AC6763B-2FB4422F-87B323BB-E2C556CD'
const AK = 'cG9ydGFsLmtpYnMuY2hfOWo5YzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1T0do'

const useStyles = makeStyles(theme => ({
  page1text: {
    marginTop:theme.spacing(10)
  },
  avatar:{
    position:'absolute',
    bottom:'-0.5rem',
    zIndex:1,
    width:'35%',
    minWidth:125,
    maxWidth:200
  },
  bytePosition:{
    left:100
  },
  dotPosition:{
    right:100
  },
  imageBox:{
    background:'#fff',
    textAlign:'center',
    padding:theme.spacing(2)
  },
  centerText:{
    textAlign:'center'
  },
  divider:{
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2)
  },
  subtitle:{
    marginBottom:theme.spacing(4)
  },
  speechBubble:{
	 position: 'relative',
	 borderRadius: '.4em',
   color:'#fff',
   animationDelay:'0.25s',
   padding:theme.spacing(2),
   marginBottom:theme.spacing(3),
   '&:after': {
   	content: '""',
   	position: 'absolute',
   	bottom: 0,
   	left: '50%',
   	width: 0,
   	height: 0,
   	border: '20px solid transparent',
   	borderBottom: 0,
   	marginLeft: -10,
   	marginBottom: -20
   }
  },
  speechBubbleByte:{
   background: '#42a951',
   '&:after':{
     borderTopColor: '#42a951',
     borderLeft: 0,
   }
  },
  speechBubbleDot:{
    background: '#143153',
    '&:after':{
      borderTopColor: '#143153',
      borderRight: 0,
    }
  },
  playBtn:{
    position:'absolute',
    bottom:theme.spacing(1),
    left:theme.spacing(1)
  },
  tabsContainer:{
    position:'absolute',
    right:0,
    zIndex:10,
    background:'#fafafa'
  },
  displayNone:{
    display:'none'
  },
  displayBlock:{
    display:'block'
  },
  brushStroke1:{
    background: 'url('+IMG_BRUSH_STROKE1+')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 95%',
    padding: '8px 0'
  },
  brushStroke2:{
    background: 'url('+IMG_BRUSH_STROKE2+')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 95%',
    padding: '8px 0'
  },
  brushStroke2:{
    background: 'url('+IMG_BRUSH_STROKE3+')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 95%',
    padding: '8px 0'
  },
  backToTopButton:{
    position:'absolute',
    bottom:theme.spacing(2),
    right:theme.spacing(2)
  },
  toAmpelflyerBtn:{
    marginTop:theme.spacing(4)
  },
  imageContainer:{
    margin:'0 auto',
    textAlign:'center',
    padding:theme.spacing(4)
  },
  bigHeadingHash:{
    [theme.breakpoints.only('md')]:{
      fontSize:'2.75rem'
    },
    [theme.breakpoints.only('sm')]:{
      fontSize:'2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
      wordBreak:'break-word',
      marginTop:theme.spacing(8)
    },
    fontSize:'3.75rem'
  }
}))

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})(props => <Tabs scrollButtons="auto" {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#000',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))(props => <Tab disableRipple {...props} />)

const fp_auto_height_responsive = 'fp-auto-height-responsive'

const InternetAnschluss = observer(() => {
  const ui = useContext(UiStore)
  const globalUi = useContext(GlobalUiStore)

  const afterLoad = (origin, destination, direction) => {
    ui.setSelectTab(destination.index)
    ui.doMagic('section',destination.index)

    if (destination.index === ui.LAST_SECTION_INDEX - 1) {
      ui.showBackToTopBtn()
    } else {
      ui.hideBacktoTopBtn()
    }
  }

  const afterSlideLoad = (sec, origin, destination, direction) => {
    ui.doMagic('slide',destination.index)
    if (direction === 'right' && destination.index === 0) {
      const nextSectionIndex = parseInt(sec.index) + 2
      window.fullpage_api.moveTo(nextSectionIndex)
    }
  }

  useEffect(() => {
    const style = document.createElement('style')
    const color = 'rgba(0, 0, 0, 0.54)'
    style.innerHTML = `
     .fp-controlArrow.fp-next {
       border-color: transparent transparent transparent ${color};
       border-width: 2rem 0 2rem 2rem;
     }
     .fp-controlArrow.fp-prev {
       border-color: transparent ${color} transparent transparent;
       border-width: 2rem 2rem 2rem 0;
     }
    `
    document.head.append(style)
  },[])

  return (
   <>
    <Sections />
    <ReactFullpage
     pluginWrapper={pluginWrapper}
     licenseKey={LK}
     afterSlideLoad={afterSlideLoad}
     afterLoad={afterLoad}
     scrollHorizontally={true}
     scrollHorizontallyKey={AK}
     responsiveWidth={ui.RESPONSIVE_WIDTH}
     render={({ state, fullpageApi }) => {
      return (
       <ReactFullpage.Wrapper>
        <Page1 />
        <Page2 />
        <Page3 />
        <Page4 />
        <Page5 />
       </ReactFullpage.Wrapper>
      )
     }}
   />
   {!ui.isSmartphone &&
    <>
     <ByteAvatar />
     <DotAvatar />
    </>
   }
   <ToggleSoundButton />
   <BackToTopButton />
  </>
 )
})

const Sections = () => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    small
    ?
    <SectionDrawer/>
    :
    <SectionTabs/>
  )
}

const SectionTabs = observer(() => {
  const classes = useStyles()
  const ui = useContext(UiStore)
  const { t } = useTranslation()

  const handleChange = (event, newValue) => {
   ui.setSelectTab(newValue)
   window.fullpage_api.moveTo(newValue+1)
  }

  return (
    <div className={classes.tabsContainer}>
    <StyledTabs value={ui.selectedTab} onChange={handleChange} aria-label="select-section">
     <StyledTab label={t('internetanschluss.hashtag')} />
     <StyledTab label={t('internetanschluss.sections.contentFilter')} />
     <StyledTab label={t('internetanschluss.sections.whatToKnow')} />
     <StyledTab label={t('internetanschluss.sections.sensitization')} />
     <StyledTab label={t('ampelflyer.title')} />
   </StyledTabs>
   </div>
  )
})

const SectionDrawer = observer(() => {
  const ui = useContext(UiStore)
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = index => event => {
   ui.setSelectTab(index)
   window.fullpage_api.moveTo(index)
   ui.toggleDrawerOpen()
  }

  return (
   <>
     <IconButton onClick={ui.toggleDrawerOpen} className={classes.tabsContainer}>
      <Menu />
     </IconButton>
     <Drawer anchor="right" open={ui.drawerOpen} onClose={ui.toggleDrawerOpen}>
      <List component="nav" aria-label="select-section">
       <ListItem button onClick={handleChange(1)}>
        <ListItemText primary={t('internetanschluss.hashtag')} />
       </ListItem>
       <ListItem button onClick={handleChange(2)}>
        <ListItemText primary={t('internetanschluss.sections.contentFilter')} />
       </ListItem>
       <ListItem button onClick={handleChange(3)}>
        <ListItemText primary={t('internetanschluss.sections.whatToKnow')} />
       </ListItem>
       <ListItem button onClick={handleChange(4)}>
        <ListItemText primary={t('internetanschluss.sections.sensitization')} />
       </ListItem>
       <ListItem button onClick={handleChange(5)}>
        <ListItemText primary={t('ampelflyer.title')} />
       </ListItem>
      </List>
     </Drawer>
   </>
  )
})

const ToggleSoundButton = observer(() => {
  const classes = useStyles()
  const ui = useContext(UiStore)
  return (
    <IconButton className={classes.playBtn} onClick={ui.toggleSound}>
     <Icon> {ui.playSound ? <VolumeUp /> : <VolumeOff />} </Icon>
    </IconButton>
  )
})

const BackToTopButton = observer(() => {
  const classes = useStyles()
  const ui = useContext(UiStore)
  const onClick = () => {
    window.fullpage_api.moveTo(1)
  }
  return (
    ui.backToTopBtn
    ?
    <Fab aria-label="back-to-top" className={classes.backToTopButton} onClick={onClick}>
     <KeyboardArrowUp />
    </Fab>
    :
    null
  )
})

const Page1 = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={`section ${fp_auto_height_responsive}`}>
     <Container maxWidth="md">
      <Typography variant="h2" gutterBottom className={classes.bigHeadingHash}>
       <span className={classes.brushStroke1}> {t('internetanschluss.hashtag')} </span>
       <br />
       <span className={classes.brushStroke1}> {t('internetanschluss.title')} </span>
      </Typography>

      <Box className={classes.page1text}>
       <Typography variant="h4" gutterBottom> {t('internetanschluss.howSaveAreYou.title')} </Typography>
       <Typography variant="body1" gutterBottom> {t('internetanschluss.howSaveAreYou.content')} </Typography>
      </Box>
     </Container>
    </div>
  )
}

const Page2 = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
   <div className={`section`}>

    <div className="slide">
     <Container maxWidth="md" className={classes.centerText}>
      <Typography variant="h4"> {t('internetanschluss.sslInterception.title')} </Typography>
     </Container>
    </div>

    <div className="slide">
     <Container maxWidth="md" className={classes.centerText}>

      <Box boxShadow={1} className={classes.imageBox}>
       <Typography variant="body1" gutterBottom> {t('internetanschluss.sslInterception.point1')} </Typography>
       <Divider variant="middle" className={classes.divider} />
       <ImageZoom
         image={{
           src: IMG_SSL_1,
           alt: 'ssl1',
           className: 'img',
           style: { width:'80%' }
         }}
         zoomImage={{
           src: IMG_SSL_1,
           alt: 'ssl1'
         }}
        />
      </Box>
     </Container>
    </div>

    <div className="slide">
     <Container maxWidth="md" className={classes.centerText}>
      <Box boxShadow={1} className={classes.imageBox}>
       <Typography variant="body1" gutterBottom> {t('internetanschluss.sslInterception.point2')} </Typography>
       <Divider variant="middle" className={classes.divider} />
       <ImageZoom
         image={{
           src: IMG_SSL_2,
           alt: 'ssl2',
           className: 'img',
           style: { width:'80%' }
         }}
         zoomImage={{
           src: IMG_SSL_2,
           alt: 'ssl2'
         }}
        />
      </Box>
     </Container>
    </div>

    <div className="slide">
     <Container maxWidth="md" className={classes.centerText}>
      <Box boxShadow={1} className={classes.imageBox}>
       <Typography variant="body1" gutterBottom> {t('internetanschluss.sslInterception.point3')} </Typography>
       <Divider variant="middle" className={classes.divider} />
       <ImageZoom
         image={{
           src: IMG_SSL_3,
           alt: 'ssl3',
           className: 'img',
           style: { width:'80%' }
         }}
         zoomImage={{
           src: IMG_SSL_3,
           alt: 'ssl3'
         }}
        />
      </Box>
     </Container>
    </div>

    <div className="slide">
     <Container maxWidth="md" className={classes.centerText}>
      <Box boxShadow={1} className={classes.imageBox}>
       <Typography variant="body1" gutterBottom> {t('internetanschluss.sslInterception.point4')} </Typography>
       <Divider variant="middle" className={classes.divider} />
       <ImageZoom
         image={{
           src: IMG_SSL_4,
           alt: 'ssl4',
           className: 'img',
           style: { width:'80%' }
         }}
         zoomImage={{
           src: IMG_SSL_3,
           alt: 'ssl4'
         }}
        />
      </Box>
     </Container>
    </div>

   </div>
  )
})

const Page3 = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  let image = IMG_FS_DE
  if (i18next.language.match(/fr*/)) {
    image = IMG_FS_FR
  }

  return (
    <div className={`section ${fp_auto_height_responsive}`}>
     <Container maxWidth="md">
      <Box>
       <Typography variant="h4" gutterBottom className={classes.subtitle}> {t('internetanschluss.ourProtectionAndWhatToKnow.title')} </Typography>
       <Typography variant="body1" gutterBottom>
        {t('internetanschluss.ourProtectionAndWhatToKnow.point1')}
        {t('internetanschluss.ourProtectionAndWhatToKnow.point2')}{' '}
        {t('internetanschluss.ourProtectionAndWhatToKnow.point3')} <br/>
        {t('internetanschluss.ourProtectionAndWhatToKnow.point4')}
       </Typography>
       <div className={classes.imageContainer}>
         <ImageZoom
         image={{
           src: image,
           alt: 'whatToKnow',
           className: 'img',
           style: { height: '30vh' }
         }}
         zoomImage={{
           src: image,
           alt: 'whatToKnow'
         }}
        />
       </div>
       <Typography variant="body1">{t('internetanschluss.ourProtectionAndWhatToKnow.point5')}</Typography>
      </Box>
     </Container>
    </div>
  )
}

const Page4 = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ui = useContext(UiStore)

  const onClick = event => {
    window.fullpage_api.moveTo(ui.LAST_SECTION_INDEX)
  }

  return (
    <div className={`section`}>
     <Container maxWidth="md">
      <Box>
       <Typography variant="h4" gutterBottom className={classes.subtitle}> {t('internetanschluss.sensitization.title')} </Typography>
       <Typography variant="body1" gutterBottom>
        {t('internetanschluss.sensitization.point1')}{' '}
        {t('internetanschluss.sensitization.point2')}
       </Typography>
       <Button
        className={classes.toAmpelflyerBtn}
        variant="contained"
        color="primary"
        onClick={onClick}>{t('internetanschluss.sensitization.toAmpelflyer')}</Button>
      </Box>
     </Container>
    </div>
  )
})

const Page5 = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ui = useContext(UiStore)
  const history = useHistory()

  const onClick = (e) => {
    history.push('/datenschutz/ampelflyer')
  }

  return (
    <div className={`section ${fp_auto_height_responsive}`}>
     <Container maxWidth="md">
      <div className={classes.imageContainer}>
        <ImageZoom
        image={{
          src: IMG_REAL_FLYER,
          alt: 'realFlyer',
          className: 'img',
          style: { height: '40vh' }
        }}
        zoomImage={{
          src: IMG_REAL_FLYER,
          alt: 'realFlyer'
        }}
       />
      </div>
       <Typography variant="body1">
        {t('ampelflyer.heading1')}<br/>{t('ampelflyer.heading2')}
       </Typography>
       <Button
        className={classes.toAmpelflyerBtn}
        variant="contained"
        color="primary"
        onClick={onClick}>{t('internetanschluss.sensitization.createYourOwn')}</Button>
    </Container>
   </div>
  )
}

const Page6 = () => {
  return (
    <div className={`section ${fp_auto_height_responsive}`}>
     <Container maxWidth="md">
      <Box>
       <TwitterTimelineEmbed
        sourceType="profile"
        screenName="dunkelmunkel"
        options={{height: '80vh'}}
       />
      </Box>
     </Container>
    </div>
  )
}

const ByteAvatar = observer((props) => {
  const classes = useStyles()
  const ui = useContext(UiStore)
  const { t } = useTranslation()

  const animation = 'animated faster '
  let animationByte = animation + (ui.showByte ? 'slideInUp' : 'slideOutDown')
  let animationBubble = animation + (ui.showByte ? 'fadeInDown' : 'fadeOutUp')
  let bubbleDisplay = ui.byteWait ? classes.displayNone : classes.displayBlock
  animationByte = ui.byteWait && ui.showByte ? animation + 'slideOutDown25' : animationByte

  const onClick = (e) => { if (ui.showByte) ui.toggleByteWait() }

  useEffect(() => { if (!ui.byteWait) { ui.play(ui.audioMsg) } },[ui.byteWait])
  useEffect(() => {
   ui.setByteTimer(
    setTimeout(() => { ui.play(ui.audioMsg) },ui.SPEECH_BUBBLE_DELAY)
   )
   return () => { clearTimeout(ui.byteTimer) }
  },[ui.currentTextByte])

  return (
    <div className={`${classes.bytePosition} ${classes.avatar}`} onClick={onClick} style={{zIndex:ui.byteZIndex}}>
     <div
      className={`${classes.speechBubble} ${classes.speechBubbleByte} ${animationBubble} ${bubbleDisplay}`}
      dangerouslySetInnerHTML={{__html:t(ui.currentTextByte)}}
     />
     <img src={IMG_Byte} alt="byte" className={animationByte} style={{width:'100%'}}/>
    </div>
  )
})

const DotAvatar = observer((props) => {
  const classes = useStyles()
  const ui = useContext(UiStore)
  const { t } = useTranslation()

  const animation = 'animated faster '
  let animationDot = animation + (ui.showDot ? 'slideInUp' : 'slideOutDown')
  let animationBubble = animation + (ui.showDot ? 'fadeInDown' : 'fadeOutUp')
  let bubbleDisplay = ui.dotWait ? classes.displayNone : classes.displayBlock
  animationDot = ui.dotWait && ui.showDot ? animation + 'slideOutDown25' : animationDot

  const onClick = (e) => { if (ui.showDot) ui.toggleDotWait() }

  useEffect(() => { if (!ui.dotWait) { ui.play(ui.audioMsg) } },[ui.dotWait])
  useEffect(() => {
   ui.setDotTimer(
    setTimeout(() => { ui.play(ui.audioMsg) },ui.SPEECH_BUBBLE_DELAY)
   )
   return () => { clearTimeout(ui.dotTimer) }
  },[ui.currentTextDot])

  return (
   <div className={`${classes.dotPosition} ${classes.avatar}`} onClick={onClick} style={{zIndex:ui.dotZIndex}}>
    <div
     className={`${classes.speechBubble} ${classes.speechBubbleDot} ${animationBubble} ${bubbleDisplay}`}
     dangerouslySetInnerHTML={{__html:t(ui.currentTextDot)}}
     />
    <img src={IMG_Dot} alt="byte" className={animationDot} style={{width:'100%'}}/>
   </div>
  )
})



export default InternetAnschluss
