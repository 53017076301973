import React, { useContext,useEffect,useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer,useObserver } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Avatar,
  Button,
  Container,
  Grid,
  Dialog,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  useMediaQuery,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { MailOutline,Close,DeleteOutline,AddCircleOutline,Phone,AccountCircle,Publish } from '@material-ui/icons'
import KibsDropzone from 'shared/components/dropzone'

const useStyles = makeStyles(theme => ({
 root: {
   flexGrow:1,
   padding:theme.spacing(2),
   paddingTop:theme.spacing(6),
   borderTop:'1px solid #ddd'
 },
 wrapper:{
   width:'100%'
 },
 field:{
   width:'50%'
 },
 spaceRight:{
   paddingRight:theme.spacing(2)
 },
 columnIcon:{
   width:72
 },
 columnFields:{

 },
 columnRemove:{
   width:10
 },
 columnAdd:{
   width:10
 },
 closeButton: {
   position: 'absolute',
   right: theme.spacing(1),
   top: theme.spacing(1),
   color: theme.palette.grey[500]
 },
 action:{
   padding:theme.spacing(1)
 },
 add:{
   color:theme.palette.primary.light
 },
 editAvatarLayer:{
   display:'none',
   justifyContent:'center',
   alignItems:'center',
   borderRadius:'50%',
   top: 0,
   left: 0,
   position: 'absolute',
   webkitTransition: 'bottom 0.15s linear',
   transition: 'bottom 0.15s linear',
   width: '100%',
   transition: 'bottom 0.15s linear',
   background: 'rgba(0, 0, 0, 0.2)',
   color:'white'
 }
}));


const EditProfile = observer(() => {
  const classes = useStyles()
  const { userEdit,notifier } = useContext(UiStore)
  const { t } = useTranslation()
  const { user } = useContext(DataStore)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const save = () => {
    user.update(
      () => {
        userEdit.handleClose()
        user.check(
         () => { notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS) },
         () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
        )
      },
      () => {
        notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
   <Dialog fullScreen={fullScreen} aria-labelledby="user-profile" open={userEdit.open} onClose={userEdit.handleClose} maxWidth="md">
     <IconButton aria-label="close" className={classes.closeButton} onClick={userEdit.handleClose}>
       <Close />
     </IconButton>
     <Grid container spacing={0} className={classes.root}>
      <ChangeName />
      <ChangeMails />
      <ChangePhoneNumbers />
     </Grid>
     <DialogActions>
      <Button color="primary" onClick={save}>{t('core.general.save')}</Button>
     </DialogActions>
   </Dialog>
  )
})

const ChangeName = observer(() => {
  const classes = useStyles()
  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const { user } = useContext(DataStore)

  const setName = (e) => { user.setName(e.target.value) }
  const setForeName = (e) => { user.setForeName(e.target.value) }

  const resetName = () => {
    user.setForeName('')
    user.setName('')
  }

  return (
    !upSm
    ?
    <TableContainer>
      <Table className={classes.wrapper}>
       <TableBody>
       <TableRow>
        <TableCell className={classes.columnIcon}>
         <ChangeAvatar size={40}/>
        </TableCell>
        <TableCell className={classes.columnFields}>
         <TextField
          id="edit-foreName"
          label={t('core.user.foreName')}
          value={user.foreName}
          onChange={setForeName}
          className={`${classes.field} ${classes.spaceRight}`}
         />
         <TextField
          id="editName"
          label={t('core.user.name')}
          value={user.name}
          onChange={setName}
          className={classes.field}
         />
        </TableCell>
        <TableCell className={classes.columnRemove}>
          <IconButton aria-label="close" className={classes.action} onClick={resetName}>
           <DeleteOutline />
          </IconButton>
        </TableCell>
        <TableCell className={classes.columnAdd} style={{width:80}}>
        </TableCell>
      </TableRow>
     </TableBody>
    </Table>
   </TableContainer>
   :
   <Grid container>
    <Grid item xs={12}>
      <TextField
       id="edit-foreName"
       label={t('core.user.foreName')}
       value={user.foreName}
       onChange={setForeName}
       fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
       id="editName"
       label={t('core.user.name')}
       value={user.name}
       onChange={setName}
       fullWidth
      />
    </Grid>
   </Grid>
  )
})

export const ChangeAvatar = observer((props) => {
  const classes = useStyles()
  const { userEdit } = useContext(UiStore)
  const { user } = useContext(DataStore)
  const { cssClasses,size } = props
  const [display,setDisplay] = useState('none')

  const onFiles = (files) => {
   if (files.length > 0) {
    user.updateAvatar(
      files,
      () => {
        userEdit.handleCloseAvatar()
      },
      () => {}
    )
   }
  }

  const show = () => setDisplay('flex')
  const hide = () => setDisplay('none')

  return (
   <>
      <div
       className={cssClasses}
       style={{position:'relative',cursor:'pointer'}}
       onClick={userEdit.handleOpenAvatar}
       onMouseOver={show}
       onMouseOut={hide}
       onTouchStart={show}
       onTouchEnd={hide}>
       {
         user.photo
         ?
         <Avatar src={user.photo} style={{width:size,height:size}} />
         :
         <AccountCircle style={{width:size,height:size}} />
       }
       <div className={classes.editAvatarLayer} style={{height:size,display:display}}>
        <Publish />
       </div>
      </div>
    <Dialog aria-labelledby="change-avatar" open={userEdit.openAvatar} onClose={userEdit.handleCloseAvatar} maxWidth="md">
     <KibsDropzone
      accept="image/*"
      multiple={false}
      onFiles={onFiles}
      showThumbnails={false}
     />
    </Dialog>
   </>
  )
})

const ChangeMails = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(DataStore)

  return useObserver(() => (
   <TableContainer>
    <Table size="small">
     <TableBody>
      {user.mailsWithoutOAuth.map((d,i) => {
        if (!d.mustBeDeleted) {
          return <ChangeMailRow
                  index={i}
                  isLast={(i+1===user.mailsWithoutOAuth.length)}
                  d={d}
                  key={'edit-mail-tablerow-'+i}
                  add={user.addMailWithoutOAuth}
                  remove={user.removeMailWithoutOAuth}
                 />
        } else {
          return null
        }
       })
      }
     </TableBody>
    </Table>
   </TableContainer>
 ))
})

const ChangeMailRow = observer((props) => {
  const classes = useStyles()
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.down('sm'))

  let { index,isLast,d,add,remove } = props
  const { t } = useTranslation()

  const onChangeMail = (e) => {
    d.changeMail(e.target.value)
  }
  const onChangeIdentifier = (e) => {
    d.changeIdentifier(e.target.value)
  }
  const onRemove = () => {
    remove(d.clientId)
  }

  return (
    !upSm
    ?
    <TableRow>
     <TableCell className={classes.columnIcon}><MailOutline /></TableCell>
     <TableCell className={classes.columnFields}>
       <TextField
        id={"edit-mail-"+index}
        label={t('core.user.mail')}
        value={d.mail}
        className={`${classes.field} ${classes.spaceRight}`}
        onChange={onChangeMail}
       />
       <TextField
        id={"edit-mail-label-"+index}
        label={t('core.general.label')}
        value={d.identifier}
        className={classes.field}
        onChange={onChangeIdentifier}
       />
     </TableCell>
     <TableCell className={classes.columnRemove}>
       <IconButton aria-label="close" className={classes.action} onClick={onRemove}>
        <DeleteOutline />
       </IconButton>
     </TableCell>
     <TableCell className={classes.columnAdd}>
       {isLast &&
        <Tooltip title={t('core.general.add')} placement="bottom">
         <IconButton aria-label="add" className={classes.action} onClick={add}>
          <AddCircleOutline className={classes.add}/>
         </IconButton>
        </Tooltip>
       }
     </TableCell>
   </TableRow>
   :
   <Grid container>
    <Grid item xs={12}>
      <TextField
       id={"edit-mail-"+index}
       label={t('core.user.mail')}
       value={d.mail}
       onChange={onChangeMail}
       fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
       id={"edit-mail-label-"+index}
       label={t('core.general.label')}
       value={d.identifier}
       onChange={onChangeIdentifier}
       fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <IconButton aria-label="close" className={classes.action} onClick={onRemove}>
       <DeleteOutline />
      </IconButton>
      {isLast &&
       <Tooltip title={t('core.general.add')} placement="bottom">
        <IconButton aria-label="add" className={classes.action} onClick={add}>
         <AddCircleOutline className={classes.add}/>
        </IconButton>
       </Tooltip>
      }
    </Grid>
   </Grid>
 )
})

const ChangePhoneNumbers = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(DataStore)

  return useObserver(() => (
   <TableContainer>
    <Table size="small">
     <TableBody>
      {user.phones.map((d,i) => {
        if (!d.mustBeDeleted) {
          return <ChangePhoneNumbersRow
                  index={i}
                  isLast={(i+1===user.phones.length)}
                  d={d}
                  key={'edit-phone-tablerow-'+i}
                  add={user.addPhone}
                  remove={user.removePhone}
                 />
        } else return null
       })
      }
     </TableBody>
    </Table>
   </TableContainer>
 ))
})

const ChangePhoneNumbersRow = observer((props) => {
  const classes = useStyles()
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.down('sm'))

  let { index,isLast,d,add,remove } = props
  const { t } = useTranslation()

  const onChangePhone = (e) => {
    d.changePhone(e.target.value)
  }
  const onChangeIdentifier = (e) => {
    d.changeIdentifier(e.target.value)
  }
  const onRemove = () => {
    remove(d.clientId)
  }

  return (
    !upSm
    ?
    <TableRow>
     <TableCell className={classes.columnIcon}><Phone /></TableCell>
     <TableCell className={classes.columnFields}>
       <TextField
        id={"edit-phone-"+index}
        label={t('core.user.phone')}
        value={d.telefon}
        className={`${classes.field} ${classes.spaceRight}`}
        onChange={onChangePhone}
       />
       <TextField
        id={"edit-phone-label-"+index}
        label={t('core.general.label')}
        value={d.identifier}
        className={classes.field}
        onChange={onChangeIdentifier}
       />
     </TableCell>
     <TableCell className={classes.columnRemove}>
       <IconButton aria-label="close" className={classes.action} onClick={onRemove}>
        <DeleteOutline />
       </IconButton>
     </TableCell>
     <TableCell className={classes.columnAdd}>
       {isLast &&
        <Tooltip title={t('core.general.add')} placement="bottom">
         <IconButton aria-label="add" className={classes.action} onClick={add}>
          <AddCircleOutline className={classes.add}/>
         </IconButton>
        </Tooltip>
       }
     </TableCell>
   </TableRow>
   :
   <Grid container>
    <Grid item xs={12}>
      <TextField
       id={"edit-phone-"+index}
       label={t('core.user.phone')}
       value={d.telefon}
       onChange={onChangePhone}
       fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
       id={"edit-phone-label-"+index}
       label={t('core.general.label')}
       value={d.identifier}
       onChange={onChangeIdentifier}
       fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <IconButton aria-label="close" className={classes.action} onClick={onRemove}>
       <DeleteOutline />
      </IconButton>
      {isLast &&
       <Tooltip title={t('core.general.add')} placement="bottom">
        <IconButton aria-label="add" className={classes.action} onClick={add}>
         <AddCircleOutline className={classes.add}/>
        </IconButton>
       </Tooltip>
      }
    </Grid>
   </Grid>
  )
})


export default EditProfile
