import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import Ajax from 'api/ajax'
import ls from 'local-storage'
import RichTextEditor from 'react-rte'
import { addHttps } from 'shared/functions/helper'




class Suggestion {
  id
  title = ''
  description = RichTextEditor.createEmptyValue()
  link = ''
  image = ''
  categories = []
  zyklus1 = false
  zyklus2 = false
  zyklus3 = false

  reset = () => {
    this.id = null
    this.title = ''
    this.description = RichTextEditor.createEmptyValue()
    this.link = ''
    this.image = ''
    this.categories = []
    this.zyklus1 = false
    this.zyklus2 = false
    this.zyklus3 = false
  }

  constructor (data) {
   if (data) {
     const {
       id,title,description,link,image,categories,zyklus1,zyklus2,zyklus3
     } = data

     this.id = id
     this.title = title
     this.description = RichTextEditor.createValueFromString(description,'html')
     this.link = link
     this.image = image
     this.categories = categories.map(c => new Category(c))
     this.zyklus1 = zyklus1
     this.zyklus2 = zyklus2
     this.zyklus3 = zyklus3
   }
  }

  get serialized () {
    return {
      id:this.id,
      title:this.title,
      description:this.description.toString('html'),
      link:this.link,
      image:this.image,
      categories:this.categories.map(c => c.serialized),
      zyklus1:this.zyklus1,
      zyklus2:this.zyklus2,
      zyklus3:this.zyklus3
    }
  }

  setLink = (link) => this.link = link
  setTitle = (title) => this.title = title
  setDescription = (description) => this.description = description
  setImage = (image) => this.image = image
  toggleZyklus1 = () => this.zyklus1 = !this.zyklus1
  toggleZyklus2 = () => this.zyklus2 = !this.zyklus2
  toggleZyklus3 = () => this.zyklus3 = !this.zyklus3

  setCategories = (arr) => {
    const newElement = arr[arr.length-1]
    const existsArr = this.categories.filter(category => category.id === newElement.id)
    if (existsArr && existsArr.length === 1) {
      this.removeCategory(newElement)
    } else {
      this.categories.push(new Category(newElement))
    }
  }
  removeCategory = (category) => {
    this.categories = this.categories.filter(c => c.id !== category.id)
  }

  uploadImage = (image,catalogId,cb) => {
    const formData = new FormData()
    if (image) {
     formData.append('image',image[0])
     formData.append('catalogId',catalogId)
     Ajax.uploadExampleImage(
       formData,
       (res) => {
         if (res && res.data && res.data.success) {
           this.setImage(res.data.image)
         }
         cb()
       },
       () => { cb() }
     )
    }
  }

  suggest = (catalogId,cbSuccess,cbFail) => {
    Ajax.catalogUpsertExample(
      catalogId,
      this.serialized,
      cbSuccess,
      cbFail
    )
  }
  decline = (catalogId,cbSuccess) => {
    Ajax.catalogDeclineExample(
      catalogId,
      this.id,
      cbSuccess,
      ()=>{}
    )
  }
  accept = (catalogId,cbSuccess) => {
    Ajax.catalogAcceptExample(
      catalogId,
      this.serialized,
      cbSuccess,
      ()=>{}
    )
  }
  delete = (catalogId,exampleId,cbSuccess,cbFail) => {
    Ajax.catalogDeleteExample(
      catalogId,
      exampleId,
      cbSuccess,
      cbFail
    )
  }
}

class Post {
  author
  text

  setAuthor = (author) => this.author = author
  setText = (text) => this.text = text

  addComment = (catalogId,exampleId,cbSuccess,cbFail) => {
   if (this.text !== "" && this.author !== "") {
     Ajax.catalogAddComment(
       catalogId,
       exampleId,
       this.serialized,
       (res) => {
         this.setAuthor('')
         this.setText('')
         if (res && res.data && res.data.success) {
          cbSuccess(res.data.comment)
         }
       },
       cbFail
     )
   }
  }

  get serialized () {
    return {
      author:this.author,
      text:this.text
    }
  }

}

class Example {
  id
  title = ''
  description = ''
  link = ''
  image = ''
  categories = []
  zyklus1 = false
  zyklus2 = false
  zyklus3 = false
  likes = 0
  dislikes = 0
  comments = []

  post = new Post()

  get liked () {
    const likes = ls('likes')
    return likes ? likes.filter(l => l === this.id).length === 1 && this.likes > -1 : false
  }
  get disliked () {
    const dislikes = ls('dislikes')
    return dislikes ? dislikes.filter(d => d === this.id).length === 1 && this.dislikes > -1 : false
  }

  mode = {
    LIKE:1,
    DISLIKE:2
  }

  constructor (data) {
   if (data) {
     const {
       id,title,description,link,image,categories,likes,dislikes,zyklus1,zyklus2,zyklus3,comments
     } = data

     this.id = id
     this.title = title
     this.description = description
     this.link = link && link !== "" ? addHttps(link) : null
     this.image = image
     this.categories = categories.map(c => new Category(c))
     this.zyklus1 = zyklus1
     this.zyklus2 = zyklus2
     this.zyklus3 = zyklus3
     this.likes = likes
     this.dislikes = dislikes
     this.comments = comments
   }
  }

  doThumbs = (mode,catalogId,cbSuccess,cbFail) => {
    if (mode === this.mode.LIKE) {
      let likes = ls('likes')
      if (!likes) likes = []
      const alreadyLiked = likes.filter(l => l === this.id).length > 0
      if (!alreadyLiked) {
        this.addLike(catalogId,cbSuccess,cbFail)
        likes.push(this.id)
      } else {
        this.removeLike(catalogId,cbSuccess,cbFail)
        likes = likes.filter(l => l !== this.id)
      }
      ls('likes',likes)
    }
    else if (mode === this.mode.DISLIKE) {
      let dislikes = ls('dislikes')
      if (!dislikes) dislikes = []
      const alreadyDisliked = dislikes.filter(d => d === this.id).length > 0
      if (!alreadyDisliked) {
        this.addDislike(catalogId,cbSuccess,cbFail)
        dislikes.push(this.id)
      } else {
        this.removeDislike(catalogId,cbSuccess,cbFail)
        dislikes = dislikes.filter(d => d !== this.id)
      }
      ls('dislikes',dislikes)
    }
  }

  addComment = (comment) => {
    this.comments.push(comment)
  }

  addLike = (catalogId,cbSuccess,cbFail) => {
    Ajax.catalogExampleAddLike(
      catalogId,
      this.id,
      () => {
        this.likes++
        cbSuccess()
      },
      cbFail
    )
  }
  removeLike = (catalogId,cbSuccess,cbFail) => {
    Ajax.catalogExampleRemoveLike(
      catalogId,
      this.id,
      () => {
        this.likes--
        cbSuccess()
      },
      cbFail
    )
  }
  addDislike = (catalogId,cbSuccess,cbFail) => {
    Ajax.catalogExampleAddDislike(
      catalogId,
      this.id,
      () => {
        this.dislikes++
        cbSuccess()
      },
      cbFail
    )
  }
  removeDislike = (catalogId,cbSuccess,cbFail) => {
    Ajax.catalogExampleRemoveDislike(
      catalogId,
      this.id,
      () => {
        this.dislikes--
        cbSuccess()
      },
      cbFail
    )
  }

}

class Catalog {
  id
  banner
  filter = []
  heading
  primaryColor
  secondaryColor
  exampleLink
  about
  teaser
  theme
  categories = []

  suggestion = new Suggestion()
  examples = []
  currentCatalogItem = new Example()
  isAdmin = false
  sortByLikes = false

  setCurrentCatalogItem = (example) => {
    this.currentCatalogItem = example
    window.location.hash = 'item='+this.encodeCatalogItemURLName(example.title)+'----'+example.id
  }

  checkHash = (cb) => {
    const hashParams = window.location.hash
     .split("&")
     .map(v => v.split("="))
     .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} )

    const item = hashParams['#item']
    if (item) {
      const itemId = item.split('----')[1]
      const matches = this.examples.filter(e => e.id == itemId)
      if (matches && matches.length === 1) {
        this.setCurrentCatalogItem(matches[0])
        return cb()
      }
    }
    return null
  }

  encodeCatalogItemURLName = (name) => {
    return encodeURI(name).replace(/%20/g,'-')
  }

  deleteExample = (exampleId) => {
    this.examples = this.examples.filter(e => e.id !== exampleId)
  }
  toggleSortByLikes = () => {
    this.sortByLikes = !this.sortByLikes
  }

  get currentItemToEdit () {
    const item = new Suggestion(this.currentCatalogItem)
    return item
  }

  get filteredExamples () {
    let cycles = {
      zyklus1:false,
      zyklus2:false,
      zyklus3:false
    }
    let selectedCategoryFilter = []
    let fExamples = []

    this.filter.map(filter => {
      if (filter.isCycleFilter && filter.selected) { cycles['zyklus'+filter.cycle] = true }
      if (filter.isCategoryFilter && filter.selected) {
        selectedCategoryFilter.push(filter.id)
      }
    })

    // no filter set, return all
    if (!cycles.zyklus1 && !cycles.zyklus2 && !cycles.zyklus3 && selectedCategoryFilter.length === 0) {
      fExamples = this.examples
    }
    // cycle filter and categoryfilter
    else if ((cycles.zyklus1 || cycles.zyklus2 || cycles.zyklus3) && selectedCategoryFilter.length > 0) {
      for (let i = 0; i < this.examples.length; i++) {
        const example = this.examples[i]
        if ((example.zyklus1 && cycles.zyklus1) ||
            (example.zyklus2 && cycles.zyklus2) ||
            (example.zyklus3 && cycles.zyklus3)
        ) {
         const matchingCategories = example.categories.filter(c => selectedCategoryFilter.indexOf(c.id) !== -1)
         if (matchingCategories.length > 0) {
           fExamples.push(example)
         }
        }
      }
    }
    // only cycle filter
    else if ((cycles.zyklus1 || cycles.zyklus2 || cycles.zyklus3) && selectedCategoryFilter.length === 0) {
      for (let i = 0; i < this.examples.length; i++) {
        const example = this.examples[i]
        if (
          (cycles.zyklus1 && example.zyklus1) ||
          (cycles.zyklus2 && example.zyklus2) ||
          (cycles.zyklus3 && example.zyklus3)
        ) {
         fExamples.push(example)
        }
      }
    }
    // only categoryfilter
    else if ((!cycles.zyklus1 && !cycles.zyklus2 && !cycles.zyklus3) && selectedCategoryFilter.length > 0) {
      for (let i = 0; i < this.examples.length; i++) {
       const example = this.examples[i]
       const matchingCategories = example.categories.filter(c => selectedCategoryFilter.indexOf(c.id) !== -1)
       if (matchingCategories.length > 0) {
        fExamples.push(example)
       }
     }
    }

   if (this.sortByLikes) {
     fExamples = fExamples.sort((a,b) => b.likes-a.likes)
   }
   return fExamples
  }

  verifyTokenAdmin = (cbSuccess,cbFail) => {
    Ajax.verifyTokenAdmin(
     (res) => {
       if (res && res.data && res.data.success) {
         this.isAdmin = true
       } else {
         this.isAdmin = false
       }
     },
     cbFail
    )
  }

  getExamples = (cbSuccess,cbFail) => {
    Ajax.catalogGetExamples(
     this.id,
     (res) => {
       if (res && res.data && res.data.success) {
         this.initExamples(res.data.examples)
       }
       cbSuccess()
     },
     cbFail
    )
  }

  getConfigByName = (catalogName,cbSuccess,cbFail) => {
   Ajax.getCatalogConfigByName(
     catalogName,
     (res) => {
       if (res && res.data && res.data.config) {
        this.init(res.data.config)
        cbSuccess()
       }
     },
     cbFail,
   )
  }

  init = (config) => {
    const { id,banner,categories,heading,primaryColor,secondaryColor,exampleLink,teaser,about,theme } = config

    this.id = id
    this.banner = banner
    this.filter = []
    this.filter = [...categories.map(c => new CategoryFilter(c))]
    this.heading = heading ? heading : ""
    this.primaryColor = primaryColor
    this.secondaryColor = secondaryColor
    this.exampleLink = exampleLink ? exampleLink : ""
    this.teaser = teaser
    this.about = about
    this.theme = theme
    this.categories = categories
    for (let i = 1; i < 4; i++) {
     this.filter.push( new CycleFilter({ cycle:i }) )
    }
  }

  initExamples = (examples) => {
   this.examples = examples.map(e => new Example(e))
  }

}



class Config {
  id
  heading
  banner
  teaser
  about = RichTextEditor.createEmptyValue()
  primaryColor
  secondaryColor
  exampleLink = ''
  categories

  pendings
  theme
  tempCategoryName = ''
  tempColorScheme = ''
  currentCategory = new Category({})
  currentSuggestion
  DEBOUNCE_TIMER = 800

  constructor (data) {
    const {
      id,
      heading,
      banner,
      teaser,
      about,
      categories,
      theme,
      primaryColor,
      secondaryColor,
      exampleLink,
      pendings
    } = data

    this.id = id
    this.heading = heading
    this.banner = banner
    this.teaser = teaser
    this.about = about && about !== '' ? RichTextEditor.createValueFromString(about,'html') : RichTextEditor.createEmptyValue()
    this.theme = theme
    this.primaryColor = primaryColor
    this.secondaryColor = secondaryColor
    this.exampleLink = exampleLink
    this.pendings = pendings
    if (categories) {
      this.categories = categories.map(c => new Category(c))
    } else {
      this.categories = []
    }
  }

  setHeading = (heading) => this.heading = heading
  setTeaser = (teaser) => this.teaser = teaser
  setAbout = (about) => this.about = about
  setBanner = (banner) => this.banner = banner
  setTempCategoryName = (name) => this.tempCategoryName = name
  setTempColorScheme = (cs) => this.tempColorScheme = cs
  setCurrentCategory = (category) => this.currentCategory = this.categories.filter(c => c.id === category.id)[0]
  setCurrentSuggestion = (suggestion) => this.currentSuggestion = new Suggestion(suggestion)
  setPrimaryColor = (primary) => this.primaryColor = primary
  setSecondaryColor = (secondary) => this.secondaryColor = secondary
  setExampleLink = (exampleLink) => this.exampleLink = exampleLink
  removePending = (id) => this.pendings = this.pendings.filter(p => p.id !== id)

  createCategory = (cbSuccess,cbFail) => {
   if (this.tempCategoryName !== '') {
     Ajax.createCatalogCategory(
      this.id,
      this.tempCategoryName,
      (res) => {
        if (res && res.data && res.data.id) {
          res.data.name = this.tempCategoryName
          this.addCategory(res.data)
        }
        this.setTempCategoryName('')
        cbSuccess()
      },
      cbFail
     )
   }
  }

  deleteCatalogCategory = (categoryId,cbSuccess,cbFail) => {
    Ajax.deleteCatalogCategory(
      this.id,
      categoryId,
      cbSuccess,
      cbFail
    )
  }

  addCategory = (data) => this.categories.push(new Category(data))
  removeCategory = (category,cbSuccess,cbFail) => {
    this.categories = this.categories.filter(c => c.id !== category.id)
    this.deleteCatalogCategory(category.id,cbSuccess,cbFail)
  }

  getUncached = (src) => {
    return src +'?rand='+Math.random()
  }

  saveCategoryIcon = (icon,categoryId,cb) => {
    const formData = new FormData()
    if (icon) {
     formData.append('icon',icon[0])
     formData.append('categoryId',categoryId)
     formData.append('catalogId',this.id)
     Ajax.updateCatalogCategoryIcon(
       formData,
       (res) => {
         if (res && res.data && res.data.success) {
           this.currentCategory.setIcon(this.getUncached(res.data.icon))
         }
         cb()
       },
       () => { cb() }
     )
    }
  }

  getPendingExample = (exampleId,cb) => {
    Ajax.catalogGetExample(
      this.id,
      exampleId,
      (res) => {
        if (res && res.data && res.data.success) {
          this.setCurrentSuggestion(res.data.example)
        }
        cb()
      },
      () => {  }
    )
  }

  saveBanner = (banner,cb) => {
    const formData = new FormData()
    if (banner) {
     formData.append('banner',banner[0])
     formData.append('catalogId',this.id)
     Ajax.updateCatalogBanner(
       formData,
       (res) => {
         if (res && res.data && res.data.success) {
           this.banner = this.getUncached(res.data.banner)
         }
         cb()
       },
       () => { cb() }
     )
    }
  }

  save = () => {
   Ajax.updateCatalogConfig(
     this.serialized,
     ()=>{},
     ()=>{}
   )
  }

  get catalogUrl () {
    return '/catalog/'+this.theme
  }

  get serialized () {
    return {
      id:this.id,
      heading:this.heading,
      teaser:this.teaser,
      primaryColor:this.primaryColor,
      secondaryColor:this.secondaryColor,
      exampleLink:this.exampleLink,
      about:this.about.toString('html'),
      categories:this.categories.map(c => c.serialized)
    }
  }
}

class CategoryFilter {
  id
  icon
  name
  color

  selected
  isCategoryFilter = true

  constructor (data) {
    const {
      id,icon,name,color
    } = data

    this.id = id
    this.icon = icon
    this.name = name
    this.color = color ? color : '#fff'
  }

  toggleSelect = () => this.selected = !this.selected
}

class CycleFilter {
  selected
  color
  cycle

  isCycleFilter = true

  colors = {
    cycle1:'#e39b2d',
    cycle2:'#2f8fce',
    cycle3:'#97c941'
  }

  constructor (data) {
    if (data) {
      const { cycle } = data
      if (cycle === 1) this.color = this.colors.cycle1
      else if (cycle === 2) this.color = this.colors.cycle2
      else if (cycle ===3) this.color = this.colors.cycle3

      this.cycle = cycle
    }
  }

  toggleSelect = () => this.selected = !this.selected
}

class Category {
  id
  icon
  name
  color

  constructor (data) {
    const {
      id,icon,name,color
    } = data

    this.id = id
    this.icon = icon
    this.name = name
    this.color = color ? color : '#fff'
  }

  setName = (name) => this.name = name
  setColor = (color) => this.color = color
  setIcon = (icon) => this.icon = icon

  get serialized () {
    return {
      id:this.id,
      name:this.name,
      color:this.color
    }
  }
}

class Data {
  theme = ''
  themes = []

  config
  catalog = new Catalog()
  cycle = new CycleFilter()

  setConfig = (data) => {
    this.config = new Config(data)
  }

  getConfig = (id,cb) => {
    Ajax.getCatalogConfig(
      id,
      (res) => {
        if (res && res.data && res.data.config) {
          this.setConfig(res.data.config)
        }
        cb()
      },
      ()=>{}
    )
  }

  getAllCatalogThemes = (cbSuccess,cbFail) => {
    Ajax.getAllCatalogThemes(
      (res) => {
        if (res && res.data && res.data.themes) {
          this.themes = res.data.themes
        }
        cbSuccess()
      },
      cbFail
    )
  }
  createTheme = (cbSuccess,cbFail) => {
   Ajax.createNewCatalog(
     this.theme,
     (res) => {
       if (res && res.data ) {
         if (res.data.themes) {
          this.themes = res.data.themes
         }
         if (res.data.current) {
           this.setConfig(res.data.current)
         }
         if (res.data.themeExists) {
           cbFail()
         } else {
           cbSuccess()
         }
       }
     },
     cbFail
   )
  }
  setTheme = (theme) => {
   if (theme.match(/^[a-z0-9_]+$/i) || theme === '') {
     this.theme = theme
   }
  }
}

decorate(Post,{
  author:observable,
  text:observable,
  serialized:computed
})

decorate(Example,{
  id:observable,
  title:observable,
  description:observable,
  link:observable,
  image:observable,
  categories:observable,
  zyklus1:observable,
  zyklus2:observable,
  zyklus3:observable,
  likes:observable,
  dislikes:observable,
  liked:computed,
  disliked:computed,
  comments:observable
})

decorate(Suggestion,{
  id:observable,
  title:observable,
  description:observable,
  link:observable,
  image:observable,
  categories:observable,
  zyklus1:observable,
  zyklus2:observable,
  zyklus3:observable,
  currentMode:observable,
  serialized:computed
})

decorate(Config,{
  currentCategory:observable,
  currentSuggestion:observable,
  id:observable,
  heading:observable,
  teaser:observable,
  about:observable,
  banner:observable,
  primaryColor:observable,
  secondaryColor:observable,
  exampleLink:observable,
  categories:observable,
  tempCategoryName:observable,
  tempColorScheme:observable,
  pendings:observable,
  serialized:computed,
  catalogUrl:computed
})

decorate(CategoryFilter,{
  selected:observable
})
decorate(CycleFilter,{
  selected:observable
})

decorate(Category,{
  id:observable,
  icon:observable,
  name:observable,
  color:observable,
  serialized:computed
})

decorate(Catalog,{
  id:observable,
  heading:observable,
  teaser:observable,
  about:observable,
  banner:observable,
  primaryColor:observable,
  secondaryColor:observable,
  exampleLink:observable,
  filter:observable,
  examples:observable,
  isAdmin:observable,
  categories:observable,
  theme:observable,
  suggestion:observable,
  currentCatalogItem:observable,
  filteredExamples:computed,
  currentItemToEdit:computed,
  sortByLikes:observable
})

decorate(Data,{
  themes:observable,
  theme:observable,
  config:observable,
  catalog:observable,
  cycle:observable
})


const DataStore = createContext(new Data())
export default DataStore
