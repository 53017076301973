import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import {
  Typography,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia
} from '@material-ui/core'

import {
 Label,ExitToApp
} from '@material-ui/icons'


const Datenschutz = observer(() => {
  return (
    null
  )
})

export default Datenschutz
