import React, {useEffect,useState} from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import Ajax from 'api/ajax'

import {
  useMediaQuery,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
 image:{
   width:'60%',
   maxWidth:500,
   maxHeight:500,
   borderRadius:'50% !important'
 },
 memberImageContainer:{
   background: '#fff',
   borderRadius: '50%',
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
   backgroundPosition:'center center',
   width:100,
   height:100,
   marginLeft:'auto',
   marginRight:'auto',
   cursor:'pointer',
   marginTop:20,
   marginBottom:20,
   boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
   transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
 },
 grow:{
   transition: 'all .2s ease-in-out',
   '&:hover':{
     transform: 'rotate(-5deg) scale(1.5)'
   }
 },
}));



const MeetTheTeam = observer(() => {
  const classes = useStyles()
  const [team,setTeam] = useState([])
  const [currentMember,setCurrentMember] = useState({ links:[],vorname:'',nachname:'',image_toon:'' })
  const { t } = useTranslation()

  const theme = useTheme()
  const upSm = useMediaQuery(theme.breakpoints.down('sm'))

  const sortTeam = (team) => {
   const order = [
     'monika','kurt','oliver','simon',
     'samuel','roger','nico','mike',
     'christian','denise'
   ]

   for (let i = 0; i < order.length; i++) {
     const m = team.filter(tm => {
       if (tm.vorname.toLowerCase().trim() === order[i].toLowerCase().trim()) {
         tm.links = [tm.link_1,tm.link_2,tm.link_3]
         return tm
       }
     })[0]
     order[i] = m
   }
    return order
  }


  useEffect(() => {
   Ajax.getKibsTeam(
     (res) => {
       if (res && res.data && res.data.success) {
         setTeam(sortTeam(res.data.team))
         const rand = Math.floor(Math.random() * res.data.team.length)
         setCurrentMember(res.data.team[rand])
       } else {
         console.log('fail');
       }
     },
     () => {}
   )
 },[])

 return (
   <section id="meettheteam" className="features-area pt-100 pb-70">
     <div className="section-title">
      <span>{t('core.general.ourSingular')}</span>
      <h2>{t('core.landingpage.team.title')}</h2>
      <div className="bar" />
     </div>

     <div className="container" style={{display:'flex'}}>
       <div className="col-lg-6 col-sm-12 col-xs-12">
         <Grid container>
          {team.map((m,i) => {
            return <Grid item xs={3} onHover={()=>{setCurrentMember(m)}} onClick={()=>{setCurrentMember(m)}}>
             <div
              style={{backgroundImage:'url('+m.image_toon+')'}}
              className={`${classes.memberImageContainer} ${classes.grow}`}
            />
            </Grid>
          })}
         </Grid>
       </div>

       {
         !upSm
         ?
         <div className="col-lg-6">
          <div className="single-team-box">
           <div className="image">
             <img src={currentMember.image_toon} alt="image" className={classes.image}/>
             <ul className="social">
               {currentMember.links.map((link,i) => {
                 if (!link) return null
                 return <li>
                   <a href={link} target="_blank">
                     <i className="bx bxl-facebook" />
                   </a>
                 </li>
               })}
             </ul>
           </div>
           <div className="content">
             <h3>{currentMember.vorname} {currentMember.nachname}</h3>
             <span>{currentMember.funktion}</span>
           </div>
         </div>


         </div>
         :
         null
       }

     </div>
   </section>
 )
})

export default MeetTheTeam
