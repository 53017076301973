import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'



const Configuration = observer(() => {
  return (
    <div>Configuration</div>
  )
})


export default Configuration
