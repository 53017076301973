import React,{ useEffect,useState,useContext,Component } from 'react'
import { Route,Redirect } from 'react-router'
import Ajax from 'api/ajax'
import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'
import { observer } from 'mobx-react-lite'
import ls from 'local-storage'

const PrivateRoute = observer(({ component: Component, ...rest }) => {
  const ui = useContext(UiStore)
  const { user } = useContext(DataStore)
  useEffect(() => { ui.setLoading(false) },[])
  useEffect(() => { ui.setPageTitle(rest.title) })
  window.kibsportal.routes.setVisibility(window.kibsportal.routes.VISIBILITY_PRIVATE)
  return (
   <Route {...rest} render={(props) => {
    const isAuthenticated = ls('token') ? true : false
    user.setAuthenticated(isAuthenticated)
    ui.triggerLayout()

    return <>
     {isAuthenticated && <Component {...props} />}
     {!isAuthenticated &&
       <Redirect to={{
         pathname:'/login',
         search:'?redirect_uri='+props.location.pathname+props.location.search
        }}
       />
     }
     </>
   }}
   />
 )
})


export default PrivateRoute
