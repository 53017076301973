import React,{ useContext,useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import UiStore from 'core/stores/ui'
import { observer } from 'mobx-react-lite'
import axios from 'axios'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    position:'fixed',
    top:0,
    left:0,
    width:'100%',
    zIndex:99999999,
    height:'100vh',
    background:'transparent'
  },
});

const LinearDeterminate = observer(() => {
  const classes = useStyles()
  const { loading } = useContext(UiStore)
  const UI = useContext(UiStore)

  axios.interceptors.request.use(
    config => {
      UI.setLoading(true)
      return config
    },
    error => Promise.reject(error)
  )

  axios.interceptors.response.use(response => {
     UI.setLoading(false)
     return response
   }, error => {
    return error
  });

  return (
    loading
    ?
    <div className={classes.root}>
     <LinearProgress color="secondary" />
    </div>
    :
    null
  )
})

export default LinearDeterminate
