import React, { useContext,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import history from 'history.js'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Container,
  Paper,
  Avatar,
  Typography,
  Divider,
  Button,
  ButtonGroup
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

import SignIn   from 'shared/components/login/index'
import SignUp   from 'shared/components/register/index'

import 'ext/animatedCSSBackground_1.css'

const useStyles = makeStyles(theme => ({
  root:{
   position:'relative',
   height:'100vh',
   overflow:'hidden'
  },
  context:{
    position:'absolute',
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    zIndex:1
  },
  content:{
    position:'relative'
  },
  paper:{
    width:480,
    [theme.breakpoints.only('xs')]:{
      width:'100vw',
      height:'100vh',
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    padding:theme.spacing(4),
    boxShadow: '0 2px 10px 0 rgba(0,0,0,.2)',
    background:'#ffffff',
    color:'#000000',
    margin:'0 auto',
    textAlign:'center'
  },
  avatar: {
     width: theme.spacing(12),
     height: theme.spacing(12),
     margin: '0 auto',
     marginBottom:theme.spacing(2),
     background:'#c64ec8',
     color:'#ffffff'
   },
   divider:{
     marginBottom:theme.spacing(2),
     marginTop:theme.spacing(2),
     color:'#ccc'
   },
   button:{
     color:'#ffffff',
     background:'#c64ec8'
   }
}));


const InviteRoutine = observer((props) => {
  const classes = useStyles()
  const { inviteRoutine,user } = useContext(DataStore)

  useEffect(() => {
    if (props.match.params.code) {
      inviteRoutine.checkInvite(
        props.match.params.code,
        user.id,
        () => {},
        () => {}
      )
    }
  },[user.id])

  return (
   <div className={classes.root}>
    <div className={classes.context}>
     <Content />
    </div>
    <div className="area" >
     <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
     </ul>
    </div >
   </div>
  )
})

const Content = observer(() => {
  const classes = useStyles()
  const { inviteRoutine } = useContext(DataStore)

  return (
   <div className={classes.content}>
    <Container maxWidth="md">
     <Paper variant="outlined" elevation={3} className={classes.paper}>
      {inviteRoutine.alreadyJoined && <AlreadyJoinedCard />}
      {inviteRoutine.valid && !inviteRoutine.expired && !inviteRoutine.alreadyJoined && <JoinCard />}
      {inviteRoutine.expired && <ExpireCard />}
      {!inviteRoutine.valid && <WrongCodeCard />}
     </Paper>
    </Container>
   </div>
  )
})

const JoinCard = observer(() => {
  const { inviteRoutineUI } = useContext(UiStore)
  return (
   <>
    {inviteRoutineUI.joinStep === inviteRoutineUI.joinSteps.WELCOME && <JoinCardStepWelcome />}
    {inviteRoutineUI.joinStep === inviteRoutineUI.joinSteps.JOIN_AND_GO_TO_SCHOOL && <JoinCardStepJoinAndGoToSchool />}
    {inviteRoutineUI.joinStep === inviteRoutineUI.joinSteps.HAVE_ACCOUNT_OR_NEW && <JoinCardStepHaveAccountOrNew />}
    {inviteRoutineUI.joinStep === inviteRoutineUI.joinSteps.SIGN_UP && <JoinCardStepSignUp />}
    {inviteRoutineUI.joinStep === inviteRoutineUI.joinSteps.SIGN_IN && <JoinCardStepSignIn />}
   </>
  )
})

const JoinCardStepSignIn = observer(() => {
  const { inviteRoutine } = useContext(DataStore)
  const { inviteRoutineUI,notifier } = useContext(UiStore)
  const { school } = inviteRoutine
  const { t } = useTranslation()

  const callback = () => {
    inviteRoutine.join(
      () => {
        inviteRoutineUI.setJoinStep(inviteRoutineUI.joinSteps.JOIN_AND_GO_TO_SCHOOL)
      },
      () => {
        notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
    <SignIn
     callback={callback}
     hideLinks={true}
    />
  )
})
const JoinCardStepSignUp = observer(() => {
  const { inviteRoutine } = useContext(DataStore)
  const { inviteRoutineUI,notifier } = useContext(UiStore)
  const { school } = inviteRoutine
  const { t } = useTranslation()

  const callback = () => {
    inviteRoutine.join(
      () => {
        inviteRoutineUI.setJoinStep(inviteRoutineUI.joinSteps.JOIN_AND_GO_TO_SCHOOL)
      },
      () => {
        notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
    <SignUp
     callback={callback}
     hideLinks={true}
    />
  )
})
const JoinCardStepHaveAccountOrNew = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { inviteRoutine } = useContext(DataStore)
  const { inviteRoutineUI } = useContext(UiStore)
  const { school } = inviteRoutine

  const goToSignUp = () => {
    inviteRoutineUI.setJoinStep(inviteRoutineUI.joinSteps.SIGN_UP)
  }
  const goToSignIn = () => {
    inviteRoutineUI.setJoinStep(inviteRoutineUI.joinSteps.SIGN_IN)
  }

  return (
    <>
     <Typography variant="body1">
      {t('core.dashboard.inviteRoutine.haveAccountOrNew')}
     </Typography>
     <Divider className={classes.divider}/>
     <ButtonGroup variant="contained">
      <Button className={classes.button} onClick={goToSignIn}>{t('core.dashboard.inviteRoutine.goToSignIn')}</Button>
      <Button className={classes.button} onClick={goToSignUp}>{t('core.dashboard.inviteRoutine.goToSignUp')}</Button>
     </ButtonGroup>
    </>
  )
})
const JoinCardStepJoinAndGoToSchool = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { inviteRoutine,dashboard } = useContext(DataStore)
  const { school } = inviteRoutine

  const goTo = () => {
    dashboard.setCurrentSchool(school)
    history.push('/dashboard')
  }

  return (
    <>
     <Typography variant="body1">
      {t('core.dashboard.inviteRoutine.youAreNowMember')}{" "}
      {school.name}
     </Typography>
     <Divider className={classes.divider}/>
     <Button fullWidth variant="contained" className={classes.button} onClick={goTo}>{t('core.dashboard.inviteRoutine.goTo')} {school.name}</Button>
    </>
  )
})

const JoinCardStepWelcome = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { inviteRoutine,user } = useContext(DataStore)
  const { inviteRoutineUI,notifier } = useContext(UiStore)
  const { from,school } = inviteRoutine

  const next = () => {
    if (user.isAuthenticated) {
      inviteRoutine.join(
        () => {
          inviteRoutineUI.setJoinStep(inviteRoutineUI.joinSteps.JOIN_AND_GO_TO_SCHOOL)
        },
        () => {
          notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
        }
      )
    } else {
      inviteRoutineUI.setJoinStep(inviteRoutineUI.joinSteps.HAVE_ACCOUNT_OR_NEW)
    }
  }

  const space = " "
  return (
   <>
    {from
     ?
     <>
      {from.photo ? <Avatar src={from.photo} className={classes.avatar} /> : <AccountCircle className={classes.avatar}  />}
      <Typography variant="body1">
       {from.foreName} {from.name}{space}
       {t('core.dashboard.inviteRoutine.invitedYou')}{space}
       {school.name}{space}
       {t('core.dashboard.inviteRoutine.join')}
      </Typography>
     </>
     :
     <>
      <Avatar className={classes.avatar}>{inviteRoutine.schoolAcronym}</Avatar>
      <Typography variant="body1">
       {t('core.dashboard.inviteRoutine.invitedBySystemLink')}{space}
       {school.name}{space}
       {t('core.dashboard.inviteRoutine.join')}
      </Typography>
     </>
    }
    <Divider className={classes.divider}/>
    <Button fullWidth variant="contained" className={classes.button} onClick={next}>{t('core.general.join')}</Button>
   </>
  )
})

const ExpireCard = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
     <Typography variant="h5">{t('core.dashboard.inviteRoutine.linkIsExpired')}</Typography>
     <Divider className={classes.divider}/>
     <Typography variant="caption">{t('core.dashboard.inviteRoutine.youCanRegisterYourself')}</Typography>{" "}
     <Link to="/login">{t('core.general.test')}</Link>
    </>
  )
})

const WrongCodeCard = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
     <Typography variant="h5">{t('core.dashboard.inviteRoutine.wrongCode')}</Typography>
     <Divider className={classes.divider}/>
     <Typography variant="caption">{t('core.dashboard.inviteRoutine.typoOrBrutforce')}</Typography>
    </>
  )
})

const AlreadyJoinedCard = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { inviteRoutine,dashboard } = useContext(DataStore)
  const { school } = inviteRoutine

  const goTo = () => {
    dashboard.setCurrentSchool(school)
    history.push('/dashboard')
  }

  return (
    <>
     <Typography variant="h5">{t('core.dashboard.inviteRoutine.alreadyJoined')}</Typography>
     <Divider className={classes.divider}/>
     <Button fullWidth variant="contained" className={classes.button} onClick={goTo}>{t('core.dashboard.inviteRoutine.goTo')} {school.name}</Button>
    </>
  )
})


export default InviteRoutine
