import axios from 'axios'
import ls from 'local-storage'
import history from '../history'

// before each call
axios.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      const token = ls('token')

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    return config
  },
  error => Promise.reject(error)
)

// after each call
axios.interceptors.response.use(response => {
   return response;
}, error => {
  if (error.response.status === 401 && window.kibsportal.routes.isPrivate()) {
   ls.remove('token')
   history.push('/login?redirect_uri='+window.kibsportal.routes.lastRoute)
  }
  else if (error.response.status === 403 && window.kibsportal.routes.isPrivate()) {
    history.push('/forbidden')
  }
  return error;
});


class Ajax {
 //####################################################
 signIn (mail,password,cbSuccess,cbFail) {
   axios
    .post('/login', {
     mail: mail,
     password: password
    })
    .then(cbSuccess)
    .catch(cbFail)
  }
 //####################################################
 signUp (name,foreName,mail,password,cbSuccess,cbFail) {
   axios
    .post('/signup', {
     name: name,
     foreName:foreName,
     mail:mail,
     password: password
    })
    .then(cbSuccess)
    .catch(cbFail)
  }
 //####################################################
 resetPw (mail,cbSuccess,cbFail) {
    axios
     .post('/resetpw', {
       mail:mail
     })
     .then(cbSuccess)
     .catch(cbFail)
  }
  //####################################################
  changePW (data,cbSuccess,cbFail) {
    axios
     .post('/changepw',data)
     .then(cbSuccess)
     .catch(cbFail)
  }
  //####################################################
  checkUser (cbSuccess,cbFail) {
     axios
      .post('/checkuser')
      .then(cbSuccess)
      .catch(cbFail)
  }
 //####################################################
 makeTwineEmbed (data,cbSuccess) {
   axios
    .post('/twine/make',data)
    .then(cbSuccess)
    .catch((err)=>console.log(err))
 }
 //####################################################
 getTwineEmbed (data,cbSuccess) {
   axios
    .post('/twine/get',data)
    .then(cbSuccess)
    .catch((err)=>console.log(err))
 }
 //####################################################
 addTwineReferer (data,cbSuccess) {
   axios
    .post('/twine/addreferer',data)
    .then(cbSuccess)
    .catch((err)=>console.log(err))
 }
 //####################################################
 updateAvatar(formData,cbSuccess,cbFail) {
   axios.post('/user/avatar/', formData, {
     headers: {
      'Content-Type': 'multipart/form-data'
     }
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 updateUser (data,cbSuccess,cbFail) {
   axios
    .post('/user/update', {
      data:data
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingThirdparty(cbSuccess,cbFail) {
   axios
    .post('/phishing/api/thirdparty')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingMagic(data,cbSuccess,cbFail) {
   axios
    .post('/phishing/api/magic',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingAddScripts(data,cbSuccess,cbFail) {
   axios
    .post('/phishing/api/addscripts',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingSetup(data,cbSuccess,cbFail) {
   axios
    .post('/phishing/api/setup',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingLive(data,cbSuccess,cbFail) {
   axios
    .post('/phishing/api/live',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingDelete(data,cbSuccess,cbFail) {
   axios
    .post('/phishing/api/delete',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 phishingAbort(data,cbSuccess,cbFail) {
   axios
    .post('/phishing/api/abort',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getKibsMeta (cbSuccess,cbFail) {
   axios
    .post('/kibs/meta')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getKibsTeam (cbSuccess,cbFail) {
   axios
    .post('/kibs/team')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getKibsNews (cbSuccess,cbFail) {
   axios
    .post('/kibs/news')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 finalDeletionAccount (cbSuccess,cbFail) {
   axios
    .post('/user/delete')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 checkInvite (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/join',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getMailingList (cbSuccess,cbFail) {
   axios
    .post('/admin/utility/mails/list')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 createSurvey (data,cbSuccess,cbFail) {
   axios
    .post('/admin/surveys/create',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getInviteRequestsWhereUserIsNotFirstAtLocation (cbSuccess,cbFail) {
   axios
    .post('/admin/releases/notfirst')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getInviteRequestsWhereUserIsFirstAtLocation (cbSuccess,cbFail) {
   axios
    .post('/admin/releases/first')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 declineInvite (data,cbSuccess,cbFail) {
   axios
    .post('/admin/releases/decline',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 acceptInvite (data,cbSuccess,cbFail) {
   axios
    .post('/admin/releases/accept',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 acceptInviteForNotFirstUsers (data,cbSuccess,cbFail) {
   axios
    .post('/admin/releases/notfirst/accept',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getSMICalc (data,cbSuccess,cbFail) {
  axios
   .post('/smicalc/get',data)
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 calculateSMIPercent (data,cbSuccess,cbFail) {
  axios
   .post('/smicalc/calculate',data)
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 getSchoolNumbers (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/schoolnumbers/get',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 updateSchoolnumbers (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/schoolnumbers/update',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 leaveSchool (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/leave',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 joinSchoolByLink (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/join/accept',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 declineRequest (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/requests/decline',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 acceptRequest (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/requests/accept',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 sendInviteLink (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/invite/mail',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
//####################################################
 getSchoolpage (schoolId,cbSuccess,cbFail) {
  axios
   .post('/schoolpage',{ schoolId })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 getSchooltechData (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/techdata/get',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 updateSchooltechData (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/techdata/update', data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 removeSchooltechData (techId,schoolId,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/techdata/remove', { techId,schoolId })
    .then(cbSuccess)
    .catch(cbFail)
 }
//####################################################
 generateInviteLink (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/invitelink/generate',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getMemberDetails (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/member/details', data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 addMemberFunction (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/member/function/add', data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 deleteMemberFunction (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/member/function/delete', data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 addMemberTeachingLevel (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/member/teachinglevel/add', data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 deleteMemberTeachingLevel (data,cbSuccess,cbFail) {
   axios
    .post('/schoolpage/member/teachinglevel/delete', data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityGetTextConference (cbSuccess,cbFail) {
   axios
    .post('/utility/text/conference/get')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityGetTextHelpdesk (cbSuccess,cbFail) {
   axios
    .post('/utility/text/helpdesk/get')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityGetTextCommunity (cbSuccess,cbFail) {
   axios
    .post('/utility/text/community/get')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityUpdateTextConference (data,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/text/conference/update',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityUpdateTextHelpdesk (data,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/text/helpdesk/update',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityUpdateTextCommunity (data,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/text/community/update',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityJoinSchool (data,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/school/join',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityLeaveSchool (data,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/school/leave',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityGetSchools (query,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/schools',{ query })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityGetUser (query,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/users',{ query })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 utilityGetUserInfo (userId,cbSuccess,cbFail) {
   axios
    .post('/admin/utility/user/info',{ userId })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 // data has to be
 /*
  {
   scope:{columName:1,columnName:0,columName:1},
   keys:{key:1,key:0,key:1}
  }
  scope can be ---
   - name
  keys can be ---
   - schulhaus
 */
 getSchulhausData (data,cbSuccess,cbFail) {
   axios
    .post('/school/data',data)
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getLocations (query,cbSuccess,cbFail) {
  axios
   .post('/location/get',{
     query:query
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 cancelInviteRequest (erzKey,cbSuccess,cbFail) {
   axios
    .post('/user/location/request/cancel',{
      erzKey:erzKey
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getPendingRequests (cbSuccess,cbFail) {
  axios
   .post('/user/locations/requests')
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 requestJoinLocations (erzKeys,cbSuccess,cbFail) {
   axios
    .post('/locations/request', {
      erzKeys:erzKeys
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 verifyToken (cbSuccess,cbFail) {
   axios
    .post('/token/verify')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 verifyTokenAdmin (cbSuccess,cbFail) {
   axios
    .post('/token/verify/admin')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 checkAmpelFlyer (schulhausKey,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/check',{
      schulhausKey
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 createAmpelflyer (schulhausKey,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/create',{
      schulhausKey
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 deleteAmpelflyer (ampelflyerId,schulhausKey,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/delete',{
      ampelflyerId,
      schulhausKey
    })
    .then(cbSuccess)
    .then(cbFail)
 }
 //####################################################
 getAmpelFlyer (ampelflyerId,schulhausKey,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/get',{
      ampelflyerId,
      schulhausKey
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 printAmpelFlyer (ampelflyerId,schulhausKey,lng,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/print',
     {
      ampelflyerId,
      schulhausKey,
      lng
    },
    {
      responseType:'blob'
    }
   )
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getAllAmpelflyerServices (schulhausKey,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/services/all',{
      schulhausKey
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getSchoolsWhereUserIsMember(cbSuccess,cbFail) {
   axios
    .post('/user/schools/all')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 updateAmpelflyer(data,schulhausKey,cbSuccess,cbFail) {
   axios
    .post('/datenschutz/ampelflyer/update',{
      data,
      schulhausKey
    })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 updateAmpelflyerLogo(formData,cbSuccess,cbFail) {
   axios.post('/datenschutz/ampelflyer/update/logo', formData, {
     headers: {
      'Content-Type': 'multipart/form-data'
     }
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 createNewAmpelflyerService(formData,cbSuccess,cbFail) {
   axios.post('/datenschutz/ampelflyer/services/create', formData, {
    headers: {
     'Content-Type': 'multipart/form-data'
    }
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 createNewCatalog(theme,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/create', {
    theme
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 getAllCatalogThemes(cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/themes/all')
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 getCatalogConfig(catalogId,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/config',{
     catalogId
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 getCatalogConfigByName(catalogName,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/config/get',{
     catalogName
   })
   .then(cbSuccess)
   .catch(cbFail)
 }

 //####################################################
 updateCatalogBanner(formData,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/update/banner', formData, {
     headers: {
      'Content-Type': 'multipart/form-data'
     }
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 updateCatalogCategoryIcon(formData,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/category/update/icon', formData, {
     headers: {
      'Content-Type': 'multipart/form-data'
     }
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 uploadExampleImage(formData,cbSuccess,cbFail) {
   axios.post('/catalog/suggestion/image', formData, {
     headers: {
      'Content-Type': 'multipart/form-data'
     }
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 createCatalogCategory(catalogId,categoryName,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/category/add',{
     catalogId,
     categoryName
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 deleteCatalogCategory(catalogId,categoryId,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/category/delete',{
     catalogId,
     categoryId
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 updateCatalogConfig(data,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/config/update',{
    data
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 catalogGetExample(catalogId,exampleId,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/pending/get',{
    catalogId,
    exampleId
   })
   .then(cbSuccess)
   .catch(cbFail)
  }
//####################################################
catalogAcceptExample(catalogId,example,cbSuccess,cbFail) {
  axios.post('/catalog/admin/database/pending/accept',{
   catalogId,
   example
  })
  .then(cbSuccess)
  .catch(cbFail)
 }
 //####################################################
 catalogDeclineExample(catalogId,exampleId,cbSuccess,cbFail) {
   axios.post('/catalog/admin/database/pending/decline',{
    catalogId,
    exampleId
   })
   .then(cbSuccess)
   .catch(cbFail)
  }
  //####################################################
  catalogDeleteExample(catalogId,exampleId,cbSuccess,cbFail) {
    axios.post('/catalog/admin/database/example/delete',{
     catalogId,
     exampleId
    })
    .then(cbSuccess)
    .catch(cbFail)
   }
//####################################################
catalogUpsertExample(catalogId,data,cbSuccess,cbFail) {
  axios.post('/catalog/suggestion',{
   catalogId,
   data
  })
  .then(cbSuccess)
  .catch(cbFail)
 }
//####################################################
catalogGetExamples(catalogId,cbSuccess,cbFail) {
  axios.post('/catalog/examples/all',{
   catalogId
  })
  .then(cbSuccess)
  .catch(cbFail)
 }
 //####################################################
 catalogAddComment(catalogId,exampleId,data,cbSuccess,cbFail) {
   axios.post('/catalog/example/comment/add',{
    catalogId,
    exampleId,
    data
   })
   .then(cbSuccess)
   .catch(cbFail)
  }
//####################################################
catalogExampleAddLike(catalogId,exampleId,cbSuccess,cbFail) {
  axios.post('/catalog/example/like/add',{
    catalogId,
    exampleId
  })
  .then(cbSuccess)
  .catch(cbFail)
}
//####################################################
catalogExampleRemoveLike(catalogId,exampleId,cbSuccess,cbFail) {
  axios.post('/catalog/example/like/remove',{
    catalogId,
    exampleId
  })
  .then(cbSuccess)
  .catch(cbFail)
}
//####################################################
catalogExampleAddDislike(catalogId,exampleId,cbSuccess,cbFail) {
  axios.post('/catalog/example/dislike/add',{
    catalogId,
    exampleId
  })
  .then(cbSuccess)
  .catch(cbFail)
}
//####################################################
catalogExampleRemoveDislike(catalogId,exampleId,cbSuccess,cbFail) {
  axios.post('/catalog/example/dislike/remove',{
    catalogId,
    exampleId
  })
  .then(cbSuccess)
  .catch(cbFail)
}
//####################################################
 redirecterCheckState(domain,cbSuccess,cbFail) {
   axios.post('/redirecter/domain/check',{
     domain
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 redirecterApply(domain,redirect,cbSuccess,cbFail) {
   axios.post('/redirecter/apply',{
     domain,
     redirect
   })
   .then(cbSuccess)
   .catch(cbFail)
 }
 //####################################################
 getPendingRedirects (cbSuccess,cbFail) {
   axios
    .post('/admin/releases/redirecter/pendings')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 getAllRedirects (cbSuccess,cbFail) {
   axios
    .post('/admin/releases/redirecter/all')
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 acceptRedirect (id,cbSuccess,cbFail) {
   axios
    .post('/admin/releases/redirecter/accept',{ id })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
 declineRedirect (id,cbSuccess,cbFail) {
   axios
    .post('/admin/releases/redirecter/decline',{ id })
    .then(cbSuccess)
    .catch(cbFail)
 }
 //####################################################
}



const AjaxClient = new Ajax()
export default AjaxClient
