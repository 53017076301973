import React, { useState,useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'


import {
} from '@material-ui/core'

import {
 ExpandMore,Label
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container:{
    paddingTop:theme.spacing(10),
    paddingBottom:theme.spacing(10),
    textAlign:'center'
  },

}))



const Bookmarklet = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    console.log('test');
  })

  return (
   null
  )
})


export default Bookmarklet
