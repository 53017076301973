import React, { useContext, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Button,
  Typography
} from "@material-ui/core"

import IMG_FEE from 'media/img/Fee_inFarbschema.png'

const useStyles = makeStyles(theme => ({
  denise: {
    background: '#fff',
    borderRadius: '50%',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center center',
    maxWidth:'80%',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
  }
}));


const Helpdesk = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { kibsmeta } = useContext(DataStore)
  const { utilityText } = useContext(UiStore)

  const _toSai = () => {
    const win = window.open('https://www.swisscom.ch/de/schulen-ans-internet/sai-anschluss.html', '_blank');
    win.focus()
  }

  useEffect(utilityText.getTextHelpdesk,[])

  return (
    <section className="digital-experience-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>{t('core.landingpage.helpdesk.title')}</h2>
          <div className="bar" />
        </div>
        <div className="tab digital-experience-tab">
          <div className="tab_content">
            <div className="tabs_item">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="digital-experience-content">
                    <h3>{t('core.landingpage.helpdesk.intro')}</h3>
                    <p></p>
                    <div className="experience-inner-content">
                      <div className="icon">
                        <i className="flaticon-call" />
                      </div>
                      <h3><a href={"tel:"+kibsmeta.telefon}>{kibsmeta.telefon}</a></h3>
                      <Typography variant="h6" component="p">
                       {t('core.landingpage.helpdesk.tel')}{" "}
                      </Typography>

                    </div>
                    <div className="experience-inner-content">
                      <div className="icon">
                        <i className="flaticon-email-1" />
                      </div>
                      <h3><a href={"mailto:"+kibsmeta.mail}>{kibsmeta.mail}</a></h3>
                      <Typography variant="h6" component="p">
                       {t('core.landingpage.helpdesk.mail')}{" "}
                      </Typography>
                      <Typography variant="subtitle1" component="p" dangerouslySetInnerHTML={{ __html: utilityText.textHelpdesk.toString('html') }} />
                      <p>
                       <Button variant="contained" onClick={_toSai} className={`${classes.btnColor} phButton`}>
                        {t('core.landingpage.helpdesk.sai')}{" "}
                       </Button>
                      </p>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6" style={{ display:"none" }}>
                  <div className="digital-experience-image" >
                    <img src="//uploader.kibs.ch/kibsteam/Denise_cartoonized.png" alt="image" className={classes.denise}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
 )
})

export default Helpdesk
