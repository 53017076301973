import React, { useState } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from '@material-ui/core/styles'
import { Trans,useTranslation } from 'react-i18next'


import {
  Typography,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardActionArea,
  CardContent,
  CardMedia
} from '@material-ui/core'

import {
 PictureAsPdf,ExitToApp,PlayArrow,Pause
} from '@material-ui/icons'

const PLAYER_HEIGHT = 430

const useStyles = makeStyles(theme => ({
  spacing:{
    paddingTop:theme.spacing(6),
    paddingBottom:theme.spacing(6)
  },
  paddingTopBottom:{
    paddingTop:100,
    paddingBottom:100
  },
  [theme.breakpoints.down('sm')]:{
    paddingTopBottom:{
      paddingTop:30,
      paddingBottom:30
    }
  },
  iframe:{
    width:'100%',
    height:PLAYER_HEIGHT,
    border:'none'
  },
  playlist:{
    height:PLAYER_HEIGHT,
    overflowY:'auto',
    border:'1px solid #eee'
  }
}))

const VIDEOS = {
  de:[
    'kaliu5QJPOk',
    'z44LKq7hf1c',
    'uhhXwjyYhWo',
    'EMWfKfyF_3g',
    'okfcjMl1kA8',
    'TgkG2Hs9828',
    'g8zE5J58AZA',
    '1W0Sn1e-enE'
  ],
  fr:[
    'ZrQmYQUqj4',
    'VTgXacQz-v8',
    'ytgy-NeCKiY',
    'J2KXfFd2YY',
    'VfjF7dQryA4',
    'KjZ-Y3C0UBQ',
    'NiMD2XmrEmU',
    't-ffd3VAtUU'
  ]
}

const HOST = "https://uploader.kibs.ch/factsheets/"
const PDFS = {
  de:[
    "Factsheet_1st-Level-edu-Support.pdf",
    "Factsheet_Audiovisuelle-Ausstattung.pdf",
    "Factsheet_Datenschutz.pdf",
    "Factsheet_Geraeteinfrastruktur.pdf",
    "Factsheet_Internetbandbreite.pdf",
    "Factsheet_Organisation-und-Kommunikation.pdf",
    "Factsheet_WLAN.pdf"
  ],
  fr:[
    "FR_Factsheet_1st-Level-edu-Support.pdf",
    "FR_Factsheet_Audiovisuelle-Ausstattung.pdf",
    "FR_Factsheet_Datenschutz.pdf",
    "FR_Factsheet_Geraeteinfrastruktur.pdf",
    "FR_Factsheet_Internetbandbreite.pdf",
    "FR_Factsheet_Organisation-und-Kommunikation.pdf",
    "FR_Factsheet_WLAN.pdf"
  ]
}



const Unterstuetzung = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [videoId,setVideoId] = useState(VIDEOS.de[0])

  const _setVideoId = (videoId) => { setVideoId(videoId) }

  return (
    <Container maxWidth="md" className={classes.paddingTopBottom}>

     <Typography variant="h6">
      <Trans i18nKey="unterstuetzung.intro">
       {[
         <a target='_blank' href='https://www.lp-sl.bkd.be.ch/de/start/themen/medien-und-informatik.html'></a>
       ]}
      </Trans>
     </Typography>

     <List className={classes.spacing}>
      <ListItem button component="a" href={HOST+PDFS.de[0]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.firstLevelEduSupport')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
      <ListItem button component="a" href={HOST+PDFS.de[1]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.audioVisual')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
      <ListItem button component="a" href={HOST+PDFS.de[2]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.dataProtection')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
      <ListItem button component="a" href={HOST+PDFS.de[3]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.devices')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
      <ListItem button component="a" href={HOST+PDFS.de[4]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.bandwidth')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
      <ListItem button component="a" href={HOST+PDFS.de[5]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.organizationCommunication')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
      <ListItem button component="a" href={HOST+PDFS.de[6]}>
       <ListItemIcon><PictureAsPdf /></ListItemIcon>
       <ListItemText primary={t('unterstuetzung.wlan')} />
       <ListItemSecondaryAction><ExitToApp /></ListItemSecondaryAction>
      </ListItem>
     </List>

     <Grid container className={classes.spacing}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
       <iframe
        src={"https://www.youtube.com/embed/"+videoId+'?rel=0'}
        className={classes.iframe}
        allowFullScreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
       ></iframe>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
       <List className={classes.playlist}>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[0]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[0] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.teaserSI')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[1]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[1] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.devicesTeacher')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[2]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[2] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.organizeDigital')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[3]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[3] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.audiVisualClassroom')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[4]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[4] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.networkBandwidth')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[5]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[5] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.computerRoom')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[6]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[6] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.devicesSchool')} />
         </ListItem>
         <ListItem button onClick={() => { _setVideoId(VIDEOS.de[7]) }}>
          <ListItemIcon> {videoId === VIDEOS.de[7] ? <Pause /> : <PlayArrow /> } </ListItemIcon>
          <ListItemText primary={t('unterstuetzung.conceptAndPed')} />
         </ListItem>
       </List>
      </Grid>
     </Grid>

    </Container>
  )
}

export default Unterstuetzung
