import React, { useContext,useEffect } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import history from 'history.js'
import AnimateHeight from 'react-animate-height'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'
import { PendingsDialog } from './FreshStart'
import { ourServices, ourAuthServices } from 'shared/functions/ourservices'


import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CardActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  ButtonGroup,
  Button,
  Tooltip,
  Divider
} from '@material-ui/core'
import { Add,Home,Drafts,Traffic,MoreVert,Person,ExpandMore,Lock } from '@material-ui/icons'
import { amber,blue } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root:{
   position:'relative',
   marginTop:theme.spacing(2),
  },
  media:{
    height:140,
    objectFit:'contain'
  },
  card:{
   minHeight:120,
   cursor:'pointer',
   height:'auto',
   margin:'0 auto',
   margin:theme.spacing(2),
   textAlign:'center',
   position:'relative'
  },
  mediaSchooltech:{
    backgroundSize:'contain',
    objectFit:'contain',
    filter: 'grayscale(100%)'
  },
  unAuthorizedServices:{
    background:'#ccc',
  },
  rel:{
    position:'relative'
  },
  unAuthInfoContainer:{
    display:'flex',
    height:'100%',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column'
  },
  unAuthInfoStyles:{
    background:'rgba(255,255,255,.5)',
    padding:theme.spacing(3),
    textAlign:'center',
    border:'1px solid #eee'
  },
  lock:{
    position:'absolute',
    fontSize:'3rem',
    left:'1rem',
    top:'-3rem',
    margin:'0 auto',
    zIndex:1,
    height:'80%',
    color:'#919191',
  },
  center:{
    justifyContent:'center',
    textAlign:'center'
  }
}));


const PortalServices = observer(() => {
  const { schoolPageUI } = useContext(UiStore)
  const { dashboard,user } = useContext(DataStore)
  const { t } = useTranslation()
  const classes = useStyles()

  const authservices = ourAuthServices().map(s => {
    s.onClick = () => { schoolPageUI.handleOpenRegisterDialog() }
    return s
   }
  )
  const services = ourServices()

  return (
   <div className={classes.root}>
    {!user.isAuthenticated &&
      <Grid container className={`${classes.rel} ${classes.unAuthorizedServices}`}>
        {authservices.map((as,i) => {
          return <Grid item xs={6} sm={4} md={3} lg={3} key={'school-specific-authservice-'+i}>
           <AuthService
            img={as.img}
            title={as.title}
            onClick={as.onClick}
           />
          </Grid>
        })}
      </Grid>
    }
    <Grid container>
    {services.map((s,j) => {
      return <Grid item xs={6} sm={4} md={3} lg={3} key={'school-specific-service-'+j}>
       <Service
        media={s.img}
        title={s.title}
        onClick={s.goTo}
       />
      </Grid>
    })}
    </Grid>
    <RegisterDialog />
   </div>
  )
})

const RegisterDialog = observer(() => {
  const classes = useStyles()
  const { schoolPageUI } = useContext(UiStore)
  const register = () => {
    schoolPageUI.handleCloseRegisterDialog()
    history.push('/login?redirect_uri=/dashboard')
  }

  const { t } = useTranslation()

  return (
    <Dialog onClose={schoolPageUI.handleCloseRegisterDialog} aria-labelledby="register-before-use" open={schoolPageUI.openRegisterDialog}>
      <DialogContent className={classes.center}>
          <DialogContentText id="alert-dialog-description">
            {t('core.dashboard.unAuthorizedServices')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.center}>
          <Button variant="contained" color="primary" onClick={register}>{t('core.login.title')}</Button>
        </DialogActions>
    </Dialog>
  )
})

const Service = observer((props) => {
  const { media,title,onClick,text } = props
  const { schoolPageUI } = useContext(UiStore)
  const classes = useStyles()
  const theme = useTheme();
  const upXs = useMediaQuery(theme.breakpoints.down('xs'))
  const { t } = useTranslation()

  return (
    <Card className={classes.card} onClick={onClick}>
       <CardActionArea>
         <CardMedia
           component="img"
           height="140"
           image={media}
           title={t(title)}
         />
         <CardContent>
           <Typography gutterBottom variant={!upXs?'h5':'caption'} component="h2">
             {t(title)}
           </Typography>
           {text &&
             <Typography variant="body2" color="textSecondary" component="p">
               {t(text)}
             </Typography>
           }
         </CardContent>
       </CardActionArea>
     </Card>
  )
})

const AuthService = observer((props) => {
  const { img,title,onClick,text } = props
  const { schoolPageUI } = useContext(UiStore)
  const classes = useStyles()
  const theme = useTheme();
  const upXs = useMediaQuery(theme.breakpoints.down('xs'))
  const { t } = useTranslation()

  console.log(props);

  return (
    <Card className={classes.card} onClick={onClick}>
     <Lock className={classes.lock}/>
       <CardActionArea>
         <CardMedia
           component="img"
           height="140"
           image={img}
           title={t(title)}
           className={classes.mediaSchooltech}
         />
         <CardContent>
           <Typography gutterBottom variant={!upXs?'h5':'caption'} component="h2" style={{opacity:0.5}}>
             {t(title)}
           </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
             {t(text)}
           </Typography>
         </CardContent>
       </CardActionArea>
     </Card>
  )
})



export default PortalServices
