import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import IMG_KIBS_LOGO_BW from 'media/img/KiBSLogo_B&W.png'
import IMG_KIBS_LOGO_THUMBNAIL from 'media/img/KiBSThumbNail_B&W.png'
import IMG_KIBS_LOGO_VIOLET from 'media/img/KibsLogo_violet.png'
import IMG_KIBS_LOGO_PINK from 'media/img/KibsLogo_pink.png'
import IMG_KIBS_LOGO_RED from 'media/img/KibsLogo_red.png'
import IMG_KIBS_LOGO_THREE_COLORED from 'media/img/KibsLogo_threeColored.png'
import IMG_PHBERN_LOGO_BLACK from 'media/img/Log_PHB_black_ohne.png'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  image:{
    maxWidth:'100%'
  },
  thumbnail:{
    maxWidth:'100%',
    height:45
  },
  link:{
    display:'flex',
    textDecoration:'none',
    outline:'none',
    fontWeight:400,
    color:'#457d9a',
    '&:hover':{
      textDecoration:'none',
      outline:'none',
      fontWeight:400,
      color:'#457d9a',
    }
  }
}));

export function KibsLogo () {
  const classes = useStyles()

  return (
    <Link to={'/'} className={classes.link}>
     <span style={{fontWeight:'bold'}}>kibs - PH</span>Bern
    </Link>
  )
}

export function PHBernLogo () {
  const classes = useStyles()
  return (<img src={IMG_PHBERN_LOGO_BLACK} alt="kibs.ch" className={classes.image} style={{maxHeight:40}}/>)
}

export function KibsLogoImage () {
  const classes = useStyles()
  return (<img src={IMG_KIBS_LOGO_RED} alt="kibs.ch" className={classes.image}/>)
}
