import React, { useContext,useEffect,useState } from "react"
import ReactDOM from "react-dom"

import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { observer,useObserver } from 'mobx-react-lite'
import UiStore from './stores/ui'
import DataStore from './stores/data'


import {
} from '@material-ui/core/'

import {
} from '@material-ui/icons'



const useStyles = makeStyles(theme => ({
 root:{
   position:'relative',
   width:'100%',
   maxWidth:900,
   display:'flex',
   alignItems:'center',
   flexDirection:'column',
   margin:'0 auto',
   minWidth:300,
   [theme.breakpoints.up('sm')]: {
     minWidth:500
   }
 }
}))

const SchoolDocuments = observer((props) => {
  const classes = useStyles()
  const data = useContext(DataStore)
  const { t } = useTranslation()

  useEffect(() => {
    const schoolId = props.schoolId
    if (schoolId) {
      data.getSchooltechData(
        schoolId,
        () => {},
        () => {}
      )
    }
  },[])

  return (
   null
  )
})

export default SchoolDocuments
