import React, { useState,useEffect,useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useFetch from 'use-http'
import { usePageVisibility } from 'react-page-visibility'
import { List as MovableList, arrayMove } from "react-movable"

import AudioDrivePlayer from './Player'
import { AudioDriveMinimalPlayer } from './Player'
import GlobalUiStore from 'core/stores/ui'

import WEBP_AUDIODRIVE from './audiodrive.webp'
import IMG_LOGO from './logo.png'
import './index.css'

import {
  Avatar,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Container,
  Paper,
  TextField,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {
 Send,PlayArrow,Pause,Check,Remove,Close,Link,Code,Replay
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
 root:{
    backgroundColor: '#ff7b73',
    color: 'white',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
 },
 list:{
   maxHeight:900,
   overflow:'auto'
 },
 listItem:{
   backgroundColor:'#ff5722',
   borderRadius:5,
   marginTop:'1rem',
   marginBottom:'1rem',
   cursor:'move'
 },
 embeddedPlayer:{
  border:'1px solid rgba(0,0,0,.8)'
 },
 customInput:{
   display:'flex',
   justifyContent:'center'
 },
 subhow:{
  textAlign:'center'
 },
 webp:{
   maxWidth:900,
   margin:'0 auto',
   padding:'1rem',
   margin:'1rem'
 },
 reset:{
   position:'fixed',
   top:'1rem',
   right:'.5rem'
 },
 logo:{
   width:120,
 },

}))

const DRIVE = {
  API_KEY:"AIzaSyCXMDbSk42Hs1rDo2uS2fsXTVUQv7BSxo4",
  ENDPOINT:"https://www.googleapis.com/drive/v3/files"
}

function isDriveLink (url) {
  const pattern = "https://drive.google.com/drive/folders/"
  return url.startsWith(pattern)
}

function getDriveId (url) {
  const matches = url.match(/[-\w]{25,}/)
  if (matches && matches[0]) {
    return matches[0]
  } else return false
}

function requestFolder (get,directories,files,cb) {
  if (directories.length > 0) {
    const folderId = directories[0]
    const url = "?q='" + folderId + "'+in+parents&key=" + DRIVE.API_KEY;

    get(url).then(res => {
      if (res.files.length > 0) {
        res.files.map(file => {
          if (file.mimeType === 'application/vnd.google-apps.folder') {
           directories.push(file.id)
         }
         else if (file.mimeType.startsWith('audio')) {
           files.push({ id:file.id, name:file.name, checked:true })
         }
        })
      }
     directories.shift()
     requestFolder(get,directories,files,cb)
    })
  } else {
    return cb(files)
  }
}



function readFromClipboard (get,lastDriveLink,cb) {
  navigator.clipboard
   .readText()
   .then(text => {
     if (isDriveLink && text !== lastDriveLink) {
       const folderId = getDriveId(text)
       if (folderId) {
         requestFolder(
           get,
           [folderId],
           [],
           (files) => {
             cb({ files, text })
           }
         )
       } else {
         cb({ files:[], text})
       }
     }
   })
   .catch(err => {
     console.error('Failed to read clipboard contents: ', err);
   });
}


const AudioDrive = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { organizer } = useContext(DataStore)
  const data = useContext(DataStore)
  const { get, loading, error } = useFetch(DRIVE.ENDPOINT)
  const isVisible = usePageVisibility()
  const [open,setOpen] = useState(isVisible)

  useEffect(() => {
   if (isVisible) {
     navigator.clipboard
     .readText()
     .then(text => {
       if (isDriveLink(text)) {
         setOpen(true)
       }
     })
   }
  },[isVisible])

  const hasFiles = organizer.files.length > 0

  return (
   <section className={classes.root}>
    <IconButton className={classes.reset} onClick={data.resetApp}><Replay /></IconButton>
    <img src={IMG_LOGO} alt="logo" className={classes.logo} />
    <Typography variant="h3">{t('audiodrive.title')}</Typography>
    {!hasFiles && <AudioDriveExplain get={get} loading={loading}/>}
    {hasFiles && <AudioDriverMovableList />}
    {hasFiles && <EmbeddedPlayer />}
    {organizer.current && <AudioDriveMinimalPlayer id={organizer.current} playing={organizer.playing} />}
    <RequestDialog get={get} open={open} onClose={()=>setOpen(false)}/>
   </section>
  )
})

const AudioDriverMovableList = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { organizer } = useContext(DataStore)

  const _setCurrent = (id) => {
    organizer.setCurrent(id)
    if (organizer.current === id) {
      organizer.togglePlay()
    } else {
      organizer.play()
    }
  }

  return (
    <MovableList
      values={organizer.files}
      onChange={({ oldIndex, newIndex }) =>
        organizer.setFiles(arrayMove(organizer.files, oldIndex, newIndex))
      }
      renderList={({ children, props }) => {
        return <List className={classes.list} {...props}> {children} </List>
      }}
      renderItem={({ value, props }) => {
       let avatar = null
       avatar = value.name.charAt(0).toUpperCase()
       if (organizer.current === value.id) {
         avatar = organizer.playing ? <Pause /> : <PlayArrow />
       }
       return <ListItem onMouseDown={()=>{ _setCurrent(value.id) }} className={classes.listItem} {...props}>
        <ListItemAvatar >
         <Avatar >
          {avatar}
         </Avatar>
        </ListItemAvatar>
        <ListItemText secondary={<span style={{ color:'white' }}>{value.name}</span>} />
        <IconButton onClick={()=>organizer.toggleFile(value.id)}>
         {value.checked ? <Check style={{ color:'#76ff03' }}/> : <Remove style={{ color:'#f44336' }}/>}
        </IconButton>
       </ListItem>
      }}
    />
  )
})

const AudioDriveExplain = observer(({ get,loading }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { organizer } = useContext(DataStore)

  const _request = () => {
   requestFolder(
    get,
    ["1Z703h2LAz6P3YaiIEXYV4LLoF3pXewdr"],
    [],
    (files) => {
      organizer.setFiles(files)
    }
   )
  }

  return (
    <Container maxWidth="md" className={classes.subhow}>
     <Typography variant="body1">{t('audiodrive.sub')}</Typography>
     <div className={`${classes.webp} audiodrive-box`}>
      <img src={WEBP_AUDIODRIVE} alt="audiodrive" style={{ maxWidth:'100%',maxHeight:'100%' }}/>
     </div>
     <Typography variant="body2">{t('audiodrive.how')}</Typography>
     <Button onClick={_request}>{ loading ? <CircularProgress style={{ width:18, height:18 }}/> : t('audiodrive.tryNow')}</Button>
    </Container>
  )
})

const RequestDialog = observer(({ get, open, onClose }) => {
  const { t } = useTranslation()
  const { organizer } = useContext(DataStore)

  const _request = () => {
    readFromClipboard(
      get,
      organizer.lastDriveLink,
      ({ files,text }) => {
        organizer.setFiles(files)
        organizer.setLastDriveLink(text)
        onClose()
      }
    )
  }

  return (
    <Dialog onClose={onClose}  open={open}>
     <DialogContent>
      <Typography variant="body1">{t('audiodrive.clipboard')}</Typography>
     </DialogContent>
     <DialogActions>
      <Button onClick={onClose} color="primary">
        <Close />
      </Button>
      <Button onClick={_request} color="primary" autoFocus>
        <Check />
      </Button>
    </DialogActions>
    </Dialog>
  )
})

const EmbeddedPlayer = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { organizer } = useContext(DataStore)
  const { width, height } = organizer.breakpoint
  const { notifier } = useContext(GlobalUiStore)
  const src = window.location.href + '/' + organizer.getCheckedTracks().join(',')

  const _embedCode = () => {
    return `<iframe src="${src}" style="border:0;width:${width}px;height:${height}px"></iframe>`
  }
  const _onCopy = () => {
    notifier.push(t('core.notifier.copiedToClipboard'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
  }


  return (
   <section style={{ width:width+'px',height:height+'px',position:'relative' }}>
     <div className={classes.customInput}>
       <TextField
        value={width}
        type="number"
        onChange={(e)=>{organizer.setCustomBreakpointWidth(e.target.value)}}
        InputProps={{
         inputProps: { style: { textAlign:'center', color:'white' } },
        }}
       />
       <Close />
       <TextField
        value={height}
        type="number"
        onChange={(e)=>{organizer.setCustomBreakpointHeight(e.target.value)}}
        InputProps={{
         inputProps: { style: { textAlign:'center', color:'white' } },
        }}
       />
     </div>
    <iframe src={src} style={{ width:width+'px',height:height+'px' }} className={classes.embeddedPlayer}></iframe>
    <div className={classes.customInput}>
     <CopyToClipboard text={_embedCode()} onCopy={_onCopy}>
      <Button fullWidth variant="contained" color="secondary"> <Code /> {t('audiodrive.embedCode')}</Button>
     </CopyToClipboard>
    </div>
   </section>
  )
})

export default AudioDrive
