import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import ls from 'local-storage'

import AUDIO_MSG  from 'media/audio/internetanschluss/msgIn.m4a'

class UI {
  audioMsg = new Audio(AUDIO_MSG)
  selectedTab = 0
  drawerOpen = false
  backToTopBtn = false



  playSound = false
  showByte = false
  showDot = false
  currentTextByte = ''
  currentTextDot = ''
  onEnterByteTimer = null
  onEnterDotTimer = null

  dotZIndex = 0
  byteZIndex = 0

  RESPONSIVE_WIDTH = 410
  LAST_SECTION_INDEX = 5
  SPEECH_BUBBLE_DELAY = 250
  ON_ENTER_PAGE_DELAY = 1500
  ADDITIONAL_DELAY_IF_BOTH_SHOWN = 2500

  // type === section || slide
  doMagic (type,index) {
    const byteItem = this.dotText .filter(item => { if (item[type] === index) return item })[0]
    const dotItem  = this.byteText.filter(item => { if (item[type] === index) return item })[0]
    const botAreShown = byteItem && dotItem

    clearTimeout(this.onEnterByteTimer)
    clearTimeout(this.byteTimer)
    clearTimeout(this.onEnterDotTimer)
    clearTimeout(this.dotTimer)


    if (byteItem) {
     // already shown, no delay
     if (this.showByte) {
       this.currentTextByte = byteItem.text
       this.play(this.audioMsg)
       this.handleShowByte()
     } else {
     // new on screen, delay fade in
       this.onEnterByteTimer = setTimeout(() => {
        this.currentTextByte = byteItem.text
        this.play(this.audioMsg)
        this.handleShowByte()
       },this.ON_ENTER_PAGE_DELAY)
     }
    } else {
      this.handleHideByte()
    }
    if (dotItem) {
     if (this.showDot) {
       this.currentTextDot = dotItem.text
       this.play(this.audioMsg)
       this.handleShowDot()
     } else {
       const delay = botAreShown ? this.ON_ENTER_PAGE_DELAY + this.ADDITIONAL_DELAY_IF_BOTH_SHOWN : this.ON_ENTER_PAGE_DELAY
       this.onEnterDotTimer = setTimeout(() => {
         this.currentTextDot = dotItem.text
         this.play(this.audioMsg)
         this.handleShowDot()
       },delay)
     }
    } else {
      this.handleHideDot()
    }
  }
  showBackToTopBtn = () => { this.backToTopBtn = true }
  hideBacktoTopBtn = () => { this.backToTopBtn = false }
  dotText = [
   {section:0,text:'internetanschluss.dot.howSaveAreYou'},
   {section:2,text:'internetanschluss.dot.ourProtectionAndWhatToKnow'},
   {slide:  1,text:'internetanschluss.dot.sslInterception.point1'},
   {slide:  3,text:'internetanschluss.dot.sslInterception.point3'}
  ]
  byteText = [
   {section:0,text:'internetanschluss.byte.howSaveAreYou'},
   {section:2,text:'internetanschluss.byte.ourProtectionAndWhatToKnow'},
   {section:3,text:'internetanschluss.byte.ampelflyer'}
  ]

  dotTimer = null
  byteTimer = null
  dotWait = false
  byteWait = false
  toggleSound = () => this.playSound = !this.playSound

  play = (audio) => {
   if (this.playSound) {
    audio.volume = 0.2
    audio.play()
   }
  }

  handleShowByte = () => {
    this.showByte = true
    this.byteWait = false
  }
  handleHideByte = () => {
    this.showByte = false

  }
  handleShowDot = () => {
    this.showDot = true
    this.dotWait = false
  }
  handleHideDot = () => {
    this.showDot = false
  }
  toggleDotWait = () => {
    this.dotWait = !this.dotWait
    if (this.dotWait && this.showDot && this.showByte) {
      this.dotZIndex = 0
      this.byteZindex = 1
    } else {
      this.dotZIndex = 1
      this.byteZIndex = 0
    }
  }
  toggleByteWait = () => {
    this.byteWait = !this.byteWait
    if (this.byteWait && this.showByte && this.showDot) {
      this.byteZIndex = 0
      this.dotZIndex = 1
    } else {
      this.byteZIndex = 1
      this.dotZIndex = 0
    }
  }

  setDotTimer = (timer) => this.dotTimer = timer
  setByteTimer = (timer) => this.byteTimer = timer
  toggleDrawerOpen = () => this.drawerOpen = !this.drawerOpen
  setLastDirection = (direction) => this.lastDirection = direction
  setSelectTab = (index) => this.selectedTab = index


  get isSmartphone () {
    const userAgent = window.navigator.userAgent
    const isAndroid = () => Boolean(userAgent.match(/Android/i))
    const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))

    const mobileSize = window.innerWidth <= 600
    return (isAndroid() || isIos()) && mobileSize
  }
}

decorate(UI,{
  currentTextByte:observable,
  currentTextDot:observable,
  lastDirection:observable,
  showByte:observable,
  showDot:observable,
  playSound:observable,
  selectedTab:observable,
  drawerOpen:observable,
  dotTimer:observable,
  byteTimer:observable,
  dotWait:observable,
  byteWait:observable,
  onEnterDotTimer:observable,
  onEnterByteTimer:observable,
  dotZIndex:observable,
  byteZIndex:observable,
  showBackToTopBtn:observable,
  isSmartphone:computed
})


const UiStore = createContext(new UI())
export default UiStore
