import React, { useEffect, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import {
    Container,
    Typography,
    Button,
    Paper,
    IconButton,
    ListItemIcon,
    useMediaQuery,
    Grid,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(5)
    },
    heading: {
        margin: '0 auto',
        textAlign: 'center',
        padding: theme.spacing(2)
    },

    content: {
        marginTop: 70,
        marginBottom: 70,
        paddingLeft: theme.spacing(5)
    },
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(2),
    },
    media: {
        height: 220,
    },
    spacer: {
        marginBottom: 70
    },
    padding: {
        padding: "0.5rem"
    }

}))

const Kompass = observer(() => {
    const classes = useStyles()
    const { t } = useTranslation()

    function openPdf(id) {
        window.open("https://uploader.kibs.ch/core/kompass/" + id + ".pdf", "_blank")
    }


    return (
        <>
            <Typography variant="h3" className={classes.heading}> {t('core.kompass.title')} </Typography>
            <Container maxWidth="md" className={classes.root}>


                <Typography component="p" className={classes.content}>
                    Im Laufe der Geschichte haben neu eingeführte Technologien immer eine Reihe von Fragen und Bedenken hervorgerufen.
                    Um solchen Fragen und Bedenken sicher und entspannt zu begegnen, nehmen wir in drei Positionspapieren Stellung zu aktuellen Themen.
                    Wir stellen unsere Vorgehensweise als Kompass zur Orientierung im System Schule in der digitalen Transformation vor.
                </Typography>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={4} lg={4} className={classes.padding}>
                                <Card className={classes.root}>
                                    <CardActionArea onClick={() => { openPdf("Aufbruch_in_die_digitale_Welt") }}>
                                        <CardMedia
                                            className={classes.media}
                                            image="https://uploader.kibs.ch/core/kompass/p1.png"
                                            title="Aufbruch in die digitale Welt"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Aufbruch in die digitale Welt
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                6 konkrete Schritte, die uns helfen digital geprägt zu denken in einer digital gepräften Gesellschaft.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" onClick={() => { openPdf("Aufbruch_in_die_digitale_Welt") }}>
                                            Lesen
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} className={classes.padding}>
                                <Card className={classes.root}>
                                    <CardActionArea onClick={() => { openPdf("Wenn Menschen sich durch Maschinen in Frage gestellt fühlen") }}>
                                        <CardMedia
                                            className={classes.media}
                                            image="https://uploader.kibs.ch/core/kompass/p2.png"
                                            title="Wenn Menschen sich durch Maschinen in Frage gestellt führen"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Wenn Menschen sich durch Maschinen in Frage gestellt fühlen
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Zum Umgang mit technologischen Neuerungen in einer digital geprägten Gesellschaft.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" onClick={() => { openPdf("Wenn Menschen sich durch Maschinen in Frage gestellt fühlen") }}>
                                            Lesen
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} className={classes.padding}>
                                <Card className={classes.root}>
                                    <CardActionArea onClick={() => { openPdf("VorwissenML") }}>
                                        <CardMedia
                                            className={classes.media}
                                            image="https://uploader.kibs.ch/core/kompass/p3.png"
                                            title="Aufbruch in die digitale Welt"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Vorwissen: Maschinelles Lernen und Künstliche Intelligenz
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                An konkreten Schulbeispielen erklärt inklusive Meinungen aus der Schulinformatik.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" onClick={() => { openPdf("VorwissenML") }}>
                                            Lesen
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} className={classes.padding}>
                                <Card className={classes.root}>
                                    <CardActionArea onClick={() => { openPdf("Handreichung KI Volksschule") }}>
                                        <CardMedia
                                            className={classes.media}
                                            image="https://uploader.kibs.ch/core/kompass/p4.png"
                                            title="Handreichung KI Volksschule"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Handreichung KI Volksschule
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Um selbst Erfahrungen beim Anwenden von KI-Tools zu sammeln und sich eine ei-
                                                gene Meinung bzw. Haltung zu bilden, können folgende Schritte unterstützend sein.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" onClick={() => { openPdf("Handreichung KI Volksschule") }}>
                                            Lesen
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4} className={classes.padding}>
                                <Card className={classes.root}>
                                    <CardActionArea onClick={() => { openPdf("Leitsätze KI Volksschule für SuS") }}>
                                        <CardMedia
                                            className={classes.media}
                                            image="https://uploader.kibs.ch/core/kompass/p5.png"
                                            title="Leitsätze KI Volksschule für SuS"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Leitsätze KI Volksschule für SuS
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Damit Lernende eine Orientierungshilfe bei der Nutzung von KI-Tools erhalten, empfiehlt es sich Leitsätze,
                                                basierend auf der Haltung und Kultur der Schule, zu erarbeiten.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="primary" onClick={() => { openPdf("Leitsätze KI Volksschule für SuS") }}>
                                            Lesen
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.spacer} />
            </Container>
        </>
    )
})




export default Kompass
