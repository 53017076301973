import React,{ useContext,useEffect } from 'react'

import DataStore from './stores/data'
import GlobalUiStore from 'core/stores/ui'
import queryString from 'query-string'
import { observer } from 'mobx-react-lite'

import Sheets from './sheets'

const Ampelflyer = observer((props) => {
  const store = useContext(DataStore)
  const globalUI = useContext(GlobalUiStore)

  store.init()

  useEffect(() => {
    const flyerManager = store.flyerManager
    globalUI.setLoading(true)

    const params = queryString.parse(window.location.search)
    let fk_schulhaus_key = null
    if (params && params.schulhaus) {
      fk_schulhaus_key = params.schulhaus
    }
    else if (props.schoolId) {
      fk_schulhaus_key = props.schoolId
    }
    if (fk_schulhaus_key) {
     flyerManager.getSchulhausData(fk_schulhaus_key,() => {
       flyerManager.getAllFlyer(
         fk_schulhaus_key,
         () => {
           flyerManager.getAllAmpelflyerServices(
             fk_schulhaus_key,
             () => { globalUI.setLoading(false) },
             () => { globalUI.setLoading(false) }
           )
         },
         () => { globalUI.setLoading(false) }
       )
     })
    } else {
     globalUI.setLoading(false)
    }
  })

  return (
   <Sheets />
  )
})

export default Ampelflyer
