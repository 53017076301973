import React, { useContext,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import history from 'history.js'

import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'

import {
  Avatar,
  Button,
  Chip,
  Dialog,
  Divider,
  Box,
  useMediaQuery,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Tooltip
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Close,Edit,AccountCircle,MailOutline,Phone } from '@material-ui/icons'
import EditProfile, { ChangeAvatar } from './editProfile'
import Privacy from './privacy'


const useStyles = makeStyles(theme => ({
 root:{
   minWidth:320
 },
 background:{
   minHeight:150,
   background:theme.palette.primary.main,
   paddingTop:theme.spacing(2),
   color:'white'
 },
 avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin:'0 auto',
    marginBottom:theme.spacing(2),
    display:'block'
  },
  close:{
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: 'white',
  },
  edit:{
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(0),
    color: 'white'
  },
  buttonContainer:{
   padding:theme.spacing(5)
 },
 space:{
   marginTop:theme.spacing(1),
   marginBottom:theme.spacing(1)
 },
 dataBox:{
   paddingTop:theme.spacing(2),
   paddingBottom:theme.spacing(2)
 },
 data:{
   verticalAlign:'super',
   marginLeft:15
 },
 divider:{
   border: 'none',
   height: 1,
   margin: 0,
   flexShrink: 0,
   marginTop:theme.spacing(3),
   backgroundColor: 'rgba(255, 255, 255, 0.12)'
 },
 tableCell:{
   color:'#fff',
   borderBottom:'none'
 },
 chip:{
   color:'white',
   borderColor:'white'
 },
}));


const Profile = observer((props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { userProfile,userEdit,userPrivacy } = useContext(UiStore)
  const { user } = useContext(DataStore)

  const signOut = () => {
    user.signOut()
    history.push('/')
    userProfile.handleClose()
  }


  return (
    <Dialog aria-labelledby="user-profile" open={userProfile.open} onClose={userProfile.handleClose} maxWidth="md">
     <div className={classes.root}>

      <UserEntries
       user={user}
       editable={true}
       onClose={userProfile.handleClose}
      />

      <div className={classes.buttonContainer}>
       <div className={classes.space} />
       <Button fullWidth color="primary" onClick={userPrivacy.handleOpen}>
        {t('core.user.privacy')}
       </Button>
       <Button fullWidth color="secondary" onClick={signOut}>
        {t('core.general.logout')}
       </Button>
      </div>

      <EditProfile />
      <Privacy />

     </div>
    </Dialog>
  )
})

export const UserEntries = observer((props) => {
  const { t } = useTranslation()
  let { user,editable,profilePhotoSize,onClose } = props
  profilePhotoSize = profilePhotoSize ? profilePhotoSize : 40
  const { userProfile,userEdit } = useContext(UiStore)
  const classes = useStyles()
  const size = 'small', variant = 'outlined', className = classes.chip, placement = ""

  return (
     <div className={classes.background}>
      {editable
       ?
       <ChangeAvatar cssClasses={classes.avatar} size={64}/>
       :
         user.photo
         ?
         <Avatar src={user.photo} className={classes.avatar} style={{width:profilePhotoSize,height:profilePhotoSize}} />
         :
         <AccountCircle className={classes.avatar} style={{width:profilePhotoSize,height:profilePhotoSize}}  />
      }

      <Box textAlign="center">
       <Typography variant="button"> {user.foreName} </Typography>
       <Typography variant="button"> {user.name} </Typography>
      </Box>
      <Box textAlign="center">
      {user.functions && user.functions.map((f,i) => {
       return <Tooltip title={t('core.'+f.translationKey)} placement={placement}>
         <Chip
          key={'member-function-'+i}
          variant={variant} size={size}
          label={t('core.'+f.translationKey+'Short')}
          className={className}

         />
         </Tooltip>
       })
      }
      {user.teachingLevels && user.teachingLevels.map((tl,i) => {
       return <Tooltip title={t('core.'+tl.translationKey)} placement={placement}>
         <Chip
          key={'member-teachinglevel-'+i}
          variant={variant} size={size}
          label={t('core.'+tl.translationKey+'Short')}
          className={className}
         />
        </Tooltip>
       })
      }
      </Box>
      <div className={classes.divider} />
      <Box textAlign="left" className={classes.dataBox}>
      {user.mails && user.mails.map((m,i) => {
        if (m.isKibsAuth || m.isOfficeOAuth || m.isGoogleOAuth) {
          return <TableContainer key={'mail-address-'+i}>
           <Table size="small" aria-label="mail-addresses-table">
             <TableBody>
               <TableRow>
                <TableCell align="left" className={classes.tableCell}> <MailOutline /> </TableCell>
                <TableCell align="left" className={classes.tableCell}> {m.mail} </TableCell>
                <TableCell align="left" className={classes.tableCell}> {m.identifier} </TableCell>
               </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
        return null
      })}
        {user.mailsWithoutOAuth && user.mailsWithoutOAuth.map((m,i) => {
          if (m.mail !== "") {
            return <TableContainer key={'mail-address-'+i}>
             <Table size="small" aria-label="mail-addresses-table">
               <TableBody>
                 <TableRow>
                  <TableCell align="left" className={classes.tableCell}> <MailOutline /> </TableCell>
                  <TableCell align="left" className={classes.tableCell}> {m.mail} </TableCell>
                  <TableCell align="left" className={classes.tableCell}> {m.identifier} </TableCell>
                 </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
          return null
        })}
       {user.phones && user.phones.map((p,i) => (
         <TableContainer key={'phone-number-'+i}>
          <Table size="small" aria-label="phone-numbers-table">
            <TableBody>
              <TableRow>
               <TableCell align="left" className={classes.tableCell}> <Phone /> </TableCell>
               <TableCell align="left" className={classes.tableCell}> {p.telefon} </TableCell>
               <TableCell align="left" className={classes.tableCell}> {p.identifier} </TableCell>
              </TableRow>
             </TableBody>
           </Table>
         </TableContainer>
       ))}
      </Box>

      {editable &&
        <IconButton aria-label="edit" className={classes.edit} onClick={userEdit.handleOpen}>
         <Edit />
        </IconButton>
      }

      <IconButton aria-label="close" className={classes.close} onClick={onClose}>
       <Close />
      </IconButton>

     </div>
  )
})




export default Profile
