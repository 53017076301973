import React from 'react'

import {
  SimpleCatalogAdministration
} from 'services/simplecatalog/index'

const Catalogs = () => {
  return (
    <SimpleCatalogAdministration />
  )
}


export default Catalogs
