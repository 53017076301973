import React, { useContext,useEffect,useState } from "react"
import ReactDOM from "react-dom"

import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { observer,useObserver } from 'mobx-react-lite'
import UiStore from './stores/ui'
import DataStore from './stores/data'
import GlobaUI from 'core/stores/ui'
import { ConfigItem } from 'services/schooltech/index'

import {
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Avatar,
  Chip,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel
} from '@material-ui/core/'

import {
} from '@material-ui/icons'



const useStyles = makeStyles(theme => ({
 root:{
   position:'relative',
   width:'100%',
   maxWidth:900,
   display:'flex',
   alignItems:'center',
   flexDirection:'column',
   margin:'0 auto',
   minWidth:300,
   [theme.breakpoints.up('sm')]: {
     minWidth:500
   }
 },
 description:{
   width:'100%',
   display:'flex',
   alignItems:'center'
 },
 heading: {
   fontSize: theme.typography.pxToRem(15),
   flexBasis: '65%',
   flexShrink: 0,
 },
 secondaryHeading: {
   fontSize: theme.typography.pxToRem(15),
   color: theme.palette.text.secondary,
 },
 c1:{
   background:'#e39b2d',
   marginRight:theme.spacing(1)
 },
 c2:{
   background:'#2f8fce',
   marginRight:theme.spacing(1)
 },
 c3:{
   background:'#97c941',
 },
 devicesContainer:{
   display:'flex',
   flexDirection:'column',
   position:'relative'
 },
 chip:{
   margin:theme.spacing(1)
 },
 saveBtn:{
   maxWidth:500,
   marginTop:theme.spacing(.5)
 },
}))

const SchoolNumbers = observer((props) => {
  const classes = useStyles()
  const data = useContext(DataStore)
  const { t } = useTranslation()

  useEffect(() => {
    const schoolId = props.schoolId
    if (schoolId) {
      data.getSchoolNumbers(
        schoolId,
        () => {},
        () => {}
      )
    }

  },[])

  return (
   <>
    <CountSuS />
    <CountTeacher />
    <Cycles />
    <Devices />
    <SavePart schoolId={props.schoolId}/>
   </>
  )
})

const CountSuS = observer((props) => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  const onChange = (e) => { data.setCountSus(e.target.value) }

  return (
    <ConfigItem
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schoolnumbers.countSuS')}</Typography>
        <Typography className={classes.secondaryHeading}>{data.countSuS}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       type="number"
       placeholder={t('schoolnumbers.countSuS')}
       value={data.countSuS}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const CountTeacher = observer((props) => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  const onChange = (e) => { data.setCountTeacher(e.target.value) }

  return (
    <ConfigItem
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schoolnumbers.countTeacher')}</Typography>
        <Typography className={classes.secondaryHeading}>{data.countTeacher}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       type="number"
       placeholder={t('schoolnumbers.countTeacher')}
       value={data.countTeacher}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const Cycles = observer((props) => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  const toggleZyklus1 = () => data.setZyklus1()
  const toggleZyklus2 = () => data.setZyklus2()
  const toggleZyklus3 = () => data.setZyklus3()


  return (
    <ConfigItem
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schoolnumbers.zyklus')}</Typography>
        <div className={classes.secondaryHeading} style={{display:'flex',flexDirection:'row'}}>
         {!!data.zyklus1 && <Avatar className={classes.c1}>1</Avatar>}
         {!!data.zyklus2 && <Avatar className={classes.c2}>2</Avatar>}
         {!!data.zyklus3 && <Avatar className={classes.c3}>3</Avatar>}
        </div>
       </div>
     }
     edit={
      <>
       <FormControlLabel
         control={
           <Checkbox
             checked={data.zyklus1}
             onChange={toggleZyklus1}
             name="zyklus1"
             color="primary"
           />
         }
         label={t('schoolnumbers.zyklus1')}
       />
       <FormControlLabel
         control={
           <Checkbox
             checked={data.zyklus2}
             onChange={toggleZyklus2}
             name="zyklus2"
             color="primary"
           />
         }
         label={t('schoolnumbers.zyklus2')}
       />
       <FormControlLabel
         control={
           <Checkbox
             checked={data.zyklus3}
             onChange={toggleZyklus3}
             name="zyklus3"
             color="primary"
           />
         }
         label={t('schoolnumbers.zyklus3')}
       />
      </>
     }
    />
  )
})



const Devices = observer((props) => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const classes = useStyles()

  return (
   <>
    <ConfigItem
     key={'schoolnumbers-devices'}
     view={
      <>
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schoolnumbers.devices')}</Typography>
        <div className={classes.secondaryHeading}>
         {data.IOS && <Chip className={classes.chip} avatar={<Avatar>{data.IOS_COUNT}</Avatar>} label={t('schoolnumbers.ios')} />}
         {data.ANDROID && <Chip className={classes.chip} avatar={<Avatar>{data.ANDROID_COUNT}</Avatar>} label={t('schoolnumbers.android')} />}
         {data.OSX && <Chip className={classes.chip} avatar={<Avatar>{data.OSX_COUNT}</Avatar>} label={t('schoolnumbers.osx')} />}
         {data.WINDOWS && <Chip className={classes.chip} avatar={<Avatar>{data.WINDOWS_COUNT}</Avatar>} label={t('schoolnumbers.windows')} />}
         {data.CHROMEOS && <Chip className={classes.chip} avatar={<Avatar>{data.CHROMEOS_COUNT}</Avatar>} label={t('schoolnumbers.chromeos')} />}
         {data.LINUX && <Chip className={classes.chip} avatar={<Avatar>{data.LINUX_COUNT}</Avatar>} label={t('schoolnumbers.linux')} />}
         {data.OTHERS && <Chip className={classes.chip} avatar={<Avatar>{data.OTHERS_COUNT}</Avatar>} label={t('schoolnumbers.others')} />}
        </div>
       </div>
      </>
     }
     edit={<div className={classes.devicesContainer}>
      <Device deviceName={t('schoolnumbers.ios')} deviceCount={data.IOS_COUNT} onChange={(e) => { data.setIosCount(e.target.value) }} />
      <Device deviceName={t('schoolnumbers.android')} deviceCount={data.ANDROID_COUNT} onChange={(e) => { data.setAndroidCount(e.target.value) }} />
      <Device deviceName={t('schoolnumbers.osx')} deviceCount={data.OSX_COUNT} onChange={(e) => { data.setOsxCount(e.target.value) }} />
      <Device deviceName={t('schoolnumbers.windows')} deviceCount={data.WINDOWS_COUNT} onChange={(e) => { data.setWindowsCount(e.target.value) }} />
      <Device deviceName={t('schoolnumbers.chromeos')} deviceCount={data.CHROMEOS_COUNT} onChange={(e) => { data.setChromeosCount(e.target.value) }} />
      <Device deviceName={t('schoolnumbers.linux')} deviceCount={data.LINUX_COUNT} onChange={(e) => { data.setLinuxCount(e.target.value) }} />
      <Device deviceName={t('schoolnumbers.others')} deviceCount={data.OTHERS_COUNT} onChange={(e) => { data.setOthersCount(e.target.value) }} />
     </div>}
    />
   </>
  )
})

const Device = observer((props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { deviceName,deviceCount,onChange,expand } = props

  return (
    <ConfigItem
     expandFirst={expand}
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{deviceName}</Typography>
        <Typography className={classes.secondaryHeading}>{deviceCount}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       min={0}
       variant="filled"
       type="number"
       placeholder={deviceName}
       value={deviceCount}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const SavePart = observer((props) => {
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const { notifier } = useContext(GlobaUI)
  const classes = useStyles()

  const save = () => {
    data.update(
      props.schoolId,
      (res) => {
        notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
      },
      () => {
        notifier.push(t('core.notifier.5xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
    <Button color="primary" className={classes.saveBtn} fullWidth variant="outlined" onClick={save}> {t('core.general.save')} </Button>
  )
})



export default SchoolNumbers
