import React, {useContext,useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'

import {
  Typography
} from '@material-ui/core'

import IMG_ADVICE from 'media/img/advice.png'

const Conference = observer(() => {
  const { t } = useTranslation()
  const { kibsmeta } = useContext(DataStore)

  return (
    <section className="about-area bg-fff5ee pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{t('core.landingpage.advice.title')}</h2>
          <div className="bar" />
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-image">
              <img src={IMG_ADVICE} alt="image" style={{ maxWidth:300 }}/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
            <Typography variant="h6" component="p">{t('core.landingpage.advice.text')}</Typography>
            <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html:t('core.landingpage.advice.text2')  }} />
            <Typography variant="h6" component="p">{t('core.landingpage.advice.text3')}</Typography>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Conference
