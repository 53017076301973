import React, { useContext,useEffect } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import { useObserver } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UIStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight:'80vh'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spacing:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  isOAuth:{
    padding:theme.spacing(3)
  }
}));

const ResetPwHash = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(DataStore)
  const { notifier,loginUI } = useContext(UIStore)

  useEffect(() => {
    if (props.match.params.hash) { loginUI.setHash(props.match.params.hash) }
  },[])

  const changePW = () => {
    loginUI.changePW(
      () => {
        loginUI.setNewPassword('')
        loginUI.setNewPasswordRepeat('')
        notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
      },
      () => {
        loginUI.setNewPassword('')
        loginUI.setNewPasswordRepeat('')
        notifier.push(t('core.notifier.500'),notifier.variants.ERROR)
      }
    )
  }

  const setNewPassword = (e) => { loginUI.setNewPassword(e.target.value) }
  const setNewPasswordRepeat = (e) => { loginUI.setNewPasswordRepeat(e.target.value) }

  return useObserver(() => (
   <Container component="main" maxWidth="xs">
    <div className={classes.paper}>
      <Typography component="h1" variant="h5" className={classes.spacing}>
        {t('core.resetPw.title')}
      </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="password"
          label={t('core.login.password')}
          value={loginUI.newPassword}
          onChange={setNewPassword}
          name="pw"
          autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="password"
          label={t('core.resetPw.repeat')}
          value={loginUI.newPasswordRepeat}
          onChange={setNewPasswordRepeat}
          name="pw-repeat"
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={changePW}
          className={classes.submit}
        >
          {t('core.resetPw.title')}
        </Button>
      </div>

    </Container>
  ));
}

export default ResetPwHash
