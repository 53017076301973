import React, { useContext,useEffect } from "react"
import ReactDOM from "react-dom"
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'


import IMG_PREPERATION_2 from 'media/img/phishing/Phishing_Preparation2_single.png'
import IMG_FRAME_BOTTOM from 'media/img/phishing/Phishing_Rahmen_horinz.png'


import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import {
  Warning,Error,Info,Label
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
 banner:{
   background: 'linear-gradient(to right, rgba(36,31,31,1) 0%, rgba(36,31,31,1) 32%, rgba(74,71,70,1) 100%)',
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
 },
 avatar:{
   backgroundImage:'url('+IMG_PREPERATION_2+')',
   backgroundRepeat:'no-repeat',
   backgroundSize:'cover',
   width:'100%',
   height:'90vh',
   minHeight:500,
   overflowX:'hidden',
 },
 content:{
   height:'100vh',
   paddingTop:theme.spacing(12),
   paddingBottom:theme.spacing(12),
 },
 mainTitle:{
   padding: '.3em 1em .25em',
   fontWeight: 400,
   fontSize: '3rem',
   color: 'white',
   fontFamily: "'Bellefair', serif",
   position: 'relative',
   lineHeight: 1.3,
   position:'absolute',
   width: '100%',
   height: '70vh',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'flex-end',
   top:0
 },
 bottom:{
  position:'sticky',
  zIndex:10,
  bottom:-10
 },
 alert:{
   padding: '36px 16px',
   display: 'flex',
   fontSize: '1.875rem',
   fontWeight: 400,
   lineHeight: 1.43,
   borderRadius: 4,
   marginTop:theme.spacing(5),
   marginBottom:theme.spacing(5),
   letterSpacing: '0.01071em',
 },
 alertInfo:{
   color: 'rgb(13, 60, 97)',
   backgroundColor: 'rgb(232, 244, 253)',
 },
 alertWarning:{
   color: 'rgb(102, 60, 0)',
   backgroundColor: 'rgb(255, 244, 229)'
 },
 alertDanger:{
   color: 'rgb(97, 26, 21)',
   backgroundColor: 'rgb(253, 236, 234)'
 }
}))

const PhishingInfo = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
   <>
    <section className={classes.banner}>
     <div className={`${classes.avatar} animated fadeIn delay-05s`} />
     <h1 class={classes.mainTitle}>
       <span className="animated fadeIn delay-1s">"{t('phishing.info.moral1')}</span>
       <span className="animated fadeIn delay-2s"style={{paddingLeft:100}}>{t('phishing.info.moral2')}</span>
       <span className="animated fadeIn delay-3s"style={{paddingRight:110}}>{t('phishing.info.moral3')}</span>
       <span className="animated fadeIn delay-4s"style={{paddingLeft:20}}>{t('phishing.info.moral4')}"</span>
     </h1>
    </section>
    <section className={classes.content}>
     <Container maxWidth="md">
      <Typography variant="h5" gutterBottom paragraph>{t('phishing.info.para1')}</Typography>
      <Typography variant="body1" gutterBottom paragraph> {t('phishing.info.para2')}</Typography>
      <Typography variant="body1" gutterBottom paragraph> {t('phishing.info.para3')}</Typography>
     </Container>
     <div className={`${classes.alert} ${classes.alertInfo}`}>
      <Container maxWidth="md">
       <Info /> {t('phishing.info.para4')}
      </Container>
     </div>
     <Container maxWidth="md">
      <Typography variant="body1" gutterBottom paragraph> {t('phishing.info.para5')}</Typography>
     </Container>
     <div className={`${classes.alert} ${classes.alertWarning}`}>
      <Container maxWidth="md">
       <Warning /> {t('phishing.info.para6')}
      </Container>
     </div>
     <Container maxWidth="md">
      <Typography variant="body1" gutterBottom paragraph> {t('phishing.info.para7',{title:t('phishing.title')})}</Typography>
      <Typography variant="body1" gutterBottom paragraph> {t('phishing.info.para8')}</Typography>
      <Typography variant="body1" gutterBottom paragraph> {t('phishing.info.para9.text',{title:t('phishing.title')})}</Typography>
      <List component="nav">
        <ListItem> <ListItemIcon> <Label /> </ListItemIcon><ListItemText primary={t('phishing.info.para9.point1')} /> </ListItem>
        <ListItem> <ListItemIcon> <Label /> </ListItemIcon><ListItemText primary={t('phishing.info.para9.point2')} /> </ListItem>
        <ListItem> <ListItemIcon> <Label /> </ListItemIcon><ListItemText primary={t('phishing.info.para9.point3')} /> </ListItem>
      </List>
     </Container>
     <div className={`${classes.alert} ${classes.alertDanger}`}>
      <Container maxWidth="md">
       <Error /> {t('phishing.info.alert',{title:t('phishing.title')})}
      </Container>
     </div>
     <div className={classes.bottom}>
      <img src={IMG_FRAME_BOTTOM} alt="frame-bottom" style={{maxWidth:'100%'}}/>
     </div>
    </section>
   </>
  )
})

export default PhishingInfo
