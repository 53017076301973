import { createContext,useContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import Ajax from 'api/ajax'
import { createMuiTheme } from '@material-ui/core/styles'
import { xor } from 'lodash'
import RichTextEditor from 'react-rte'

import DataStore from './data'

class DashboardUI {
  showMemberlist = false
  handleOpenMemberList = () => this.showMemberlist = true
  handleCloseMemberList = () => this.showMemberlist = false
}

class AdminUI {
  releaseDialogOpen = false
  releaseRedirectDialogOpen = false
  releaseListItems = {
    MUST_DO:1,
    CAN_DO:2,
    REDIRECTS:3
  }
  selectedReleaseListItem = this.releaseListItems.MUST_DO
  editPanelOpen = false

  setReleaseListItem = (item) => {
    this.selectedReleaseListItem = item
  }
  handleOpenReleaseDialog = () => { this.releaseDialogOpen = true }
  handleCloseReleaseDialog = () => { this.releaseDialogOpen = false }
  handleOpenReleaseRedirectDialog = () => { this.releaseRedirectDialogOpen = true }
  handleCloseReleaseRedirectDialog = () => { this.releaseRedirectDialogOpen = false }
  handleOpenEditPanel = () => this.editPanelOpen = true
  handleCloseEditPanel = () => this.editPanelOpen = false
}

class InviteRoutineUI {
  joinSteps = {
    WELCOME:1,
    JOIN_AND_GO_TO_SCHOOL:2,
    HAVE_ACCOUNT_OR_NEW:3,
    SIGN_IN:4,
    SIGN_UP:5
  }
  joinStep = this.joinSteps.WELCOME

  setJoinStep = (step) => {
    this.joinStep = step
  }
}

class InviteUI {
  open = false
  pages = {
    ONE:1,
    TWO:2
  }
  page = this.pages.ONE
  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
  goToPageOne = () => { this.changePage(this.pages.ONE) }
  goToPageTwo = () => { this.changePage(this.pages.TWO) }
  changePage = (page) => { this.page = page }

  get isPageOne () { return this.page === this.pages.ONE }
  get isPageTwo () { return this.page === this.pages.TWO }
}

class SchoolPageUI {
  openRegisterDialog = false
  openMemberDetailsDialog = false
  openAddFunctionsOrTeachingLevelsMenu = false
  addFunctionsOrTeachingLevelsAnchorEl = null
  openRequests = false
  hoveredMemberIndex = -1
  openSchoolServicesPanel = 0
  currentElementNumber = -1
  currentRowNumber = 0
  selectedSchoolIndex = 0
  selectedSchoolMenuAnchorElement = null
  showSchoolChooseMenu = false
  PANEL_HEIGHT = 'auto'
  PASSED_SCHOOLPAGE_BANNER_PIXELS = 150

  services = {
    SCHOOL_TECH:1,
    SMICALC:2,
    AMPELFLYER:3,
    MEMBER_AND_INVITE:4
  }
  currentService = this.services.SCHOOL_TECH

  setSelectedSchoolIndex = (index) => this.selectedSchoolIndex = index
  setSelectedSchoolMenuAnchorElement = (anchorEl) => this.selectedSchoolMenuAnchorElement = anchorEl
  handleShowSchoolChooseMenu = () => this.showSchoolChooseMenu = true
  handleCloseSchoolChooseMenu = () => this.showSchoolChooseMenu = false

  setCurrentService = (s) => {
    this.currentService = s
  }
  handleOpenRegisterDialog = () => this.openRegisterDialog = true
  handleCloseRegisterDialog = () => this.openRegisterDialog = false
  setCurrentElementNumber = (elementNumber) => {
    this.currentElementNumber = elementNumber
  }
  setCurrentRowNumber = (rowNumber) => {
    this.currentRowNumber = rowNumber
  }
  handleOpenSchoolServicesPanel = (rowNumber,elementNumber) => {
    const open = this.PANEL_HEIGHT, close = 0
    if (elementNumber === this.currentElementNumber) {
      this.openSchoolServicesPanel = this.openSchoolServicesPanel === open ? close : open
    }
    else if (rowNumber === this.currentRowNumber && elementNumber !== this.currentElementNumber) {
      this.openSchoolServicesPanel = open
    }
    else if (rowNumber !== this.currentRowNumber) {
      this.openSchoolServicesPanel = close
      this.setCurrentRowNumber(rowNumber)
      this.openSchoolServicesPanel = open
    } else {
      this.openSchoolServicesPanel = this.openSchoolServicesPanel === open ? close : open
    }
    this.setCurrentRowNumber(rowNumber)
    this.setCurrentElementNumber(elementNumber)
  }
  amIOpen (rowNumber) {
    return rowNumber === this.currentRowNumber ? this.openSchoolServicesPanel : 0
  }

  handleOpenMemberDetailsDialog = () => {
    this.openMemberDetailsDialog = true
  }
  handleCloseMemberDetailsDialog = () => {
    this.openMemberDetailsDialog = false
  }
  handleOpenAddFunctionsOrTeachingLevelsMenu = (anchorEl) => {
    this.openAddFunctionsOrTeachingLevelsMenu = true
    this.addFunctionsOrTeachingLevelsAnchorEl = anchorEl
  }
  handleCloseAddFunctionsOrTeachingLevelsMenu = () => {
    this.openAddFunctionsOrTeachingLevelsMenu = false
    this.addFunctionsOrTeachingLevelsAnchorEl = null
  }
  handleOpenRequests = () => {
    this.openRequests = true
  }
  handleCloseRequests = () => {
    this.openRequests = false
  }
  toggleOpenRequests = () => {
    this.openRequests = !this.openRequests
  }
  setHovered = (index) => {
    this.hoveredMemberIndex = index
  }


}

class UserProfileUI {
  open = false

  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
}

class UserEditUI {
  open = false
  openAvatar = false

  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
  handleOpenAvatar = () => { this.openAvatar = true }
  handleCloseAvatar = () => { this.openAvatar = false }
}

class UserPrivacyUI {
  open = false
  tab = 0

  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
  setTab = (value) => this.tab = value
}

class UtilityUI {
  u = {
    USER_FN:1,
    SCHOOL_FN:2,
    TEXT_HELPDESK:3,
    TEXT_COMMUNITY:4,
    USER_MAILS:5,
    TEXT_CONFERENCE:6,
    HELPDESK:7,
    REDIRECTS:8
  }

  currentUtil = this.u.USER_FN
  setUtil = (util) => this.currentUtil = util
}

class UtilityMailsUI {
  mails = []
  seperators = {
    SEMICOLON:1,
    COLON:2,
    SPACE:3,
    COMMA:4,
    CUSTOM:5
  }
  seperator = this.seperators.SPACE
  get _seperator () {
    if (this.seperator == this.seperators.SEMICOLON) return ";"
    if (this.seperator == this.seperators.COLON) return ":"
    if (this.seperator == this.seperators.SPACE) return " "
    if (this.seperator == this.seperators.COMMA) return ","
  }

  setMails = (mails) => this.mails = mails
  setSeperator = (seperator) => this.seperator = seperator

  get = () => {
    Ajax.getMailingList(
      (res) => {
        if (res && res.data && res.data.success) {
          this.setMails(res.data.list)
        }
      },
      () => {}
    )
  }
}

class UtilityTextUI {
  textHelpdesk = RichTextEditor.createEmptyValue()
  textCommunity = RichTextEditor.createEmptyValue()
  textConference = RichTextEditor.createEmptyValue()

  setTextConference = (text) => {
   this.textConference = text
  }
  setTextHelpdesk = (text) => {
   this.textHelpdesk = text
  }
  setTextCommunity = (text) => {
   this.textCommunity = text
  }

  getTextConference = () => {
    Ajax.utilityGetTextConference(
      (res) => {
        if (res && res.data && res.data.success) {
          const text = res.data.text ? RichTextEditor.createValueFromString(res.data.text,'html') : RichTextEditor.createEmptyValue()
          this.setTextConference(text)
        }
      },
      () => {}
    )
  }
  getTextHelpdesk = () => {
    Ajax.utilityGetTextHelpdesk(
      (res) => {
        if (res && res.data && res.data.success) {
          const text = res.data.text ? RichTextEditor.createValueFromString(res.data.text,'html') : RichTextEditor.createEmptyValue()
          this.setTextHelpdesk(text)
        }
      },
      () => {}
    )
  }
  getTextCommunity = () => {
    Ajax.utilityGetTextCommunity(
      (res) => {
        if (res && res.data && res.data.success) {
          const text = res.data.text ? RichTextEditor.createValueFromString(res.data.text,'html') : RichTextEditor.createEmptyValue()
          this.setTextCommunity(text)
        }
      },
      () => {}
    )
  }
  updateTextConference = () => {
    Ajax.utilityUpdateTextConference(
      { data: this.textConference.toString('html') },
      (res) => {},
      () => {}
    )
  }
  updateTextHelpdesk = () => {
    Ajax.utilityUpdateTextHelpdesk(
      { data: this.textHelpdesk.toString('html') },
      (res) => {},
      () => {}
    )
  }
  updateTextCommunity = () => {
    Ajax.utilityUpdateTextCommunity(
      { data: this.textCommunity.toString('html') },
      (res) => {},
      () => {}
    )
  }
}

class UtilityUserUI {
  searchTerm = ''
  suggestions = []
  user

  setSuggestions = (suggestions) => {
    this.suggestions = suggestions
  }

  setSearchTerm = (e) => {
    this.searchTerm = e.target.value
    this.user = null
    if (this.searchTerm.trim() === '') {
      this.suggestions = []
      this.selected = []
    }
  }

  get = (cbFail) => {
    if (this.searchTerm === '') return
    Ajax.utilityGetUser(
     this.searchTerm,
     (res) => {
       res = res.data
       if (res.success) {
         this.setSuggestions(res.suggestions)
       } else cbFail()
     },
     cbFail
    )
   }

  infos = (userId,cbSuccess,cbFail) => {
    Ajax.utilityGetUserInfo(
      userId,
      (res) => {
        if (res && res.data && res.data.success) {
          this.user = res.data.user
          this.searchTerm = ''
          this.setSuggestions([])
        }
        cbSuccess()
      },
      cbFail
    )
  }
}

class UtilitySchoolUI {
  searchTerm = ''
  suggestions = []
  school
  open = false
  currentSchool = null
  loading = true
  wasAlreadyAMember = false

  setWasAlreadyAMember = (v) => this.wasAlreadyAMember = v
  setSuggestions = (suggestions) => {
    this.suggestions = suggestions
  }
  setCurrentSchool = (school) => {
    this.currentSchool = school
  }
  setLoading = (v) => {
    this.loading = v
  }

  handleOpen = () => this.open = true
  handleClose = () => this.open = false

  setSearchTerm = (e) => {
    this.searchTerm = e.target.value
    this.school = null
    if (this.searchTerm.trim() === '') {
      this.suggestions = []
      this.selected = []
    }
  }

  get = (cbFail) => {
    if (this.searchTerm === '') return
    Ajax.utilityGetSchools(
     this.searchTerm,
     (res) => {
       res = res.data
       if (res.success) {
         this.setSuggestions(res.suggestions)
       } else cbFail()
     },
     cbFail
    )
   }

   join = (cb) => {
     if (this.currentSchool) {
       this.setLoading(true)
       Ajax.utilityJoinSchool(
         { erzKey: this.currentSchool.erz_key },
         (res) => {
           if (res && res.data && res.data.success) {
            this.setLoading(false)
            this.setWasAlreadyAMember(!res.data.joined)
            cb()
           }
         },
         () => {}
       )
     }
   }
   leave = (cb) => {
     if (this.currentSchool && !this.wasAlreadyAMember) {
       Ajax.utilityLeaveSchool(
         { erzKey: this.currentSchool.erz_key },
         (res) => {
           this.setWasAlreadyAMember(false)
           if (res && res.data && res.data.success) {
             cb()
           }
         },
         () => {}
       )
     } else {
       this.setWasAlreadyAMember(false)
       cb()
     }
   }

}

class LocationUI {
 open = false
 openPendingDialog = false
 openForeignerDialog = false
 searchTerm = ''
 suggestions = []
 selected = []

 handleOpen = () => { this.open = true }
 handleClose = () => { this.open = false }
 handleOpenPendingDialog = () => { this.openPendingDialog = true }
 handleClosePendingDialog = () => { this.openPendingDialog = false }
 handleOpenForeignerDialog = () => { this.openForeignerDialog = true }
 handleCloseForeignerDialog = () => { this.openForeignerDialog = false }
 setSuggestions = (suggestions) => {
   this.suggestions = suggestions
 }
 setPendings = (pendings) => {
   this.pendings = pendings
 }
 setSearchTerm = (e) => {
   this.searchTerm = e.target.value
   if (this.searchTerm.trim() === '') {
     this.suggestions = []
     this.selected = []
   }
 }

 isSelected = (erzKey) => {
   return this.selected.includes(erzKey)
 }

 toggleItem = (erzKey) => {
   this.selected = xor(this.selected, [erzKey])
 }

 getLocations = (cbFail) => {
   if (this.searchTerm === '') return
   Ajax.getLocations(
    this.searchTerm,
    (res) => {
      res = res.data
      if (res.success) {
        this.setSuggestions(res.suggestions)
      } else cbFail()
    },
    cbFail
   )
  }


  requestJoinKibs = (cbSuccess,cbFail) => {
   const KIBS_KEY = 999999

   Ajax.requestJoinLocations(
    [KIBS_KEY],
    (res) => {
      res = res.data
      if (res.success) {
       cbSuccess()
      } else {
       cbFail()
      }
    },
    cbFail
   )
  }

  requestJoinLocations = (cbSuccess,cbFail) => {
   if (this.selected.length > 0) {
     Ajax.requestJoinLocations(
       this.selected,
       (res) => {
         res = res.data
         if (res.success) {
          cbSuccess()
         } else {
          cbFail()
         }
       },
       cbFail
     )
   } else return
  }

}

class OAuth2Dialog {
  open = false
  link = ''
  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
  setLink = (link) => { this.link = link }

  get isGoogle () {
    return this.link === '/login/google'
  }
  get isO365 () {
    return this.link === '/login/o365'
  }
}

class Notifier {
 open = false
 variants = {
   SUCCESS:'success',
   ERROR:'error',
   WARNING:'warning',
   INFO:'info'
 }
 pos = {
   UPPER_RIGHT:{
     vertical:'top',
     horizontal:'right'
   },
   BOTTOM_RIGHT:{
     vertical:'bottom',
     horizontal:'right'
   }
 }
 message = ''
 variant = this.variants.INFO
 autoHideDuration = 2500
 position = this.pos.BOTTOM_RIGHT

 handleOpen = () => { this.open = true }
 handleClose = () => { this.open = false }
 setMessage = (msg) => { this.message = msg }
 push = (msg,variant,pos = this.pos.BOTTOM_RIGHT) => {
   this.setMessage(msg)
   if (variant) this.setVariant(variant); else this.setVariant(this.variants.INFO)
   this.setPosition(pos)
   this.handleOpen()
 }
 setVariant = (v) => { this.variant = v }
 setPosition = (pos) => {
   this.position = pos
 }
}

class SAISidebarUI {
 views = {
   SAI:0,
   PRECONDITIONS:1,
   SERVICES:2,
   CONNECTIONON:3,
   CONNECTIONOFF:4,
   CERTIFICATE:5
 }
 view = this.views.SAI
 setView = view => this.view = view
 open = false
 handleOpen = () => this.open = true
 handleClose = () => this.open = false
}

class LoginUI {
  hash
  isOAuthModalOpen = false
  handleOpenOAuthModal = () => this.isOAuthModalOpen = true
  handleCloseOAuthModal = () => this.isOAuthModalOpen = false

  newPassword = ''
  newPasswordRepeat = ''
  setNewPassword = (pw) => this.newPassword = pw
  setNewPasswordRepeat = (pw) => this.newPasswordRepeat = pw
  setHash = (hash) => this.hash = hash

  changePW = (cbSuccess,cbFail) => {
    console.log(this);
    if (this.hash && this.newPassword && this.newPassword !== '' && this.newPasswordRepeat && this.newPasswordRepeat !== '') {
      Ajax.changePW(
        { hash:this.hash,password:this.newPassword,passwordRepeat:this.newPasswordRepeat },
        (res) => {
          if (res && res.data && res.data.success) {
            cbSuccess()
          } else cbFail()
        }
      )
    } else {
      cbFail()
    }
  }
}

class Footer {
  languageMenuOpen = false
  languageMenuAnchorEl = null

  handleOpenLanguageMenu = () => this.languageMenuOpen = true
  handleCloseLanguageMenu = () => this.languageMenuOpen = false
  setLanguageMenuAnchorEl = (anchorEl) => this.languageMenuAnchorEl = anchorEl
}

class Header {
  infoBoxOpen = false
  profileMenuOpen = false
  anchorEl = null

  setAnchorEl = (anchorEl) => this.anchorEl = anchorEl
  handleOpenProfileMenu = () => this.profileMenuOpen = true
  handleCloseProfileMenu = () => {
    this.profileMenuOpen = false
    this.anchorEl = null
  }
  handleOpenInfoBox = () => this.infoBoxOpen = true
  handleCloseInfoBox = () => {
   this.infoBoxOpen = false
   this.anchorEl = null
  }
  handleToggleSchoolMenu = () => {
    this.schoolMenuOpen = !this.schoolMenuOpen
    if (!this.schoolMenuOpen) {
      this.handleCloseSchoolMenu()
    }
  }
}

class Themer {
  themes = {
   DARK: { palette: { type: 'dark' }},
   LIGHT: { palette: { type: 'light' }},
   PHSHIT: {
    typography: {
     fontFamily: [
       'Mukta'
     ]
    },
    overrides: {
     MuiButton: {
      root: {
        // Some CSS
        textTransform: 'inherit',
      }
    },
    MuiTab: {
      root: {
        textTransform: 'inherit'
      }
     }
    },
    palette: {
     primary: {
       light: '#76accb',
       main: '#457d9a',
       dark: '#0a516c',
       contrastText: '#fff',
     },
     secondary: {
       light: '#e699c3',
       main: '#b36a93',
       dark: '#823d65',
       contrastText: '#fff',
     }
    }
   }
  }

  current = createMuiTheme(this.themes.PHSHIT)

  setTheme = (theme) => {
    this.current = createMuiTheme(theme)
  }
  setCustom = (primary,secondary) => {
   this.setTheme({
    palette:{
     primary:   { main:primary   },
     secondary: { main:secondary }
    }
   })
  }

}



// ###################################
// ###################################
// ###################################
class UI {
  oauth2Dialog = new OAuth2Dialog()
  notifier = new Notifier()
  location = new LocationUI()
  saiSidebarUI = new SAISidebarUI()
  footer = new Footer()
  header = new Header()
  themer = new Themer()
  loginUI = new LoginUI()
  userProfile = new UserProfileUI()
  userEdit = new UserEditUI()
  userPrivacy = new UserPrivacyUI()
  admin = new AdminUI()
  schoolPageUI = new SchoolPageUI()
  inviteUI = new InviteUI()
  inviteRoutineUI = new InviteRoutineUI()
  dashboardUI = new DashboardUI()
  utility = new UtilityUI()
  utilityUser = new UtilityUserUI()
  utilitySchool = new UtilitySchoolUI()
  utilityText = new UtilityTextUI()
  utilityMails = new UtilityMailsUI()

  loading = true
  setLoading = (value) => { this.loading = value }

  currentPath = null
  triggerLayout = () => {
    this.currentPath = window.location.pathname
  }
  setPageTitle = (title) => {
    if (title && title !== '') {
     document.title = title || ""
    }
  }


  // computed value for finding sites where you want to disable footer and header from kibs portal
  get withLayout () {

    const showNoLayoutHereRegexes = [
      /\/datenschutz\/ampelflyer\/pdf/,
      /\/internetanschluss/,
      /\/fakewebsites/,
      /\/phishing/,
      /\/twine\/embed/,
      /\/sonicpi/,
      /\/audiodrive/,
      /\/kurt/,
      /\/bookmarklet/,
      /\/dogodswork\/build/,
      /^\/join\/[a-zA-Z0-9_-]*$/ // inviteRoutine - join
    ]

    let showLayout = true
    if (this.currentPath) {
      for (let i = 0;i < showNoLayoutHereRegexes.length; i++) {
        const regex = showNoLayoutHereRegexes[i]
        showLayout = this.currentPath.match(regex) ? false : true
        if (!showLayout) break
      }
    }

    return showLayout
  }
}


// ###################################
// ###################################
// ###################################

decorate(UI,{
  loading:observable,
  withLayout:computed,
  currentPath:observable
})
decorate(UtilityUI,{
  currentUtil:observable
})
decorate(UtilityUserUI,{
  suggestions:observable,
  searchTerm:observable,
  user:observable
})
decorate(UtilitySchoolUI,{
  suggestions:observable,
  searchTerm:observable,
  school:observable,
  open:observable,
  currentSchool:observable,
  loading:observable,
  wasAlreadyAMember:observable
})
decorate(UtilityTextUI,{
  textHelpdesk:observable,
  textCommunity:observable,
  textConference:observable
})
decorate(UtilityMailsUI, {
  mails:observable,
  seperator:observable,
  _seperator:computed
})
decorate(DashboardUI,{
  showMemberlist:observable
})
decorate(Themer,{
  current:observable
})
decorate(LoginUI,{
  isOAuthModalOpen:observable,
  newPassword:observable,
  newPasswordRepeat:observable,
  hash:observable
})
decorate(Footer,{
  languageMenuOpen:observable,
  languageMenuAnchorEl:observable
})
decorate(Header,{
  infoBoxOpen:observable,
  schoolMenuOpen:observable,
  profileMenuOpen:observable,
  anchorEl:observable
})
decorate(Notifier, {
  message:observable,
  variant:observable,
  open:observable,
  position:observable
})
decorate(OAuth2Dialog, {
 open:observable,
 link:observable,
 isGoogle: computed,
 isO365: computed
})
decorate(LocationUI, {
  suggestions:observable,
  open:observable,
  searchTerm:observable,
  selected:observable,
  openPendingDialog:observable,
  openForeignerDialog:observable
})
decorate(UserProfileUI,{
  open:observable
})
decorate(UserEditUI,{
  open:observable,
  openAvatar:observable
})
decorate(UserPrivacyUI,{
  open:observable,
  tab:observable
})
decorate(AdminUI, {
  releaseDialogOpen:observable,
  releaseRedirectDialogOpen:observable,
  selectedReleaseListItem:observable,
  editPanelOpen:observable
})
decorate(InviteUI,{
  open:observable,
  page:observable,
  isPageOne:computed,
  isPageTwo:computed
})
decorate(InviteRoutineUI,{
  joinStep:observable
})
decorate(SAISidebarUI,{
  view:observable,
  open:observable
})
decorate(SchoolPageUI,{
  openRegisterDialog:observable,
  openMemberDetailsDialog:observable,
  openAddFunctionsOrTeachingLevelsMenu:observable,
  addFunctionsOrTeachingLevelsAnchorEl:observable,
  openRequests:observable,
  hoveredMemberIndex:observable,
  openSchoolServicesPanel:observable,
  currentElementNumber:observable,
  currentRowNumber:observable,
  currentService:observable,
  selectedSchoolMenuAnchorElement:observable,
  selectedSchoolIndex:observable,
  showSchoolChooseMenu:observable
})





const UiStore = createContext(new UI())
export default UiStore
