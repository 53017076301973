import React, { useContext,useCallback,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer,useObserver } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ls from 'local-storage'
import {
  AppBar,
  Avatar,
  ListItemAvatar,
  Toolbar,
  Typography,
  Button,
  MenuList,
  IconButton,
  Popover,
  CardContent,
  Slide,
  Grid,
  ClickAwayListener,
  ButtonGroup,
  useScrollTrigger,
  Popper,
  Paper,
  Grow,
  ListItemIcon
} from '@material-ui/core/'

import {
  InfoOutlined,
  AccountCircle,
  More,
  ArrowDropDown,
  Add
} from '@material-ui/icons'

import { KibsLogo,PHBernLogo } from 'shared/components/logo'
import Profile from 'core/user/profile'
import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display:'flex',
    fontSize:24
  },
  offset: {
    ...theme.mixins.toolbar,
    flexGrow: 1
  },
  aboutContainer:{
    maxWidth:600
  },
  header:{
   backgroundColor:'#fff',
   color:'#000',
   zIndex:10
  }
}));

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={true}>
      {children}
    </Slide>
  );
}

const Header = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ui = useContext(UiStore)
  const { user } = useContext(DataStore)
  const data = useContext(DataStore)

  useEffect(() => {
    user.check(
      () => {},
      () => {}
    )
  },[user.token])

  return (
    ui.withLayout
    ?
    <div className={classes.root}>
     <HideOnScroll>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar>
         <div className={classes.title}>
          <PHBernLogo />
         </div>
          {
            user.isAuthenticated
            ?
            <AuthMenu />
            :
            <UnauthMenu />
          }
        </Toolbar>
      </AppBar>
     </HideOnScroll>

     <Profile />
    </div>
    :
    null
  )
})

const InfoBox = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { header } = useContext(UiStore)
  const onClick = (e) => {
    header.setAnchorEl(e.currentTarget)
    header.handleOpenInfoBox()
  }

  return (
   <>
    <IconButton onClick={onClick} color="inherit">
     <InfoOutlined />
    </IconButton>
    <Popover
      id="core-info-about-box"
      open={header.infoBoxOpen}
      anchorEl={header.anchorEl}
      onClose={header.handleCloseInfoBox}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <CardContent className={classes.aboutContainer}> {t('core.about')} </CardContent>
    </Popover>
   </>
  )
})

const AuthMenu = observer(() => {
  const { t } = useTranslation()
  const { user } = useContext(DataStore)
  const history = useHistory()
  const { header,userProfile } = useContext(UiStore)

  const openMenu = (e) => {
    header.setAnchorEl(e.currentTarget)
    header.handleOpenProfileMenu()
  }

  const openUserProfile = () => {
    userProfile.handleOpen()
    header.handleCloseProfileMenu()
  }

  return (
   <>
    <IconButton
     edge="end"
     aria-label="account of current user"
     aria-controls={'profile-menu'}
     aria-haspopup="true"
     onClick={openUserProfile}
     color="primary"
    >
    {
     user.photo
     ?
     <Avatar src={user.photo} />
     :
     <AccountCircle />
    }
    </IconButton>
   </>
  )
})


const UnauthMenu = observer((props) => {
  const { t } = useTranslation()
  const { chooseSchoolOverlay } = useContext(UiStore)
  const history = useHistory()

  const goToLogin = () => {
    history.push('/login')
  }

  return (

    <Button color="primary" onClick={goToLogin}>
     <Typography variant="h6" component="p">{t('core.login.title')}</Typography>
    </Button>
  )
})

export default Header
