import React, { useContext,useEffect,useState } from "react"
import ReactDOM from "react-dom"

import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { observer,useObserver } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import UiStore from './stores/ui'
import DataStore from './stores/data'
import { ConfigItem } from './index'
import MaskedInput from 'react-text-mask'

import {
 Button,
 TextField,
 Paper,
 Typography,
 ExpansionPanel,
 ExpansionPanelDetails,
 ExpansionPanelSummary,
 ClickAwayListener,
 Table,
 TableContainer,
 TableCell,
 TableRow,
 TableBody,
 IconButton,
 Tooltip,
 Radio,
 RadioGroup,
 FormControlLabel,
 FormControl,
 FormLabel,
 Select,
 MenuItem,
 Input,
 useMediaQuery,
 Fab,
 Dialog,
 DialogActions,
 DialogContent,
 DialogContentText,
 DialogTitle
} from '@material-ui/core/'

import {
 ExpandMore,ExpandLess,AddCircleOutline,Delete
} from '@material-ui/icons'



const useStyles = makeStyles(theme => ({
 internetConnection:{
   display:'flex',
   flexDirection:'column',
   position:'relative'
 },
 description:{
   width:'100%',
   display:'flex',
   alignItems:'center'
 },
 heading: {
   fontSize: theme.typography.pxToRem(15),
   flexBasis: '55%',
   flexShrink: 0,
 },
 secondaryHeading: {
   fontSize: theme.typography.pxToRem(15),
   color: theme.palette.text.secondary,
   float:'right'
 },
 addInternetconnectionBtn:{
   position:'absolute',
   right:'-4em'
 }
}))


const TechData = observer((props) => {
  const classes = useStyles()
  const data = useContext(DataStore)
  const { t } = useTranslation()
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.only('xs'))


  return (
   <div className={classes.root}>
    {data.internetConnections.map((td,i) => {
      let name = td.name && td.name !== '' ? td.name : t('schooltech.internetConnection')
      if ((!td.name || td.name === '') && data.internetConnections.length > 1) {
        name += ' ' + (i+1)
      }
      return (
        <>
        <ConfigItem
         key={'techdata-'+i}
         view={
          <>
           <div className={classes.description}>
            <Typography className={classes.heading} style={{flexBasis:'90%'}}>{name}</Typography>
            <Typography className={classes.secondaryHeading}><RemoveInternetConnection clientId={td.clientId}/></Typography>
           </div>
           {i+1 === data.internetConnections.length && !isXS && <AddInternetConnection />}
          </>
         }
         edit={<div className={classes.internetConnection}>
           <ChangeName techdata={td} placeholder={name}/>
           <ChangeResponsible techdata={td} />
           <ChangeUpload techdata={td} />
           <ChangeDownload techdata={td} />
           <ChangeIP techdata={td} />
           <ChangeSubnetmask techdata={td} />
           <ChangeProvider techdata={td} providers={data.providers} />
           <ChangeFirewall techdata={td} firewalls={data.firewalls} />
           <ChangeSoftwareSolution techdata={td} softwareSolutions={data.softwareSolutions} />
         </div>}
        />
        {i+1 === data.internetConnections.length && isXS && <AddInternetConnection isXS={isXS} />}
       </>
      )

    })}
    <RemoveInternetConnectionDialog />
   </div>
  )
})

const RemoveInternetConnectionDialog = observer(() => {
  const data = useContext(DataStore)
  const ui = useContext(UiStore)
  const { t } = useTranslation()
  const ic = data.getICByClientId(data.clientIdMarkedAsToDelete)
  const name = ic && ic.name ? ic.name : ''

  const removeInternetConnection = (e) => {
    ui.handleCloseDeleteInternetConnectionDialog()
    data.removeInternetConnection()
  }
  return (
    <Dialog
      open={ui.openDeleteInternetConnectionsDialog}
      onClose={ui.handleCloseDeleteInternetConnectionDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {name} {t('schooltech.deleteInternetConnection')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ui.handleCloseDeleteInternetConnectionDialog} color="primary">
          {t('core.general.cancel')}
        </Button>
        <Button onClick={removeInternetConnection} color="secondary" autoFocus>
          {t('core.general.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const RemoveInternetConnection = observer((props) => {
 const ui = useContext(UiStore)
 const data = useContext(DataStore)
 const { clientId } = props

 const markAsRemoveable = (e) => {
   e.stopPropagation()
   data.markAsRemoveable(clientId)
   ui.handleOpenDeleteInternetConnectionDialog()
 }

 return (
   <IconButton aria-label="delete" onClick={markAsRemoveable}>
    <Delete />
   </IconButton>
 )
})

const AddInternetConnection = observer((props) => {
  const classes = useStyles()
  const { isXS } = props
  const { t } = useTranslation()
  const data = useContext(DataStore)

  const addInternetConnection = (e) => {
    e.stopPropagation()
    data.addEmptyInternetConnection()
  }

  const tooltipText = t('schooltech.internetConnection') + ' ' + t('core.general.add')

  return (
   <div className={classes.addInternetconnectionBtn} style={isXS ? {bottom:'1em',right:'1em'} : {} }>
    <Tooltip title={tooltipText} placement="right">
     <IconButton aria-label="add" onClick={addInternetConnection}>
      <AddCircleOutline />
     </IconButton>
    </Tooltip>
   </div>
  )
})

const ChangeName = observer((props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { techdata,placeholder } = props

  const onChange = (e) => { techdata.setName(e.target.value) }
  const name = techdata.name && techdata.name !== '' ? techdata.name : placeholder

  return (
    <ConfigItem
     expandFirst={true}
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.internetConnection')}</Typography>
        <Typography className={classes.secondaryHeading}>{name}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       placeholder={t('schooltech.nameOfConnection')}
       value={techdata.name}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const ChangeUpload = observer((props) => {
  const { t } = useTranslation()
  const { techdata } = props
  const classes = useStyles()

  const onChange = (e) => { techdata.setUpload(e.target.value) }
  const title = techdata.upload ? techdata.upload : t('schooltech.upload')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.upload')}</Typography>
        <Typography className={classes.secondaryHeading}>{title} {t('schooltech.mbps')}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       type="number"
       placeholder={t('schooltech.upload')}
       value={techdata.upload}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const ChangeDownload = observer((props) => {
  const { t } = useTranslation()
  const { techdata } = props
  const classes = useStyles()

  const onChange = (e) => { techdata.setDownload(e.target.value) }
  const title = techdata.download ? techdata.download : t('schooltech.download')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.download')}</Typography>
        <Typography className={classes.secondaryHeading}>{title} {t('schooltech.mbps')}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       type="number"
       placeholder={t('schooltech.download')}
       value={techdata.download}
       onChange={onChange}
       fullWidth
      />
     }
    />
  )
})

const ChangeIP = observer((props) => {
  const { t } = useTranslation()
  const { techdata } = props
  const classes = useStyles()

  const onChange = (e) => { techdata.setIP(e.target.value) }
  const title = techdata.ip ? techdata.ip : t('schooltech.ip')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.ip')}</Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
       </div>
     }
     edit={
       <TextField
        autoFocus
        variant="filled"
        placeholder={t('schooltech.ip')}
        fullWidth
        value={techdata.ip}
        onChange={onChange}
       />
     }
    />
  )
})


const ChangeSubnetmask = observer((props) => {
  const { t } = useTranslation()
  const { techdata } = props
  const classes = useStyles()

  const onChange = (e) => { techdata.setSubnetmask(e.target.value) }
  const title = techdata.subnetmask ? techdata.subnetmask : t('schooltech.subnetmask')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.subnetmask')}</Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
       </div>
     }
     edit={
      <TextField
       autoFocus
       variant="filled"
       placeholder={t('schooltech.subnetmask')}
       fullWidth
       value={techdata.subnetmask}
       onChange={onChange}
      />
     }
    />
  )
})

const ChangeProvider = observer((props) => {
  const { t } = useTranslation()
  const { techdata,providers } = props
  const classes = useStyles()

  const onChange = (e) => {
   const pv = providers.filter(p => p.id == e.target.value)[0]
   techdata.setProvider(pv)
  }

  const title = techdata.provider && techdata.provider.translationKey ? t('schooltech.'+techdata.provider.translationKey) : t('schooltech.provider')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.provider')}</Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
       </div>
     }
     edit={
       <FormControl component="fieldset">
         <FormLabel component="legend">{t('schooltech.provider')}</FormLabel>
         <RadioGroup aria-label="provider" name="provider-solution" value={techdata.provider.id} onChange={onChange}>
          {providers.map((p,i) => {
           return <FormControlLabel
                   key={'select-provider-'+i}
                   value={p.id}
                   control={<Radio />}
                   label={t('schooltech.'+p.translationKey)}
                  />
          })}
         </RadioGroup>
       </FormControl>
     }
    />
  )
})

const ChangeSoftwareSolution = observer((props) => {
  const { t } = useTranslation()
  const { techdata,softwareSolutions } = props
  const classes = useStyles()

  const onChange = (e) => {
   const sw = softwareSolutions.filter(sw => sw.id == e.target.value)[0]
   techdata.setSoftwareSolution(sw)
  }

  const title = techdata.sw && techdata.sw.translationKey ? t('schooltech.'+techdata.sw.translationKey) : t('schooltech.software.title')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.software.title')}</Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
       </div>
     }
     edit={
       <FormControl component="fieldset">
         <FormLabel component="legend">{t('schooltech.software.title')}</FormLabel>
         <RadioGroup aria-label="provider" name="provider-solution" value={techdata.sw.id} onChange={onChange}>
          {softwareSolutions.map((sw,i) => {
           return <FormControlLabel
                   key={'select-sw-'+i}
                   value={sw.id}
                   control={<Radio />}
                   label={t('schooltech.'+sw.translationKey)}
                  />
          })}
         </RadioGroup>
       </FormControl>
     }
    />
  )
})

const ChangeFirewall = observer((props) => {
  const { t } = useTranslation()
  const { techdata,firewalls } = props
  const classes = useStyles()

  const onChange = (e) => {
   const fw = firewalls.filter(f => f.id == e.target.value)[0]
   techdata.setFirewall(fw)
  }

  const title = techdata.firewall && techdata.firewall.translationKey ? t('schooltech.'+techdata.firewall.translationKey) : t('schooltech.firewall')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.firewall')}</Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
       </div>
     }
     edit={
       <FormControl component="fieldset">
         <FormLabel component="legend">{t('schooltech.firewall')}</FormLabel>
         <RadioGroup aria-label="firewall" name="firewall-solution" value={techdata.firewall.id} onChange={onChange}>
          {firewalls.map((f,i) => {
           return <FormControlLabel
                   key={'select-firewall-'+i}
                   value={f.id}
                   control={<Radio />}
                   label={t('schooltech.'+f.translationKey)}
                  />
          })}
         </RadioGroup>
       </FormControl>
     }
    />
  )
})

const ChangeResponsible = observer((props) => {
  const { t } = useTranslation()
  const { techdata } = props
  const { member } = useContext(DataStore)
  const classes = useStyles()

  const setResponsible = (e) => {
   const m = member.filter(m => m.id == e.target.value)[0]
   techdata.setResponsible(m)
  }
  const title = techdata.responsible && techdata.responsible.name && techdata.responsible.foreName ? techdata.responsible.foreName + ' ' + techdata.responsible.name : t('schooltech.responsible')

  return (
    <ConfigItem
     dontHighlight={true}
     view={
       <div className={classes.description}>
        <Typography className={classes.heading}>{t('schooltech.responsible')}</Typography>
        <Typography className={classes.secondaryHeading}>{title}</Typography>
       </div>
     }
     edit={
       <FormControl component="fieldset">
        <FormLabel component="legend">{t('schooltech.responsible')}</FormLabel>
         <RadioGroup aria-label="responsible" name="responsible-person" value={techdata.responsible.id} onChange={setResponsible}>
          {member.map((m,i) => {
           return <FormControlLabel
                   key={'select-responsible-'+i}
                   value={m.id}
                   control={<Radio />}
                   label={m.foreName + ' ' + m.name}
                  />
          })}
         </RadioGroup>
       </FormControl>
     }
    />
  )
})





export default TechData
