import React, { useState,useCallback,useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import GlobalUiStore from 'core/stores/ui'

import {
  Typography,
  Button,
  Container,
  Paper,
  TextField,
} from '@material-ui/core'
import {

} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
 root:{
   display:'flex',
   flexDirection:'column',
   justifyContent:'center',
   alignItems:'center',
   minHeight:'75vh',
   paddingTop:64,
   paddingBottom:64
 },
}))


const SonicPi = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
   <Container maxWidth="sm" className={classes.root}>


   </Container>
  )
})

export default SonicPi
