import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useObserver } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UIStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container:{
    minHeight:'80vh'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spacing:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  isOAuth:{
    padding:theme.spacing(3)
  }
}));

const ResetPw = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(DataStore)
  const { notifier,loginUI } = useContext(UIStore)

  const orderResetPW = () => {
    user.forgotPw(
      (res) => {
        if (res.isOAuth) {
          loginUI.handleOpenOAuthModal()
        } else {
          notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
        }
      },
      () => {
        notifier.push(t('core.notifier.500'),notifier.variants.ERROR)
      }
    )
  }

  const onChange = (e) => {
    user.setForgotPwMail(e.target.value)
  }

  return useObserver(() => (
   <Container component="main" maxWidth="xs" className={classes.container}>
    <div className={classes.paper}>
      <Typography component="h1" variant="h5" className={classes.spacing}>
        {t('core.resetPw.title')}
      </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('core.login.email')}
          value={user.forgotPwMail}
          onChange={onChange}
          name="email"
          autoComplete="email"
          autoFocus
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={orderResetPW}
          className={classes.submit}
        >
          {t('core.resetPw.title')}
        </Button>
      </div>

      <Dialog onClose={loginUI.handleCloseOAuthModal} aria-labelledby="isOAuth-dialog-title" open={loginUI.isOAuthModalOpen}>
        <p className={classes.isOAuth}>{t('core.login.isOAuth')}</p>
      </Dialog>

    </Container>
  ));
}

export default ResetPw
