import React, { useContext,useEffect } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import moment from 'moment'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Divider,
  Dialog,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Tooltip,
  Toolbar,
  useMediaQuery
} from '@material-ui/core'
import { AccountCircle,Check,Close,Forward } from '@material-ui/icons'
import { UserEntries } from 'core/user/profile'
import { red, green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root:{
   position:'relative',
   marginTop:theme.spacing(2),
   minHeight:'80vh'
  },
  profileDialog:{
    minWidth:370
  },
  card:{
   minHeight:120,
   cursor:'pointer',
   position:'relative',
   height:'90%',
   margin:'0 auto',
   margin:theme.spacing(2),
   [theme.breakpoints.down('sm')]: {
     width:'80%%',
   },
    [theme.breakpoints.up('md')]:{
      maxWidth:192
    }
  },
  cardCenter:{
   display:'flex',
   flexDirection:'column',
   justifyContent:'center',
   alignItems:'center',
   textAlign:'center',
   height:'100%'
  },
  badge:{
    position:'absolute',
    top:theme.spacing(2),
    right:theme.spacing(2)
  },
  acceptDeclineColumn:{
    display:'flex',
    flexDirection:'column'
  },
  acceptDeclineButton:{
    padding:1
  },
  list:{
    marginRight:theme.spacing(5),
    marginTop:-theme.spacing(2),
    height:'100%'
  },
  listItem:{
    color:'rgba(0,0,0,0.7)',
    borderRight:'3px solid transparent',
    paddingRight:theme.spacing(3),
    '&:focus': {
       borderRight:'3px solid red',
       color:'rgba(0,0,0,1)',
    },
  },
  appBar: {
   top: 'auto',
   bottom: 0,
 },
 divider:{
   borderRight:'1px solid #fff',
   width:1,
   height:10
 }
}));

const Release = observer(() => {
  const classes = useStyles()
  const { administration } = useContext(DataStore)
  const { admin } = useContext(UiStore)
  const { t } = useTranslation()


  return (
    <div className={classes.root}>
     <Grid container>
     <ReleaseList />

      <Grid item sm={12} xs={12} md={10} lg={10}>
       <Grid container>
        {admin.selectedReleaseListItem === admin.releaseListItems.MUST_DO && <MustDoReleases />}
        {admin.selectedReleaseListItem === admin.releaseListItems.CAN_DO && <CanDoReleases />}
        {admin.selectedReleaseListItem === admin.releaseListItems.REDIRECTS && <RedirectReleases />}
       </Grid>
      </Grid>

     </Grid>
     {administration.currentReleaseUser && <Profile />}
     {administration.currentRedirectRequest && <ReleaseRedirectDialog />}
    </div>
  )
})

const ReleaseList = observer(() => {
  const classes = useStyles()
  const { administration } = useContext(DataStore)
  const { admin } = useContext(UiStore)
  const { t } = useTranslation()
  const selectListitem = (item) => admin.setReleaseListItem(item)
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
   !mdDown
   ?
   <Grid item xs={2} sm={2} md={2} lg={2}>
    <List component="nav" className={classes.list}>
     <ListSubheader>{t('core.admin.release.user')}</ListSubheader>
     <ListItem button autoFocus className={classes.listItem} onClick={() => { selectListitem(admin.releaseListItems.MUST_DO) }}>
      <ListItemText primary={t('core.admin.release.mustDo')} />
     </ListItem>
     <ListItem button className={classes.listItem} onClick={() => { selectListitem(admin.releaseListItems.CAN_DO) }}>
      <ListItemText primary={t('core.admin.release.canDo')} />
     </ListItem>
     <ListSubheader>{t('core.admin.release.redirects')}</ListSubheader>
     <ListItem button className={classes.listItem} onClick={() => { selectListitem(admin.releaseListItems.REDIRECTS) }}>
      <ListItemText primary={t('core.admin.release.mustDo')} />
     </ListItem>
    </List>
    </Grid>
    :
    <AppBar position="fixed" color="primary" className={classes.appBar}>
     <Toolbar>
      <Button color="inherit" onClick={() => { selectListitem(admin.releaseListItems.MUST_DO) }}>
       {t('core.admin.release.mustDo')}
      </Button>
      <Button color="inherit" onClick={() => { selectListitem(admin.releaseListItems.CAN_DO) }}>
       {t('core.admin.release.canDo')}
      </Button>
      <div className={classes.divider} />
      <Button color="inherit" onClick={() => { selectListitem(admin.releaseListItems.REDIRECTS) }}>
       {t('core.admin.release.mustDo')}
      </Button>
     </Toolbar>
    </AppBar>
  )
})

const RedirectReleases = observer(() => {
  const classes = useStyles()
  const { administration } = useContext(DataStore)
  const { admin } = useContext(UiStore)
  const { t } = useTranslation()

  useEffect(() => {
    administration.getPendingRedirects(
      () => {},
      () => {}
    )
  },[])

  const handleOpen = (request) => {
    administration.setCurrentRedirectRequest(request)
    admin.handleOpenReleaseRedirectDialog()
  }

  return (
   <>

     {administration.redirectRequests.length === 0 &&
      <Box textAlign="center">
       <Typography variant="h6">
        {t('core.admin.noReleases')}
       </Typography>
      </Box>
     }

     {administration.redirectRequests.map((req,i) => {
       const size = 40
       return <Grid item xs={12} sm={6} md={3} lg={2} key={'redirect-request-'+i}>
         <Card className={classes.card}>
           <CardActionArea style={{height:'100%'}} onClick={()=>{handleOpen(req)}}>
            <CardContent style={{height:'100%'}}>
              <div className={classes.cardCenter}>
               <Forward style={{width:size,height:size}} />
               <Typography variand="body1" component="p">{req.domain}</Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
       </Grid>
     })}

   </>
  )
})

const CanDoReleases = observer(() => {
  const classes = useStyles()
  const { administration } = useContext(DataStore)
  const { admin } = useContext(UiStore)
  const { t } = useTranslation()

  useEffect(() => {
    administration.getInviteRequestsWhereUserIsNotFirstAtLocation(
      () => {},
      () => {}
    )
  },[])

 const handleOpen = (user) => {
   administration.setCurrentReleaseUser(user)
   administration.setFirstUser(false)
   admin.handleOpenReleaseDialog()
 }

  return (
   <>

    {administration.inviteRequestsWhereUserIsNotFirstAtLocation.length === 0 &&
     <Box textAlign="center">
      <Typography variant="h6">
       {t('core.admin.noReleases')}
      </Typography>
     </Box>
    }

    {administration.inviteRequestsWhereUserIsNotFirstAtLocation.map((user,i) => {
      const name = user.foreName + ' ' + user.name
      const size = 40
      return <Grid item xs={12} sm={6} md={3} lg={2} key={'user-with-first-'+i}>
        <Card className={classes.card}>
         <Badge className={classes.badge} badgeContent={user.locations.length} color="secondary" />
          <CardActionArea style={{height:'100%'}} onClick={()=>{handleOpen(user)}}>
           <CardContent style={{height:'100%'}}>
             <div className={classes.cardCenter}>
             {
               user.photo
               ?
               <Avatar src={user.photo} style={{width:size,height:size}} />
               :
               <AccountCircle style={{width:size,height:size}} />
             }
              <Typography variand="body1" component="p">{name}</Typography>
             </div>
           </CardContent>
         </CardActionArea>
       </Card>
      </Grid>
    })}


   </>
  )
})

const MustDoReleases = observer(() => {
  const { administration } = useContext(DataStore)
  const classes = useStyles()
  const { admin } = useContext(UiStore)
  const { t } = useTranslation()

  useEffect(() => {
    administration.getInviteRequestsWhereUserIsFirstAtLocation(
      () => {},
      () => {}
    )
  },[])

 const handleOpen = (user) => {
   administration.setCurrentReleaseUser(user)
   administration.setFirstUser(true)
   admin.handleOpenReleaseDialog()
 }
  return (
   <>
    {administration.inviteRequestsWhereUserIsFirstAtLocation.length === 0 &&
     <Box textAlign="center">
      <Typography variant="h6">
       {t('core.admin.noReleases')}
      </Typography>
     </Box>
    }

    {administration.inviteRequestsWhereUserIsFirstAtLocation.map((user,i) => {
      const name = user.foreName + ' ' + user.name
      const size = 40
      return <Grid item xs={12} sm={6} md={4} lg={3} key={'user-with-first-'+i}>
        <Card className={classes.card}>
         <Badge className={classes.badge} badgeContent={user.locations.length} color="secondary" />
          <CardActionArea style={{height:'100%'}} onClick={()=>{handleOpen(user)}}>
           <CardContent style={{height:'100%'}}>
             <div className={classes.cardCenter}>
             {
               user.photo
               ?
               <Avatar src={user.photo} style={{width:size,height:size}} />
               :
               <AccountCircle style={{width:size,height:size}} />
             }
              <Typography variand="body1" component="p">{name}</Typography>
             </div>
           </CardContent>
         </CardActionArea>
       </Card>
      </Grid>
    })}

   </>
  )
})

const ReleaseRedirectDialog = observer(() => {
  const { administration } = useContext(DataStore)
  const { notifier,admin } = useContext(UiStore)
  const { currentRedirectRequest } = administration
  const { t } = useTranslation()
  const classes = useStyles()
  moment.locale('de')

  const acceptRedirect = () => {
   administration.acceptRedirectRequest(
     (success) => {
       admin.handleCloseReleaseRedirectDialog()
       if (success) {
         notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
       } else {
         notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
       }
     },
     () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
   )
  }
  const declineRedirect = () => {
   administration.declineRedirectRequest(
     () => {
       admin.handleCloseReleaseRedirectDialog()
       notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
     },
     () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
   )
  }



  return (
    <Dialog aria-labelledby="release-redirect-profile" open={admin.releaseRedirectDialogOpen} onClose={admin.handleCloseReleaseRedirectDialog} maxWidth="md">
     <div className={classes.profileDialog}>
     <List>
       <ListItem button key={'redirect-request-dialog-'}>
        <ListItemText primary={`${currentRedirectRequest.domain} --> ${currentRedirectRequest.redirect}`} secondary={`${moment(currentRedirectRequest.created).fromNow()} ${t('redirecter.applied')}`} />

        <ListItemSecondaryAction className={classes.acceptDeclineColumn}>
         <Tooltip title={t('core.general.decline')} placement="top">
          <IconButton onClick={() => { declineRedirect() }} className={classes.acceptDeclineButton}>
           <Close style={{color:red[500]}}/>
          </IconButton>
         </Tooltip>
         <Tooltip title={t('core.general.release')} placement="top">
          <IconButton onClick={() => { acceptRedirect() }} className={classes.acceptDeclineButton}>
           <Check style={{color:green[500]}}/>
          </IconButton>
         </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
     </List>

     </div>
    </Dialog>
  )
})
const Profile = observer(() => {
  const { administration } = useContext(DataStore)
  const { admin } = useContext(UiStore)
  const classes = useStyles()

  return (
    <Dialog aria-labelledby="release-user-profile" open={admin.releaseDialogOpen} onClose={admin.handleCloseReleaseDialog} maxWidth="md">
     <div className={classes.profileDialog}>
      <UserEntries
       onClose={admin.handleCloseReleaseDialog}
       user={administration.currentReleaseUser}
       profilePhotoSize={60}
      />
      <Requests />
     </div>
    </Dialog>
  )
})



const Requests = observer(() => {
  const { administration } = useContext(DataStore)
  const { notifier,admin } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  moment.locale('de')

  const declineInvite = (erzKey,userId) => {
    administration.declineInvite(
      erzKey,
      userId,
      (success,numberLocationsLeft) => {
        if (numberLocationsLeft === 0) {
          admin.handleCloseReleaseDialog()
        }
        notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
      },
      () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
    )
  }
  const acceptInvite = (erzKey,userId) => {
    administration.acceptInvite(
      erzKey,
      userId,
      (success,numberLocationsLeft) => {
        if (numberLocationsLeft === 0) {
          admin.handleCloseReleaseDialog()
        }
        notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
      },
      () => { notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR) }
    )
  }

  const u = administration.currentReleaseUser

  return (

      <List dense>
       { u.locations.map((l,j) => {
         const address = l.strasse + ', ' + l.plz + ' ' + l.standort
         const since = moment(l.created).fromNow()
         return <ListItem button key={'location-with-first-'+j}>
           <ListItemText primary={`${l.name} (${since}) ${t('redirecter.applied')}`} secondary={address} />

           <ListItemSecondaryAction className={classes.acceptDeclineColumn}>
            <Tooltip title={t('core.general.decline')} placement="top">
             <IconButton onClick={() => { declineInvite(l.erz_key,u.id) }} className={classes.acceptDeclineButton}>
              <Close style={{color:red[500]}}/>
             </IconButton>
            </Tooltip>
            <Tooltip title={t('core.general.release')} placement="top">
             <IconButton onClick={() => { acceptInvite(l.erz_key,u.id) }} className={classes.acceptDeclineButton}>
              <Check style={{color:green[500]}}/>
             </IconButton>
            </Tooltip>
           </ListItemSecondaryAction>
         </ListItem>
       })}
      </List>
  )
})


export default Release
