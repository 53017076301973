import React, { useContext,useEffect,useState } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'
import { UserEntries } from 'core/user/profile'
import { useHistory } from 'react-router-dom'


import {
  Badge,
  Chip,
  Dialog,
  Collapse,
  Avatar,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListSubheader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core'
import { AccountCircle,Add,Check,Close,ExpandLess,ExpandMore } from '@material-ui/icons'
import { amber, red, green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root: {
     width: '100%',
     backgroundColor: theme.palette.background.paper,
     paddingTop:0
   },
   avatar:{
     width:40,
     height:40
   },
   detailsDialog:{
     minWidth:370
   },
   chip:{
     cursor:'pointer',
     display:'flex'
   },
   divider:{
     marginTop:theme.spacing(1),
     marginBottom:theme.spacing(1)
   },
   pendingColor:{
     backgroundColor:amber[500]
   },
   badge:{
     width:'100%'
   },
   buttonContainer:{
    padding:theme.spacing(5)
  },
  space:{
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1)
  },
  acceptDeclineColumn:{
    display:'flex',
    flexDirection:'column'
  },
  acceptDeclineButton:{
    padding:1
  },
  memberList:{
    maxHeight:'100vh',
    overflow:'auto'
  }
}));


const MemberList = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { member,requests } = props
  const { schoolPage } = useContext(DataStore)
  const { schoolPageUI } = useContext(UiStore)
  const { school } = schoolPage


  const getDetails = (m) => {
    m.getDetails(
      schoolPage.id,
      (detailedMember) => {
        schoolPage.setCurrentMember(detailedMember)
        schoolPageUI.handleOpenMemberDetailsDialog()
      },
      () => {}
    )
  }

  const openMenu = (e,m) => {
    e.stopPropagation()
    schoolPage.setCurrentMember(m)
    schoolPageUI.handleOpenAddFunctionsOrTeachingLevelsMenu(e.currentTarget)
  }

  const acceptRequest = (request) => {
    request.accept(
     () => {
       school.addMember(request)
     },
     () => {}
    )
  }
  const declineRequest = (request) => {
    request.decline(
      () => {
        school.removeRequest(request)
      },
      () => {}
    )
  }


  return (
   <>
    <List dense className={classes.root} onMouseLeave={()=>{schoolPageUI.setHovered(-1)}}>
     {requests.length > 0 &&
       <>
       <ListItem button onClick={schoolPageUI.toggleOpenRequests} className={classes.pendingColor}>
        <ListItemText primary={t('core.dashboard.freshStart.pendings')} />
        <Badge badgeContent={requests.length} color="secondary" />
       </ListItem>
       <Collapse in={schoolPageUI.openRequests} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
         {requests.map((r,j) => {
           const name = r.foreName + ' ' + r.name
           const image = r.photo ? <Avatar src={r.photo} className={classes.avatar}/> : <AccountCircle className={classes.avatar}/>

          return <ListItem
           button
           key={'open-request-'+j}
           className={classes.pendingColor}>
             <ListItemAvatar>
              {image}
             </ListItemAvatar>
             <ListItemText
              primary={name}
             />
             <ListItemSecondaryAction className={classes.acceptDeclineColumn}>
              <Tooltip title={t('core.general.decline')} placement="top">
               <IconButton onClick={() => { declineRequest(r) }} className={classes.acceptDeclineButton}>
                <Close style={{color:red[500]}}/>
               </IconButton>
              </Tooltip>
              <Tooltip title={t('core.general.accept')} placement="top">
               <IconButton onClick={() => { acceptRequest(r) }} className={classes.acceptDeclineButton}>
                <Check style={{color:green[500]}}/>
               </IconButton>
              </Tooltip>
             </ListItemSecondaryAction>
           </ListItem>
         })}
        </List>
      </Collapse>
      </>
     }

     {member.map((m,i) => {
       const name = m.foreName + ' ' + m.name
       const image = m.photo ? <Avatar src={m.photo} className={classes.avatar}/> : <AccountCircle className={classes.avatar}/>
       const chips = m.hasFunctionsOrTeachingLevels ? <MemberRoles member={m} schoolId={schoolPage.id} /> : null
       return <ListItem
        onMouseEnter={() => {schoolPageUI.setHovered(i)}}
        button
        onClick={()=>{getDetails(m)}}
        className={classes.memberList}
        key={'member-item-'+m.id} className={m.justAdded ? "animated flash" : ''}>

         <ListItemAvatar>
          {image}
         </ListItemAvatar>
         <ListItemText
          primary={name}
          secondary={chips}
         />
          {schoolPageUI.hoveredMemberIndex === i &&
            <ListItemSecondaryAction>
             <IconButton edge="end" aria-label="delete" onClick={(e)=>{openMenu(e,m)}}>
              <Add />
             </IconButton>
            </ListItemSecondaryAction>
          }
       </ListItem>
     })}

    </List>
    <MemberDetails />
    <MemberFunctionsAndTeachingLevelsMenu />
   </>
  )
})

const MemberDetails = observer(() => {
  const { schoolPageUI } = useContext(UiStore)
  const { schoolPage,user } = useContext(DataStore)
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const leaveLocation = () => {
    const itsMe = user.itsMe(schoolPage.currMember)
    schoolPage.leaveLocation(
      itsMe,
      () => {
        schoolPageUI.handleCloseMemberDetailsDialog()
        if (itsMe) {
          user.removeLocation(schoolPage.id)
          history.push('/dashboard')
        } else {
          schoolPage.school.removeMember(schoolPage.currMember)
        }
      },
      () => {}
    )
  }

  return (
    <Dialog aria-labelledby="member-details-profile" open={schoolPageUI.openMemberDetailsDialog} onClose={schoolPageUI.handleCloseMemberDetailsDialog} maxWidth="md">
     <div className={classes.detailsDialog}>
      <UserEntries
       onClose={schoolPageUI.handleCloseMemberDetailsDialog}
       user={schoolPage.currMember}
       profilePhotoSize={60}
      />
      <div className={classes.buttonContainer}>
       <div className={classes.space} />
       <Button fullWidth color="secondary" onClick={leaveLocation}>
        {t('core.dashboard.schoolPage.leaveLocation')}
       </Button>
      </div>
     </div>
    </Dialog>
  )
})

const MemberRoles = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { schoolPageUI } = useContext(UiStore)
  const { schoolPage } = useContext(DataStore)
  const { member } = props
  const { functions, teachingLevels } = member


  const deleteMemberFn = (fn) => {
   member.deleteMemberFunction(
     schoolPage.id,
     fn,
     () => {},
     () => {}
   )
  }
  const deleteMemberTL = (tl) => {
    member.deleteMemberTeachingLevel(
      schoolPage.id,
      tl,
      () => {},
      () => {}
    )
  }

  const size = 'small', variant = 'outlined', className = 'chip', placement = ""
  return (
    <div>
     {functions.map((f,i) => {
      return <Tooltip title={t('core.'+f.translationKey)} placement={placement}>
        <Chip
         key={'member-function-'+i}
         variant={variant} size={size}
         label={t('core.'+f.translationKey+'Short')}
         className={className}
         onDelete={() => { deleteMemberFn(f) }}
        />
        </Tooltip>
      })
    }
    {teachingLevels.map((tl,i) => {
      return <Tooltip title={t('core.'+tl.translationKey)} placement={placement}>
        <Chip
         key={'member-teachinglevel-'+i}
         variant={variant} size={size}
         label={t('core.'+tl.translationKey+'Short')}
         className={className}
         onDelete={() => { deleteMemberTL(tl) }}
        />
       </Tooltip>
      })
    }
    </div>
  )
})

const MemberFunctionsAndTeachingLevelsMenu = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { schoolPageUI } = useContext(UiStore)
  const { schoolPage } = useContext(DataStore)
  const { currMember } = schoolPage

  const addFn = (fn) => {
   currMember.addMemberFunction(
     schoolPage.id,
     fn,
     () => {
       schoolPageUI.handleCloseAddFunctionsOrTeachingLevelsMenu()
     },
     () => {}
   )
  }

  const addTL = (memberTL) => {
   currMember.addMemberTeachingLevel(
     schoolPage.id,
     memberTL,
     () => {
       schoolPageUI.handleCloseAddFunctionsOrTeachingLevelsMenu()
     },
     () => {}
   )
  }

  return (
    <Menu
      anchorEl={schoolPageUI.addFunctionsOrTeachingLevelsAnchorEl}
      keepMounted
      open={schoolPageUI.openAddFunctionsOrTeachingLevelsMenu}
      onClose={schoolPageUI.handleCloseAddFunctionsOrTeachingLevelsMenu}
    >
     {schoolPage.memberFunctions.map((mFn,i) => {
       return <MenuItem key={'memer-fn-'+mFn.id} onClick={()=>{addFn(mFn)}}>
         {t('core.'+mFn.translationKey)}
       </MenuItem>
     })}
     <Divider className={classes.divider}/>
     {schoolPage.teachingLevels.map((tl,i) => {
       return <MenuItem key={"teaching-level-"+tl.id} onClick={() => { addTL(tl) }}>
         {t('core.'+tl.translationKey)}
       </MenuItem>
     })}
    </Menu>
  )
})





export default MemberList
