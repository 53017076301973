import React, {useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'


const Subscription = observer(() => {
  return (
    <section className="subscribe-area ptb-100">
      <div className="container">
        <div className="subscribe-content">
          <span>Get Started Instantly!</span>
          <h2>Get Only New Update from this Newsletter</h2>
          <form className="newsletter-form" data-toggle="validator">
            <input type="email" className="input-newsletter" placeholder="Enter your email" name="EMAIL" required autoComplete="off" />
            <button type="submit">Subscribe</button>
            <div id="validator-newsletter" className="form-result" />
          </form>
        </div>
      </div>
    </section>
  )
})

export default Subscription
