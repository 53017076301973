import { createContext,useContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import Ajax from 'api/ajax'

import DataStore from './data'

class UI {
 modes = {
   ENTER_DOMAIN:1,
   ENTER_REDIRECT:2,
   A_RECORD_EXPLAINER:3
 }
 mode = this.modes.ENTER_DOMAIN
 setMode = (mode) => this.mode = mode

 isTLD = (domain) => { return domain.split('.').length === 2 }
}


// ###################################
// ###################################
// ###################################

decorate(UI,{
 mode:observable
})






const UiStore = createContext(new UI())
export default UiStore
