import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import db from './examples.json'
import './index.css'

import IMG_EXAMPLEIMAGE from 'media/img/ampelsystem/exampleImage.jpg'
import IMG_HUMAN from 'media/img/ampelsystem/human.png'
import IMG_EXAMPLE_3 from 'media/img/ampelsystem/img_example3.png'
import IMG_EXAMPLE_7 from 'media/img/ampelsystem/img_example7.png'
import IMG_EXAMPLE_8 from 'media/img/ampelsystem/img_example8.png'
import IMG_EXAMPLE_9 from 'media/img/ampelsystem/img_example9.png'
import IMG_MEDIA from 'media/img/ampelsystem/media.jpg'
import IMG_PERSONDATA from 'media/img/ampelsystem/persondata.jpg'
import IMG_PUPILWORK from 'media/img/ampelsystem/pupilwork.jpg'

import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardMedia,
  CardActions,
  Divider,
  Avatar,
  IconButton,
  Collapse,
  List,
  ListSubheader,
  ListItemIcon,
  ListItem,
  ListItemText
} from '@material-ui/core'
import { TrafficLight,SingleTrafficLight } from 'services/ampelflyer/pdf'

import {
 ExpandMore,Label
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container:{
    paddingTop:theme.spacing(10),
    paddingBottom:theme.spacing(10),
    textAlign:'center'
  },
  media: {
   height: 0,
   paddingTop: '56.25%', // 16:9
 },
 expand: {
   transform: 'rotate(0deg)',
   marginLeft: 'auto',
   transition: theme.transitions.create('transform', {
     duration: theme.transitions.duration.shortest,
   }),
 },
 expandOpen: {
   transform: 'rotate(180deg)',
 },
 singleTrafficLight:{
   position:'relative'
 },
 divider:{
   marginTop:theme.spacing(4),
   marginBottom:theme.spacing(4)
 },
 trafficLights:{
   margin:'0 auto'
 },
 trafficTextSpace:{
   marginLeft:theme.spacing(7)
 }
}))



const Ampelsystem = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { examples, categories, colors } = db

  return (
   <Grid container justify="center" alignItems="center" spacing={4} className={classes.container}>
    <Grid item xs={12} sm={12} md={10} lg={10}>
     <Typography variant="h3">{t('ampelsystem.explained.title')}</Typography>
    </Grid>
    <Grid item xs={11} sm={10} md={10} lg={10}>
     <Typography variant="h5">{t('ampelsystem.explained.subtitle')}</Typography>
     <Typography variant="body1">{t('ampelsystem.explained.what')}</Typography>
     <Divider className={classes.divider}/>
    </Grid>
    <Grid item xs={12} sm={12} md={10} lg={10}>
     <Grid container spacing={3}>
     {Object.keys(examples).map((key,i) => {
       return <Grid item xs={12} sm={6} md={4} lg={4}>
        <Example data={examples[key]} />
       </Grid>
     })}
     </Grid>

    </Grid>
   </Grid>
  )
})

const Example = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [open,setOpen] = useState(false)

 const handleExpandClick = () => { setExpanded(!expanded) }
 const handleClose = () => setOpen(false)
 const handleOpen = () => setOpen(true)

 const { data } = props
 const { ampel, key, technicalSolution, actors, categories } = data

 let categoryImage = ''
 if (categories[0] == db.categories.PERSONDATA) categoryImage = IMG_PUPILWORK
 if (categories[0] == db.categories.PUPILWORK) categoryImage = IMG_PERSONDATA
 if (categories[0] == db.categories.MEDIA) categoryImage = IMG_MEDIA
 if (categories[0] == db.categories.EVALUATION) categoryImage = IMG_EXAMPLEIMAGE

 const createMarkup = (key) => {
  return {
    __html: t('ampelsystem.examples.'+key+'.content')
  }
 }

  return (
    <Card className={classes.root}>
     <CardActionArea onClick={handleExpandClick}>
      <CardMedia
        className={classes.media}
        image={categoryImage}
        title="Paella dish"
      />
      <CardContent style={{textAlign:'left'}}>
        <TrafficLight active={ampel}/>
        <div className={classes.trafficTextSpace}>
        <Typography gutterBottom>
         {categories.map(c => t('ampelsystem.categories.'+c))}
        </Typography>
        <Typography variant="body1" color="textSecondary">
         {actors.map((a,j) => t('ampelsystem.actors.'+a) + (j+1 === actors.length ? '': ', '))}
        </Typography>
       </div>
      </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <IconButton
          className={`${classes.expand} ${expanded ? classes.expandOpen : ''}`}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph dangerouslySetInnerHTML={createMarkup(key)} />
          <List
           dense
           component="nav"
           aria-labelledby="nested-list-subheader"
           subheader={
             <ListSubheader component="div"> {t('ampelsystem.technicalSolution.title')} </ListSubheader>
           }
          >
           {technicalSolution.map((ts,a) => {
             return <ListItem>
               <ListItemIcon><Label /></ListItemIcon>
               <ListItemText primary={t('ampelsystem.technicalSolution.'+ts)} />
             </ListItem>
           })}
          </List>
        </CardContent>

        {t('ampelsystem.examples.'+key+'.comment') !== '' &&
        <>
         <Button onClick={handleOpen}>{t('ampelsystem.more')}</Button>
         <Dialog onClose={handleClose} open={open}>
          <DialogTitle >{t('ampelsystem.comments')}</DialogTitle>
          <DialogContent>
           <DialogContentText >
            {t('ampelsystem.examples.'+key+'.comment')}
           </DialogContentText>
          </DialogContent>
         </Dialog>
        </>
       }

      </Collapse>
    </Card>
  )
})

export default Ampelsystem
