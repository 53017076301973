import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'

class Catalog {
  filterOpen = true
  handleOpenFilter = () => this.filterOpen = true
  handleCloseFilter = () => this.filterOpen = false

  inspectOpen = false
  handleOpenInspect = () => this.inspectOpen = true
  handleCloseInspect = () => this.inspectOpen = false

  editOpen = false
  handleOpenEdit = () => this.editOpen = true
  handleCloseEdit = () => this.editOpen = false

  aboutOpen = false
  handleOpenAbout = () => this.aboutOpen = true
  handleCloseAbout = () => this.aboutOpen = false
}

class Suggestion {
  activeStep = 0
  setActiveStep = (step) => this.activeStep = step
  nextStep = () => { this.setActiveStep(this.activeStep + 1) }
  prevStep = () => { this.setActiveStep(this.activeStep - 1) }
}

class UI {
  selectedTab = 0
  suggestionUi = new Suggestion()
  catalogUi = new Catalog()

  showTouchAppIcon = false
  createThemeOpen = false
  updateSuggestionOpen = false

  editCategoryOpen = false
  editCategoryAnchorEl = null

  editColorSchemeOpen = false
  editColorSchemeAnchorEl = null

  setSelectedTab = (tab) => this.selectedTab = tab
  handleOpenUpdateSuggestion = () => this.updateSuggestionOpen = true
  handleCloseUpdateSuggestion = () => this.updateSuggestionOpen = false
  handleOpenCreateTheme = () => this.createThemeOpen = true
  handleCloseCreateTheme = () => this.createThemeOpen = false
  handleOpenCategory = () => this.editCategoryOpen = true
  handleCloseCategory = () => this.editCategoryOpen = false
  handleOpenColorScheme = () => this.editColorSchemeOpen = true
  handleCloseColorScheme = () => this.editColorSchemeOpen = false
  setEditCategoryAnchorEl = (anchorEl) => this.editCategoryAnchorEl = anchorEl
  setEditColorSchemeAnchorEl = (anchorEl) => this.editColorSchemeAnchorEl = anchorEl
  toggleShowTouchAppIcon = () => this.showTouchAppIcon = !this.showTouchAppIcon
}

decorate(Catalog,{
  filterOpen:observable,
  inspectOpen:observable,
  editOpen:observable,
  aboutOpen:observable
})

decorate(Suggestion,{
  activeStep:observable
})

decorate(UI,{
  createThemeOpen:observable,
  updateSuggestionOpen:observable,
  editCategoryOpen:observable,
  editCategoryAnchorEl:observable,
  showTouchAppIcon:observable,
  editColorSchemeOpen:observable,
  editColorSchemeAnchorEl:observable,
  selectedTab:observable
})


const UiStore = createContext(new UI())
export default UiStore
