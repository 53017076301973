import React, { useContext,useEffect,useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer,useObserver } from 'mobx-react-lite'
import history from '../../history'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Box,
  Grid,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
  Tabs,
  Tab
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow:1,
    padding:theme.spacing(2),
    paddingTop:theme.spacing(6),
    borderTop:'1px solid #ddd'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));


const Privacy = observer(() => {
  const classes = useStyles()
  const { notifier,userPrivacy } = useContext(UiStore)
  const { t } = useTranslation()
  const { user } = useContext(DataStore)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event, newValue) => {
    userPrivacy.setTab(newValue);
  };


  return (
   <Dialog fullScreen={fullScreen} aria-labelledby="user-privacy" open={userPrivacy.open} onClose={userPrivacy.handleClose} maxWidth="md">
     <IconButton aria-label="close" className={classes.closeButton} onClick={userPrivacy.handleClose}>
       <Close />
     </IconButton>
     <Grid container spacing={0} className={classes.root}>

      <div className={classes.tabsRoot}>

       <Tabs
         value={userPrivacy.tab}
         onChange={handleChange}
         aria-label="Vertical tabs example"
         className={classes.tabs}
       >
         <Tab label={t('core.user.deletion')} {...a11yProps(0)} />
       </Tabs>
       <TabPanel value={userPrivacy.tab} index={0}>
         <DeleteAccount />
       </TabPanel>
      </div>

     </Grid>
   </Dialog>
  )
})

const DeleteAccount = observer(() => {
  const { t } = useTranslation()
  const { user } = useContext(DataStore)

  const finalAccountDeletion = () => {
   user.finalDeletion(
     () => {
       history.push('/login')
       window.location.reload()
     },
     () => {}
   )
  }
  return (
    <Box>
     <Typography component="p" variant="body1">{t('core.user.finalDeletionText')}</Typography>
     <Button variant="contained" color="secondary" onClick={finalAccountDeletion}>{t('core.user.finalDeletion')}</Button>
    </Box>
  )
})

const AllDataAboutMyAccount = observer(() => {
  const { t } = useTranslation()
  const orderMyData = () => {

  }
  return (
    <Box>
     <Typography component="p" variant="body1">{t('core.user.orderDataText')}</Typography>
     <Button variant="contained" color="secondary" onClick={orderMyData}>{t('core.user.orderData')}</Button>
    </Box>
  )
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}




export default Privacy
