import React, {useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core/'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  sticky:{
    top: 64,
    zIndex: 10,
    position: 'sticky',
    backgroundColor: 'inherit',
    [theme.breakpoints.only('xs')]: {
      top:56
    },
  }
}))

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Navigation = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (event, newValue) => {

  };

  return (
   <div className={classes.sticky}>
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={0}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="navigation-kibs"
        >
          <Tab label={t('core.landingpage.services')} {...a11yProps(0)} />
          <Tab label={t('core.landingpage.team.title')} {...a11yProps(0)} />
          <Tab label={t('core.landingpage.helpdesk.title')} {...a11yProps(0)} />
        </Tabs>
      </AppBar>

    </div>
   </div>
  );
})

export default Navigation
