export const randomHexColor = function () {
 return "#" +
  Math.random()
   .toString(16)
   .substr(2, 6)
}


export const addHttps = function (url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
}

export const getContrast = function (hexcolor) {

	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex;
		}).join('');
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);

	// Get YIQ ratio
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	// Check contrast
	return (yiq >= 128) ? 'black' : 'white';

}

export const getSchoolShortname = (name) => {
  const split = name.split(' ')
  if (split.length >= 2) {
    return split[0].charAt(0) + split[1].charAt(0)
  } else {
    return split[0].charAt(0)
  }
}

export const getAddedOrReplacedParam = (url, param, value) => {
 param = encodeURIComponent(param);
 var r = "([&?]|&amp;)" + param + "\\b(?:=(?:[^&#]*))*";
 var a = document.createElement('a');
 var regex = new RegExp(r);
 var str = param + (value ? "=" + encodeURIComponent(value) : "");
 a.href = url;
 var q = a.search.replace(regex, "$1"+str);
 if (q === a.search) {
    a.search += (a.search ? "&" : "") + str;
 } else {
    a.search = q;
 }
 return a.search
}

export const hash = (s) => {
  return s.split('').reduce((hash, c) => {
    return (((hash << 5) - hash) + c.charCodeAt(0)) | 0;
  }, 0);
}

export const checkImage = path =>
 new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve({path, status: 'ok'});
    img.onerror = () => resolve({path, status: 'error'});

    img.src = path;
})

export const spreadElements3 = (elements,container,callbackEachTime,callbackEnd) => {
 function _getElementOffset(elm, height) {
   return height ? 55 : 70
	var cStyle = elm.ownerDocument && elm.ownerDocument.defaultView && elm.ownerDocument.defaultView.getComputedStyle
		&& elm.ownerDocument.defaultView.getComputedStyle(elm, null),
		ret = cStyle && cStyle.getPropertyValue(height ? 'height' : 'width') || '';
  	if (ret && ret.indexOf('.') > -1) {
		ret = parseFloat(ret)
			+ parseInt(cStyle.getPropertyValue(height ? 'padding-top' : 'padding-left'))
			+ parseInt(cStyle.getPropertyValue(height ? 'padding-bottom' : 'padding-right'))
			+ parseInt(cStyle.getPropertyValue(height ? 'border-top-width' : 'border-left-width'))
			+ parseInt(cStyle.getPropertyValue(height ? 'border-bottom-width' : 'border-right-width'));
 	} else {
		ret = height ? elm.offsetHeight : elm.offsetWidth;
	}
	return ret;
  }
  function getOffsetWidth(elm) {
  	return _getElementOffset(elm);
  }
  function getOffsetHeight(elm) {
  	return _getElementOffset(elm, true);
  }

  function shuffleArray (d,same){
   var b;
   for (var c = d.length - 1; c > 0; c--) {
     if(same)
      b = Math.floor(Math.random() * (c + 1)); else
      b = Math.floor(Math.random() * (c + 1));
     var a = d[c];
     d[c] = d[b];
     d[b] = a;
   }
   return d;
  }


   var ox = container.offsetLeft
   var oy = container.offsetTop
   var cx = getOffsetWidth(container)/2+ox
   var cy = getOffsetHeight(container)/2+oy
   var g = 0.01;
   var hs = new Array();
   var ws = new Array();
   for(var i=0; i < elements.length; i++){
    var w = getOffsetWidth(elements[i]);
    var h = getOffsetHeight(elements[i]);
    ws[i] = w;
    hs[i] = h;
    // start in center of container
    var x = cx + Math.round((Math.random()*100-50) / 10);
    var y = cy + Math.round((Math.random()*100-50) / 10);
    elements[i].style.left = (x-w/2)+"px";
    elements[i].style.top  = (y-h/2)+"px";
   }

   function overlaps(x1,y1,w1,h1,x2,y2,w2,h2){
    return !(x2 > x1+w1 || x2+w2 < x1 || y2 > y1+h1 || y2+h2 < y1);
   }

   function collide(x1,y1,w1,h1,x2,y2,w2,h2){
    var r1 = Math.max(w1,h1) / 2;
    var r2 = Math.max(w2,h2) / 2;
    var dx = x1-x2;
    var dy = y1-y2;
    var moveX = 0;
    var moveY = 0;
    if(dx !== 0 || dy !== 0){
     var distance = Math.sqrt(dx * dx + dy * dy);
     var minDistance = r1 + r2;
     if(overlaps(x1-w1/2,y1-h1/2,w1,h1,x2-w2/2,y2-h2/2,w2,h2)){
      distance = (distance - minDistance) / distance * 0.1;
      moveX = dx * distance;
      moveY = dy * distance;
     }
    }
    return [moveX,moveY];
   }

   function boundIt(x,y,w,h){
    if(x < ox+10) x = oy+10;
    if(y < oy+20) y = oy+20;
    if(x+w > ox+(cx-ox)*2-10) x = ox+(cx-ox)*2-w-10;
    if(y+h > oy+(cy-oy)*2-10) y = oy+(cy-oy)*2-h-10;
    return [x,y];
   }

   var run = 30;
   function runFunc() {
    var randomArray = [];
    for(var i=0; i < elements.length; i++) randomArray.push(i);
    randomArray = shuffleArray(randomArray);

    for(var ra=0; ra < randomArray.length; ra++){
     var i = randomArray[ra];
     var w1 = ws[i];
     var h1 = hs[i];
     if(run % 5 == 0){
      w1 = getOffsetWidth(elements[i]);
      h1 = getOffsetHeight(elements[i]);
      ws[i] = w1;
      hs[i] = h1;
     }

     var x1 = parseFloat(elements[i].style.left)+w1/2;
     var y1 = parseFloat(elements[i].style.top)+h1/2;
     var nx = x1 + (Math.max(-5,Math.min(5,cx - x1)))*g;
     var ny = y1 + (Math.max(-5,Math.min(5,cy - y1)))*g;
     for(var z=0; z < elements.length; z++){
      if(i == z) continue;
      var w2 = ws[z];
      var h2 = hs[z];
      var x2 = parseFloat(elements[z].style.left)+w2/2;
      var y2 = parseFloat(elements[z].style.top)+h2/2;

      var move = collide(x1,y1,w1,h1,x2,y2,w2,h2);
      var moveX = move[0];
      var moveY = move[1];
      var p = boundIt(x2+moveX-w2/2,y2+moveY-h2/2,w2,h2);
      elements[z].style.left = (p[0])+"px";
      elements[z].style.top = (p[1])+"px";
      nx -= moveX;
      ny -= moveY;
     }

     var p = boundIt(nx-w1/2,ny-h1/2,w1,h1);
     elements[i].style.left = (p[0])+"px";
     elements[i].style.top = (p[1])+"px";
     if(typeof (callbackEachTime) == "function") callbackEachTime(elements[i]);
    }
    run--;
    if(run > 0) setTimeout(runFunc,10); else
     if(typeof (callbackEnd) == "function") callbackEnd(elements);
   };
   setTimeout(runFunc,100);
  }

export const spreadElements2 = (containerId,numElements,safeRadius = 55,irregularity = 10) => {
  // declarations
  var positions = [];
  safeRadius = safeRadius < 5 ? 5 : safeRadius

  const c = document.getElementById(containerId)
  const marginLeft = 76
  const maxX = c.offsetWidth - marginLeft
  const maxY = c.offsetHeight

  // Returns a random integer between min (included) and max (excluded)
  // Using Math.round() will give you a non-uniform distribution!
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  // generate random positions
  function generatePositionsArray(maxX, maxY, safeRadius, irregularity = 5) {
      // declarations
      var positionsArray = [];
      var r, c;
      var rows;
      var columns;
      // count the amount of rows and columns
      rows = Math.floor(maxY / safeRadius);
      columns = Math.floor(maxX / safeRadius);
      // loop through rows
      for (r = 1; r <= rows; r += 1) {
          // loop through columns
          for (c = 1; c <= columns; c += 1) {
              // populate array with point object
              positionsArray.push({
                  x: Math.round(maxX * c / columns) + getRandomInt(irregularity * -1, irregularity),
                  y: Math.round(maxY * r / rows) + getRandomInt(irregularity * -1, irregularity)
              });
          }
      }
      // return array
      return positionsArray;
  }
  positions = generatePositionsArray(maxX, maxY, safeRadius, irregularity);

  // get random position from positions array
  function getRandomPosition(array, removeTaken) {
      // declarations
      var randomIndex;
      var coordinates;
      // get random index
      randomIndex = getRandomInt(0, array.length - 1);
      // get random item from array
      coordinates = array[randomIndex];
      // check if remove taken
      if (removeTaken) {
          // remove element from array
          array.splice(randomIndex, 1);
      }
      // return position
      return coordinates;
  }

  function checkOutOfBounds (arr,maxX,maxY,safeRadius) {
    let temp = []
    for (let i = 0; i < arr.length; i++) {
      const pos = arr[i]
      if (pos.y + safeRadius <= maxY) {
        temp.push(pos)
      }
    }
    return temp
  }
  const inBound = checkOutOfBounds(positions,maxX,maxY,safeRadius)
  console.log(positions);
console.log(inBound);

  return inBound.length >= numElements ? inBound : positions
}

export const spreadElements = (numberElements,maxSize,containerId) => {
  var positions = [], number=numberElements, size=maxSize, m = ele(containerId),
  mw = parseFloat(getComputedStyle(m).getPropertyValue("width")) - size,
  mh = parseFloat(getComputedStyle(m).getPropertyValue("height")) - size,
  f = true
  var newX, nn, newY

  var i
  for (i = 0; i < number; i++) {
   var o = {}
   positions.push(o)
   var t = true
   if (i!=0) {
    while (t) {
     newX = rand(mw)
     newY = rand(mh)

     for (nn = 0; nn < i; nn++) {
      if (
       !(((newX > positions[nn].x + size) || (newY > positions[nn].y + size)) ||
         ((newX + size < positions[nn].x) || (newY + size < positions[nn].y)))) {
           break;
       }
       if (nn == i-1) t = false;
      }}} else {
       newX = rand(mw);
       newY = rand(mh);
     }

     positions[i].x = newX
     positions[i].y = newY
  }

  function rand(x) { return Math.random()*x }
  function ele(x){ return document.getElementById(x) }

  return positions
}
