import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core/styles'
import { useObserver } from 'mobx-react-lite'
import { Close } from '@material-ui/icons'

import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  msg: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const NotifierWrapper = (props) => {
  const classes = useStyles()
  const { className, message, onClose, variant, ...other } = props

  return useObserver(() => (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={message}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
         <Close />
        </IconButton>,
      ]}
      {...other}
    />
  ))
}

NotifierWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}


const Notifier = () => {
  const { notifier } = useContext(UiStore)
  return useObserver(() => (
    <Snackbar
      anchorOrigin={{
        vertical: notifier.position.vertical,
        horizontal: notifier.position.horizontal,
      }}
      open={notifier.open}
      autoHideDuration={notifier.autoHideDuration}
      onClose={notifier.handleClose}
    >
     <NotifierWrapper
      onClose={notifier.handleClose}
      variant={notifier.variant}
      message={notifier.message}
     />
    </Snackbar>
  ))
}


export default Notifier
