import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import ls from 'local-storage'


class UI {
 expandText = false
 toggleExpand = () => { this.expandText = !this.expandText }
}

decorate(UI,{
  expandText:observable
})


const UiStore = createContext(new UI())
export default UiStore
