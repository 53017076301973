import React, { useContext, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'


import {
  Typography,
  Button
} from '@material-ui/core'
import {
  ExitToApp
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  denise: {
    background: '#fff',
    borderRadius: '50%',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center center',
    maxWidth:'60%',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
  },
  alert:{
    display: 'flex',
    padding: '6px 16px',
    fontWeight: 400,
    lineHeight: 1.43,
    borderRadius: 4,
    letterSpacing: '0.01071em',
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
    marginTop:theme.spacing(4)
  },
  toSaiBtn: {
    margin:'0 auto',
    marginTop:theme.spacing(4)
  },
  btnColor:{
  },
}));


const Helpdesk = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { kibsmeta } = useContext(DataStore)
  const { utilityText } = useContext(UiStore)

  const _toSai = () => {
    const win = window.open('https://www.swisscom.ch/de/schulen-ans-internet/sai-anschluss.html', '_blank');
    win.focus()
  }

  useEffect(utilityText.getTextHelpdesk,[])

  return (
    <section id="helpdesk" className="digital-experience-area ptb-100">
      <div className="container-fluid">
        <div className="section-title">
          <h2>{t('core.landingpage.helpdesk.title')}</h2>
          <div className="bar" />
        </div>
        <div className="tab digital-experience-tab">
          <div className="tab_content">
            <div className="tabs_item">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="digital-experience-content">
                    <h1>{t('core.landingpage.helpdesk.intro')}</h1>
                    <p></p>
                    <div className="experience-inner-content">
                      <h3><a href={"tel:"+kibsmeta.telefon}>{kibsmeta.telefon}</a></h3>
                      <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html: utilityText.textHelpdesk.toString('html') }} />
                    </div>
                    <div className="experience-inner-content">
                      <h3><a href={"mailto:"+kibsmeta.mail}>{kibsmeta.mail}</a></h3>
                      <Typography variant="h6" component="p">{t('core.landingpage.helpdesk.mail')}{" "}</Typography>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="digital-experience-image" >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {new Date().getMonth() === 11 && <Typography variant="h5" className={classes.alert}>{t('core.landingpage.helpdesk.xmas',{year:new Date().getFullYear(),nextYear:(new Date().getFullYear()+1)})}</Typography>}
        <div className={classes.toSaiBtn}>
         <Button variant="contained" onClick={_toSai} className={`${classes.btnColor} phButton`}>
          {t('core.landingpage.helpdesk.sai')}{" "}
         </Button>
        </div>
      </div>
    </section>
 )
})

export default Helpdesk
