import React, {useEffect,useContext} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import IMG_DISCORD from 'media/img/discord.png'

import {
  Button,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  toDiscordBtn: {
    marginTop:theme.spacing(4)
  },
  image:{
    maxWidth:'100%'
  },
  btnColor:{
  },

}))


const Discord = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const data = useContext(DataStore)
  const { utilityText } = useContext(UiStore)
  const { kibsmeta } = data

  useEffect(utilityText.getTextCommunity,[])

  return (
    <section id="discord" className="subscribe-area ptb-100" style={{background:'none'}}>
      <div className="container-fluid">
        <div className="subscribe-content">
        <img src={IMG_DISCORD} alt="discord" className={classes.image} />
          <h1>{t('core.landingpage.discord.title')}</h1>
          <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html: utilityText.textCommunity.toString('html') }} />
          <div className={classes.toDiscordBtn}>
           <Button variant="contained" href={kibsmeta.discord} className={`${classes.btnColor} phButton`}>
            {t('core.landingpage.discord.takePart')}
           </Button>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Discord
