import React, { useEffect,useContext,useRef } from 'react'
import { useObserver,observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import { getContrast } from 'shared/functions/helper'
import DataStore from './stores/data'
import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'
import RichTextEditor from 'react-rte-link-extended'
import { ChromePicker,CirclePicker } from 'react-color'
import { Link } from 'react-router-dom'
import { getSchoolShortname } from 'shared/functions/helper'


import {
  Badge,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Typography,
  Grid,
  Button,
  TextField,
  CardMedia,
  Input,
  IconButton,
  InputAdornment,
  Paper,
  Chip,
  Avatar,
  Popover,
  Tabs,
  Tab,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Dialog,
} from '@material-ui/core/'

import {
  ExpandMore,
  Add,
  TouchApp
} from '@material-ui/icons/'

import {
  blue
} from '@material-ui/core/colors'

import KibsDropzone from 'shared/components/dropzone/index'
import Suggestion from './Suggestion'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth:theme.breakpoints.values.md,
    margin:'0 auto',
    minHeight:'80vh'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  fab:{
    margin:theme.spacing(1)
  },
  input: {
    margin: theme.spacing(1),
  },
  bannerContainer:{
    width:'100%'
  },
  banner:{
    width:'100%',
    height:300,
    maxHeight:300,
    backgroundSize:'cover'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContainer:{
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
    position:'relative'
  },
  catalogUrl:{
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2),
  },
  touchApp:{
    transform: 'rotate(320deg)',
    position:'absolute',
    fontSize:'4rem',
    top:15,
    left:30,
    zIndex:1,
    color:'#ffe0bd'
  },
  circlePicker:{
    padding:theme.spacing(1)
  },
  chromepicker:{
    width:'100% !important'
  },
  badge: {
    padding:theme.spacing(0, 2)
  },
  rte:{
    minHeight:300
  },
  about:{
    display:'flex',
    flexDirection:'column'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const AdminConfig = observer(() => {
  const ui = useContext(UiStore)
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)

  const handleChange = (e,newTab) => {
    ui.setSelectedTab(newTab)
  }

  return (
    <div>
      <Tabs
        value={ui.selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label={t('catalog.admin.configuration')} />
        <Tab
         label={
          <Badge className={classes.badge} color="secondary" badgeContent={config.pendings.length}>
           {t('catalog.admin.pendingExamples')}
          </Badge>
         }
        />
      </Tabs>
      <TabPanel value={ui.selectedTab} index={0}>
       <Configurator />
      </TabPanel>
      <TabPanel value={ui.selectedTab} index={1}>
       <Pendings />
      </TabPanel>
    </div>
  )
})

const Pendings = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config,catalog } = useContext(DataStore)
  const ui = useContext(UiStore)

  const getPendingExample = exampleId => event => {
    config.getPendingExample(exampleId,() => {
      ui.handleOpenUpdateSuggestion()
    })
  }

  return (
   <List component="nav">
    {config.pendings.map((p,i) => {
     return <Box key={'penginds-'+i} boxShadow={2}>
     <ListItem button onClick={getPendingExample(p.id)}>
      <ListItemAvatar>
       <Avatar style={{backgroundColor:blue[500]}}> {getSchoolShortname(p.title)} </Avatar>
      </ListItemAvatar>
      <ListItemText primary={p.title} />
     </ListItem>
     </Box>
    })}
    {config.currentSuggestion && <UpdateSuggestionDialog example={config.currentSuggestion} />}
   </List>
  )
})

const UpdateSuggestionDialog = observer((props) => {
  const ui = useContext(UiStore)
  const { config } = useContext(DataStore)

  const onFinish = () => {
    config.removePending(props.example.id)
    ui.handleCloseUpdateSuggestion()
  }

  return (
    <Dialog onClose={ui.handleCloseUpdateSuggestion} aria-labelledby="update-suggestion-title" open={ui.updateSuggestionOpen}>
     <Container maxWidth={"sm"}>
      <Suggestion
       mode={'update'}
       example={props.example}
       allCategories={config.categories}
       catalogName={config.theme}
       catalogId={config.id}
       onFinish={onFinish}
      />
     </Container>
    </Dialog>
  )
})

const Configurator = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)
  const { notifier } = useContext(GlobalUiStore)
  const ui = useContext(UiStore)

  const [debouncedSave] = useDebouncedCallback(
   () => {
     config.save()
     notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
   },
   config.DEBOUNCE_TIMER
  )

  useEffect(debouncedSave,[
    config.heading,
    config.banner,
    config.teaser,
    config.primaryColor,
    config.secondaryColor,
    config.exampleLink,
    config.about
  ])

  return (
    <Grid container justify="center" alignItems="center">
     <Grid item xs={12}>
      <div className={classes.root}>
       <HowToGetYourCatalog />
       <ConfigItem
        defaultExpanded={true}
        heading={t('catalog.admin.heading')}
        children={<ConfigCustomThemeName />}
       />
       <ConfigItem
        heading={t('catalog.admin.teaser')}
        children={<ConfigTeaser />}
       />
       <ConfigItem
        heading={t('catalog.admin.categories')}
        children={<ConfigCategories />}
        display={'block'}
        onChange={ui.toggleShowTouchAppIcon}
       />
       <ConfigItem
        heading={t('catalog.admin.colorScheme')}
        children={<ConfigColorScheme />}
       />
       <ConfigItem
        heading={t('catalog.admin.exampleLink')}
        children={<ConfigExampleLink />}
       />
       <ConfigItem
        heading={t('catalog.admin.about')}
        children={<ConfigAbout />}
       />
       <ConfigItem
        heading={t('catalog.admin.banner')}
        children={<ConfigBanner />}
       />
      </div>
    </Grid>
   </Grid>
 )
})

const HowToGetYourCatalog = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)

  return (
   <div>
    <Typography variant="body1"> {t('catalog.admin.howToGetYourCatalog',{theme:config.theme})} </Typography>
    <Typography variant="body1"> {t('catalog.admin.howToInsertExamples')} </Typography>
    <Button className={classes.catalogUrl} variant="outlined" fullWidth>
     <Link to={config.catalogUrl}>{t('catalog.admin.catalog')} {config.theme}</Link>
    </Button>
   </div>
  )
}


const ConfigItem = observer((props) => {
  const classes = useStyles()
  let { onChange,display,defaultExpanded, heading, subHeading, children} = props
  heading = heading ? heading : ''
  subHeading = subHeading ? subHeading : ''
  display = display ? display : 'flex'
  onChange = onChange ? onChange : ()=>{}

  return (
   <ExpansionPanel defaultExpanded={defaultExpanded} onChange={onChange}>
    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
    {heading}
    {subHeading}
    </ExpansionPanelSummary>
    <ExpansionPanelDetails style={{ display }}>
     {children}
    </ExpansionPanelDetails>
   </ExpansionPanel>
 )
})

const ConfigCustomThemeName = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)

  const onChange = (e) => {
    config.setHeading(e.target.value)
  }

  return (
    <TextField
      id={'customthemename'}
      className={classes.textField}
      value={config.heading}
      onChange={onChange}
      helperText={t('catalog.admin.headingHint')}
      variant="filled"
      fullWidth
    />
  )
})

const ConfigExampleLink = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)

  const onChange = (e) => {
    config.setExampleLink(e.target.value)
  }

  return (
    <TextField
      id={'examplelink'}
      className={classes.textField}
      value={config.exampleLink}
      onChange={onChange}
      helperText={t('catalog.admin.exampleLink')}
      variant="filled"
      fullWidth
    />
  )
})

const ConfigAbout = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)

  const onChange = (value) => {
    config.setAbout(value)
  }

  return (
   <div className={classes.about}>
    <RichTextEditor
     value={config.about}
     onChange={onChange}
    />
    <Typography component="p" variant="caption">{t('catalog.admin.aboutHint')}</Typography>
   </div>
  )
})

const ConfigTeaser = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)

  const onChange = (e) => {
    config.setTeaser(e.target.value)
  }

  return (
    <TextField
      id={'config-teaser'}
      className={classes.textField}
      value={config.teaser}
      onChange={onChange}
      multiline
      rows={6}
      helperText={t('catalog.admin.teaserHint')}
      variant="filled"
      fullWidth
    />
  )
})

const ConfigColorScheme = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)
  const ui = useContext(UiStore)

  const setColor = (color,event) => {
    if (config.tempColorScheme === 'primary') {
     config.setPrimaryColor(color.hex)
    }
    else if (config.tempColorScheme === 'secondary') {
      config.setSecondaryColor(color.hex)
    }
  }

  const editColorScheme = scheme => event => {
    config.setTempColorScheme(scheme)

    ui.setEditColorSchemeAnchorEl(event.currentTarget)
    ui.handleOpenColorScheme()
  }

  const pC = config.primaryColor && config.primaryColor !== "" ? getContrast(config.primaryColor) : 'black'
  const sC = config.secondaryColor && config.secondaryColor !== "" ? getContrast(config.secondaryColor) : 'black'

  return (
   <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
     <Button
      fullWidth
      variant="outlined"
      style={{ color:pC,backgroundColor:config.primaryColor }}
      onClick={editColorScheme('primary')}> {t('catalog.admin.primaryColor')} </Button>
    </Grid>
    <Grid item xs={12} sm={6}>
     <Button
      fullWidth
      variant="outlined"
      style={{ color:sC,backgroundColor:config.secondaryColor }}
      onClick={editColorScheme('secondary')}> {t('catalog.admin.secondaryColor')} </Button>
    </Grid>
    <Popover
      id={'edit-category-chip'}
      open={ui.editColorSchemeOpen}
      anchorEl={ui.editColorSchemeAnchorEl}
      onClose={ui.handleCloseColorScheme}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
     <div className={classes.circlePicker}>
      <CirclePicker
       color={config.tempColorScheme === 'primary' ? config.primaryColor : config.secondaryColor}
       onChangeComplete={setColor}
      />
     </div>
    </Popover>
   </Grid>
  )
})

const ConfigBanner = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)
  const globalUi = useContext(GlobalUiStore)

  const onFiles = (files) => {
   if (files.length > 0) {
    globalUi.setLoading(true)
    config.saveBanner(files,() => {
     globalUi.setLoading(false)
    })
   }
  }

  return (
   <div className={classes.bannerContainer}>
    {config.banner && <CardMedia className={classes.banner} image={config.banner} />}
    <KibsDropzone
     accept="image/*"
     multiple={false}
     onFiles={onFiles}
     showThumbnails={false}
    />
   </div>
  )
})

const ConfigCategories = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)
  const { notifier } = useContext(GlobalUiStore)

  const onChange = (e) => {
    config.setTempCategoryName(e.target.value)
  }

  const addCategory = (e) => {
    config.createCategory(
      () => { notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT) },
      () => {}
    )
  }

  return (
   <>
     <Input
      id="add-category-input"
      type="text"
      fullWidth
      value={config.tempCategoryName}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={addCategory}
          >
           <Add />
          </IconButton>
        </InputAdornment>
      }
     />
    <Typography variant="caption">{t('catalog.admin.filterCategories')}</Typography>
    <Categories />
   </>
  )
})

const Categories = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { config } = useContext(DataStore)
  const { notifier } = useContext(GlobalUiStore)
  const touchIcon = useRef(null)

  const ui = useContext(UiStore)
  const categories = config.categories
  const currentCategory = config.currentCategory

  const removeCategory = category => event => {
    config.removeCategory(
     category,
     () => { notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT) },
     () => { }
    )
  }

  const editCategory = category => event => {
    config.setCurrentCategory(category)

    ui.setEditCategoryAnchorEl(event.currentTarget)
    ui.handleOpenCategory()
  }

  useEffect(() => {
    if (touchIcon.current) {
      touchIcon.current.addEventListener('animationend',() => {
        console.log('animation ended');
      })
    }
  },[touchIcon.current])

  return (
    <Paper className={classes.chipContainer} elevation={0}>
     {categories.map((category,i) => {
       let icon = null
       let backgroundColor = category.color ? category.color : '#fff'
       const color = getContrast(category.color)
       if (category.icon) {
         icon = <Avatar src={category.icon} style={{ backgroundColor,color }}/>
       } else {
         icon = <Avatar style={{ backgroundColor,color,border:'1px solid' }}> {category.name.charAt(0).toUpperCase()} </Avatar>
       }

       return <Chip
        avatar={icon}
        variant="outlined"
        onClick={editCategory(category)}
        onDelete={removeCategory(category)}
        key={'catagory-'+category.id}
        label={category.name}
        className={classes.chip}
        style={{ backgroundColor,color }}
       />
      })}

      <Popover
        id={'edit-category-chip'}
        open={ui.editCategoryOpen}
        anchorEl={ui.editCategoryAnchorEl}
        onClose={ui.handleCloseCategory}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
       <EditCategoryMenu />
      </Popover>
     </Paper>
  )
})


const EditCategoryMenu = observer(() => {
  const classes = useStyles()
  const { config } = useContext(DataStore)
  const { notifier } = useContext(GlobalUiStore)
  const { t } = useTranslation()
  const category = config.currentCategory
  const globalUi = useContext(GlobalUiStore)

  const setName = (e) => {
    category.setName(e.target.value)
  }
  const setColor = (color,event) => {
   category.setColor(color.hex)
  }

  const [debouncedSave] = useDebouncedCallback(
   () => {
     config.save()
     notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
   },
   config.DEBOUNCE_TIMER
  )

  const onFiles = (files) => {
   if (files.length > 0) {
    globalUi.setLoading(true)
    config.saveCategoryIcon(files,category.id,() => {
     globalUi.setLoading(false)
    })
   }
  }

  useEffect(debouncedSave,[
   category.name,
   category.color
  ])

  return (
   <>
    {category &&
     <>
      <Input
       id="edit-category-name"
       type="text"
       fullWidth
       autoFocus
       value={category.name}
       onChange={setName}
      />
      <ChromePicker color={category.color} onChangeComplete={setColor} className={classes.chromepicker}/>
      <KibsDropzone
       accept="image/*"
       multiple={false}
       onFiles={onFiles}
       showThumbnails={false}
      />
     </>
    }
  </>
 )
})





export default AdminConfig
