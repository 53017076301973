import React, {useEffect,useContext} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import UiStore from 'core/stores/ui'
import moment from 'moment'

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Container,
  Typography,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  ListItemIcon,
  useMediaQuery
} from '@material-ui/core'
import {
  ArrowRight,
  PictureAsPdf,
  ExitToApp,
  ExpandMore,
  Menu,
  GetApp
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
 root:{
   display:'flex',
   flexDirection:'row',
   marginBottom:theme.spacing(5)
 },
 heading:{
   margin:'0 auto',
   textAlign:'center',
   padding:theme.spacing(2)
 },
 sidebarEntry:{
   color:'#666'
 },
 expansionPanelHeading:{
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
},
 sidebar:{
   position:"fixed",
   top: 70,
   width: 240,
   padding: '16px 16px 16px 0px',
   position: 'sticky',
   marginTop: 70,
   overflowY: 'auto',
   flexShrink: 0
 },
 content:{
   marginTop:70,
   paddingLeft:theme.spacing(5)
 },
 servicesTable:{
   minWidth: 650,
 },
 sidebarMenuButton:{
  position:'absolute'
},

}))

const SAI = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()


  return (
   <>
    <div style={{
      backgroundImage: "url(//uploader.kibs.ch/core/sai/sai-anschluss.jpg)",
      backgroundPosition: 'center 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 320px',
      minHeight: 320
    }} />
    <Typography variant="h3" className={classes.heading}> {t('core.sai.title')} </Typography>
    <Container maxWidth="md" className={classes.root}>
     <Sidebar />
     <Content />
    </Container>
   </>
  )
})

const Sidebar = observer(() => {
  const classes = useStyles()
  const { saiSidebarUI } = useContext(UiStore)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    isMobile
    ?
    <DesktopSidebar />
    :
    <>
     <IconButton className={classes.sidebarMenuButton} onClick={saiSidebarUI.handleOpen}><Menu/></IconButton>
     <MobileSidebar />
    </>
  )
})

const DesktopSidebar = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { saiSidebarUI } = useContext(UiStore)

  const _setContent = (view) => saiSidebarUI.setView(view)

  return (
   <div className={classes.sidebar}>
    <List component="nav" aria-label="main mailbox folders">
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.SAI) }}> <ListItemText className={classes.sidebarEntry} primary={t('core.sai.title')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.PRECONDITIONS) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.preconditions')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.SERVICES) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.services.title')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.CONNECTIONON) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.connectionOn')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.CONNECTIONOFF) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.connectionOff')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.CERTIFICATE) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.certificate.title')} /> </ListItem>
    </List>
   </div>
  )
})

const MobileSidebar = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { saiSidebarUI } = useContext(UiStore)

  const _setContent = (view) => saiSidebarUI.setView(view)
  return (
    <Drawer open={saiSidebarUI.open} onClose={saiSidebarUI.handleClose}>
    <List component="nav" aria-label="main mailbox folders">
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.SAI) }}> <ListItemText className={classes.sidebarEntry} primary={t('core.sai.title')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.PRECONDITIONS) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.preconditions')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.SERVICES) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.services.title')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.CONNECTIONON) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.connectionOn')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.CONNECTIONOFF) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.connectionOff')} /> </ListItem>
     <ListItem button onClick={() => { _setContent(saiSidebarUI.views.CERTIFICATE) }}> <ListItemText className={classes.sidebarEntry}  primary={t('core.sai.certificate.title')} /> </ListItem>
    </List>
    </Drawer>
  )
})

const Content = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { saiSidebarUI } = useContext(UiStore)

  return (
    <div className={classes.content}>
     {saiSidebarUI.view === saiSidebarUI.views.SAI && <Intro />}
     {saiSidebarUI.view === saiSidebarUI.views.PRECONDITIONS && <Preconditions />}
     {saiSidebarUI.view === saiSidebarUI.views.SERVICES && <Services />}
     {saiSidebarUI.view === saiSidebarUI.views.CONNECTIONON && <ConnectionOn />}
     {saiSidebarUI.view === saiSidebarUI.views.CONNECTIONOFF && <ConnectionOff />}
     {saiSidebarUI.view === saiSidebarUI.views.CERTIFICATE && <Certificate />}
    </div>
  )
})

const Intro = observer(() => {
  const { t } = useTranslation()

    const _toSai = () => {
      const win = window.open('https://www.swisscom.ch/de/schulen-ans-internet/sai-anschluss.html', '_blank');
      win.focus()
    }
  return (
   <>
    <Typography variant="subtitle1"> {t('core.sai.headline')} </Typography>
    <Typography variant="subtitle1"> {t('core.sai.subheader')} </Typography>
    <List>
      <ListItem button onClick={_toSai}>
       <ListItemIcon><ExitToApp /></ListItemIcon>
       <ListItemText primary={t('core.landingpage.helpdesk.sai')} />
      </ListItem>
    </List>
   </>
  )
})

const Preconditions = observer(() => {
  const { t } = useTranslation()
  return (
    <Typography variant="subtitle1">
     {t('core.sai.preconditionsText')}
    </Typography>
  )
})

const Certificate = observer(() => {
  const { t } = useTranslation()

  const toMobileDL = () => {
   const a = document.createElement('a')
   a.href = "//uploader.kibs.ch/core/sai/ZscalerRootCertificate-2048-SHA256.cer"
   a.download = true
   a.click()
  }

  const toArchive = () => {
    const win = window.open('https://www.swisscom.ch/content/dam/swisscom/de/sai-new/internetzugang/documents/ssl-guide-and-certificates.zip', '_blank');
    win.focus()
  }


  return (
    <List component="nav" aria-label="connectionon steps">
     <ListItem button onClick={toMobileDL}>
      <ListItemIcon><GetApp /></ListItemIcon>
      <ListItemText
       primary={t('core.sai.certificate.mobileDL')}
       secondary={t('core.sai.certificate.mobileDLContent')}
      />
     </ListItem>
     <ListItem button onClick={toArchive}>
      <ListItemIcon><GetApp /></ListItemIcon>
      <ListItemText
       primary={t('core.sai.certificate.desktopDL')}
       secondary={t('core.sai.certificate.desktopDLContent')}
      />
     </ListItem>
    </List>
  )
})

const ConnectionOff = observer(() => {
  const { t } = useTranslation()
  return (
   <>
    <Typography variant="subtitle1">
     {t('core.sai.connectionOFF.headline')}
    </Typography>
    <List component="nav" aria-label="connectionon steps">
     <ListItem button>
      <ListItemText primary={"• " + t('core.sai.connectionOFF.point1')} />
     </ListItem>
     <ListItem>
      <ListItemText primary={"• " + t('core.sai.connectionOFF.point2')} />
     </ListItem>
     <ListItem>
      <ListItemText primary={"• " +  t('core.sai.connectionOFF.point3')} />
     </ListItem>
     <ListItem>
      <ListItemText primary={"• " +  t('core.sai.connectionOFF.point4')} />
     </ListItem>
    </List>
    <Typography variant="subtitle1">
     {t('core.sai.connectionOFF.note')}
    </Typography>
   </>
  )
})

const ConnectionOn = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
   <>
   <List component="nav" aria-label="connectionon steps">
    <ListItem button>
     <ListItemIcon>1.</ListItemIcon>
     <ListItemText
      primary={t('core.sai.connectionON.first.primary')}
      secondary={t('core.sai.connectionON.first.secondary')}
     />
    </ListItem>
    <ListItem button>
     <ListItemIcon>2.</ListItemIcon>
     <ListItemText
      primary={t('core.sai.connectionON.second.primary')}
      secondary={t('core.sai.connectionON.second.secondary')}
     />
    </ListItem>
    <ListItem button>
     <ListItemIcon>3.</ListItemIcon>
     <ListItemText
      primary={t('core.sai.connectionON.third.primary')}
      secondary={t('core.sai.connectionON.third.secondary')}
     />
    </ListItem>
   </List>

   <ExpansionPanel>
     <ExpansionPanelSummary
       expandIcon={<ExpandMore />}
       aria-controls="further"
       id="further-header"
     >
       <Typography className={classes.expansionPanelHeading}>{t('core.sai.connectionON.progress.title')}</Typography>
     </ExpansionPanelSummary>
     <ExpansionPanelDetails>
       <List>
        <ListItem>
         <ListItemText
          primary={t('core.sai.connectionON.progress.first.primary')}
          secondary={t('core.sai.connectionON.progress.first.secondary')}
         />
        </ListItem>
        <ListItem>
         <ListItemText
          primary={t('core.sai.connectionON.progress.second.primary')}
          secondary={t('core.sai.connectionON.progress.second.secondary')}
         />
        </ListItem>
        <ListItem>
         <ListItemText
          primary={t('core.sai.connectionON.progress.third.primary')}
          secondary={t('core.sai.connectionON.progress.third.secondary')}
         />
        </ListItem>
        <ListItem>
         <ListItemText
          primary={t('core.sai.connectionON.progress.fourth.primary')}
          secondary={t('core.sai.connectionON.progress.fourth.secondary')}
         />
        </ListItem>
        <ListItem>
         <List subheader={
          <ListSubheader component="div" id="nested-list-subheader">
           {t('core.sai.connectionON.progress.fifth.primary')}
          </ListSubheader>
          }>
          <ListItem>
           <ListItemText secondary={t('core.sai.connectionON.progress.fifth.secondary.point1')}/>
          </ListItem>
          <ListItem>
           <ListItemText secondary={t('core.sai.connectionON.progress.fifth.secondary.point2')}/>
          </ListItem>
         </List>
        </ListItem>
       <ListItem>
        <ListItemText
         primary={t('core.sai.connectionON.progress.sixth.primary')}
         secondary={t('core.sai.connectionON.progress.sixth.secondary')}
        />
       </ListItem>
      </List>
     </ExpansionPanelDetails>
   </ExpansionPanel>

   </>
  )
})

const Services = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()

  const _toPricelist = () => {
    window.open(t('core.sai.services.pricinglistLink'),'_blank')
  }

  return (
   <>
     <TableContainer component={Paper}>
      <Table className={classes.servicesTable} aria-label="services table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="right">{t('core.sai.services.formular.title')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         <TableRow>
          <TableCell align="left">{t('core.sai.services.service_1.name')}</TableCell>
          <TableCell align="left">{t('core.sai.services.service_1.hint')}</TableCell>
          <TableCell align="right">
           <IconButton href={t('core.sai.services.service_1.formular')} target="_blank">
            <PictureAsPdf />
           </IconButton>
          </TableCell>
        </TableRow>
       </TableBody>
      </Table>
    </TableContainer>
    <List component="nav" aria-label="main mailbox folders">
     <ListItem button onClick={_toPricelist}>
      <ListItemIcon><ExitToApp /></ListItemIcon>
      <ListItemText primary={t('core.sai.services.pricinglist')} />
     </ListItem>
    </List>
   </>
  )
})



export default SAI
