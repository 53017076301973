import React, { useEffect, useContext } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import DataStore from 'core/stores/data'
import moment from 'moment'
import Linkify from 'react-linkify'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import {
  Typography,Divider,Container,IconButton
} from '@material-ui/core'

import {
  Twitter,Add,Facebook
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
 divider:{
   marginTop:theme.spacing(10),
   marginBottom:theme.spacing(10)
 },
 container:{
   paddingTop:theme.spacing(5),
   textAlign:'center'
 },
 twitter:{
   backgroundColor: '#1da1f2',
   borderColor: '#1da1f2',
   color: '#ffffff',
   margin:theme.spacing(2)
 },
 facebook:{
   backgroundColor: '#3b5998',
   borderColor: '#3b5998',
   color: '#ffffff',
   margin:theme.spacing(2)
 },
 socialMediaContainer:{
   padding:theme.spacing(10)
 }
}))

const NewsTimeline = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { news,kibsmeta } = useContext(DataStore)

  useEffect(() => {
    news.tweets.length = 0
    news.getNews()
  },[])

  var linkifyOptions =
  {
    formatHref: function (href, type) {
      if (type === 'hashtag') {
        href = 'https://twitter.com/hashtag/' + href.substring(1);
      }
      return href;
    }
  }

  return (
   <>
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.socialMediaContainer}>
      <IconButton className={classes.twitter} href={kibsmeta.twitter}><Twitter /></IconButton>
      <IconButton className={classes.facebook} href={kibsmeta.facebook}><Facebook /></IconButton>
     </div>
     <Typography variant="h5">{t('core.news.followUs')}</Typography>
     <Divider className={classes.divider} />
    </Container>

    <VerticalTimeline>
     {news.tweets.map((t,i) => {

       return <VerticalTimelineElement
         key={'tweet-'+i}
         className="vertical-timeline-element--work"
         date={moment(t.created_at).fromNow()}
         iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
         icon={<Twitter />}
       >
         <p><Linkify options={linkifyOptions}>{t.text}</Linkify></p>
         {t.extended_entities && <ExtendedEntities media={t.extended_entities.media} />}
       </VerticalTimelineElement>
     })}
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<Add />}
      />
    </VerticalTimeline>
   </>
  )
})


const ExtendedEntities = observer((props) => {
  return (
    props.media && props.media.map((m,i) => {
      if (m.type === 'photo') return <img src={m.media_url_https} alt={m.type} style={{maxWidth:'100%'}} />
      return null
    })
  )
})

export default NewsTimeline
