import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import Ajax from 'api/ajax'

class Data {


}

decorate(Data,{

})

const DataStore = createContext(new Data())
export default DataStore
