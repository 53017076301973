import React, { useEffect,useContext } from 'react'
import { useObserver,observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import DataStore from './stores/data'
import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'

import {
  Typography,
  Grid,
  Fab,
  Divider,
  Tooltip,
  IconButton,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
  Input,
  DialogContent
} from '@material-ui/core/'

import {
  Add
} from '@material-ui/icons/'

import AdminConfig from './AdminConfig'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth:theme.breakpoints.values.md,
    margin:'0 auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  fab:{
    margin:theme.spacing(1)
  },
  input: {
    margin: theme.spacing(1),
  },
  divider:{
    width:'100%',
    margin:theme.spacing(1)
  },
  boxShadowOn:{
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.1)'
  },
  boxShadowOff:{
    boxShadow:'none'
  }
}));


const Admin = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { themes } = useContext(DataStore)
  const ui = useContext(UiStore)
  const data = useContext(DataStore)
  const { config } = useContext(DataStore)
  const globalUi = useContext(GlobalUiStore)

  useEffect(() => {
    globalUi.setLoading(true)
    data.getAllCatalogThemes(
      () => { globalUi.setLoading(false) },
      () => { globalUi.setLoading(false) }
    )
  },[])

  const getConfig = id => event => {
    data.getConfig(id)
  }

  return (
    <Grid container justify="center" alignItems="center">
     <Grid item xs={12}>

      <div className={classes.root}>

        <Tooltip title={t('catalog.admin.createNewCatalog')}>
         <IconButton onClick={ui.handleOpenCreateTheme} color="primary" aria-label="add-catalog" className={classes.fab}>
          <Add />
         </IconButton>
        </Tooltip>

        {themes.map((t,i) => {
         let boxShadow = classes.boxShadowOn
         if (config && config.id === t.id) {
           boxShadow = classes.boxShadowOff
         }
         return <Fab
          onClick={getConfig(t.id)}
          key={'theme-'+i}
          variant="extended"
          aria-label="like"
          color="secondary"
          className={`${classes.fab} ${boxShadow}`}> {t.theme} </Fab>
        })}

      </div>

     </Grid>
     <CreateThemeDialog />
     <Divider className={classes.divider}/>
     {data.config && <AdminConfig />}
    </Grid>
  )
})

const CreateThemeDialog = observer(() => {
  const classes = useStyles()
  const ui = useContext(UiStore)
  const data = useContext(DataStore)
  const { t } = useTranslation()
  const globalUi = useContext(GlobalUiStore)
  const { notifier } = useContext(GlobalUiStore)

  const onClick = (e) => {
    globalUi.setLoading(true)
    data.createTheme(
      () => {
       globalUi.setLoading(false)
       data.setTheme('')
       ui.handleCloseCreateTheme()
      },
      () => {
        globalUi.setLoading(false)
        notifier.push(t('core.notifier.themeExists'),notifier.variants.ERROR)
      }
    )
  }
  const onChange = (e) => {
    data.setTheme(e.target.value)
  }
  return (
    <Dialog onClose={ui.handleCloseCreateTheme} aria-labelledby="simple-dialog-title" open={ui.createThemeOpen}>
     <DialogTitle id="simple-dialog-title">{t('catalog.admin.nameOfURL')}</DialogTitle>
     <DialogContent>
       <Input
         fullWidth
         className={classes.input}
         value={data.theme}
         onChange={onChange}
         inputProps={{
           'aria-label': 'description',
         }}
       />
     </DialogContent>
     <DialogActions>
       <Button onClick={ui.handleCloseCreateTheme} color="secondary">
         {t('core.general.close')}
       </Button>
       <Button onClick={onClick} color="primary" autoFocus>
         {t('core.general.create')}
       </Button>
     </DialogActions>
    </Dialog>
  )
})



export default Admin
