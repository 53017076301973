import React, { useContext,useEffect,useCallback } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import i18next from 'i18n'

import {
  AppBar,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  CircularProgress
} from '@material-ui/core/'

import { Link } from 'react-router-dom'
import { Print, DeleteForever, Close } from '@material-ui/icons/'

import KibsDropzone from 'shared/components/dropzone'
import DataStore from './stores/data'
import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iframe:{
    width:'100%',
    height:'100%',
    border:'none',
    overflow:'auto',
    webkitOverflowScrolling:'touch'
  },
  progressContainer:{
    position:'absolute',
    margin: '0 auto',
    top: '50%',
    left:'50%',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
  lastUpdate:{
    textAlign:'right',
    marginTop:theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize:'0.75rem',
    minHeight: '1em',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.03333em'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfiguratorActions = observer(() => {
 const classes = useStyles()
 const { t } = useTranslation()
 const { configuratorActions } = useContext(UiStore)
 const { flyerManager } = useContext(DataStore)
 const flyer = flyerManager.current

 return (
  <Container maxWidth="md" style={{marginTop:25}}>
   <Grid container spacing={2}>
    <Grid item xs={12} sm={3}>
    <Button
     fullWidth
     variant="outlined"
     startIcon={<DeleteForever />}
     color="secondary"
     onClick={configuratorActions.handleOpenDelete}> {t('core.general.delete')} </Button>
    </Grid>
    <Grid item xs={12} sm={9}>
    <Button
     fullWidth
     variant="outlined"
     color="primary"
     onClick={configuratorActions.handleOpenPreview}> {t('core.general.preview')} / {t('core.general.print')} </Button>
    </Grid>
   </Grid>
   <PDFPreview />

   <Typography variant="caption" display="block" gutterBottom className={classes.lastUpdate}>
    {t('ampelflyer.configurator.lastUpdate',{
      name:flyer.from.vorname + ' ' + flyer.from.name
     }
    )}
   </Typography>

  </Container>
 )
})



const PDFPreview = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { configuratorActions,pdf } = useContext(UiStore)
  const globalUI = useContext(GlobalUiStore)
  const { flyerManager } = useContext(DataStore)
  const flyer = flyerManager.current

  const params = '?schulhaus='+flyerManager.schulhaus.erz_key + '&ampelflyer=' + flyer.id + '&lng=' + i18next.language
  const previewLink = '/datenschutz/ampelflyer/pdf' + params
  const onLoad = () => { configuratorActions.setIframeLoading(false) }

  const print = () => {
    globalUI.setLoading(true)
    pdf.print(
      flyer.id,
      flyerManager.schulhaus.erz_key,
      flyer.title,
      i18next.language,
      () => { globalUI.setLoading(false) },
      () => { globalUI.setLoading(false) }
    )
  }

  useEffect(() => {
    return () => { configuratorActions.setIframeLoading(true) }
  },[])

  return (
    <Dialog fullScreen open={configuratorActions.openPreview} onClose={configuratorActions.handleClosePreview} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={configuratorActions.handleClosePreview} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('core.general.preview')}
          </Typography>
           <Button onClick={print} color="inherit" startIcon={<Print />}>
            {t('core.general.print')}
           </Button>
        </Toolbar>
      </AppBar>
      {configuratorActions.iframeLoading &&
        <div className={classes.progressContainer}>
         <CircularProgress />
        </div>
      }
     <iframe onLoad={onLoad} src={previewLink} className={classes.iframe}></iframe>
   </Dialog>
  )
})

export const DeleteFlyerDialog = observer(() => {
  const { t } = useTranslation()
  const { configuratorActions,sheets } = useContext(UiStore)
  const { flyerManager } = useContext(DataStore)
  const flyer = flyerManager.current

  const deleteFlyer = () => {
    flyerManager.deleteFlyer(
      () => {
        configuratorActions.handleCloseDelete()
        if (flyerManager.flyer && flyerManager.flyer.length > 0) {
          const nextFlyerIndex = flyerManager.flyer.length -1
          sheets.select(nextFlyerIndex)
          flyerManager.switchFlyer(nextFlyerIndex)
        }
      },
      () => { configuratorActions.handleCloseDelete() }
    )
  }

  return (
  <Dialog open={configuratorActions.openDelete} onClose={configuratorActions.handleCloseDelete} aria-labelledby="add-service-dialog">
    <DialogTitle id="delete-flyer-title">{t('core.general.delete').toUpperCase()}</DialogTitle>
    <DialogContent>
     {t('ampelflyer.configurator.descriptionDelete',{flyername:flyer.title})}
    </DialogContent>
    <DialogActions>
     <Button onClick={configuratorActions.handleCloseDelete} color="primary">
      {t('core.general.no')}
     </Button>
     <Button onClick={deleteFlyer} color="secondary">
      {t('core.general.yes')}
     </Button>
    </DialogActions>
  </Dialog>
 )
})

export const AddServiceDialog = observer(() => {
  const { t } = useTranslation()
  const { serviceModal } = useContext(UiStore)
  const { flyerManager,newService } = useContext(DataStore)

  const onChange = (e) => newService.setName(e.target.value)
  const createService = () => {
    newService.create(
      flyerManager.schulhaus.erz_key,
      (services) => {
        flyerManager.addServices(services)
        serviceModal.handleClose()
      },
      () => { serviceModal.handleClose() }
    )
  }
  const togglePublic = (e) => { newService.setPublic(e.target.checked) }
  const onFiles = useCallback(newService.setImage)

  return (
    <Dialog open={serviceModal.open} onClose={serviceModal.handleClose} aria-labelledby="add-service-dialog">
      <DialogTitle id="add-service-title">{t('ampelflyer.configurator.addService')}</DialogTitle>
      <DialogContent>
        <KibsDropzone
         accept="image/*"
         multiple={false}
         onFiles={onFiles}
        />
        <TextField
          id="service-name"
          value={newService.name}
          onChange={onChange}
          helperText={t('ampelflyer.configurator.serviceName')}
          type="text"
          fullWidth
        />
        {newService.isSaveable &&
          <FormControlLabel
            value="top"
            control={<Switch color="primary" onChange={togglePublic} value={newService.isPublic} />}
            label={t('ampelflyer.configurator.isPublicDecision')}
            labelPlacement="start"
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={serviceModal.handleClose} color="secondary">
          {t('core.general.close')}
        </Button>

        {newService.isSaveable &&
          <Button onClick={createService} color="primary">
            {t('core.general.create')}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
})
