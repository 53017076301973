import React, {useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'


const Fellows = observer(() => {
return (
   <section id="fellows" className="features-area bg-fff5ee pt-100 pb-70">
    <div className="container">
      <div className="section-title text-width">
        <span>Fellowship</span>
        <h2>Fellows</h2>
        <div className="bar" />
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="single-features">
            <div className="icon">
              <i className="flaticon-promotion" />
            </div>
            <h3>
              <a href="#">Marketing Analysis</a>
            </h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            <div className="number">
              <span>1</span>
            </div>
            <div className="shape">
              <img src="assets/img/features-shape/features-shape-1.png" alt="image" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-features">
            <div className="icon">
              <i className="flaticon-speed" />
            </div>
            <h3>
              <a href="#">Website Optimization</a>
            </h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            <div className="number">
              <span>2</span>
            </div>
            <div className="shape">
              <img src="assets/img/features-shape/features-shape-1.png" alt="image" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-features">
            <div className="icon">
              <i className="flaticon-email" />
            </div>
            <h3>
              <a href="#">Email Marketing</a>
            </h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            <div className="number">
              <span>3</span>
            </div>
            <div className="shape">
              <img src="assets/img/features-shape/features-shape-1.png" alt="image" />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="single-features">
            <div className="icon">
              <i className="flaticon-network" />
            </div>
            <h3>
              <a href="#">Social Media Marketing</a>
            </h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            <div className="number">
              <span>4</span>
            </div>
            <div className="shape">
              <img src="assets/img/features-shape/features-shape-1.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 )
})

export default Fellows
