import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import Ajax from 'api/ajax'

class Data {
  schoolKey
  name = ''
  strasse = ''
  plz = ''
  phones = []
  deletedPhones = []
  standort = ''
  firewalls = []
  providers = []
  softwareSolutions = []
  internetConnections = []
  member = []
  sw = null

  counter = 0
  clientIdMarkedAsToDelete = null

  setData = (data) => {
    const { name,strasse,plz,phones,standort,firewalls,softwareSolutions,providers,internetConnections,member } = data
    if (name) this.setName(name)
    if (strasse) this.setStreet(strasse)
    if (plz) this.setZip(plz)
    if (standort) this.setLocation(standort)
    if (firewalls) this.setFirewalls(firewalls)
    if (softwareSolutions) this.setSoftwareSolutions(softwareSolutions)
    if (providers) this.setProviders(providers)
    if (member) this.setMember(member)
    if (internetConnections) this.internetConnections = internetConnections.map(ic => new InternetConnection(ic,this.getNewCounter()))
    if (this.internetConnections.length === 0) {
      this.addEmptyInternetConnection()
    }
    if (phones && phones.length > 0) {
      this.phones = phones.map((p,i) => {
        p.clientId = i
        return new Phone(p)
      })
    } else {
      this.phones = [new Phone({clientId:this.getNewCounter()})]
    }
  }
  addEmptyInternetConnection = () => {
    this.internetConnections.push(new InternetConnection({},this.getNewCounter()))
  }
  removeInternetConnection = (cbSuccess,cbFail) => {
    const clientId = this.clientIdMarkedAsToDelete
    const filtered = this.internetConnections.filter(ic => ic.clientId === clientId)
    if (filtered && filtered[0]) {
     const ic = filtered[0]
     if (ic.id) {
       this.remove(ic.id,cbSuccess,cbFail)
     }
    }
    this.internetConnections = this.internetConnections.filter(ic => ic.clientId !== clientId)
    if (this.internetConnections.length === 0) {
      this.addEmptyInternetConnection()
    }
  }
  getICByClientId = (clientId) => this.internetConnections.filter(ic => ic.clientId === clientId)[0]
  markAsRemoveable = (clientId) => this.clientIdMarkedAsToDelete = clientId
  setName = (name) => { this.name = name }
  setStreet = (street) => { this.strasse = street }
  setZip = (zip) => { this.plz = zip }
  setLocation = (location) => { this.standort = location }
  setFirewalls = (firewalls) => { this.firewalls = firewalls }
  setSoftwareSolutions = (softwareSolutions) => { this.softwareSolutions = softwareSolutions }
  setProviders = (providers) => { this.providers = providers }
  setMember = (member) => { this.member = member }
  addPhone = () => {
    this.phones = [...this.phones,new Phone({clientId:this.getNewCounter()})]
  }
  removePhone = (clientId) => {
   this.phones.filter(p => {
     if (p.clientId === clientId) {
      if (p.id) {
        p.setOp(p.OPS.DELETE)
        this.deletedPhones.push(p)
      }
     }
   })

   this.phones = this.phones.filter(p => {
     if (p.clientId !== clientId && !p.mustBeDeleted) {
       return p
     }
   })

   const hasOnlyDeletedPhonesLeft = this.phones.filter(p => p.mustBeDeleted).length === this.phones.length
   // if it would be empty, add a new one so user has a field to insert more
   if (hasOnlyDeletedPhonesLeft || this.phones.length === 0) {
     this.addPhone()
   }
  }
  getNewCounter = () => {
    this.counter++
    return this.counter
  }
  getSchooltechData = (schoolKey,cbSuccess,cbFail) => {
    Ajax.getSchooltechData(
      { schoolKey },
      (res) => {
       if (res && res.data && res.data.success) {
         this.schoolKey = schoolKey
         this.setData(res.data.techdata)
       }
       cbSuccess()
      },
      cbFail
    )
  }
  update = (cbSuccess,cbFail) => {
    Ajax.updateSchooltechData(
      {
        schoolKey:this.schoolKey,
        data:this.serialized
      },
      cbSuccess,
      cbFail
    )
  }

  remove = (techId,cbSuccess,cbFail) => {
    Ajax.removeSchooltechData(
      techId,
      this.schoolKey,
      cbSuccess,
      cbFail
    )
  }

  get serialized () {
    const p = this.phones.filter(p => {
      if (p.mustBeDeleted || p.mustBeUpdated || p.mustBeInserted) {
        return p.serialized
      }
    })
    const phones = [...p,...this.deletedPhones]
    return {
      name:this.name,
      strasse:this.strasse,
      phones:phones,
      internetConnections:this.internetConnections.map(ic => ic.serialized)
    }
  }
}

class InternetConnection {
 id
 name
 upload
 download
 ip
 subnetmask
 provider
 firewall
 responsible
 sw
 lastUpdate

 constructor (data,clientId) {
   if (data && clientId) {
     const { id,name,upload,download,ip,subnetmask,responsible,provider,firewall,sw,lastUpdate } = data
     this.id = id ? id : undefined
     this.clientId = clientId
     this.name = name ? name : ''
     this.upload = upload ? upload : 0
     this.download = download ? download : 0
     this.ip = ip ? ip : undefined
     this.subnetmask = subnetmask ? subnetmask : undefined
     this.lastUpdate = lastUpdate ? lastUpdate : undefined
     this.responsible = responsible ? responsible : { id:-1 }
     this.provider = provider ? provider : { id:1 }
     this.firewall = firewall ? firewall : { id:1 }
     this.sw = sw ? sw : { id:1 }
   }
 }
 setName = (name) => { this.name = name }
 setUpload = (upload) => { this.upload = upload }
 setDownload = (download) => { this.download = download }
 setIP = (ip) => { this.ip = ip }
 setSubnetmask = (subnetmask) => { this.subnetmask = subnetmask }
 setProvider = (provider) => { this.provider = provider }
 setFirewall = (firewall) => { this.firewall = firewall }
 setResponsible = (responsible) => { this.responsible = responsible }
 setSoftwareSolution = (sw) => { this.sw = sw }

 get serialized () {
   return {
     id:this.id,
     name:this.name,
     upload:this.upload,
     download:this.download,
     ip:this.ip,
     subnetmask:this.subnetmask,
     provider:this.provider.id,
     firewall:this.firewall.id,
     responsible:this.responsible.id,
     sw:this.sw.id
   }
 }
}

class Phone {
  telefon = ''
  identifier = ''

  OPS = {
    DELETE:'delete',
    INSERT:'insert',
    UPDATE:'update'
  }
  op

  get serialized () {
    return {
      telefon:this.telefon,
      identifier:this.identifier,
      op:this.op,
      id:this.id
    }
  }

  constructor (d) {
    Object.assign(this,d)
    if (d.telefon) this.telefon = d.telefon
    if (d.bezeichner) this.identifier = d.bezeichner
    if (d.id) this.op = this.OPS.UPDATE; else this.op = this.OPS.INSERT
  }

  setOp = (op) => {
    this.op = op
  }

  changePhone = (value) => {
    this.telefon = value
    if (this.id && !this.isEmpty) this.op = this.OPS.UPDATE
    if (!this.id && !this.isEmpty) this.op = this.OPS.INSERT
  }
   changeIdentifier = (value) => {
    this.identifier = value
    if (this.id && !this.isEmpty) this.op = this.OPS.UPDATE
    if (!this.id && !this.isEmpty) this.op = this.OPS.INSERT
  }
  get isEmpty () {
    return this.telefon.trim() === '' && this.identifier.trim() === ''
  }
  get mustBeInserted () {
    return this.op === this.OPS.INSERT && !this.id && !this.isEmpty
  }
  get mustBeDeleted () {
    return this.op === this.OPS.DELETE && this.id
  }
  get mustBeUpdated () {
    return this.op === this.OPS.UPDATE && this.id
  }

}

decorate(Data,{
  name:observable,
  strasse:observable,
  plz:observable,
  phones:observable,
  deletePhones:observable,
  standort:observable,
  serialized:computed,
  firewalls:observable,
  providers:observable,
  softwareSolutions:observable,
  internetConnections:observable,
  member:observable,
  clientIdMarkedAsToDelete:observable
})

decorate(InternetConnection,{
 id:observable,
 name:observable,
 upload:observable,
 download:observable,
 ip:observable,
 subnetmask:observable,
 responsible:observable,
 lastUpdate:observable,
 provider:observable,
 firewall:observable,
 sw:observable,
 serialized:computed,
 clientId:observable
})

decorate(Phone,{
  telefon:observable,
  identifier:observable,
  op:observable,
  isEmpty:computed,
  mustBeInserted:computed,
  mustBeDeleted:computed,
  mustBeUpdated:computed,
  serialized:computed
})

const DataStore = createContext(new Data())
export default DataStore
