import React, { useContext,useEffect,useState } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer,useObserver } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import history from 'history.js'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'
import MemberList from './MemberList'
import Invite from './Invite'
import { ServiceSmall } from './SchoolServices'
import Smicalc from 'services/smicalc/index'
import SchoolTech from 'services/schooltech/index'
import Ampelflyer from 'services/ampelflyer/index'
import { ourAuthServices } from 'shared/functions/ourservices'

import IMG_DASHBOARD_SKELETON from 'media/img/dashboard.png'
import IMG_ADD_PEOPLE from 'media/img/addPeople.png'

import {
  Box,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Drawer,
  useMediaQuery,
  Menu,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core'
import { Add,Home,Drafts } from '@material-ui/icons'
import { amber,blue } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
 banner: {
   height:'20vh',
   borderBottom:'1px solid #e9e9e9'
 },
 soeName:{
   padding:'2rem',
   textAlign:'center',
   fontSize: '1.2rem',
   '@media (min-width:600px)': {
     fontSize: '1.5rem',
    },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  }
 },
 content:{
   width:'100%',
   position:'relative',
   padding:theme.spacing(1)
 },
 leftSide:{
   borderRight:'1px solid #e9e9e9'
 },
 rightSide:{
   borderLeft:'1px solid #e9e9e9'
 },
 servicesSmall:{
   height:60,
   marginTop:theme.spacing(1),
   marginBottom:theme.spacing(1),
   [theme.breakpoints.only('xs')]: {
     height: 60,
   },
   [theme.breakpoints.only('sm')]: {
     height: 60,
   },
   [theme.breakpoints.only('md')]: {
     height: 60,
   },
   [theme.breakpoints.only('lg')]: {
     height: 60,
   },
 },
 mediaSchooltech:{
   backgroundSize:'contain',
   objectFit:'contain',
   filter: 'grayscale(100%)'
 },
 schoolChooseMenu:{
   position:'fixed',
   top:20,
   zIndex:20,
   maxWidth:200,
   [theme.breakpoints.up('sm')]: {
     maxWidth: 270,
   },
   left:0,
   right:0,
   margin:'0 auto'
 },
 formControl: {
   minWidth: 120,
   width:'100%'
 },
}));


const SchoolPage = observer((props) => {
  const { schoolPage } = useContext(DataStore)

  useEffect(() => {
    if (props.match.params.schoolid) {
     schoolPage.setId(props.match.params.schoolid)
     schoolPage.getData(
       () => {},
       () => {}
     )
    }
    return () => {
      schoolPage.setData(null)
    }
  },[window.location.pathname])

  return useObserver(() => {
    return schoolPage.soe && schoolPage.school
    ?
    <>
      <SchoolPageHeader />
      <SchoolPageContent />
    </>
    :
    null
  })
})

const SchoolPageHeader = observer(() => {
  const classes = useStyles()
  const { schoolPage,user } = useContext(DataStore)
  const { schoolPageUI } = useContext(UiStore)
  const { soe,school } = schoolPage
  const [scrollTop,setScrollTop] = useState(window.scrollY)

  useEffect(() => {
    if (scrollTop > schoolPageUI.PASSED_SCHOOLPAGE_BANNER_PIXELS) {
     schoolPageUI.handleShowSchoolChooseMenu()
   } else {
     schoolPageUI.handleCloseSchoolChooseMenu()
   }

   const onScroll = e => { setScrollTop(e.target.documentElement.scrollTop) }
   window.addEventListener("scroll", onScroll)

   return () => window.removeEventListener("scroll", onScroll)
  },[scrollTop])

  const showSchoolChooser = user && user.schools && user.schools.length > 1 && schoolPageUI.showSchoolChooseMenu

  return (
   <section className={classes.banner}>
    <Box textAlign="center">
     <div className={classes.soeName}> {school.name} </div>
     <Typography variant="body1">{soe.name}</Typography>
    </Box>
    {showSchoolChooser && <SchoolPageChooser />}
   </section>
  )
})

const SchoolPageChooser = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user,schoolPage } = useContext(DataStore)
  const { schoolPageUI } = useContext(UiStore)

  const handleChange = (e) => {
    history.push('/dashboard/schoolpage/'+e.target.value)
  }


  return (
   <Paper className={classes.schoolChooseMenu}>
     <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="choose-school">{t('core.dashboard.schoolPage.change')}</InputLabel>
      <Select
        labelId="choose-school"
        id="choose-school-select"
        value={schoolPage.school.key}
        onChange={handleChange}
        label={t('core.dashboard.schoolPage.change')}
      >
      {user.schools.map((school,index) => (
        <MenuItem value={school.key}>{school.name}</MenuItem>
      ))}
      </Select>
     </FormControl>
   </Paper>
  )
})

const SchoolPageContent = observer(() => {
  return (
    <Grid container>
     <Left />
     <Middle />
     <Right />
    </Grid>
  )
})

const Left = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { schoolPage } = useContext(DataStore)
  const { schoolPageUI } = useContext(UiStore)
  const theme = useTheme()
  const services = ourAuthServices()
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'))

  if (schoolPage && schoolPage.id) {
    services.unshift({
      img:IMG_DASHBOARD_SKELETON,
      title:'core.dashboard.title',
      onClick: () => { history.push('/dashboard') },
      service: schoolPageUI.services.MEMBER_AND_INVITE
    })

    services[1].onClick = () => { schoolPageUI.setCurrentService(schoolPageUI.services.SCHOOL_TECH) }
    services[1].service = schoolPageUI.services.SCHOOL_TECH
    services[2].onClick = () => { schoolPageUI.setCurrentService(schoolPageUI.services.AMPELFLYER) }
    services[2].service = schoolPageUI.services.AMPELFLYER
    services[3].onClick = () => { schoolPageUI.setCurrentService(schoolPageUI.services.SMICALC) }
    services[3].service = schoolPageUI.services.SMICALC

    if (mdDown) {
     services.push({
      img:IMG_ADD_PEOPLE,
      title:'core.schoolPage.member',
      onClick: () => { schoolPageUI.setCurrentService(schoolPageUI.services.MEMBER_AND_INVITE) },
      service: schoolPageUI.services.MEMBER_AND_INVITE
     })
    }
  }

  return (
    <Grid item xs={2} sm={2} md={1} lg={1} className={classes.leftSide}>
     {services.map((s,i) => {
       const selected = schoolPageUI.currentService === s.service
       return <ServiceSmall
        img={s.img}
        onClick={s.onClick}
        key={s.img}
        styles={classes.servicesSmall}
        title={s.title}
        additionalClass={classes.mediaSchooltech}
        selected={selected}
       />
     })}
    </Grid>
  )
})

const Middle = observer(() => {
  const theme = useTheme()
  const classes = useStyles()
  const { schoolPage } = useContext(DataStore)
  const { schoolPageUI } = useContext(UiStore)

  return useObserver(() => {
    return <Grid item xs={10} sm={10} md={9} lg={9} className={classes.content}>
     {schoolPageUI.currentService === schoolPageUI.services.SCHOOL_TECH && <SchoolTech schoolId={schoolPage.id}/>}
     {schoolPageUI.currentService === schoolPageUI.services.SMICALC && <Smicalc schoolId={schoolPage.id}/>}
     {schoolPageUI.currentService === schoolPageUI.services.AMPELFLYER && <Ampelflyer schoolId={schoolPage.id}/>}
     {schoolPageUI.currentService === schoolPageUI.services.MEMBER_AND_INVITE &&
      <>
       <Invite />
       <MemberList member={schoolPage.school.member} requests={schoolPage.school.requests} />
      </>
     }
    </Grid>
  })
})

const Right = observer(() => {
  const classes = useStyles()
  const { schoolPage } = useContext(DataStore)
  const theme = useTheme()

  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    mdUp &&
     <Grid item md={2} lg={2} className={classes.rightSide}>
      <Invite />
      <MemberList member={schoolPage.school.member} requests={schoolPage.school.requests} />
     </Grid>
  )
})



export default SchoolPage
