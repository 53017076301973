import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory,Link } from 'react-router-dom'

import {
  Button,
  Typography
} from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'


import { ourServices } from 'shared/functions/ourservices'

const useStyles = makeStyles(theme => ({
  toDashboardBtn: {

  },
  image:{
    maxWidth:'100%',
    minHeight:140
  },
  btnColor:{
  },
  phrotz:{
    color:'#457d9a',
    fontWeight:'bold'
  },
  toService:{
    cursor:'pointer'
  }
}))

const Services = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const toDashboard = () => history.push('/dashboard')
  const [randomNumbers,setRandomNumbers] = useState([])
  const services = ourServices()

  useEffect(() => {
    let arr = []
    const NUMBER_OF_SERVICES_SHOWN_ON_FRONTPAGE = 3
    while (arr.length < NUMBER_OF_SERVICES_SHOWN_ON_FRONTPAGE) {
     var r = Math.floor(Math.random() * services.length)
     if(arr.indexOf(r) === -1) arr.push(r)
    }
    setRandomNumbers(arr)
  },[])


  return (
    <section id="services" className="services-area pt-100 pb-70">
      <div className="container-fluid">
        <div className="section-title">
          <h2>{t('core.landingpage.services')}</h2>
          <div className="bar" />
        </div>
        <div className="row">

          {randomNumbers.map((r,i) => {
            const s = services[r]
            return <div className="col-lg-4 col-md-6" onClick={s.goTo}>
             <div className="single-blog">
                <div className="image">
                 <img src={s.img} alt={t(s.title)} className={classes.image} />
                </div>
               <div className="content">
                 <Typography variant="h6" component="p">{t(s.text)}</Typography>
                 <Typography variant="h6"><a>{t('core.landingpage.goToService')}</a></Typography>
               </div>
             </div>
            </div>
          })}



        </div>
        <div className={classes.toDashboardBtn}>
         <Button variant="contained" onClick={toDashboard} className={`${classes.btnColor} phButton`}>
          {t('core.landingpage.goToServices')}
         </Button>

        </div>
      </div>
    </section>
  )
})

export default Services
