import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import Ajax from 'api/ajax'

class Data {
  school = {}
  schoolMember = []
  smis = []
  percent = ""
  clients = ""
  teacher = ""
  sus = ""
  byod = false
  result

  setData = (data) => {
    const {
     member,
     school
    } = data

    if (member) this.setSchoolMember(member)
    if (school) this.setSchool(school)
  }
  setPercent = (percent) => { this.percent = percent }
  setClients = (clients) => { this.clients = clients }
  setTeacher = (teacher) => { this.teacher = teacher }
  setSus = (sus) => { this.sus = sus }
  toggleBYOD = () => { this.byod = !this.byod }
  setResult = (result) => { this.result = result }
  setSchool = (school) => { this.school = school }
  setSchoolMember = (member) => { this.schoolMember = member }
  setSMI = (smis) => {
    const set = new Set([...this.smis,...smis])
    this.smis = Array.from(set)
  }

  get smisArr () { return toJS(this.smis) }
  isSelectedSMI = (m) => { return this.smis.filter(s => s.id === m.id).length === 1 }

  getSMICalc = (schoolId,cbSuccess,cbFail) => {
    Ajax.getSMICalc(
      { schoolId },
      (res) => {
       if (res && res.data && res.data.success) {
         const data = res.data.data
         this.setData(data)
       }
       cbSuccess()
      },
      cbFail
    )
  }

  calculate = (cbSuccess,cbFail) => {
    Ajax.calculateSMIPercent(
      this.serialized,
      (res) => {
        if (res && res.data && res.data.success) {
          this.setResult(res.data.result)
        }
        cbSuccess()
      },
      cbFail
    )
  }

  get serialized () {
    return {
      schoolId:this.school.erz_key,
      smis:this.smis.map(s => s.id),
      percent:this.percent,
      clients:this.clients,
      teacher:this.teacher,
      sus:this.sus,
      byod:this.byod
    }
  }
}


decorate(Data,{
 schoolMember:observable,
 school:observable,
 smis:observable,
 smisArr:computed,
 percent:observable,
 clients:observable,
 teacher:observable,
 sus:observable,
 byod:observable,
 serialized:computed,
 result:observable
})


const DataStore = createContext(new Data())
export default DataStore
