import React, {useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import './assets/css/bootstrap.min.css'
import './assets/css/animate.css'
import './assets/css/meanmenu.css'
import './assets/css/boxicons.min.css'
import './assets/css/flaticon.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/owl.theme.default.min.css'
import './assets/css/magnific-popup.min.css'
import './assets/css/nice-select.css'
import './assets/css/responsive.css'
import './assets/css/style.css'

import Banner from './Banner'
import Conference from './Conference'
import Fellows from './Fellows'
import Helpdesk from './Helpdesk'
import MeetTheTeam from './MeetTheTeam'
import Navigation from './Navigation'
import News from './News'
import Services from './Services'
import Discord from './Discord'
import Testimonial from './Testimonial'
import Advice from './Advice'


const LandingPage = observer(() => {
  return (
   <div>
    <Banner />
    <Helpdesk />
    <Advice />
    <Conference />
    <Services />
   </div>
  )
})

export default LandingPage
