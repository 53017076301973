import React, { useContext, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Typography
} from '@material-ui/core'

import IMG_ADVICE from 'media/img/advice.png'

const useStyles = makeStyles(theme => ({
  image: {
    background: '#fff',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center center',
    maxWidth:300,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
  }
}));


const Conference = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { kibsmeta } = useContext(DataStore)
  const { utilityText } = useContext(UiStore)

  useEffect(utilityText.getTextConference,[])


  return (
    <section id="advice" className="digital-experience-area ptb-100">
      <div className="container-fluid">
        <div className="section-title">
          <h2>{t('core.landingpage.conference')}</h2>
          <div className="bar" />
          <div className="subscribe-content">
           <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html: utilityText.textConference.toString('html') }} />
          </div>
        </div>
      </div>
    </section>
 )
})

export default Conference
