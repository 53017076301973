import React, { useContext } from 'react'

import DataStore from './stores/data'
import UiStore from './stores/ui'

import PropTypes from 'prop-types'
import { observer } from 'mobx-react-lite'
import { Add } from '@material-ui/icons/'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  IconButton,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  Box,
  Paper,
  Container
} from '@material-ui/core'

import Configurator from './Configurator'
import { AddServiceDialog,ConfiguratorActions,DeleteFlyerDialog } from './ConfiguratorActions'
import GlobalUiStore from 'core/stores/ui'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    position:'relative',
    marginTop:theme.spacing(3)
  },
}));

const Sheets = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sheets } = useContext(UiStore)
  const { flyerManager } = useContext(DataStore)
  const globalUI = useContext(GlobalUiStore)
  const flyer = flyerManager.current

  const handleChange = (event, newValue) => {
    sheets.select(newValue)
    flyerManager.switchFlyer(newValue)
  };

  const addSheet = (e) => {
    globalUI.setLoading(true)
    flyerManager.createFlyer(
      () => {
        globalUI.setLoading(false)
        handleChange(e,flyerManager.flyer.length - 1)
      },
      () => {
        globalUI.setLoading(false)
      }
    )
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
       <Typography variant="h2" component="h2" gutterBottom>{t('ampelflyer.title')}</Typography>
       <Typography variant="h6" gutterBottom>{t('ampelflyer.heading1')}</Typography>
       <Typography variant="h6" gutterBottom>{t('ampelflyer.heading2')}</Typography>
      </Container>
      <Tabs
        value={sheets.selected}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
       {flyerManager.flyer.map((flyer,i) => (
        <Tab wrapped key={'flyer-tab-'+i} label={flyer.title} {...a11yProps(i)} />
       ))}

       <Tooltip title={t('ampelflyer.configurator.createMoreFlyer')}>
        <IconButton onClick={addSheet}>
         <Add />
        </IconButton>
       </Tooltip>

      </Tabs>
      <TabPanel key={'flyer-tabpanel'} value={sheets.selected} index={sheets.selected}>
       <Configurator />
       <ConfiguratorActions />
      </TabPanel>

       <AddServiceDialog />
       <DeleteFlyerDialog />
    </div>
  );
})

export default Sheets
