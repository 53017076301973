import React, { useContext,useEffect,useState } from 'react'
import moment from 'moment'

import {
  Grid,
  Typography,
  Container,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  Button,
  Input,
  Slider,
  ButtonGroup,
  IconButton
} from '@material-ui/core'
import { Search,AccountCircle,Home,Mail,Phone,Edit,DeleteForever } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth:650,
    maxWidth:800,
    position:"relative",
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
    left:0,
    right:0
  },
  edit:{
    position:"absolute",
    top:"1rem",
    right:"1rem"
  },
  spacer:{
    marginBottom:"2rem"
  },
  thead:{
    background:'#dedede'
  }
}));

moment.locale('de')
const HelpdeskTimes = observer(() => {
  const { utilityUser } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const [editable,setEditable] = useState(true)
  const toggleEdit = () => {
    setEditable(!editable)
  }
  const [total,setTotal] = useState(1700)
  const changeTotal = (e) => {
    setTotal(e.target.value)
  }

  return (
    <Container component="main" className={classes.container} id="ref-parent">
      {editable &&
       <>
        <Typography component="p">Wieviel Gesamtstunden möchtest Du verteilen?</Typography>
        <Input
         style={{ width: "100%", fontSize:"1rem"}}
         type="number"
         value={total}
         onChange={changeTotal}/>
       </>
      }

     <div className={classes.spacer} />
     <Button fullWidth color="primary" variant="outlined" className={classes.calcBtn} onClick={toggleEdit}>{editable ? "Anzeigen" : "Editieren"}</Button>
     <div className={classes.spacer} />
     <HelpdeskTable editable={editable} total={total} />
     <div className={classes.spacer} />
     <AnspruchsgruppenTable editable={editable} total={total} />
     <div className={classes.spacer} />

    </Container>
  )
})


const HelpdeskTable = observer(({ editable,total }) => {
  const classes = useStyles()
  const [percentsGiven,setPercentsGiven] = useState(0)
  const [calcedTotal,setCalcedTotal] = useState(0)
  const [persons,setPersons] = useState([
    { name: "Denise",    hours: 47, phone: 17, mail: 83, calcedHours:0 },
    { name: "Monika",    hours: 3,  phone: 29, mail: 71, calcedHours:0 },
    { name: "Christian", hours: 5,  phone: 35, mail: 65, calcedHours:0 },
    { name: "Samuel",    hours: 5,  phone: 41, mail: 59, calcedHours:0 },
    { name: "Oliver",    hours: 10, phone: 5 , mail: 95, calcedHours:0 },
    { name: "Nico",      hours: 5,  phone: 11, mail: 89, calcedHours:0 },
    { name: "Kurt",      hours: 10, phone: 80, mail: 20, calcedHours:0 },
    { name: "Mike",      hours: 5,  phone: 40, mail: 60, calcedHours:0 },
    { name: "Simon",     hours: 5,  phone: 40, mail: 60, calcedHours:0 },
    { name: "Andreas",   hours: 5,  phone: 5,  mail: 95, calcedHours:0 }
  ])

  const addPerson = () => {
   setPersons([...persons,{ name:"",hours:0,phone:30,mail:70 }])
  }

  const removePerson = (name) => {
    const removed = persons.filter(p => p.name !== name)
    setPersons(removed)
  }

  const calc = () => {
    let ct = 0, pg = 0
    const newPersons = persons.map(p => {
      p.calcedHours = total * p.hours / 100
      ct += total * p.hours / 100
      pg += p.hours
      return p
    })
    setCalcedTotal(parseInt(ct))
    setPercentsGiven(pg)
    setPersons(newPersons)
  }

  const downloadCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Mitarbeiter:in,Stunden,% Anteil E-Mail, % Anteil Tel."
    csvContent += "\r\n";

    persons.forEach((p) => {
     let row = p.name+","+p.calcedHours+","+p.mail+","+p.phone
     csvContent += row + "\r\n";
    });
    csvContent += "Total,"+calcedTotal+",,"
    csvContent += "\r\n";

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "personen_"+new Date()+".csv");
    document.body.appendChild(link); // Required for FF
    link.click()
  }

  useEffect(calc,[])
  useEffect(calc,[editable,total])

  return (
   <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>Mitarbeiter:in</TableCell>
            <TableCell align="right">{editable ? "Stunden %" : "Stunden"}</TableCell>
            <TableCell align="right">{editable ? "Mail %" : "Mail"}</TableCell>
            <TableCell align="right">{editable ? "Telefon %" : "Telefon"}</TableCell>
            {editable && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {persons.map((p) => (
            <HelpdeskTableRow key={p.name} person={p} editable={editable} removePerson={removePerson} change={calc} />
          ))}
          <TableRow style={{background:'#ddd'}}>
            <TableCell component="th" scope="row"></TableCell>
            {editable && <TableCell align="right">{percentsGiven?percentsGiven:0}%</TableCell>}
            {!editable && <TableCell align="right">{parseInt(calcedTotal)}</TableCell>}
          </TableRow>
          {editable &&
           <TableRow>
            <TableCell colSpan={5}>
             <Button variant="contained" color="primary" onClick={addPerson}>Person hinzufügen</Button>
            </TableCell>
           </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>

    {!editable && <Button fullWidth color="secondary" variant="contained" onClick={downloadCSV}>Als CSV herunterladen</Button>}

   </>
  )
})


const HelpdeskTableRow = observer(({ editable,person,removePerson,change }) => {
  const setPersonName = (e) => {
    person.name = e.target.value
  }
  const setPersonHours = (e) => {
    person.hours = parseInt(e.target.value)
    setHours(person.hours)
    change()
  }
  const setPersonMail = (e,newValue) => {
    person.mail = newValue
    person.phone = 100 - newValue
    setMail(person.mail)
    setPhone(person.phone)
    change()
  }
  const setPersonPhone = (e,newValue) => {
    person.phone = newValue
    person.mail = 100 - newValue
    setPhone(person.phone)
    setMail(person.mail)
    change()
  }

  const [hours,setHours] = useState(person.hours)
  const [mail,setMail] = useState(person.mail)
  const [phone,setPhone] = useState(person.phone)

  return (
   editable
   ?
    <TableRow key={person.name}>
      <TableCell style={{width:"30%"}} component="th" scope="row"><Input defaultValue={person.name} onChange={setPersonName}/></TableCell>
      <TableCell style={{width:"20%"}} align="right">
       <Input type="number" value={hours} onChange={setPersonHours} min={0} max={100} />
      </TableCell>
      <TableCell style={{width:"25%"}} align="right">
       <Slider value={mail} onChange={setPersonMail} valueLabelDisplay="auto" marks={[{value:0,label:"0%",value:100,label:"100%"}]} aria-labelledby="continuous-slider" />
      </TableCell>
      <TableCell style={{width:"25%"}} align="right">
       <Slider value={phone} onChange={setPersonPhone} valueLabelDisplay="auto" marks={[{value:0,label:"0%",value:100,label:"100%"}]} aria-labelledby="continuous-slider" />
      </TableCell>
      <TableCell>
       <IconButton color="secondary" onClick={() => removePerson(person.name)}>
        <DeleteForever />
       </IconButton>
      </TableCell>
    </TableRow>
    :
    <TableRow key={person.name}>
      <TableCell component="th" scope="row">{person.name}</TableCell>
      <TableCell align="right">{person.calcedHours}</TableCell>
      <TableCell align="right">{person.mail}%</TableCell>
      <TableCell align="right">{person.phone}%</TableCell>
    </TableRow>

  )
})


const AnspruchsgruppenTable = observer(({ editable,total }) => {
  const classes = useStyles()
  const [percentsGiven,setPercentsGiven] = useState(0)
  const [calcedTotal,setCalcedTotal] = useState(0)
  const [groups,setGroups] = useState([
    { name: "Schule (SMI;SL;Behörden)", hours: 10, calcedHours: 0 },
    { name: "BKD",                      hours: 10, calcedHours: 0  },
    { name: "SAI",                      hours: 10, calcedHours: 0  },
    { name: "Swisscom",                 hours: 10, calcedHours: 0  },
    { name: "Andere",                   hours: 10, calcedHours: 0  }
  ])

  const addGroup = () => {
   setGroups([...groups,{ name:"",hours:0 }])
  }

  const removeGroup = (name) => {
    const removed = groups.filter(g => g.name !== name)
    setGroups(removed)
  }

  const calc = () => {
    let ct = 0, pg = 0
    const newGroups = groups.map(g => {
      g.calcedHours = total * g.hours / 100
      ct += total * g.hours / 100
      pg += g.hours
      return g
    })
    setCalcedTotal(ct)
    setPercentsGiven(pg)
    setGroups(newGroups)
  }

  const downloadCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Anspruchsgruppe,%,Stunden"
    csvContent += "\r\n";

    groups.forEach((g) => {
     let row = g.name+","+g.hours+","+g.calcedHours
     csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "anspruchsgruppen_  "+new Date()+".csv");
    document.body.appendChild(link); // Required for FF
    link.click()
  }

  useEffect(calc,[])
  useEffect(calc,[editable,total])

  return (
   <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell>Anspruchsgruppe</TableCell>
            <TableCell align="right">{editable ? "%" : "Anteil"}</TableCell>
            <TableCell align="right">{editable ? "h" : "Stunden"}</TableCell>
            {editable && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((g) => (
            <AnspruchsGruppeTableRow key={g.name} group={g} editable={editable} removeGroup={removeGroup} change={calc} />
          ))}
          <TableRow style={{background:(editable ? '#ddd' : '#fff' )}}>
            <TableCell component="th" scope="row"></TableCell>
            {editable && <TableCell align="right">{percentsGiven?percentsGiven:0}%</TableCell>}
          </TableRow>
          {editable &&
           <TableRow>
            <TableCell colSpan={5}>
             <Button variant="contained" color="primary" onClick={addGroup}>Anspruchsgruppe hinzufügen</Button>
            </TableCell>
           </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>

    {!editable && <Button fullWidth color="secondary" variant="contained" onClick={downloadCSV}>Als CSV herunterladen</Button>}
   </>
  )
})

const AnspruchsGruppeTableRow = observer(({ editable,group,removeGroup,total,change }) => {
  const setGroupName = (e) => {
    group.name = e.target.value
  }
  const setGroupHours = (e) => {
    group.hours = parseInt(e.target.value)
    setHours(group.hours)
    change()
  }
  const setGroupCalcedHours = () => {
    group.calcedHours = total * group.hours / 100
    setGroupCalcedHours(group.calcedHours)
    change()
  }

  const [hours,setHours] = useState(group.hours)

  return (
   editable
   ?
    <TableRow key={group.name}>
      <TableCell style={{width:"30%"}} component="th" scope="row"><Input defaultValue={group.name} onChange={setGroupName}/></TableCell>
      <TableCell style={{width:"35%"}} align="right">
       <Input type="number" value={hours} onChange={setGroupHours} min={0} max={100} />
      </TableCell>
      <TableCell style={{width:"35%"}} align="right">
       {group.calcedHours}
      </TableCell>
      <TableCell>
       <IconButton color="secondary" onClick={() => removeGroup(group.name)}>
        <DeleteForever />
       </IconButton>
      </TableCell>
    </TableRow>
    :
    <TableRow key={group.name}>
      <TableCell component="th" scope="row">{group.name}</TableCell>
      <TableCell align="right">{group.hours}%</TableCell>
      <TableCell align="right">{group.calcedHours || group.calcedHours === 0 ? group.calcedHours : 0}</TableCell>
    </TableRow>

  )
})



export default HelpdeskTimes
