import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'
import AjaxClient from 'api/ajax'

class Data {

 domain = ""
 redirect = ""
 state = -1
 registrar = ""

 setDomain = (domain) => this.domain = domain
 setRedirect = (redirect) => this.redirect = redirect
 setRegistrar = (registrar) => this.registrar = registrar

 checkDomain = (cbSuccess,cbFail) => {
   if (this.domain.trim() !== "") {
     AjaxClient.redirecterCheckState(
       this.domain,
       (res) => {
         if (res && res.data && res.data.success) {
           this.state = res.data.state
           cbSuccess(res.data.state)
         } else cbFail()
       },
       cbFail
     )
   }
 }

 applyForRedirect = (cbSuccess) => {
   if (this.domain.trim() !== "" && this.redirect.trim() !== "") {
     AjaxClient.redirecterApply(
       this.domain,
       this.redirect,
       (res) => {
         this.setRegistrar(res.data.registrar)
         cbSuccess(res.data.success)
       },
       () => {}
     )
   }
 }

}


decorate(Data,{
  domain:observable,
  redirect:observable,
  registrar:observable,
  state:observable
})


const DataStore = createContext(new Data())
export default DataStore
