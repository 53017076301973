import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Grid,
  TextField,
  Typography,
  Paper
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth:theme.breakpoints.values.sm,
    margin:'0 auto',
    minHeight:'80vh'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  input: {
    margin: theme.spacing(1),
  },
  paper:{
    padding:theme.spacing(2)
  }
}))

const Surveys = observer(() => {
  const classes = useStyles()
  return (
    <Grid container justify="center" alignItems="center">
     <Grid item xs={12}>
      <div className={classes.root}>
       <CreateSurvey />
       <ConfigureSurveys />
      </div>
    </Grid>
   </Grid>
  )
})


const CreateSurvey = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { adminSurveys } = useContext(DataStore)

  const onChange = (e) => {
    adminSurveys.createSurvey(
      e.target.value,
      () => {},
      () => {}
    )
  }
  return (
   <section>
   <TextField
     id={'custom-survey-link'}
     value={adminSurveys.gFormsLink}
     onChange={onChange}
     helperText={t('surveys.admin.createHelper')}
     variant="filled"
     fullWidth
   />
    <Paper className={classes.paper} elevation={1}>
     <Typography variant="body1" component="p"> {t('surveys.admin.howItWorks')} {t('surveys.admin.howItWorks2')} </Typography>
    </Paper>

   </section>
  )
})

const ConfigureSurveys = observer(() => {
  return (
    null
  )
})


export default Surveys
