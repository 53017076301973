import React, { useContext,useEffect,useState } from 'react'
import {deburr,debounce} from 'lodash'
import { FixedSizeList as FSL } from 'react-window'
import moment from 'moment'

import {
  Grid,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  Paper,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Tooltip,
  Chip,
  ListItemSecondaryAction
} from '@material-ui/core'
import { Search,AccountCircle,Home,Mail,Phone } from '@material-ui/icons'

import GoogleLogo from 'media/img/googleLogo.svg'
import OfficeLogo from 'media/img/office365Logo.png'
import IMG_KIBS_LOGO_RED from 'media/img/KibsLogo_red.png'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop:25
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  profile:{
    marginTop:theme.spacing(3)
  },
  authIcon:{
    height:40,
    width:'auto'
  },
  subheader:{
    fontStyle:'italic'
  }
}));

moment.locale('de')
const UserFind = observer(() => {
  const { utilityUser } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="md" id="ref-parent">
     <SearchBar />
     {utilityUser.suggestions.length > 0 && <div>{utilityUser.suggestions.length} {t('core.admin.utility.hits')}</div>}
     {utilityUser.suggestions.length > 0  && <UserList />}
     {utilityUser.user && <UserProfile />}
    </Container>
  )
})

const UserProfile = observer(() => {
  const classes = useStyles()
  const { utilityUser } = useContext(UiStore)
  const { user } = utilityUser
  const { t } = useTranslation()

  const mail = user.mails[0]
  let logo = '', text = ''
  if (mail.isGoogleOAuth) {
    logo = GoogleLogo
    text = t('core.admin.utility.isGoogleOAuth')
  }
  else if (mail.isKibsAuth) {
    logo = OfficeLogo
    text = t('core.admin.utility.isOfficeOAuth')
  }
  else if (mail.isOfficeOAuth) {
    logo = IMG_KIBS_LOGO_RED
    text = t('core.admin.utility.isKibsAuth')
  }

  const subheader = user.mails[0].mail + ' (' + t('core.admin.utility.since') + ' ' + moment(user.created).format("Do MMM YYYY") + ')'

  return (
    <Card className={classes.profile}>
      <CardHeader
        avatar={
            user.photo
            ?
            <Avatar src={user.photo} className={classes.avatar} />
            :
            <AccountCircle />
        }
        action={
          <Icon aria-label="auth-method">
           <Tooltip title={text}>
            <img src={logo} className={classes.authIcon} />
           </Tooltip>
          </Icon>
        }
        title={user.foreName + ' ' + user.name}
        subheader={subheader}
      />
      <CardContent>
      {user.schools.length > 0 &&
       <List dense>
        {user.schools.map((s,i) => {
          return <ListItem>
           <ListItemIcon>
            <Home />
           </ListItemIcon>
           <ListItemText primary={s.name} />
           <ListItemSecondaryAction>
            {s.userFn.length > 0 &&
             s.userFn.map((fn,j) => (
               <Chip variant="outlined" label={t('core.'+fn.translationKey+'Short')}/>
             ))
            }
           </ListItemSecondaryAction>
          </ListItem>
        })}
       </List>
      }
      {user.mails.length > 0 &&
       <List dense>
        {user.mails.map((m,i) => {
          if (i === 1) return null
          return <ListItem>
           <ListItemIcon>
            <Mail />
           </ListItemIcon>
           <ListItemText primary={<a href={"mailto:"+m.mail}>{m.mail}</a>}/>
          </ListItem>
        })}
       </List>
      }
      {user.phones.length > 0 &&
       <List dense>
        {user.phones.map((p,i) => {
          const tel = p.bezeichner && p.bezeichner !== '' ? p.telefon + ' ('+p.bezeichner+')': p.telefon
          return <ListItem>
           <ListItemIcon>
            <Phone />
           </ListItemIcon>
           <ListItemText primary={<a href={"tel:"+p.telefon}>{tel}</a>} />
          </ListItem>
        })}
       </List>
      }
      </CardContent>
    </Card>
  )
})

const getUsers = debounce((user,cb) => { user.get(cb) },300)

const SearchBar = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { utilityUser } = useContext(UiStore)

  const _onKeyDown = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault()
    }
  }

  return (
    <Paper component="form" className={classes.searchRoot} elevation={0}>
      <InputBase
        autoFocus
        value={utilityUser.searchTerm}
        onChange={utilityUser.setSearchTerm}
        onKeyUp={getUsers.bind(this,utilityUser)}
        onKeyDown={_onKeyDown}
        className={classes.searchInput}
        placeholder={t('core.admin.utility.userDetailsPlaceholder')}
        inputProps={{ 'aria-label': t('core.admin.utility.userDetailsPlaceholder') }}
      />
      <IconButton className={classes.searchIconButton} aria-label="search" onClick={getUsers.bind(this,utilityUser)}>
        <Search />
      </IconButton>
    </Paper>
  );
})

const UserList = observer(() => {
  const { utilityUser } = useContext(UiStore)
  const [width,setWidth] = useState(0)

  useEffect(() => {
    const d = document.getElementById('ref-parent')
    setWidth(d.offSetWidth)
  },[window.width])

  return (
     <FSL height={500} width={width} itemSize={66} itemCount={utilityUser.suggestions.length} itemData={utilityUser.suggestions}>
      {Row}
     </FSL>
  )
})

const Row = ({ data, index, style }) => (
  <UserListItem  data={data[index]} style={style} />
)


const UserListItem = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data,style } = props
  const { utilityUser,notifier } = useContext(UiStore)
  const { user } = useContext(DataStore)

  const _onClick = () => {
   utilityUser.infos(
     data.id,
     () => {},
     () => {}
   )
  }

  let styles = { ...style }

  return (
   <ListItem button style={styles} onClick={_onClick}>
    <ListItemText primary={data.name + ' ' + data.foreName} secondary={data.mail} />
   </ListItem>
  )
})


export default UserFind
