import React, { useContext,useEffect,useState } from 'react'
import moment from 'moment'

import {
  Grid,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  Paper,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Tooltip,
  Chip,
  ListItemSecondaryAction
} from '@material-ui/core'
import { Search,AccountCircle,Home,Mail,Phone,Forward,AccessTime,Check } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop:25
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  profile:{
    marginTop:theme.spacing(3)
  },
  authIcon:{
    height:40,
    width:'auto'
  },
  subheader:{
    fontStyle:'italic'
  }
}));

moment.locale('de')
const Redirects = observer(() => {
  const { utilityUser } = useContext(UiStore)
  const { administration } = useContext(DataStore)
  const { allRedirectRequests } = administration
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    administration.getAllRedirects(
      () => {},
      () => {}
    )
  },[])

  return (
    <Container component="main" maxWidth="md" id="ref-parent">
     <List dense>
     {allRedirectRequests.map((r) => {
       return <ListItem>
        <ListItemIcon>
         <Forward />
        </ListItemIcon>
        <ListItemText primary={`${r.domain} --> ${r.redirect}`} secondary={moment(r.created).fromNow()}/>
        <ListItemSecondaryAction>
         {r.pending ? <AccessTime /> : <Check />}
        </ListItemSecondaryAction>
       </ListItem>
     })}
    </List>
   </Container>
  )
})



export default Redirects
