import React, { useContext, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import OAuthView from '../login/oauth'
import { Link } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'
import queryString from 'query-string'
import { useObserver } from 'mobx-react-lite'
import history from 'history.js'

const useStyles = makeStyles(theme => ({
  container:{
    minHeight:'80vh'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spacing:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const SignUp = (props) => {
  const { oauth2Dialog,notifier } = useContext(UiStore)
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(DataStore)
  const { callback, hideLinks } = props

  const signUp = () => {
    user.signUp(
    (res) => {
      if (res.emptyFields) {
        notifier.push(t('core.notifier.emptyFields'),notifier.variants.ERROR)
      }
      else if (res.mailExists) {
       notifier.push(t('core.notifier.mailExists'),notifier.variants.ERROR)
      }
      else if (res.passwordNotValid) {
        notifier.push(t('core.notifier.passwordNotValid'),notifier.variants.ERROR)
      }
      else if (res.failedToCreate) {
        notifier.push(t('core.notifier.5xx'),notifier.variants.ERROR)
      }
      else if (res.wasOldHelpdeskUserThatTriedToRegisterWithAnAlreadyValidAccount) {
        history.push('/dashboard')
      }
      else {
        if (callback) {
          callback()
        } else {
          const params = queryString.parse(window.location.search)
          if (params && params.redirect_uri) {
            history.push(params.redirect_uri)
          } else {
            history.push('/dashboard')
          }
        }
      }
    },
    () => {
     notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
    }
   )
  }

  useEffect(() => {
   oauth2Dialog.handleClose()
   return () => { oauth2Dialog.handleClose() }
  })

  const setForeName = (e) => {
    user.setForeName(e.target.value)
  }
  const setName = (e) => {
    user.setName(e.target.value)
  }
  const setMail = (e) => {
    user.setMail(e.target.value)
  }
  const setPassword = (e) => {
    user.setPassword(e.target.value)
  }
  const submitOnEnter = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      signUp()
    }
  }


  return useObserver(() => (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.spacing}>
          {t('core.register.title')}
        </Typography>
        <OAuthView callback={callback} />
        <div className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                value={user.foreName}
                onChange={setForeName}
                onKeyDown={submitOnEnter}
                label={t('core.register.firstName')}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label={t('core.register.lastName')}
                value={user.name}
                onChange={setName}
                onKeyDown={submitOnEnter}
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t('core.login.email')}
                value={user.mail}
                onChange={setMail}
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t('core.login.password')}
                type="password"
                id="password"
                onChange={setPassword}
                value={user.password}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signUp}
          >
            {t('core.register.account')}
          </Button>
          {!hideLinks &&
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/login">
                  {t('core.register.alreadyHaveAccount')}
                </Link>
              </Grid>
            </Grid>
          }
        </div>
      </div>
    </Container>
  ))
}

export default SignUp
