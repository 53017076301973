import React, { useMemo,useRef,useCallback,useEffect,useContext,useState } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { useDebouncedCallback } from 'use-debounce'

import {
  Typography,
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Container,
  CardMedia,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
  TextField,
  Grid,
  IconButton,
  Button,
  Tooltip,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  FormControlLabel,
  Paper
} from '@material-ui/core'

import { ExpandMore, Add } from '@material-ui/icons/'
import KibsDropzone from 'shared/components/dropzone'
import { TrafficLight,SingleTrafficLight } from './pdf'
import DataStore from './stores/data'
import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'

import IMG_PupilWork from 'media/img/ampelflyer/pupilwork.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipContainer:{
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  addServiceBtn:{
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  serviceMenuIcon:{
    backgroundRepeat:'no-repeat',
    backgroundPosition:'center center',
    backgroundSize:'contain',
    borderRadius:0,
    background:'transparent'
  },
  logo:{
    width:'100%',
    height:300,
    maxHeight:300,
    backgroundSize:'cover'
  },
  logoContainer:{
    width:'100%'
  },
  remainingCharacters:{
    right: 0,
    bottom: 0,
    position: 'absolute',
    margin: 8,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.03333em',
    minHeight: '1em'
  }
}))

const Configurator = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { COLORS } = useContext(DataStore)
  const { flyerManager } = useContext(DataStore)
  const flyer = flyerManager.current
  const { notifier } = useContext(GlobalUiStore)

  const onChange = (e) => { flyer.setTitle(e.target.value) }

  const [debouncedSave] = useDebouncedCallback(
   () => {
     flyer.save(flyerManager.schulhaus.erz_key)
     notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
   },
   flyer.DEBOUNCE_TIMER
  )

  useEffect(debouncedSave,[
    flyer.title,
    flyer.custom_schulhaus_name,
    flyer.text_green_data,
    flyer.text_yellow_data,
    flyer.text_red_data,
    flyer.impress,
    flyer.services_green,
    flyer.services_yellow,
    flyer.services_red
  ])

  return (
   <Container component="main" maxWidth="md">

     <TextField
      id={'flyer'}
      value={flyer.title}
      onChange={onChange}
      className={classes.textField}
      variant="filled"
      fullWidth
    />

    <ConfigItem
     heading={<Typography className={classes.heading}>{t('ampelflyer.configurator.customSchoolname')}</Typography>}
     children={<ConfigCustomSchoolname />}
     defaultExpanded={true}
    />
    <ConfigItem
     heading={<Typography className={classes.heading}>{t('ampelflyer.configurator.logo')}</Typography>}
     children={<ConfigLogo />}
    />
    <ConfigItem
     heading={
      <Typography className={classes.heading}>
       {t('ampelflyer.configurator.green')}
      </Typography>
     }
     subHeading={
       <span className={classes.secondaryHeading}>
        <SingleTrafficLight active={COLORS.GREEN}/>
       </span>
     }
     children={<ConfigTrafficlightData active={COLORS.GREEN} />}
    />
    <ConfigItem
     heading={
      <Typography className={classes.heading}>
       {t('ampelflyer.configurator.yellow')}
      </Typography>
     }
     subHeading={
       <span className={classes.secondaryHeading}>
        <SingleTrafficLight active={COLORS.YELLOW}/>
       </span>
     }
     children={<ConfigTrafficlightData active={COLORS.YELLOW} />}
    />
    <ConfigItem
     heading={
      <Typography className={classes.heading}>
       {t('ampelflyer.configurator.red')}
      </Typography>
     }
     subHeading={
       <span className={classes.secondaryHeading}>
        <SingleTrafficLight active={COLORS.RED}/>
       </span>
     }
     children={<ConfigTrafficlightData active={COLORS.RED} />}
    />
    <ConfigItem
     heading={<Typography className={classes.heading}>{t('ampelflyer.impress.title')}</Typography>}
     children={<ConfigImpress />}
    />
   </Container>
  );
})


const ConfigItem = (props) => {
  const classes = useStyles()
  let { defaultExpanded, heading, subHeading, children} = props
  heading = heading ? heading : ''
  subHeading = subHeading ? subHeading : ''

  return useObserver(() => (
   <ExpansionPanel defaultExpanded={defaultExpanded}>
    <ExpansionPanelSummary
     expandIcon={<ExpandMore />}
    >
    {heading}
    {subHeading}
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
     {children}
    </ExpansionPanelDetails>
   </ExpansionPanel>
 ))
}

const ConfigLogo = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { flyerManager,newService } = useContext(DataStore)
  const flyer = flyerManager.current

  const onFiles = (files) => {
   if (files.length > 0) {
     flyer.saveLogo(
       files,
       flyerManager.schulhaus.erz_key,
       flyer.id
     )
   }
  }
  const setDefaultLogo = () => {
    flyer.setDefaultLogo(
      flyerManager.schulhaus.erz_key,
      flyer.id
    )
  }

  return (
   <div className={classes.logoContainer}>
    <CardMedia className={classes.logo} image={flyer.logo} />
    <KibsDropzone
     accept="image/*"
     multiple={false}
     onFiles={onFiles}
     showThumbnails={false}
    />
    {flyer.isCustomLogo &&
     <Button fullWidth variant="outlined" onClick={setDefaultLogo}> {t('ampelflyer.configurator.defaultTitleImage')} </Button>
    }
   </div>
  )
})

const ConfigCustomSchoolname = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { flyerManager } = useContext(DataStore)
  const flyer = flyerManager.current

  const onChange = (e) => {
    flyer.setCustomSchoolname(e.target.value)
  }

  return (
    <TextField
      id={'customSchoolname'}
      className={classes.textField}
      value={flyer.custom_schulhaus_name}
      onChange={onChange}
      helperText={t('ampelflyer.configurator.descriptionCustomSchoolname')}
      variant="filled"
      fullWidth
    />
  )
})

const ConfigImpress = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { flyerManager } = useContext(DataStore)
  const flyer = flyerManager.current

  const onChange = (e) => {
    flyer.setImpress(e.target.value)
  }

  return useObserver(() => (
    <TextField
      id={'impress'}
      className={classes.textField}
      value={flyer.impress}
      onChange={onChange}
      helperText={t('ampelflyer.configurator.descriptionImpress')}
      variant="filled"
      multiline
      fullWidth
      rows={6}
      rowsMax="10"
    />
  ))
}

const ConfigTrafficlightData = observer((props) => {
  const { active } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const { serviceMenu } = useContext(UiStore)
  const { flyerManager,COLORS } = useContext(DataStore)
  const flyer = flyerManager.current

  let colorForText = ''
  let textValue = ''
  let remainingCharacters = flyer.SERVICE_TEXT_MAX_CHARACTERS
  let onChange = () => {}
  if (active === COLORS.GREEN) {
    colorForText = t('ampelflyer.configurator.colorGreen')
    textValue = flyer.text_green_data
    remainingCharacters = flyer.text_green_remaining_characters
    onChange = (e) => { flyer.setTextGreenData(e.target.value) }
  }
  else if (active === COLORS.YELLOW) {
    colorForText = t('ampelflyer.configurator.colorYellow')
    textValue = flyer.text_yellow_data
    remainingCharacters = flyer.text_yellow_remaining_characters
    onChange = (e) => { flyer.setTextYellowData(e.target.value) }
  }
  else if (active === COLORS.RED) {
    colorForText = t('ampelflyer.configurator.colorRed')
    textValue = flyer.text_red_data
    remainingCharacters = flyer.text_red_remaining_characters
    onChange = (e) => { flyer.setTextRedData(e.target.value) }
  }

  return (
   <Grid container className={classes.root} spacing={2}>
    <Grid item xs={2} md={1}>
     <TrafficLight active={active}/>
    </Grid>

    <Grid item xs={10} md={11}>
     <ServicesMenu
      active={active}
      colorForText={colorForText}
     />
    </Grid>

    <Grid item xs={12} style={{position:'relative'}}>
     <TextField
      id={active+'-data-text'}
      value={textValue}
      onChange={onChange}
      helperText={t('ampelflyer.configurator.descriptionData',{color:colorForText})}
      className={classes.textField}
      variant="filled"
      multiline
      fullWidth
      rows={4}
      rowsMax="6"
      inputProps={{ maxLength: flyer.SERVICE_TEXT_MAX_CHARACTERS }}
     />
     <div className={classes.remainingCharacters}>{remainingCharacters}</div>
    </Grid>

   </Grid>
  )
})

const ServicesMenu = observer((props) => {
  const classes = useStyles()
  const { active,colorForText } = props
  const { t } = useTranslation()
  const { flyerManager,COLORS } = useContext(DataStore)
  const { serviceMenu,serviceModal } = useContext(UiStore)
  const [open,setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  const flyer = flyerManager.current

  const openMenu = (e) => {
    serviceMenu.setAnchorEl(e.currentTarget)
    handleOpen()
  }

  const toggleService = id => event => {
   const service = flyerManager.getServiceById(id)
   if (flyer.isSelected(active,service)) {
    flyer.removeService(active,service)
   } else {
    flyer.addService(active,service)
   }
  }
  const removeService = (service) =>  {
    flyer.removeService(active,service)
  }

  return (
   <>

    <Button
     aria-label="add-service"
     onClick={openMenu}
     startIcon={<Add />}> {t('ampelflyer.configurator.descriptionServices',{color:colorForText})} </Button>

    <Menu
      id={'services-'+active}
      anchorEl={serviceMenu.anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5 + 8,
        },
      }}
    >
    <MenuItem key="add-individual-service" onClick={serviceModal.handleOpen} style={{borderBottom:'1px solid #ddd'}}>
     <ListItemIcon>
      <Add />
     </ListItemIcon>
     <Typography variant="inherit" noWrap> {t('ampelflyer.configurator.addService')} </Typography>
    </MenuItem>
    {flyerManager.services.map((service,index) => {
      let icon = null
      if (service.image) {
        icon = <Avatar className={classes.serviceMenuIcon} style={{backgroundImage:`url(${service.image})`}}/>
      } else {
        icon = <Avatar> {service.name.charAt(0)} </Avatar>
      }
      const isSelected = flyer.isSelected(active,service)
      const background = isSelected ? '#ddd' : ''
      return <MenuItem key={'service-'+service.id} onClick={toggleService(service.id)} style={{ background:background }}>
      <ListItemAvatar>
       {icon}
      </ListItemAvatar>
       <Typography variant="inherit" noWrap>
        {service.name}
       </Typography>
      </MenuItem>
     })}
    </Menu>

    <SelectedServices
     active={active}
     removeService={removeService}
    />
   </>
  )
})

const SelectedServices = observer((props) => {
  const classes = useStyles()
  const { active,removeService } = props
  const { flyerManager, COLORS } = useContext(DataStore)
  const flyer = flyerManager.current

  let selectedServices = []
  if (active === COLORS.GREEN) {
    selectedServices = flyer.services_green
  }
  else if (active === COLORS.YELLOW) {
    selectedServices = flyer.services_yellow
  }
  else if (active === COLORS.RED) {
    selectedServices = flyer.services_red
  }

  return (
   <Paper className={classes.chipContainer} elevation={0}>
    {selectedServices.map((service,i) => {
      let icon = null
      if (service.image) {
        icon = <Avatar src={service.image}/>
      } else {
        icon = <Avatar> {service.name.charAt(0)} </Avatar>
      }
      const name = service.name + '_' + Math.floor(Math.random() * 99999)
      return <Chip
       avatar={icon}
       variant="outlined"
       onDelete={removeService.bind(this,service)}
       key={name}
       label={service.name}
       className={classes.chip}
      />
     })}
    </Paper>
  )
})


export default Configurator
