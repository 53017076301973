import React, { useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Trans,useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import UIStore from './stores/ui'
import AdminUIStore from 'core/stores/ui'
import { observer } from 'mobx-react-lite'

import {
  Typography,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  InputBase,
  Paper,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import {
  Search,Check,Clear
} from '@material-ui/icons'

const KIBS_IP = "185.101.156.83"

const useStyles = makeStyles(theme => ({
  container:{
    display:'flex',
    flexDirection:'column',
    width:'100%',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    minHeight:'80vh',
    position:"relative"
  },
  subcontainer:{
    maxWidth:600,
    gap:"25px",
    padding:"1rem",
    display:"flex",
    flexDirection:"column"
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  table: {
    width:"100%",
  },
  kibsip:{
    position:"absolute",
    right:"0.5rem",
    bottom:"0.5rem"
  }
}))

const Redirecter = observer(() => {
  const { t,i18n } = useTranslation()
  const classes = useStyles()
  const ui = useContext(UIStore)
  const data = useContext(DataStore)
  const { notifier} = useContext(AdminUIStore)

  const doSubdomain = () => {
    data.setDomain("www."+data.domain)
    ui.setMode(ui.modes.ENTER_DOMAIN)
    data.checkDomain((state) => {
      if (state) {
        ui.setMode(ui.modes.ENTER_REDIRECT)
      }
    },
    () => {}
   )
  }

  const onChangeDomain = (e) => {
    data.setDomain(e.target.value)
  }
  const onKeyPressDomain = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.stopPropagation()
      e.preventDefault()
      checkDomain()
      return false
    }
    return false
  }
  const onChangeRedirect = (e) => {
    data.setRedirect(e.target.value)
  }
  const onKeyPressRedirect = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.stopPropagation()
      e.preventDefault()
      applyForRedirect()
      return false
    }
    return false
  }

  const checkDomain = () => {
    ui.setMode(ui.modes.ENTER_DOMAIN)
    data.checkDomain((state) => {
      if (state) {
        ui.setMode(ui.modes.ENTER_REDIRECT)
      }
    },
    () => {}
   )
  }

  const applyForRedirect = () => {
    data.applyForRedirect(
      (success) => {
        if (success) {
          notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
          ui.setMode(ui.modes.A_RECORD_EXPLAINER)
        } else {
          notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
        }
      },
      () => {
        notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
   <section className={classes.container}>

   <Typography variant="h3">{t('redirecter.title')}</Typography>
   <div className={classes.subcontainer}>
    <Typography variant="body1">{t('redirecter.text1')}</Typography>
    <Typography variant="body2">{t('redirecter.text2')}</Typography>
   </div>

   <div className={classes.kibsip}>{`${t('redirecter.kibsIp')}: ${KIBS_IP}`}</div>

   <Paper component="form" className={classes.root}>
    <InputBase
      className={classes.input}
      placeholder={t('redirecter.domain')}
      value={data.domain}
      onChange={onChangeDomain}
      onKeyPress={onKeyPressDomain}
    />
     {data.state !== -1 &&
      <>
       {(data.state ? <Check /> : <Clear />)}
      </>
     }
     <Divider className={classes.divider} orientation="vertical" />
     <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={checkDomain}>
       <Search />
     </IconButton>
   </Paper>

   {data.state !== -1 &&
    !data.state && <Typography component="p" variant="body1">{t('redirecter.isPending')}</Typography>
   }

  {ui.mode >= ui.modes.ENTER_REDIRECT &&
   <>
    <Paper component="form" className={classes.root}>
     <InputBase
       className={classes.input}
       placeholder={t('redirecter.redirect')}
       value={data.redirect}
       onChange={onChangeRedirect}
       onKeyPress={onKeyPressRedirect}
     />
    </Paper>
    {data.redirect !== "" && <Button className={classes.root} variant="contained" color="secondary" onClick={applyForRedirect}>{t('redirecter.apply')}</Button>}
   </>
  }

  {ui.mode >= ui.modes.A_RECORD_EXPLAINER &&
   <div className={classes.root} style={{ flexDirection:"column",marginTop:20 }}>
    <Typography component="p" variant="body1">{t('redirecter.nearlyDone')}</Typography>
    <List dense>
     <ListItem>
      <ListItemText primary={`1. ${t('redirecter.explain.one')}`} />
     </ListItem>
     <ListItem>
      <ListItemText primary={`2. ${t('redirecter.explain.two')}`} />
     </ListItem>
     <ListItem>
      <ListItemText primary={`3. ${t('redirecter.explain.three')}`} />
     </ListItem>
    </List>

    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
         <TableRow>
          <TableCell align="right"><strong>{data.domain}</strong></TableCell>
          <TableCell align="right">IN</TableCell>
          <TableCell align="right">A</TableCell>
          <TableCell align="right"><strong>{KIBS_IP}</strong></TableCell>
         </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    {data.registrar && <Typography component="p" variant="body1">{`${t('redirecter.registrar')} ${data.registrar}`}</Typography>}

    <List dense>
     <ListItem>
      <ListItemText primary={`${t('redirecter.explain.four')}`} />
     </ListItem>
    </List>

    {ui.isTLD(data.domain) &&
     <>
      <Typography component="p">{t('redirecter.needSubdomain')}</Typography>
      <Button variant="contained" color="secondary" onClick={doSubdomain}>{`${t('redirecter.from')} www.${data.domain} ${t('redirecter.doAlso')}`}</Button>
     </>
    }

   </div>
  }

   </section>
  )
})




export default Redirecter
