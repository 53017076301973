import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { toJS } from 'mobx'
import { getContrast } from 'shared/functions/helper'
import RichTextEditor from 'react-rte-link-extended'

import DataStore from './stores/data'
import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'

import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  Chip,
  MenuItem,
  Input,
  Avatar,
  CardMedia
} from '@material-ui/core'

import {
  Menu,
  Search
} from '@material-ui/icons'
import KibsDropzone from 'shared/components/dropzone/index'


const useStyles = makeStyles(theme => ({
 root: {
  width: '100%',
  maxWidth:theme.breakpoints.values.sm,
  margin:'0 auto'
 },
 button: {
   marginTop: theme.spacing(1),
   marginRight: theme.spacing(1),
 },
 actionsContainer: {
   marginBottom: theme.spacing(2),
 },
 resetContainer: {
   padding: theme.spacing(3),
 },
 chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  imageContainer:{
    width:'100%'
  },
  image:{
    width:'100%',
    height:300,
    maxHeight:300,
    backgroundSize:'cover'
  },
  cycle:{
    borderRadius:'50%',
    width:50,
    height:50,
    display:'inline-block',
    margin:theme.spacing(1),
    cursor:'pointer',
    border:'1px solid #eee'
  },
  cycleNumber:{
    display:'flex',
    height:'100%',
    alignItems:'center',
    justifyContent:'center'
  },
  rte:{
    minHeight:300
  },
  container:{
    minWidth:350
  }
}))

// props -- example, mode ('update'), catalogName, categories
const Suggestion = observer((props) => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const { suggestionUi } = useContext(UiStore)
  const { t } = useTranslation()
  const l = useLocation()
  let { mode,catalogName,allCategories,example,catalogId,onFinish } = props
  const suggestion = example ? example : catalog.suggestion
  allCategories = allCategories ? allCategories : catalog.categories
  catalogId = catalogId ? catalogId : catalog.id

  const steps = [
    t('catalog.frontend.suggestion.link'),
    t('catalog.frontend.suggestion.heading'),
    t('catalog.frontend.suggestion.description'),
    t('catalog.frontend.suggestion.categories'),
    t('catalog.frontend.suggestion.titleImage'),
    t('catalog.frontend.suggestion.zyklus')
  ]

  const stepComponents = [
   <InternetLocation suggestion={suggestion} />,
   <Title suggestion={suggestion} />,
   <Description2 suggestion={suggestion} />,
   <Categories suggestion={suggestion} allCategories={allCategories} />,
   <Image suggestion={suggestion} />,
   <Zyklus suggestion={suggestion} />
  ]

  const suggest = () => {
   suggestion.suggest(
    catalogId,
    () => {
      suggestionUi.setActiveStep(0)
      if (onFinish) { onFinish() }
    },
    () => {
      suggestionUi.setActiveStep(0)
      if (onFinish) { onFinish() }
    }
   )
  }

  const accept = () => {
   suggestion.accept(
     catalogId,
     () => {
       suggestionUi.setActiveStep(0)
       if (onFinish) { onFinish() }
     },
     () => {
       suggestionUi.setActiveStep(0)
       if (onFinish) { onFinish() }
     }
   )
  }

  const decline = () => {
   suggestion.decline(
     catalogId,
     () => {
       suggestionUi.setActiveStep(0)
       if (onFinish) { onFinish() }
     },
     () => {
       suggestionUi.setActiveStep(0)
       if (onFinish) { onFinish() }
     }
   )
  }

  let actions = [
   <Button
    key={"suggest-suggestion"}
    variant="contained"
    color="secondary"
    onClick={suggest}
    className={classes.button}
   > {t('core.general.suggest')} </Button>
  ]

  if (mode === 'edit') {
    actions = [
      <Button
       key={'accept-suggestion'}
       variant="contained"
       color="secondary"
       onClick={accept}
       className={classes.button}
      > {t('core.general.update')} </Button>
    ]
  }

  if (mode === 'update') {
    actions = [
     <Button
      key={'decline-suggestion'}
       variant="contained"
       color="secondary"
       onClick={decline}
       className={classes.button}
      > {t('core.general.decline')} </Button>,
      <Button
       key={'accept-suggestion'}
       variant="contained"
       color="secondary"
       onClick={accept}
       className={classes.button}
      > {t('core.general.accept')} </Button>
    ]
  }

  return (
   <>
    <Grid container justify="center" alignItems="center" className={classes.container}>
     <Grid item xs={12}>
     <div className={classes.root}>
      <Stepper activeStep={suggestionUi.activeStep} orientation="vertical">
       {steps.map((step,i) => {
         return <Step key={steps[i]}>
          <StepLabel>{steps[i]}</StepLabel>
          <StepContent>
           {stepComponents[i]}
           <div className={classes.actionsContainer}>
            {suggestionUi.activeStep > 0 &&
             <Button
              onClick={suggestionUi.prevStep}
              className={classes.button}
             >
              {t('core.general.back')}
             </Button>
            }
            {
              suggestionUi.activeStep === steps.length - 1
              ?
              actions
              :
              <Button
                key={"continue-step"}
                variant="contained"
                color="secondary"
                onClick={suggestionUi.nextStep}
                className={classes.button}
              >
                {t('core.general.continue')}
              </Button>
            }

           </div>
          </StepContent>
        </Step>
       })}
      </Stepper>
     </div>
     </Grid>
    </Grid>
   </>
  )
})
const InternetLocation = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion } = props

  const onChange = (e) => {
    suggestion.setLink(e.target.value)
  }

  return (
    <TextField
      id={'suggestion-link'}
      value={suggestion.link}
      onChange={onChange}
      helperText={t('catalog.frontend.suggestion.link')}
      variant="filled"
      fullWidth
    />
  )
})
const Title = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion } = props

  const onChange = (e) => {
    suggestion.setTitle(e.target.value)
  }

  return (
    <TextField
      id={'suggestion-title'}
      value={suggestion.title}
      onChange={onChange}
      helperText={t('catalog.frontend.suggestion.heading')}
      variant="filled"
      fullWidth
    />
  )
})
const Description2 = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion } = props

  const onChange = (value) => {
    suggestion.setDescription(value)
  }

  return (
   <>
    <RichTextEditor
     value={suggestion.description}
     onChange={onChange}
    />
    <Typography component="p" variant="caption">{t('catalog.frontend.suggestion.description')}</Typography>
   </>
  )
})
const Description = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion } = props

  const onChange = (e) => {
    suggestion.setDescription(e.target.value)
  }

  return (
    <TextField
      id={'suggestion-description'}
      multiline
      rows={8}
      value={suggestion.description}
      onChange={onChange}
      helperText={t('catalog.frontend.suggestion.description')}
      variant="filled"
      fullWidth
    />
  )
})
function getStyles(category, categories, theme) {
  return {
    fontWeight:
      categories.filter(c => c.id === category.id).length === 0
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Categories = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion,allCategories } = props
  const theme = useTheme()

  const onChange = event => {
   suggestion.setCategories(event.target.value)
  }
  const removeCategory = category => event => {
    suggestion.removeCategory(category)
  }

  return (
    <FormControl fullWidth>
      <Select
        id="select-categories-id"
        multiple
        value={toJS(suggestion.categories)}
        onChange={onChange}
        input={<Input id="select-multiple-categories" />}
        renderValue={selected => (
          <div className={classes.chips}>
            {suggestion.categories.map((category,i) => {
              let icon = null
              let backgroundColor = category.color
              const color = getContrast(category.color)
              if (category.icon) {
                icon = <Avatar src={category.icon} style={{ backgroundColor,color,border:'1px solid' }}/>
              } else {
                icon = <Avatar style={{ backgroundColor,color,border:'1px solid' }}> {category.name.charAt(0).toUpperCase()} </Avatar>
              }
              return <Chip
               avatar={icon}
               variant="outlined"
               onDelete={removeCategory(category)}
               key={'catagory-'+category.id}
               label={category.name}
               className={classes.chip}
               style={{ backgroundColor,color }}
              />
             })}
          </div>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          }
        }}
      >
        {allCategories.map((c,j) => (
          <MenuItem key={'c-'+j} value={c} style={getStyles(c, suggestion.categories, theme)}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
const Image = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion } = props
  const globalUi = useContext(GlobalUiStore)

  const onFiles = (files) => {
   if (files.length > 0) {
    globalUi.setLoading(true)
    suggestion.uploadImage(files,catalog.id,() => {
     globalUi.setLoading(false)
    })
   }
  }
  return (
   <div className={classes.imageContainer}>
    {suggestion.image && suggestion.image !== '' && <CardMedia className={classes.image} image={suggestion.image} />}
    <KibsDropzone
     accept="image/*"
     multiple={false}
     onFiles={onFiles}
     showThumbnails={false}
    />
   </div>
  )
})
const Zyklus = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { suggestion } = props

  const backgroundColorC1 = suggestion.zyklus1 ? '#e39b2d' : '#fff'
  const backgroundColorC2 = suggestion.zyklus2 ? '#2f8fce' : '#fff'
  const backgroundColorC3 = suggestion.zyklus3 ? '#97c941' : '#fff'
  const elevationC1 = suggestion.zyklus1 ? 0 : 2
  const elevationC2 = suggestion.zyklus2 ? 0 : 2
  const elevationC3 = suggestion.zyklus3 ? 0 : 2

  const onClick = cycle => event => {
    if (cycle === 1) suggestion.toggleZyklus1(true)
    else if (cycle === 2) suggestion.toggleZyklus2(true)
    else if (cycle === 3) suggestion.toggleZyklus3(true)
  }

  return (
    <div>
     <Paper key={"zyklus1"} onClick={onClick(1)} elevation={elevationC1} style={{backgroundColor:backgroundColorC1}} className={classes.cycle}>
      <div className={classes.cycleNumber}>1</div>
     </Paper>
     <Paper key={"zyklus2"} onClick={onClick(2)} elevation={elevationC2} style={{backgroundColor:backgroundColorC2}} className={classes.cycle}>
      <div className={classes.cycleNumber}>2</div>
     </Paper>
     <Paper key={"zyklus3"} onClick={onClick(3)} elevation={elevationC3} style={{backgroundColor:backgroundColorC3}} className={classes.cycle}>
      <div className={classes.cycleNumber}>3</div>
     </Paper>
    </div>
  )
})



export default Suggestion
