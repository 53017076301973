import React, { useContext,useCallback,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  Menu,
  MenuItem
} from '@material-ui/core'
import { KibsLogoImage } from 'shared/components/logo/index'
import { ExpandMore } from '@material-ui/icons'

import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'
import i18next from 'i18next'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height:50
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  footer:{
    textAlign:'center',
  },
  colPadding:{

  }
}));

const Footer = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ui = useContext(UiStore)
  const footer = ui.footer
  const data = useContext(DataStore)
  const { kibsmeta } = data

  useEffect(() => {
    data.setKibsMeta(
      () => {},
      () => {}
    )
  },[])

  const changeLanguage = locale => () => {
    i18next.changeLanguage(locale)
    footer.setLanguageMenuAnchorEl(null)
    footer.handleCloseLanguageMenu()
  }
  const openMenu = (e) => {
    footer.setLanguageMenuAnchorEl(e.currentTarget)
    footer.handleOpenLanguageMenu()
  }

  let selectedOption = ''
  if (i18next.language === 'de')  selectedOption = t('core.languages.german')
  else if (i18next.language === 'fr') selectedOption = t('core.languages.french')
  else selectedOption = t('core.languages.german')

  const logo = KibsLogoImage()


  return (
    ui.withLayout
    ?
    <footer className={classes.footer}>
        <div className="footer-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-12" >
                <div className="single-footer-widget">
                 <ul className="footer-contact-info">
                  <li>
                    <i className="flaticon-call" />
                    <span>{t('core.footer.phone')}</span>
                    <a href={'tel:'+kibsmeta.telefon}>{kibsmeta.telefon}</a>
                  </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="single-footer-widget pl-5">
                 <ul className="footer-contact-info">
                  <li>
                   <i className="flaticon-email-1" />
                   <span>{t('core.footer.mail')}</span>
                   <a href={'mailto:'+kibsmeta.mail}>{kibsmeta.mail}</a>
                  </li>
                 </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="single-footer-widget pl-5">
                  <ul className="footer-contact-info">
                    <li>
                      <i className="flaticon-pin" />
                      <span>{t('core.footer.address')}</span>
                      <a href="!#" target="_blank">
                       {kibsmeta.strasse}, {kibsmeta.plz} {kibsmeta.ort}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display:'flex', justifyContent:'flex-end', alignItems:'center' }}>
            <Button variant="outlined" aria-controls="language-menu" aria-haspopup="true" onClick={openMenu}>
             {selectedOption}
             <ExpandMore />
            </Button>
             <Menu
               id="language-menu"
               anchorEl={footer.languageMenuAnchorEl}
               keepMounted
               open={footer.languageMenuOpen}
               onClose={footer.handleCloseLanguageMenu}
             >
               <MenuItem onClick={changeLanguage('de')}>{t('core.languages.german')}</MenuItem>
               <MenuItem onClick={changeLanguage('fr')}>{t('core.languages.french')}</MenuItem>
             </Menu>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="copyright-area-content">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <p>
                    <i className="far fa-copyright" />
                    {new Date().getFullYear()} @ {kibsmeta.name}
                    <a href="" target="_blank">
                      {kibsmeta.shortname}
                    </a>
                  </p>
                </div>
                <div className="col-lg-6 col-md-6">
                  <ul>
                    <li>
                      <Link to="/rechtliches">{t('core.footer.dataprot')}</Link>
                    </li>
                    <li>
                      <Link to="/admin">{t('core.footer.admin')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="go-top">
          <i className="bx bx-chevron-up" />
        </div>

     </footer>
    :
    null
  )
})

export default Footer
