import React, { useContext,useEffect,useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import RichTextEditor from 'react-rte-link-extended'
import GlobalUiStore from 'core/stores/ui'

import {
  Grid,
  Typography,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  Paper,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Tooltip,
  Chip,
  ListItemSecondaryAction
} from '@material-ui/core'
import { Search,AccountCircle,Home,Mail,Phone } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop:25
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  profile:{
    marginTop:theme.spacing(3)
  },
  authIcon:{
    height:40,
    width:'auto'
  },
  subheader:{
    fontStyle:'italic'
  }
}));

export const ChangeTextHelpdesk = observer(() => {
  const { utilityText } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const { notifier } = useContext(GlobalUiStore)

  const onChange = (value) => {
    utilityText.setTextHelpdesk(value)
  }

  const [debouncedSave] = useDebouncedCallback(
   () => {
     utilityText.updateTextHelpdesk()
     notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
   },
   500
  )

  useEffect(utilityText.getTextHelpdesk,[])
  useEffect(() => debouncedSave,[utilityText.textHelpdesk])

  return (
    <Container component="main" maxWidth="md" id="ref-parent-0">
     <div className={classes.about}>
      <RichTextEditor
       value={utilityText.textHelpdesk}
       onChange={onChange}
      />
      <Typography component="p" variant="caption">{t('core.admin.utility.textHelpdesk')}</Typography>
     </div>
    </Container>
  )
})

export const ChangeTextCommunity = observer(() => {
  const { utilityText } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const { notifier } = useContext(GlobalUiStore)

  const onChange = (value) => {
    utilityText.setTextCommunity(value)
  }

  const [debouncedSave] = useDebouncedCallback(
   () => {
     utilityText.updateTextCommunity()
     notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
   },
   500
  )

  useEffect(utilityText.getTextCommunity,[])
  useEffect(() => debouncedSave,[utilityText.textCommunity])

  return (
    <Container component="main" maxWidth="md" id="ref-parent-0">
     <div className={classes.about}>
      <RichTextEditor
       value={utilityText.textCommunity}
       onChange={onChange}
      />
      <Typography component="p" variant="caption">{t('core.admin.utility.textCommunity')}</Typography>
     </div>
    </Container>
  )
})

export const ChangeTextConference = observer(() => {
  const { utilityText } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const { notifier } = useContext(GlobalUiStore)

  const onChange = (value) => {
    utilityText.setTextConference(value)
  }

  const [debouncedSave] = useDebouncedCallback(
   () => {
     utilityText.updateTextConference()
     notifier.push(t('core.notifier.saved'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
   },
   500
  )

  useEffect(utilityText.getTextConference,[])
  useEffect(() => debouncedSave,[utilityText.textConference])

  return (
    <Container component="main" maxWidth="md" id="ref-parent-0">
     <div className={classes.about}>
      <RichTextEditor
       value={utilityText.textConference}
       onChange={onChange}
      />
      <Typography component="p" variant="caption">{t('core.admin.utility.textConference')}</Typography>
     </div>
    </Container>
  )
})
