import React, { useContext,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import FreshStart from './FreshStart'
import Location from 'shared/components/location'
import SchoolServices from './SchoolServices'
import PortalServices from './PortalServices'

import {
  Container
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root:{
   position:'relative',
  }
}));


const Dashboard = observer(() => {
  const classes = useStyles()
  const { notifier } = useContext(UiStore)
  const { user } = useContext(DataStore)

  useEffect(() => {
    user.check(
      () => {},
      () => {}
    )
  },[])


  return (
   <div className={classes.root}>
    {user.isAuthenticated && (user.schools.length > 0 || user.pendings.length > 0) &&
     <Container>
      <SchoolServices />
      <PortalServices />
     </Container>
    }
    {user.isAuthenticated && user.schools.length === 0 && user.pendings.length === 0 && <FreshStart />}
    {user.isAuthenticated && <Location />}
    {!user.isAuthenticated &&
     <Container>
      <PortalServices />
     </Container>
    }
   </div>
  )
})


export default Dashboard
