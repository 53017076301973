import React, {useEffect,useContext} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import moment from 'moment'
import Linkify from 'react-linkify'

import {
  Typography,
  Button
} from '@material-ui/core'
import {
  ArrowRight
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  toNewsBtn: {
  },
  image:{
    maxWidth:'100%'
  },
  btnColor:{
  },
}))

const News = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  let { news } = useContext(DataStore)
  const history = useHistory()

  useEffect(() => {
    news.getNews()
  },[])

  const toNews = () => history.push('/aktuelles')

  var linkifyOptions =
  {
    formatHref: function (href, type) {
      if (type === 'hashtag') {
        href = 'https://twitter.com/hashtag/' + href.substring(1);
      }
      return href;
    }
  }

  return (
    <section id="aktuelles" className="blog-area pt-100 pb-70">
      <div className="container-fluid">
        <div className="section-title">
          <h2>{t('core.landingpage.news')}</h2>
          <div className="bar" />
        </div>
        <div className="row">
        {news.tweets.map((article,i) => {
          if (i > 2) return null
          return <div className="col-lg-4 col-md-6">
           <div className="single-blog">
             {article.extended_entities && article.extended_entities.media &&
              <div className="image">
               <img src={article.extended_entities.media[0].media_url_https} alt="image" className={classes.image} />
              </div>
             }
             <div className="content">
               <span>{moment(article.created_at).fromNow()}</span>
               <Typography variant="h6" component="p"><Linkify options={linkifyOptions}>{article.text}</Linkify></Typography>
             </div>
           </div>
          </div>
        })}

        </div>
        <div className={classes.toNewsBtn}>
         <Button variant="contained" onClick={toNews} className={`${classes.btnColor} phButton`}>
          {t('core.landingpage.goToNews')}
         </Button>
        </div>
      </div>
    </section>
  )
})

export default News
