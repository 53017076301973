import React, { useContext,useEffect } from 'react'

import { KibsLogo } from '../logo/index'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import { useObserver } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import OAuthView from './oauth'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'
import history from 'history.js'

const useStyles = makeStyles(theme => ({
  container:{
    minHeight:'80vh'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spacing:{
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const SignIn = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(DataStore)
  const { oauth2Dialog,notifier } = useContext(UiStore)
  const { hideLinks, callback} = props

  const signIn = () => {
    user.signIn(
    () => {
      if (callback) {
        callback()
      } else {
        const params = queryString.parse(window.location.search)
        if (params && params.redirect_uri) {
          history.push(params.redirect_uri)
        } else {
          history.push('/dashboard')
        }
      }
      notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
     },
    () => {
      notifier.push(t('core.notifier.wrongCredentials'),notifier.variants.ERROR)
    }
   )
  }

  useEffect(() => {
   return () => { oauth2Dialog.handleClose()  }
  })

  const setMail = (e) => {
    user.setMail(e.target.value)
  }
  const setPassword = (e) => {
    user.setPassword(e.target.value)
  }
  const submitOnEnter = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      signIn()
    }
  }

  return useObserver(() => (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
       <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.spacing}>
          {t('core.login.title')}
        </Typography>
          <OAuthView callback={callback} />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('core.login.email')}
            onChange={setMail}
            onKeyDown={submitOnEnter}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('core.login.password')}
            onChange={setPassword}
            onKeyDown={submitOnEnter}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={signIn}
            className={classes.submit}
          >
            {t('core.login.title')}
          </Button>
          {!hideLinks &&
            <Grid container>
           <Grid item xs>
             <Link to="/resetpw" variant="body2">
               {t('core.login.forgotPassword')}
             </Link>
           </Grid>
           <Grid item>
             <Link to="/signup" variant="body2">
               {t('core.login.dontHaveAccount')}
             </Link>
           </Grid>
         </Grid>
          }
      </div>
    </Container>
  ));
}

export default SignIn
