import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import Release from './Release'
import Configuration from './Configuration'
import Catalogs from './Catalogs'
import Surveys from './Surveys'
import Utility from './Utility'

import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',

  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



const AdminBackend = observer(() => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { t } = useTranslation()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="admin backend tabs"
        >
          <Tab label={t('core.admin.release.title')} {...a11yProps(0)} />
          <Tab label={t('core.admin.surveys')} {...a11yProps(1)} />
          <Tab label={t('core.admin.catalogs')} {...a11yProps(2)} />
          <Tab label={t('core.admin.utility.title')} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} children={<Release />} />
      <TabPanel value={value} index={1} children={<Surveys />} />
      <TabPanel value={value} index={2} children={<Catalogs />}/>
      <TabPanel value={value} index={3} children={<Utility />}/>
    </div>
  );
})




export default AdminBackend
