import React, { useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles,withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { getContrast } from 'shared/functions/helper'
import { HashLink as Link } from 'react-router-hash-link'
import i18next from 'i18n'
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/fr'

import DataStore from './stores/data'
import UiStore from './stores/ui'

import {
 Avatar,
 Container,
 Card,
 CardActionArea,
 CardActions,
 CardContent,
 CardMedia,
 Button,
 Typography,
 Dialog,
 Slide,
 AppBar,
 Toolbar,
 IconButton,
 Divider,
 TextField,
 List,
 ListItem,
 ListItemText,
 ListItemAvatar,
 Tooltip,
 Badge
} from '@material-ui/core'

import {
 Close,
 LaunchOutlined,
 ThumbUpOutlined,
 ThumbUp,
 ThumbDownOutlined,
 ThumbDown,
 CommentOutlined
} from '@material-ui/icons'

import {
  red
} from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  cycleContainer:{
    display:'flex',
    flex:1,
    justifyContent:'flex-end'
  },
  cycle:{
    borderRadius:'50%',
    display:'inline-block',
    margin:theme.spacing(1),
    cursor:'pointer',
    border:'1px solid #eee'
  },
  cycleSize:{
    width:30,
    height:30,
    margin:theme.spacing(0.5)
  },
  avatarSize:{
    width:30,
    height:30
  },
  cycleNumber:{
    display:'flex',
    height:'100%',
    alignItems:'center',
    justifyContent:'center'
  },
  card: {
    position:'relative',
  },
  media: {
    height: 140,
  },
  cardContent:{
    height:120,
    overflow:'hidden',
  },
  cardTextFade:{
    position: 'absolute',
    width: '100%',
    top: 30,
    height: 30,
    left: 0,
    zIndex: 2,
    background: '-webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(#fff))',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container:{
    marginTop:theme.spacing(1)
  },
  image:{
    margin:'0 auto',
    marginTop:theme.spacing(2),
    maxWidth:'100%',
    maxHeight:'70vh',
    display:'block'
  },
  killLinkStyle:{
    textDecoration:'none',
    color:'inherit'
  },
  commentBox:{
    display:'flex',
    flexDirection:'column',
    maxWidth:350,
    margin:'0 auto'
  },
  comments:{
    borderTop:'1px solid #ddd',
    marginTop:theme.spacing(4)
  },
  linkButtonContainer:{
    height:'3rem',
    position:'relative',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  countComments:{
    position:'absolute',
    top:5,
    right:5
  }
}))

export const CatalogItem = observer((props) => {
  const classes = useStyles()
  const { catalog,cycle } = useContext(DataStore)
  const { example } = props
  const { catalogUi } = useContext(UiStore)
  const { t } = useTranslation()

  useEffect(() => {
   moment.locale(i18next.language)
  },[])

  const inspectItem = () => {
    catalog.setCurrentCatalogItem(example)
    catalogUi.handleOpenInspect()
  }

  const editItem = () => {
    if (catalog.isAdmin) {
     catalog.currentCatalogItem = example
     catalogUi.handleOpenEdit()
    }
  }

  const deleteItem = () => {
    if (catalog.isAdmin) {
      catalog.currentItemToEdit.delete(
        catalog.id,
        example.id,
        () => { catalog.deleteExample(example.id) },
        () => {}
      )
    }
  }

  return (
    <Card className={classes.card} style={{height:(catalog.isAdmin ? 370 : 320)}}>
      <CardActionArea onClick={inspectItem}>
        <CardMedia
          className={classes.media}
          image={example.image}
          title={example.title}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {example.title}
          </Typography>
          <Typography
           variant="body2"
           color="textSecondary"
           component="p"
           style={{position:'relative'}}
          >
           <span dangerouslySetInnerHTML={{__html:example.description}} />
           <span className={classes.cardTextFade} />
          </Typography>
          {catalog.isAdmin && example.comments && example.comments.length > 0 &&
           <div className={classes.countComments}>
            <Badge color="secondary" overlap={'circle'} badgeContent={example.comments.length}>
             <Avatar
              style={{backgroundColor:catalog.primaryColor?catalog.primaryColor:red[500]}}
              > <CommentOutlined /> </Avatar>
            </Badge>
           </div>
          }
        </CardContent>
       </CardActionArea>
      <CatalogItemFilter example={example} />
      {catalog.isAdmin &&
       <CardActions>
        <Button onClick={editItem}>{t('core.general.edit')}</Button>
        <Button onClick={deleteItem}>{t('core.general.delete')}</Button>
       </CardActions>
      }
    </Card>
  )
})

const CatalogItemFilter = observer((props) => {
  const classes = useStyles()
  const { example } = props
  const { catalog,cycle } = useContext(DataStore)
  const { t } = useTranslation()

  return (
    <CardActions>
     {example.categories.map((category,i) => {
       let avatar = []
       if (category.icon) {
        avatar = [<Tooltip title={category.name} placement="top-start" key={'category-'+i}>
         <Avatar
          style={{ backgroundColor:category.color}}
          className={classes.avatarSize}
          imgProps={{
            style:{ width: '100%', height: '100%' }
          }}
          src={category.icon}
         />
        </Tooltip>]
       } else {
         const color = getContrast(category.color).toString()
         avatar = [<Tooltip title={category.name} placement="top-start" key={'category-'+i}>
          <Avatar
           style={{ backgroundColor:category.color,color,border:'1px solid #ddd' }}
           className={classes.avatarSize}
          >{category.name.charAt(0)}{category.name.charAt(1)}</Avatar>
         </Tooltip>]
       }
       return avatar
     })}
    <div className={classes.cycleContainer}>
     {example.zyklus1
      ?
      <Tooltip title={t('catalog.frontend.suggestion.zyklus')+' 1'} placement="top-start">
       <Avatar className={classes.cycleSize} style={{ backgroundColor:cycle.colors.cycle1 }}>
        <div className={classes.cycleNumber}>1</div>
       </Avatar>
      </Tooltip>
      :
      null
     }
     {example.zyklus2
      ?
      <Tooltip title={t('catalog.frontend.suggestion.zyklus')+' 2'} placement="top-start">
       <Avatar className={classes.cycleSize} style={{ backgroundColor:cycle.colors.cycle2 }}>
        <div className={classes.cycleNumber}>2</div>
       </Avatar>
      </Tooltip>
      :
      null
     }
     {example.zyklus3
      ?
      <Tooltip title={t('catalog.frontend.suggestion.zyklus')+' 3'} placement="top-start">
       <Avatar className={classes.cycleSize} style={{ backgroundColor:cycle.colors.cycle3 }}>
        <div className={classes.cycleNumber}>3</div>
       </Avatar>
      </Tooltip>
      :
      null
     }
    </div>
    </CardActions>
  )
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const CatalogItemInspect = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { catalog } = useContext(DataStore)
  const { currentCatalogItem } = catalog
  const { catalogUi } = useContext(UiStore)

  return (
    <Dialog open={catalogUi.inspectOpen} onClose={catalogUi.handleCloseInspect} TransitionComponent={Transition}>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {currentCatalogItem.title}
          </Typography>

          <Social />

        </Toolbar>
      </AppBar>
      <CatalogItemInspectContent data={currentCatalogItem} />
    </Dialog>
  )
})

const CatalogItemInspectContent = observer((props) => {
  const { data } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
   <Container maxWidth="md" className={classes.container}>
    {data.image && data.link &&
     <a href={data.link} target={'_blank'+Math.random}>
      <img src={data.image} alt={data.title} className={classes.image}/>
     </a>
    }
    {data.image && !data.link &&
     <img src={data.image} alt={data.title} className={classes.image}/>
    }
    <CatalogItemFilter example={data} />
    {data.link &&
     <div className={classes.linkButtonContainer}>
      <Button variant="contained" color="secondary" href={data.link} target={'_blank'+Math.random()}>{t('catalog.frontend.goToExternalLink')}</Button>
     </div>
    }
    {data.description && <div dangerouslySetInnerHTML={{__html:data.description}} />}
    <Comments example={data} />
   </Container>
  )
})

const Social = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const { currentCatalogItem } = catalog

  const doThumbs = mode => event => {
    currentCatalogItem.doThumbs(
      mode,
      catalog.id,
      () => { },
      () => { }
    )
  }

  return (
   <>
    <IconButton color="inherit" >
     <Link smooth to={'#comments'} className={classes.killLinkStyle}>
      <CommentOutlined />
     </Link>
    </IconButton> {currentCatalogItem.comments.length}

    <IconButton color="inherit" onClick={doThumbs(currentCatalogItem.mode.LIKE)}>
     {currentCatalogItem.liked ? <ThumbUp /> : <ThumbUpOutlined />}
    </IconButton> {currentCatalogItem.likes}

    <IconButton color="inherit" onClick={doThumbs(currentCatalogItem.mode.DISLIKE)}>
     {currentCatalogItem.disliked ? <ThumbDown /> : <ThumbDownOutlined />}
    </IconButton> {currentCatalogItem.dislikes}
   </>
  )
})

const Comments = observer((props) => {
  const classes = useStyles()
  const { example } = props
  const { comments } = example

  return (
    <section id="comments" className={classes.comments}>
     <List>
      {comments.map((comment,i) => {
       return <Comment comment={comment} key={'comment-'+i} />
      })}
     </List>
    <CommentBox example={example}/>
   </section>
  )
})

const Comment = observer((props) => {
  const classes = useStyles()
  const { comment } = props

  return (
   <ListItem>
    <ListItemAvatar>
     <Avatar alt={comment.author} src={'https://api.adorable.io/avatars/60/'+comment.author+'.png'} />
    </ListItemAvatar>
    <ListItemText
     primary={comment.author}
     secondary={
      <>
       <Typography
        component="span"
        variant="body2"
        className={classes.inline}
        color="textPrimary"
       > {moment(comment.created).fromNow()} -
      </Typography>
      {comment.text}
     </>
    }
    />
    </ListItem>
  )
})

const CommentBox = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { example } = props
  const { post } = example
  const { catalog } = useContext(DataStore)

  const addComment = () => {
   post.addComment(
     catalog.id,
     example.id,
     (comment) => {
      example.addComment(comment)
     },
     () => { }
   )
  }
  const setAuthor = (e) => {
    post.setAuthor(e.target.value)
  }
  const setText = (e) => {
    post.setText(e.target.value)
  }

  return (
   <div className={classes.commentBox}>
    <TextField
     id="comment-author"
     value={post.author}
     onChange={setAuthor}
     label={t('catalog.frontend.yourName')}
     margin="normal"
     variant="outlined"
    />
    <TextField
     id="comment-text"
     value={post.text}
     onChange={setText}
     label={t('catalog.frontend.comment')}
     margin="normal"
     variant="filled"
     multiline
     rows={4}
    />
    <Button variant="outlined" onClick={addComment}>{t('core.general.send')}</Button>
   </div>
  )
})


export default CatalogItem
