import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'

class UI {
 questFrame = false
 nextButton = false
 datetimeOpen = false
 loading = false
 abortOpen = false

 showQuest = () => this.questFrame = true
 hideQuest = () => this.questFrame = false
 showNextButton = () => this.nextButton = true
 hideNextButton = () => this.nextButton = false
 showDateTime = () => this.datetimeOpen = true
 hideDateTime = () => this.datetimeOpen = false
 showLoading = () => this.loading = true
 hideLoading = () => this.loading = false
 handleOpenAbortDialog = () => this.abortOpen = true
 handleCloseAbortDialog = () => this.abortOpen = false
}

decorate(UI,{
  questFrame:observable,
  nextButton:observable,
  datetimeOpen:observable,
  loading:observable,
  abortOpen:observable
})


const UiStore = createContext(new UI())
export default UiStore
