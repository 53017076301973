import React, { useEffect, useContext } from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import DataStore from 'core/stores/data'

import IMG_BARCAMP_LIGHT from 'media/img/tagungen/barcamplight.png'
import IMG_INFOS_SI from 'media/img/tagungen/infosausdersi.png'
import IMG_PROJEKTARBEITEN from 'media/img/tagungen/projektarbeiten.png'
import IMG_GAMEKULTUR from 'media/img/tagungen/gamekultur.png'
import IMG_LASERCUTTEN from 'media/img/tagungen/lasercutten.png'
import IMG_STORIESINDERSCHULE from 'media/img/tagungen/storiesinderschule.png'
import IMG_SPIELEINDERSCHULE from 'media/img/tagungen/spieleinderschule.png'

import {
  Container,
  Typography,
  Grid,
  Button
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
 img:{
   width:'100%'
 },
 coloredSection:{
   background:'#fff5ee'
 },
 gridContainer:{
   paddingTop:theme.spacing(15),
   paddingBottom:theme.spacing(15),
   maxWidth:960,
   margin:'0 auto'
 },
 iframe:{
  width:'100%',
  minHeight:315,
  border:'none'
},
btn:{
  background: 'linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656) !important'
}
}))

const Tagungen = observer(() => {
  const classes = useStyles()
  return (
   <>

    <section className={classes.coloredSection}>

     <Grid container spacing={8} className={classes.gridContainer}>
      <Typography variant="h4" style={{textAlign:'center'}}>SMI Tagung vom 14. November 2020</Typography>
      <Grid item xs={12} sm={12} md={6}>
       <iframe src="https://www.youtube.com/embed/d0sK8eWDpgk?rel=0" allowFullScreen className={classes.iframe}></iframe>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
       <iframe src="https://www.youtube.com/embed/Oai8zSDksq4?rel=0" allowFullScreen className={classes.iframe}></iframe>
      </Grid>
      <Button style={{margin:'0 auto'}} className={classes.btn} variant="contained" href="https://discord.gg/GDCWvgQ">
        Einladung zu Discord
      </Button>
     </Grid>
    </section>

    <section>
     <Grid container spacing={8} className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={6}>
       <img src={IMG_BARCAMP_LIGHT} alt="Barcamp Light" className={classes.img}/>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
       <Typography>
        Das “Barcamp Light” bietet Gelegenheiten, sich in moderierten Sessions über die eingebrachten Themen auszutauschen. Inhalte dazu werden von den Teilnehmenden selbst entwickelt sowie im Verlauf der Tagung ausgestaltet und moderiert. Dabei liegt der Fokus auf dem Erfahrungsaustausch und dem Wissen der Teilnehmenden und nicht auf Workshops im klassischen Sinne.
       </Typography>
      </Grid>
     </Grid>
    </section>

    <section className={classes.coloredSection}>
     <Grid container spacing={8} className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={6}>
       <Typography variant="h6">Helpdesk</Typography>
       <Typography>
          Mit dem Lockdown im Frühling haben wir unsere Helpdesk Präsenz erweitert Ihr erreicht uns:
          Montag - Freitag: 08:30 - 12:30 und 16:00 - 18:00 Uhr
          Telefon: 031 309 22 90
          E-Mail: kontakt@kibs.ch
       </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
       <img src={IMG_INFOS_SI} alt="Infos aus der SI" className={classes.img}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Neues Portal</Typography>
        <Typography>
         Einige von euch werden bemerkt haben, dass unsere Website seit Beginn des Jahres nicht mehr in der alten Funktion verfügbar ist. Seit mehreren Jahren beschäftigt uns die Frage, wie wir das Wiki ablösen und dabei die Funktionalität, Attraktivität und den Nutzen für die Schulen und SMI steigern könnten.
         Wir haben uns entschieden, von Grund auf ein neues Portal zu erstellen, welches einerseits unsere Angebote für euch präsentieren soll, andererseits aber auch dazu da ist, den Austausch unter euch zu unterstützen und die aktuelle Situation bezüglich ICT in den Schulen des Kantons Bern zu dokumentieren.
         Nutzt das Portal, haltet die Angaben zu euren Schulen aktuell, so werden wir bald nicht mehr darüber wettern müssen, dass jede Schule alles neu erfinden muss.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Fellowship</Typography>
        <Typography>
          Mit dem Wechsel der Schulinformatik vom IWM in das Netzwerk Digitale Transformation werden wir das Fellowship Programm eventuell verlieren. Wir kämpfen dafür, aktuell ist aber verordnet, dass wir für das Schuljahr 20/21 keine Ausschreibung machen dürfen.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Swisscom</Typography>
        <Typography>
         Bei der Swisscom gibt es einen Technologiewechsel. LAN-I wird durch Enterprise connect abgelöst. In den kommenden drei Jahren werden alle SAI Anschlüsse auf diese Technologie migriert, was den Austausch des Routers vor Ort nötig macht. Ihr werdet durch die Swisscom kontaktiert. Die Funktionalität und die Bedingungen zu den Anschlüssen ändern sich nicht.
         ADSL wird bis Ende Jahr durch VDSL abgelöst. Wir kontaktieren Schulen, die noch einen alten Anschluss nutzen.
        </Typography>
      </Grid>
     </Grid>
    </section>

    <section>
     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12} sm={12} md={6}>
       <img src={IMG_PROJEKTARBEITEN} alt="Barcamp Light" className={classes.img}/>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
       <Typography variant="h6">Fellowship</Typography>
       <Typography>
        Diesmal finden sich hier Projekte aus zwei Fellowship-Programmen.

        2019/2020 Wir begrüssen Heinz Mäder und Mike Buser bei uns.
        2020/2021 Wir begrüssen Simon Junker und Roger Schneider bei uns.
       </Typography>
      </Grid>
     </Grid>

     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12} sm={8} md={8}>
       <Typography variant="h6">Lasercutten in der Schule - Ein Projekt von Heinz Maeder</Typography>
       <Typography>

       Mit dem Lasercutter kann der digitale Workflow von der Idee zum realen Produkt praktisch erfahren werden.
       Im Vergleich zum Schneideplotter erweitert der Lasercutter die Möglichkeit neben dem bildnerisch-kreativen auch technisch-konstruktiv digital zu arbeiten.
       Weil die Bearbeitungszeit nur wenige Minuten dauert und günstiges, handelsübliches Material geschnitten werden kann, ist er ideal zum Entwickeln von Prototypen.

       Lasercutten in der Schule soll den Lehrpersonen helfen, den Einstieg in das spannende Thema zu finden.

       <p><a href="https://lasercutteninderschule.ch">lasercutteninderschule.ch</a></p>
       </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
       <img src={IMG_LASERCUTTEN} alt="Barcamp Light" className={classes.img}/>
      </Grid>
     </Grid>

     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12} sm={4} md={4}>
       <img src={IMG_GAMEKULTUR} alt="Barcamp Light" className={classes.img}/>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
      <Typography variant="h6">Gamekultur in der Schule - Ein Projekt von Michael Buser</Typography>
      <Typography>
        Game-Zone bei dir! Hole die Kinder und Jugendlichen da ab, wo sie grosse Teile ihrer Freizeit verbringen! Beleuchte mit der Game-Zone verschiedene Trends und Inhalte der Gamekultur direkt im Unterricht.
        Zusammen spielen! In der Game-Zone werden auch Games angespielt. Der Fokus liegt dabei auf Schweizer Games, Indie Games und sog. local co-op Games.
        Weitere Infos ​Willst du mehr über das Projekt erfahren? Besuche die Website, folge dem Projekt auf <a href="https://www.instagram.com/gamekultur_in_der_schule/">Instagram</a> (@gamekultur_in_der_schule) oder melde dich für eine der <a href="https://www.phbern.ch/weiterbildung/15949712-gamekultur-in-der-schule">zwei Weiterbildungen an der PHBern</a> an.
        
       <p><a href="https://gamekulturinderschule.ch">gamekulturinderschule.ch</a></p>
      </Typography>

      </Grid>
     </Grid>

     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12} sm={8} md={8}>
       <Typography variant="h6">Click  Stories mit TWINE - Ein Projekt von Simon Junker.</Typography>
       <Typography>

       Click Stories bieten den Lesern die Möglichkeit, Entscheidungen zu treffen, die den Lauf der Geschichte beeinflussen.
       TWINE ist ein tolles Webtool, womit die SchülerInnen solche nicht-linearen Geschichten selbst schreiben können. Damit lassen sich Kompetenzbereiche des M&I-Unterrichts stufengerecht in Sprachfächer und NMG integrieren.
       Einfache Click Stories können schon ab der 4. Klasse geschrieben werden. Aufgrund des Programmieraspekts eignet sich TWINE aber selbst für den gymnasialen Unterricht.
       StoriesInDerSchule.ch bietet konkrete Untersützungshilfen für Lehrpersonen und gliedert den Aufbau in fünf Stufen damit der Einstieg in TWINE gelingt.

        <p><a href="https://storiesinderschule.ch">storiesinderschule.ch</a></p>
       </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
       <img src={IMG_STORIESINDERSCHULE} alt="Storiesinderschule" className={classes.img}/>
      </Grid>
     </Grid>

     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12} sm={4} md={4}>
       <img src={IMG_SPIELEINDERSCHULE} alt="Spieleinderschule" className={classes.img}/>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
      <Typography variant="h6">Spiele in der Schule - Ein Projekt von Roger Schneider</Typography>
      <Typography>
       SpieleinderSchule.org - ist ein Dienst der bisher zum kollaborativen Erstellen von Kartenspielen die für unterschiedliche Settings im Unterricht eingesetzt werden können zur Verfügung stand.
       Mit dem Entscheid, das Projekt weiterzuentwickeln begeben wir uns auf Pfade neuer Technologien wie zum Beispiel Augmented Reality. Welch neue Möglichkeiten entstehen, wenn Kartenspiele zusätzlich mit digitalen Inhalten angereichert werden können oder sogar aufeinander reagieren, wenn Sie durch eine Smartphonekamera angeschaut werden?
       Es gab soviele wunderbare Rückmeldungen, auch von Euch, die wir in der Weiterentwicklung mit aufnehmen. Der Startschuss ist bereits gefallen. Wir hoffen Euch Mitte 2021 mit einem neuen Angebot empfangen zu können.

       <p><a href="https://spieleinderschule.org">spieleinderschule.org</a></p>
      </Typography>

      </Grid>
     </Grid>

     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12}>
       <Typography variant="h6">Unterstützung für Schulen mit G-Suite (neu Google Workspace)</Typography>
       <Typography>
        Seit Frühling 2020 verfügt auch Google über einen Rahmenvertrag mit educa.ch. Damit Schulen ihre G-Workspace Enterprise for Education datenschutzkonform und unter dem Rahmenvertrag stehend einsetzen können, haben wir unter chromeprojekt.ch eine Sammlung von wichtigen Informationen erstellt.
        Die Seite navigiert durch die wichtigsten Punkte, die in der Admin-Konsole angegangen werden müssen. Unabdingbar ist aber, dass Schulen die Plattform in ihrem Konzept Medien & Informatik verankern und darin vertieft auf verschiedenste Handlungsfelder eingehen.
        Brauchen sie Unterstützung beim Administrieren der Admin-Konsole? Oder bei der Überarbeitung oder Erstellung des Konzeptes? Dann melden Sie sich unter schulinformatik@phbern.ch oder kontakt@kibs.ch
       </Typography>
      </Grid>
     </Grid>

     <Grid container spacing={8} className={classes.gridContainer} style={{padding:0}}>
      <Grid item xs={12}>
       <Typography variant="h6">Auf dem Weg zu einem Kompetenzzentrum für das Lernen der Zukunft: BeLEARN</Typography>
       <Typography>
        Sind digitale Medien und Technologien ubiquitär im Klassenraum verfügbar, brauchen wir dann noch eine Mehrwert-Diskussion? Das Kompetenzzentrum BeLEARN soll einen Raum für alle diese Denkansätze bieten und den Weg ebnen für ein Lernen der Zukunft.
        Wir stellen uns Fragen, die über eine Mehrwertdiskussion hinaus gehen:

        <ul>
        <li>Wie schaffen wir Voraussetzungen für das Lernen der Zukunft?</li>
        <li>Was bedeutet Erfolg in einer digitalisierten Arbeitswelt und Gesellschaft?</li>
        <li>Wie bereiten wir die Kinder von Heute und Morgen auf die Herausforderungen von übermorgen vor?</li>
</ul>
        BeLEARN befindet sich gerade im Aufbau und die PHBern ist ein Partner in BeLEARN. In der Session wollen wir mit Euch diskutieren, wie Ihr Euch lernen der Zukunft vorstellt und welche Fragen wir uns mit Euch gemeinsam stellen dürfen. Uns interessiert:
        <ul>
         <li>Was erwartet und wünscht Ihr Euch von BeLEARN?</li>
         <li>Wie stellt Ihr Euch eine Beteiligung vor?</li>
        </ul>

        Session Kontakt: Uwe Dirksen
        Geschäftsleitung: Katrin Müller
       </Typography>
      </Grid>
     </Grid>

    </section>

   </>
  )
})

export default Tagungen
