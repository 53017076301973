import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import Ajax from 'api/ajax'
import ls from 'local-storage'


class ServiceModal {
  open = false

  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
  setName = (name) => { this.name = name }
  setIcon = (icon) => { this.icon = icon}
}

class ServiceMenu {
  open = false
  anchorEl = null

  setAnchorEl = (el) => this.anchorEl = el
}
class ConfiguratorActions {
  open = false
  openPreview = false
  openDelete = false
  iframeLoading = true

  handleOpen = () => { this.open = true }
  handleClose = () => { this.open = false }
  handleOpenPreview = () => { this.openPreview = true }
  handleClosePreview = () => { this.openPreview = false }
  handleOpenDelete = () => { this.openDelete = true }
  handleCloseDelete = () => { this.openDelete = false }
  setIframeLoading = (value) => { this.iframeLoading = value }
}
class PDF {
  positionsGreen
  positionsYellow
  positionsRed
  setPositionsGreen = (positions) => this.positionsGreen = positions
  setPositionsYellow = (positions) => this.positionsYellow = positions
  setPositionsRed = (positions) => this.positionsRed = positions

  print (ampelflyerId,schulhausKey,filename,lng,cbSuccess,cbFail) {
    Ajax.printAmpelFlyer(
      ampelflyerId,
      schulhausKey,
      lng,
      (response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]))
       const link = document.createElement('a')
       link.href = url
       link.setAttribute('download', filename+'.pdf')
       document.body.appendChild(link)
       link.click()
       cbSuccess()
      },
      cbFail
    )
  }
}

class Sheets {
  selected = 0
  select = (index) => this.selected = index
}


decorate(Sheets,{
  selected:observable
})
decorate(PDF,{
  positionsGreen:observable,
  positionsYellow:observable,
  positionsRed:observable
})
decorate(ServiceMenu,{
  anchorEl:observable
})
decorate(ConfiguratorActions,{
  open:observable,
  openPreview:observable,
  openDelete:observable,
  openDelete:observable,
  iframeLoading:observable
})
decorate(ServiceModal, {
 open:observable
})



class UI {
  serviceModal = new ServiceModal()
  serviceMenu = new ServiceMenu()
  configuratorActions = new ConfiguratorActions()
  pdf = new PDF()
  sheets = new Sheets()
}


const UiStore = createContext(new UI())
export default UiStore
