import React, {useContext,useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Typography
} from '@material-ui/core'

import IMG_ADVICE from 'media/img/advice.png'

import IMG_DEFAULT_SHAPE_1 from './assets/img/default-shape/default-shape1.png'
import IMG_DEFAULT_SHAPE_2 from './assets/img/default-shape/default-shape2.png'
import IMG_DEFAULT_SHAPE_3 from './assets/img/default-shape/default-shape3.png'
import IMG_DEFAULT_SHAPE_4 from './assets/img/default-shape/default-shape4.png'

const News = observer(() => {
  const { t } = useTranslation()
  const { kibsmeta } = useContext(DataStore)
  const { utilityText } = useContext(UiStore)

  useEffect(utilityText.getTextConference,[])


  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{t('core.landingpage.conference')}</h2>
          <div className="bar" />
        </div>
        <div className="row">
        <div className="subscribe-content">
         <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html: utilityText.textConference.toString('html') }} />
        </div>
        </div>
      </div>
      <div className="default-shape">
        <div className="shape1">
          <img src={IMG_DEFAULT_SHAPE_1} alt="image" />
        </div>
        <div className="shape2">
          <img src={IMG_DEFAULT_SHAPE_2} alt="image" />
        </div>
        <div className="shape3">
          <img src={IMG_DEFAULT_SHAPE_3} alt="image" />
        </div>
        <div className="shape4">
          <img src={IMG_DEFAULT_SHAPE_4} alt="image" />
        </div>
      </div>
    </section>
  )
})

export default News
