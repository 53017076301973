import React, { useEffect, useContext } from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { fade,makeStyles,withStyles,useTheme,createMuiTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { getContrast } from 'shared/functions/helper'

import DataStore from './stores/data'
import UiStore from './stores/ui'
import GlobalUiStore from 'core/stores/ui'
import Suggestion from './Suggestion'
import CatalogItem, { CatalogItemInspect } from './CatalogItem'

import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  useScrollTrigger,
  Box,
  Button,
  Container,
  Dialog,
  Slide,
  InputBase,
  IconButton,
  Paper,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  Grid,
  Switch,
  FormControlLabel
} from '@material-ui/core'

import {
  FilterList,
  Search,
  ChevronLeft,
  ChevronRight,
  Check,
  InfoOutlined,
  FeedbackOutlined,
  ArrowBack
} from '@material-ui/icons'

import {
  green
} from '@material-ui/core/colors'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  categoryList:{
    width:'100%',
    maxWidth:drawerWidth
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  teaser:{
    background:'rgba(255,255,255,.4)',
    padding:theme.spacing(1),
    fontSize:'1.8rem',
    [theme.breakpoints.only('sm')]:{
      fontSize:'1.4rem'
    },
    [theme.breakpoints.only('xs')]:{
      fontSize:'1rem'
    },
  },
  banner:{
    transform: 'translate3d(0px, 0px, 0px)',
    height: '50vh',
    overflow: 'hidden',
    border: 0,
    margin: 0,
    display: 'flex',
    flexGrow: 1,
    padding: 0,
    overflow: 'hidden',
    position: 'relative',
    maxHeight: 1600,
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: '50%',
    backgroundRepeat:'no-repeat',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  catalogItemsContainer:{
    flexGrow:1,
    padding: theme.spacing(3),
  },
  categoryAvatar:{
    [theme.breakpoints.down('xs')]:{
     paddingLeft:8
   },
   wordBreak:'break-word'
  },
  cycle:{
    borderRadius:'50%',
    width:50,
    height:50,
    display:'inline-block',
    margin:theme.spacing(1),
    cursor:'pointer',
    border:'1px solid #eee'
  },
  cycleNumber:{
    display:'flex',
    height:'100%',
    alignItems:'center',
    justifyContent:'center'
  },
  boxShadowOn:{
    boxShadow: '0px 3px 5px 0.3px rgba(0,0,0,0.2), 0px 6px 10px 0.3px rgba(0,0,0,0.14), 0px 1px 18px 0.3px rgba(0,0,0,0.1)'
  },
  boxShadowOff:{
    boxShadow:'none'
  },
  check:{
    color:green[500]
  },
  sorting:{
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(1),
    float:'right',
    marginRight:theme.spacing(0.5)
  },
  killAnchorStyles:{
    textDecoration:'none',
    color:'inherit'
  },
  aboutTextContainer:{
    padding:theme.spacing(2)
  }
}))

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export const CatalogSuggestion = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const globalUi = useContext(GlobalUiStore)
  const { themer } = globalUi
  const l = useLocation()
  const history = useHistory()

  useEffect(() => {
    globalUi.setLoading(true)
    const catalogName = l.pathname.split('/')[2]

    if (catalogName && catalogName !== "") {
      catalog.getConfigByName(
       catalogName,
       () => {
         globalUi.setLoading(false)
         if (catalog.primaryColor && catalog.secondaryColor) {
           themer.setCustom(catalog.primaryColor,catalog.secondaryColor)
         }
       },
       () => {
         globalUi.setLoading(false)
       }
      )
    }
  },[])

  const onFinish = () => {
    catalog.suggestion.reset()
    history.goBack()
  }

  return (
    <>
     <Navbar />
     <Suggestion
      allCategories={catalog.categories}
      catalogName={catalog.theme}
      catalogId={catalog.id}
      onFinish={onFinish}
     />
    </>
  )
})

const Catalog = observer((props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { catalog } = useContext(DataStore)
  const { catalogUi } = useContext(UiStore)
  const globalUi = useContext(GlobalUiStore)
  const { themer } = globalUi
  const l = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  const toSuggestion = () => {
    history.push(l.pathname + '/suggestion')
  }

  const onFinishEdit = () => {
   catalog.getExamples(
    () => { catalogUi.handleCloseEdit() },
    () => { catalogUi.handleCloseEdit() }
   )
  }

  const showAbout = () => {
    catalogUi.handleOpenAbout()
  }

  useEffect(() => {
    globalUi.setLoading(true)
    const catalogName = l.pathname.split('/')[2]

    if (catalogName && catalogName !== "") {
      catalog.getConfigByName(
       catalogName,
       () => {
         catalog.getExamples(
           () => {
             if (catalog.primaryColor && catalog.secondaryColor) {
              themer.setCustom(catalog.primaryColor,catalog.secondaryColor)
             }
             globalUi.setLoading(false)
             catalog.checkHash(() => {
              catalogUi.handleOpenInspect()
             })
             catalog.verifyTokenAdmin(
               () => {
                 globalUi.setLoading(false)
               },
               () => { globalUi.setLoading(false) }
             )
           },
           () => { globalUi.setLoading(false) }
         )
       },
       () => { globalUi.setLoading(false) }
      )
    }
  },[])

  return (
    <div className={classes.root}>
       <AppBar
         position="fixed"
         className={clsx(classes.appBar, {
           [classes.appBarShift]: catalogUi.filterOpen,
         })}
       >
         <Toolbar>
           <IconButton
             color="inherit"
             aria-label="open drawer"
             onClick={catalogUi.handleOpenFilter}
             edge="start"
             className={clsx(classes.menuButton, {
               [classes.hide]: catalogUi.filterOpen,
             })}
           >
             <FilterList />
           </IconButton>
           <Typography variant="h6" noWrap className={classes.title}>
             {catalog.heading}
           </Typography>
           {catalog.exampleLink &&
            <Button>
             <a className={classes.killAnchorStyles} href={catalog.exampleLink} target="_blank">{t('catalog.frontend.exampleLink')}</a>
            </Button>
           }
           {catalog.about && catalog.about.trim() !== '' &&
             <Button onClick={showAbout} color="inherit">
              {t('catalog.frontend.about')}
             </Button>
           }
           <IconButton aria-label="suggest-new-content" edge="end" color="inherit" onClick={toSuggestion}>
            <FeedbackOutlined />
          </IconButton>
         </Toolbar>
       </AppBar>
       <Drawer
         variant="permanent"
         className={clsx(classes.drawer, {
           [classes.drawerOpen]: catalogUi.filterOpen,
           [classes.drawerClose]: !catalogUi.filterOpen,
         })}
         classes={{
           paper: clsx({
             [classes.drawerOpen]: catalogUi.filterOpen,
             [classes.drawerClose]: !catalogUi.filterOpen,
           }),
         }}
         open={catalogUi.filterOpen}
       >
         <div className={classes.toolbar}>
           <IconButton onClick={catalogUi.handleCloseFilter}>
             {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
           </IconButton>
         </div>
         <Filter />
       </Drawer>
       <main className={classes.content}>
         <About />
         <Banner />
         <Sorting />
         <CatalogContent />
       </main>
       <CatalogItemInspect />
       {catalog.isAdmin &&
        <Dialog onClose={catalogUi.handleCloseEdit} open={catalogUi.editOpen}>
         <Suggestion
          mode={'edit'}
          example={catalog.currentItemToEdit}
          allCategories={catalog.categories}
          catalogName={catalog.theme}
          catalogId={catalog.id}
          onFinish={onFinishEdit}
         />
       </Dialog>}
     </div>
  )
})

const Banner = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)

  return (
    <section className={classes.banner} style={{backgroundImage:'url('+catalog.banner+')'}}>
     {catalog.teaser && catalog.teaser !== "" &&
      <Container maxWidth={"md"}>
       <div className={classes.teaser}> {catalog.teaser} </div>
      </Container>
     }
    </section>
  )
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const About = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const { catalogUi } = useContext(UiStore)

  return (
   <Dialog open={catalogUi.aboutOpen} onClose={catalogUi.handleCloseAbout} TransitionComponent={Transition}>
    <div className={classes.aboutTextContainer}>
     <Typography dangerouslySetInnerHTML={{__html:catalog.about}} variant="body1" gutterBottom />
    </div>
   </Dialog>
  )
})

const Sorting = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const { t } = useTranslation()

  return (
    <FormControlLabel
     className={classes.sorting}
     control={
       <Switch
         checked={catalog.sortByLikes}
         onChange={catalog.toggleSortByLikes}
         color="primary"
       />
     }
     labelPlacement="start"
     label={catalog.sortByLikes ? t('catalog.frontend.sortedByLikes') : t('catalog.frontend.sortByLikes')}
   />
  )
})

const Filter = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const { catalogUi } = useContext(UiStore)
  const { t } = useTranslation()

  const select = filter => event => {
    filter.toggleSelect()
  }

  return (
   <>
    <List dense className={classes.categoryList}>
     {catalog.filter.map((filter,index) => {
       const boxShadow = filter.selected ? classes.boxShadowOff : classes.boxShadowOn
       return filter.isCycleFilter
        ?
        <ListItem
         button
         onClick={select(filter)}
         key={"zyklus"+filter.cycle}
         className={classes.categoryAvatar}
         style={{whiteSpace:( catalogUi.filterOpen ? 'normal' : 'nowrap')}}>
         <ListItemAvatar>
          <Avatar style={{ backgroundColor:filter.color }} className={boxShadow}>
           <div className={classes.cycleNumber}>{filter.cycle}</div>
          </Avatar>
         </ListItemAvatar>
         <ListItemText primary={t('catalog.frontend.suggestion.zyklus') + ' ' + filter.cycle} />
         {filter.selected &&
          <ListItemIcon>
           <Check className={classes.check} />
          </ListItemIcon>
         }
        </ListItem>
        :
        null
     })}
    </List>
    <Divider />
    <List dense className={classes.categoryList}>
     {catalog.filter.map((filter, index) => {
      const boxShadow = filter.selected ? classes.boxShadowOff : classes.boxShadowOn
      return filter.isCategoryFilter
       ?
       <ListItem
        button
        onClick={select(filter)}
        key={filter.name}
        className={classes.categoryAvatar}
        style={{whiteSpace:( catalogUi.filterOpen ? 'normal' : 'nowrap')}}>
       <ListItemAvatar>
        {
          filter.icon
          ?
          <Avatar
           className={boxShadow}
           style={{ backgroundColor:filter.color}}
           imgProps={{
             style:{ width: '100%', height: '100%' }
           }}
           src={filter.icon}
          />
          :
          <Avatar
            style={{ backgroundColor:filter.color,color:getContrast(filter.color),border:'1px solid #ddd' }}
            className={classes.avatarSize}
           >{filter.name.charAt(0)}{filter.name.charAt(1)}</Avatar>
        }
       </ListItemAvatar>
       <ListItemText primary={filter.name} />
       {filter.selected &&
        <ListItemIcon>
         <Check className={classes.check} />
        </ListItemIcon>
       }
      </ListItem>

      :
      null
     })}
    </List>
   </>
  )
})

const CatalogContent = observer(() => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)

  return (
   <div className={classes.catalogItemsContainer}>
    <Grid container spacing={1}>
     {catalog.filteredExamples.map((example,i) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={"example-"+i}>
       <CatalogItem example={example} key={"example-item-"+i} />
      </Grid>
     ))}
    </Grid>

   </div>
  )
})

export const Navbar = observer((props) => {
  const classes = useStyles()
  const { catalog } = useContext(DataStore)
  const history = useHistory()

  const toCatalog = () => {
   history.goBack()
  }

  return (
    <HideOnScroll {...props}>
     <div className={classes.root}>
      <AppBar position="static">
       <Toolbar>

        <IconButton
         edge="start"
         className={classes.menuButton}
         color="inherit"
         aria-label="open drawer"
         onClick={toCatalog}
        >
         <ArrowBack />
        </IconButton>

        <Typography className={classes.title} variant="h6" noWrap>
         {catalog.heading}
        </Typography>

       </Toolbar>
      </AppBar>
     </div>
    </HideOnScroll>
  )
})

export default Catalog
