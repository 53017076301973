import React, { useContext,useEffect,useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import GlobalUiStore from 'core/stores/ui'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  Container,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Divider,
  CircularProgress,
  TextField
} from '@material-ui/core'
import { Search,AccountCircle,Home,Mail,Phone } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { useObserver,observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop:25
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  profile:{
    marginTop:theme.spacing(3)
  },
  authIcon:{
    height:40,
    width:'auto'
  },
  subheader:{
    fontStyle:'italic'
  },
  divider:{
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2)
  },
  progress:{
    position:'absolute',
    top:'20vh'
  }
}));

const UserMails = observer(() => {
  const { utilityMails } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const { notifier } = useContext(GlobalUiStore)

  const [value, setValue] = useState(utilityMails.seperators.COLON)

  const handleChange = (event) => {
   setValue(parseInt(event.target.value))
   utilityMails.setSeperator(event.target.value)
  }

  useEffect(() => {
    utilityMails.get()
  },[])

  const _onCopy = () => {
    notifier.push(t('core.notifier.copiedToClipboard'),notifier.variants.SUCCESS,notifier.pos.BOTTOM_RIGHT)
  }

  return (
    <Container component="main" maxWidth="md" id="ref-parent-3" style={{flexDirection:'column'}}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t('core.admin.utility.seperators')}</FormLabel>
        <RadioGroup aria-label="seperators" name="seperators" value={value} onChange={handleChange} style={{flexDirection:'row'}}>
          <FormControlLabel value={utilityMails.seperators.SEMICOLON} control={<Radio />} label={t('core.admin.utility.semicolon')} />
          <FormControlLabel value={utilityMails.seperators.COLON} control={<Radio />} label={t('core.admin.utility.colon')} />
          <FormControlLabel value={utilityMails.seperators.SPACE} control={<Radio />} label={t('core.admin.utility.space')} />
          <FormControlLabel value={utilityMails.seperators.COMMA} control={<Radio />} label={t('core.admin.utility.comma')} />
        </RadioGroup>
      </FormControl>
      {utilityMails.mails.length > 0
       ?
       <>
         <TextField
          fullWidth
          multiline
          rows={25}
          variant="outlined"
          value={ utilityMails.mails.map(e => e.mail).join(utilityMails._seperator) }
         />
         <CopyToClipboard text={utilityMails.mails.map(e => e.mail).join(utilityMails._seperator)} onCopy={_onCopy}>
          <Button fullWidth variant="outlined">{t('core.general.copy') + ' (' + utilityMails.mails.length + ')'}</Button>
         </CopyToClipboard>
       </>
       :
       <CircularProgress color="secondary"/>
      }
    </Container>
  )
})


export default UserMails
