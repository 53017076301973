import React, {useEffect,useState} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory,Link } from 'react-router-dom'

import {
  Button
} from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'


import { ourServices } from 'shared/functions/ourservices'

const useStyles = makeStyles(theme => ({
  toDashboardBtn: {
    margin:'0 auto'
  },
  image:{
    maxWidth:'100%'
  },
  btnColor:{
    background:'linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656) !important',
  },
}))

const Services = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const toDashboard = () => history.push('/dashboard')
  const [randomNumbers,setRandomNumbers] = useState([])
  const services = ourServices()

  useEffect(() => {
    let arr = []
    const NUMBER_OF_SERVICES_SHOWN_ON_FRONTPAGE = 3
    while (arr.length < NUMBER_OF_SERVICES_SHOWN_ON_FRONTPAGE) {
     var r = Math.floor(Math.random() * services.length)
     if(arr.indexOf(r) === -1) arr.push(r)
    }
    setRandomNumbers(arr)
  },[])


  return (
    <section className="services-area bg-fff5ee pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{t('core.landingpage.services')}</h2>
          <div className="bar" />
        </div>
        <div className="row">

          {randomNumbers.map((r,i) => {
            const s = services[r]
            return <div className="col-lg-4 col-md-6" onClick={s.goTo}>
              <div className="single-services">
                <div>
                  <img src={s.img} alt={t(s.title)} className="img" style={{height:140}}/>
                </div>
                <h3>
                  <a href="#">{t(s.title)}</a>
                </h3>
                <p>{t(s.text)}</p>
                <a href="#" className="services-btn">{t('core.landingpage.goToService')} <i className="flaticon-right-arrow" /></a>
              </div>
            </div>
          })}

        <div className={classes.toDashboardBtn}>
         <Button variant="contained" color="primary" onClick={toDashboard} className={"phButton"}>
          <ArrowRight />
          {t('core.landingpage.goToServices')}
         </Button>

        </div>

        </div>
      </div>
      <div className="default-shape">

      </div>
    </section>
  )
})

export default Services
