import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Trans,useTranslation } from 'react-i18next'


import {
  Typography,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia
} from '@material-ui/core'

import {
 Label,ExitToApp
} from '@material-ui/icons'

import IMG_BACKGROUND from 'media/img/chats/background_social_media.png'
import IMG_PARAGRAPH from 'media/img/chats/paragraph.png'
import IMG_CONTENT_INSTEAD_MEDIUM from 'media/img/chats/contentInsteadMedium.jpg'
import IMG_PARENTTEACHER from 'media/img/chats/parentteacher.png'
import IMG_CHAT_INTRO from 'media/img/chats/chat_intro.jpeg'
import IMG_SOCIALMEDIA from 'media/img/chats/socialmedia.jpg'
import IMG_ABMACHUNGEN from 'media/img/chats/abmachungen.jpg'
import IMG_DATENSCHUTZLEITFADEN_DE from 'media/img/chats/datenschutzLeitfaden_de.png'
import IMG_MI_EMPFEHLUNGEN_DE from 'media/img/chats/miempfehlungen_de.png'
import IMG_DATENSCHUTZ_UNTERRICHT from 'media/img/chats/datenschutzUnterricht_de.png'
import IMG_WORKS from 'media/img/chats/works.png'
import IMG_FEATURES from 'media/img/chats/feature-bg.png'
import IMG_SERVICES from 'media/img/chats/services_bg.jpg'
import IMG_SUBSCRIBE from 'media/img/chats/subscribe_bg.jpg'


const useStyles = makeStyles(theme => ({
  container:{
    display:'flex',
    flexDirection:'row',
    width:'100%',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
  },
  banner:{
    backgroundImage: 'url('+IMG_BACKGROUND+')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: 'cover',
    height:'100vh'
  },
  paddingTopBottom:{
    paddingTop:100,
    paddingBottom:100
  },
  responsiveImage:{
    maxWidth:'100%',
    maxHeight:'100%',
  },
  padding:{
    padding:theme.spacing(10),
    [theme.breakpoints.down('md')]:{
      padding:theme.spacing(2)
    }
  },
  iframe:{
   border:'none',
   width:'100%',
   height:500
 },
 shadow:{
  background: 'radial-gradient(ellipse farthest-side, rgba(0, 0, 0, 0.3) 13%, rgba(0, 0, 0, 0) 100%)',
  height: 19,
  width: 300,
  border: 0,
  position: 'absolute',
  top: 'inherit',
  left: 0,
  right: 0,
  margin: '0 auto',
  clip: 'rect(9px, 300px, 20px, 0px)',
  display: 'block',
  marginTop:theme.spacing(10)
},
blockLeft:{
  position: 'relative',
  maxWidth: '100%',
  padding: '0.5rem 1rem',
  margin: '1rem',
  lineHeight: 1.7,
  fontSize: '1.1em',
  fontStyle: 'italic',
  color: '#666',
  background: '#fff',
  borderLeft: '6px solid #ddedf9',
  '&::before, &::after':{
   position: 'absolute',
   left: 0,
   fontSize: '1rem',
   color: 'rgba(#ddedf9, .6)',
  },
  '&::before':{
   content: '<blockquote>',
   top: '-2rem',
  },
  '&::after':{
   content: '</blockquote>',
   bottom: '-2rem'
  }
 },
 blockRight:{
   position: 'relative',
   maxWidth: '100%',
   padding: '0.5rem 1rem',
   margin: '1rem',
   lineHeight: 1.7,
   fontSize: '1.1em',
   fontStyle: 'italic',
   color: '#666',
   background: '#fff',
   borderRight: '6px solid #ddedf9',
   '&::before, &::after':{
    position: 'absolute',
    right: 0,
    fontSize: '1rem',
    color: 'rgba(#ddedf9, .6)',
   },
   '&::before':{
    content: '<blockquote>',
    top: '-2rem',
   },
   '&::after':{
    content: '</blockquote>',
    bottom: '-2rem'
   }
  },
  card:{
  },
  media:{
    height:200
  },
  works:{
    paddingTop: 95,
    background: 'url('+IMG_WORKS+')',
    backgroundPosition: 'center top',
    backgroundSize: 'cover'
  },
  features:{
   paddingTop: 95,
   paddingBottom: 95,
   background: 'url('+IMG_FEATURES+')',
   backgroundPosition: 'center left',
   backgroundSize: 'cover'
 },
 services:{
  paddingTop: 95,
  paddingBottom: 100,
  background: 'url('+IMG_SERVICES+')',
  backgroundSize: 'cover',
  backgroundPosition: 'center top'
 },
 subscribe:{
   background: 'url('+IMG_SUBSCRIBE+')',
   paddingTop: 95,
   paddingBottom: 150,
   backgroundSize: 'cover',
   backgroundPosition: 'center top'
 },
 whiteBg:{

 }

}))

const Chats = () => {
  const { t,i18n } = useTranslation()
  const classes = useStyles()

  let socialMediaInSchoolText = '', rightComment3 = '',consentComment1 = '', sensContentComment9 = ''
  if (i18n.language === 'fr') {
    socialMediaInSchoolText = 'https://www.edoeb.admin.ch/edoeb/fr/home/documentation/datenschutz/Datenschutz%20-%20International/DSGVO.html'
    rightComment3 = 'https://www.edoeb.admin.ch/edoeb/fr/home/documentation/datenschutz/Datenschutz%20-%20International/DSGVO.html'
    sensContentComment9 = 'https://cmij.ch/'
    consentComment1 = 'https://cmij.ch/'
  } else {
    socialMediaInSchoolText = 'https://bit.ly/2rA05iJ'
    rightComment3 = 'https://bit.ly/2rA05iJ'
    sensContentComment9 = 'kontakt@kibs.ch'
    consentComment1 = 'http://kibs.ch'
  }

  return (
   <>

    <section className={classes.banner}>
     <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={10} lg={8} style={{maxWidth:540}} className={classes.paddingTopBottom}>
       <Typography component="h1" variant="h2" style={{color:'#fff'}}>{t('chats.socialMediaInSchool')}</Typography>
       <Typography component="p" style={{background: 'rgba(255,255,255,.75)', color: 'black', padding: '1rem', marginTop: '25px', borderRadius: '10px'}}>
        <Trans i18nKey="chats.socialMediaInSchoolText">
         {[<a href={socialMediaInSchoolText} target='_blank'></a>]}
        </Trans>
       </Typography>
      </Grid>
     </Grid>
    </section>

    <section className={`${classes.paddingTopBottom} ${classes.whiteBg}`}>
     <Grid container>
      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.padding}>
      <Typography component="h2" variant="h2">{t('chats.right')}</Typography>
       <List>
        <ListItem>
         <ListItemIcon><Label /></ListItemIcon>
         <ListItemText primary={
           <Trans i18nKey="chats.rightComment1">
            {[
              <br />,
              <a href="https://steigerlegal.ch/2018/06/03/whatsapp-schulen-illegal/" target='_blank'></a>
            ]}
           </Trans>
          }/>
        </ListItem>
        <ListItem>
         <ListItemIcon><Label /></ListItemIcon>
         <ListItemText primary={t('chats.rightComment2')} />
        </ListItem>
       </List>
       <Typography component="p">
        <Trans i18nKey="chats.rightComment3">
         {[
           <a href={rightComment3} target='_blank'></a>
         ]}
        </Trans>
       </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
       <img src={IMG_PARAGRAPH} alt="" className={`${classes.responsiveImage} ${classes.padding}`} />
      </Grid>
     </Grid>
    </section>

    <section className={`${classes.works} ${classes.whiteBg}`}>
     <Grid container className={classes.container}>
      <Grid item lg={4} md={4} sm={12} xs={12} className={classes.paddingTopBottom}>
       <Typography variant="h2" component="h1">{t('chats.recommendation')}</Typography>
      </Grid>
     </Grid>
    </section>

    <section className={`${classes.features} ${classes.whiteBg}`}>
     <Grid container>
      <Grid item lg={6} md={12} sm={12} xs={12}>
       <img src={IMG_CONTENT_INSTEAD_MEDIUM} alt="" className={`${classes.responsiveImage} ${classes.padding}`}/>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12} className={classes.padding}>
       <Typography component="h2">{t('chats.sensContent')}</Typography>
         <Typography>
           <i>"{t('chats.sensContentComment1')}"<br />"{t('chats.sensContentComment2')}"</i><br />
           <Trans i18nKey="chats.sensContentComment3">
            {[
              <a href='https://bildungbern.ch/fileadmin/user_upload/bildungbern/public/Leistungen/Beratung/Unsere_Ratgeber/Whatsapp_in_der_Schule_-_pragmatischer_Umgang_sinnvoll.pdf' target='_blank'></a>
            ]}
           </Trans>
           <br /><br /> {t('chats.sensContentComment4')}
         </Typography>
         <List>
          <ListItem>
           <ListItemIcon><Label /></ListItemIcon>
           <ListItemText primary={t('chats.sensContentComment5')} />
          </ListItem>
          <ListItem>
           <ListItemIcon><Label /></ListItemIcon>
           <ListItemText primary={t('chats.sensContentComment6')} />
          </ListItem>
          <ListItem>
           <ListItemIcon><Label /></ListItemIcon>
           <ListItemText primary={t('chats.sensContentComment7')} />
          </ListItem>
         </List>
         <Typography component="p">{t('chats.sensContentComment8')}</Typography>
         <Typography component="p">
         <Trans i18nKey="chats.sensContentComment9">
          {[
            <a href={'mailto:'+sensContentComment9}></a>
          ]}
         </Trans>
         </Typography>
      </Grid>
     </Grid>
    </section>

    <section className={`${classes.services} ${classes.whiteBg}`}>
     <Grid container>
      <Grid item lg={6} md={6} sm={12} className={classes.padding}>
       <Typography variant="h2" component="h2">{t('chats.consent')}</Typography>
       <Typography component="p" style={{paddingTop:20}}>
       <Trans i18nKey="chats.consentComment1">
        {[
          <a href={consentComment1} target='_blank'></a>
        ]}
       </Trans>
       </Typography>
       <Typography component="p" >{t('chats.consentComment2')}</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
       <img src={IMG_PARENTTEACHER} alt="" className={`${classes.responsiveImage} ${classes.padding}`} />
      </Grid>
     </Grid>
    </section>

    <section className={`${classes.subscribe} ${classes.whiteBg}`}>
     <Grid container className={classes.container}>
      <Grid item lg={8} md={10} sm={12} xs={12}>
       <Typography component="h2" variant="h2">{t('chats.trafficLightSystem')}</Typography>
       <div className={classes.padding}>
        <Typography component="p">{t('chats.trafficLightSystemComment1')}</Typography>
        <Typography component="p">
        <Trans i18nKey="chats.trafficLightSystemComment2">
         {[
          <a href='http://www.kibs.ch/datenschutz/ampelsystem/' target='_blank'></a>
         ]}
        </Trans>
        </Typography>
       </div>
       <iframe className={classes.iframe} src={t('chats.LINKS.learningapp')} webkitAllowFullScreen="true" mozAllowFullScreen="true" />
      </Grid>
     </Grid>
     <div className={classes.shadow} />
    </section>


    <section className={`${classes.paddingTopBottom} ${classes.whiteBg}`}>
     <Grid container className={classes.container}>
      <Grid item lg={8} md={6} sm={12} xs={12}>
       <Typography variant="h4" component="h6">{t('chats.arrangement')}</Typography>
        <blockquote className={classes.blockLeft}>
          "{t('chats.arrangementComment1')}"
        </blockquote>
        <blockquote className={classes.blockRight}>
          "{t('chats.arrangementComment2')}"
        </blockquote>
        <blockquote className={classes.blockLeft}>
          "{t('chats.arrangementComment3')}"
        </blockquote>
        <img src={IMG_CHAT_INTRO} alt="" className={`${classes.responsiveImage} ${classes.padding}`} />
      </Grid>
     </Grid>
    </section>

    <section className={`${classes.features} ${classes.paddingTopBottom} ${classes.whiteBg}`}>
     <Typography component="h1" variant="h4" style={{textAlign:'center'}}>{t('chats.actionGuide')}</Typography>
     <Grid container className={classes.container} spacing="space-between">

      <Grid item lg={4} md={4} sm={4} xs={12} className={classes.padding}>
       <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={IMG_SOCIALMEDIA}
            title=""
          />
        </CardActionArea>
       <CardContent>
        <Typography variant="h6">{t('chats.actionGuideComment1')}</Typography>
        <Typography variant="body1">{t('chats.actionGuideComment2')}</Typography>
       </CardContent>
      </Card>
      </Grid>

      <Grid item lg={4} md={4} sm={4} xs={12} className={classes.padding}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={IMG_ABMACHUNGEN}
            title=""
          />
        </CardActionArea>
       <CardContent>
        <Typography variant="h6">{t('chats.actionGuideComment1')}</Typography>
        <Typography variant="body1">{t('chats.arrangementComment3')}</Typography>
       </CardContent>
      </Card>
      </Grid>

      <Grid item lg={4} md={4} sm={4} xs={12} className={classes.padding}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={IMG_PARENTTEACHER}
            title=""
          />
        </CardActionArea>
       <CardContent>
        <Typography variant="h6">{t('chats.consent')}</Typography>
        <Typography variant="body1">{t('chats.actionGuideComment5')}</Typography>
       </CardContent>
      </Card>
      </Grid>

     </Grid>
    </section>

    <section className={`${classes.paddingTopBottom} ${classes.whiteBg}`}>
     <Grid container className={classes.container}>
      <Grid item lg={8} md={6} sm={12} xs={12}>
       <Typography variant="h4" component="h6">{t('chats.more')}</Typography>
        <List>
         <ListItem button component="a" href={t('chats.LINKS.consent')}>
          <ListItemIcon><ExitToApp /></ListItemIcon>
          <ListItemText primary={t('chats.moreComment1')} />
         </ListItem>
         <ListItem button component="a" href={t('chats.LINKS.recommendation')}>
          <ListItemIcon><ExitToApp /></ListItemIcon>
          <ListItemText primary={t('chats.moreComment2')} />
         </ListItem>
         <ListItem button component="a" href={t('chats.LINKS.dataProtection')}>
          <ListItemIcon><ExitToApp /></ListItemIcon>
          <ListItemText primary={t('chats.moreComment4')} />
         </ListItem>
        </List>
      </Grid>
     </Grid>
    </section>


   </>
  )
}




export default Chats
