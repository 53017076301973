import React, { useContext,useEffect } from "react"
import ReactDOM from "react-dom"

import { useTranslation } from 'react-i18next'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import UiStore from './stores/ui'
import DataStore from './stores/data'
import history from 'history.js'

import {
 TextField,
 Paper,
 Typography,
 Collapse,
 Select,
 MenuItem,
 Chip,
 Input,
 Grid,
 List,
 ListItem,
 ListItemText,
 ListItemSecondaryAction,
 Switch,
 Button
} from '@material-ui/core/'

import {
 ExpandMore,ExpandLess,Home,WorkOutline,Devices,SupervisorAccount,ChildCare
} from '@material-ui/icons'



const useStyles = makeStyles(theme => ({
 root:{
   position:'relative',
   width:'100%',
   maxWidth:500,
   display:'flex',
   alignItems:'center',
   flexDirection:'column',
   margin:'0 auto'
 },
 paper:{
   width:'100%',
   margin:theme.spacing(1)
 },
 content:{
   padding:theme.spacing(3)
 },
 expandIcon:{
   display:'flex',
   justifyContent:'flex-end',
   alignItems:'flex-end'
 },
 chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  result:{
    fontSize:'3rem',
    textAlign:'center'
  }
}))


const Smicalc = observer((props) => {
  const classes = useStyles()
  const data = useContext(DataStore)

  useEffect(() => {
    const schoolId = props.schoolId
    if (schoolId) {
      data.getSMICalc(
        schoolId,
        () => {},
        () => {}
      )
    }
  },[])


  return (
   <div className={classes.root}>
    <SmiHeading />
    <SmiText />
    <SmiForm />
    <SmiResult />
   </div>
  )
})

const SmiHeading = observer(() => {
  const  { t } = useTranslation()
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
     <div className={classes.content}>
      <Typography variant="h5" component="h2"> {t('smicalc.title')} </Typography>
     </div>
    </Paper>
  )
})

const SmiText = observer(() => {
  const  { t } = useTranslation()
  const classes = useStyles()
  const ui = useContext(UiStore)

  return (
    <Paper className={classes.paper} onClick={ui.toggleExpand}>
     <div className={classes.content}>
       <Typography variant="body2" color="textSecondary" component="p">
        {t('smicalc.content1')}{" "}
        <a href={t('smicalc.content2.link')} target="_new_tab">{t('smicalc.content2.placeholder')}</a>{" "}
         {t('smicalc.content3')}
       </Typography>
       <div className={classes.expandIcon}>
        {ui.expandText ? <ExpandLess /> : <ExpandMore />}
       </div>
       <Collapse in={ui.expandText} timeout="auto" unmountOnExit>
        <Typography variant="body2" color="textSecondary" component="p">{t('smicalc.content4')} </Typography>
        <Typography variant="body2" color="textSecondary" component="p">{t('smicalc.content5')} </Typography>
       </Collapse>
      </div>
    </Paper>
  )
})

const SchoolName = observer(() => {
  const classes = useStyles()
  const data = useContext(DataStore)
  const { school } = data

  return (
    <Paper className={classes.paper}>
     <div className={classes.content}>
      <Typography variant="body1"> {school.name} </Typography>
     </div>
    </Paper>
  )
})

const SmiForm = observer(() => {
  const  { t } = useTranslation()
  const classes = useStyles()
  const data = useContext(DataStore)
  const { school } = data
  const theme = useTheme()

  const hideResult = () => { data.setResult(null) }
  const handleSMI = (event) => {
    data.setSMI(event.target.value)
    hideResult()
  }
  const setPercent = (e) => {
    data.setPercent(e.target.value)
    hideResult()
  }
  const setClients = (e) => {
    data.setClients(e.target.value)
    hideResult()
  }
  const setTeacher = (e) => {
    data.setTeacher(e.target.value)
    hideResult()
  }
  const setSus = (e) => {
    data.setSus(e.target.value)
    hideResult()
  }
  const toggleBYOD = (e) => {
    data.toggleBYOD()
    hideResult()
  }

/*
<Select
  labelId="school-member"
  id="school-member"
  multiple
  fullWidth
  value={data.smisArr}
  onChange={handleSMI}
  input={<Input />}
  MenuProps={{
    PaperProps: {
     style: {
       maxHeight: 48 * 4.5 + 8,
       width: 250,
     },
   },
  }}
  renderValue={(selected) => (
   <div className={classes.chips}>
     {selected.map((m) => (
       <span key={m.id}> {m.foreName + ' ' + m.name} </span>
     ))}
   </div>
 )}
>
 <MenuItem disabled value="">
  <em>{t('smicalc.chooseSMI')}</em>
 </MenuItem>
  {data.schoolMember.map((m) => {
   const background = data.isSelectedSMI(m) ? '#ddd' : '#fff'
   return <MenuItem key={m.id} value={m} style={{ background }}>
      {m.foreName} {m.name}
    </MenuItem>
  })}
</Select>
*/

  return (
    <Paper className={classes.paper}>
     <div className={classes.content}>



     <Grid container spacing={1} alignItems="flex-end">
       <Grid item>
         <WorkOutline />
       </Grid>
       <Grid item xs={11}>
         <TextField
          fullWidth
          type="number"
          id="current-percent"
          label={t('smicalc.currentPercent')}
          onChange={setPercent}
          value={data.percent}
         />
       </Grid>
     </Grid>

     <Grid container spacing={1} alignItems="flex-end">
       <Grid item>
         <Devices />
       </Grid>
       <Grid item xs={11}>
         <TextField
          fullWidth
          type="number"
          id="number-devices"
          label={t('smicalc.countClients')}
          onChange={setClients}
          value={data.clients}
         />
       </Grid>
     </Grid>

     <Grid container spacing={1} alignItems="flex-end">
       <Grid item>
         <SupervisorAccount />
       </Grid>
       <Grid item xs={11}>
         <TextField
          fullWidth
          type="number"
          id="number-teacher"
          label={t('smicalc.countTeacher')}
          onChange={setTeacher}
          value={data.teacher}
         />
       </Grid>
     </Grid>

     <Grid container spacing={1} alignItems="flex-end">
       <Grid item>
         <ChildCare />
       </Grid>
       <Grid item xs={11}>
         <TextField
          fullWidth
          type="number"
          id="number-sus"
          label={t('smicalc.countPupils')}
          onChange={setSus}
          value={data.sus}
         />
       </Grid>
     </Grid>

     <List>
      <ListItem>
       <ListItemText id="byod" primary={t('smicalc.byod')} />
       <ListItemSecondaryAction>
        <Switch
          edge="end"
          onChange={toggleBYOD}
          value={data.byod}
          inputProps={{ 'aria-labelledby': 'switch-list-label-byod' }}
        />
      </ListItemSecondaryAction>
     </ListItem>
    </List>
    </div>

   </Paper>
  )
})

const SmiResult = observer(() => {
  const  { t } = useTranslation()
  const classes = useStyles()
  const data = useContext(DataStore)

  const calculate = () => {
    data.calculate(
      () => {},
      () => {}
    )
  }

  let percs = data.result?.percLow + "% "
  percs += data.result?.percHigh === data.result?.percLow ? "" : " - " + data.result?.percHigh + "%"

  return (
   <>
   {data.result?.currentIsHigher && <Paper className={classes.paper}>
     <div className={classes.content}>
      <Typography className={classes.result}>{data.percent}%</Typography>
      <Typography variant="body2" color="textSecondary" component="p">{t('smicalc.hint1')} </Typography>
      <Typography variant="body2" color="textSecondary" component="p">{t('smicalc.hint2')} </Typography>
     </div>
    </Paper>
   }
    {data.result?.percHigh && data.result?.percLow && <Paper className={classes.paper}>
      <div className={classes.content}>
       <Typography className={classes.result}>{percs}</Typography>
       <Typography variant="body2" color="textSecondary" component="p">{t('smicalc.hint1')} </Typography>
       <Typography variant="body2" color="textSecondary" component="p">{t('smicalc.hint2')} </Typography>
      </div>
     </Paper>
    }

    <Button fullWidth variant="outlined" color="primary" onClick={calculate}>{t('smicalc.calculate')}</Button>
   </>
  )
})


export default Smicalc
