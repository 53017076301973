import { createContext,useContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import Ajax from 'api/ajax'
import { createMuiTheme } from '@material-ui/core/styles'
import { xor } from 'lodash'

import DataStore from './data'

class UI {
 openDeleteInternetConnectionsDialog = false

 handleOpenDeleteInternetConnectionDialog = () => this.openDeleteInternetConnectionsDialog = true
 handleCloseDeleteInternetConnectionDialog = () => this.openDeleteInternetConnectionsDialog = false
}


// ###################################
// ###################################
// ###################################

decorate(UI,{
 openDeleteInternetConnectionsDialog:observable
})






const UiStore = createContext(new UI())
export default UiStore
