import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

// DE
import TRANSLATION_PORTAL_DE from 'locales/de/core.json'
import TRANSLATION_AMPELFLYER_DE from 'locales/de/ampelflyer.json'
import TRANSLATION_INTERNETANSCHLUSS_DE from 'locales/de/internetanschluss.json'
import TRANSLATION_CATALOG_DE from 'locales/de/catalog.json'
import TRANSLATION_FAKEWEBSITES_DE from 'locales/de/fakewebsites.json'
import TRANSLATION_SMICALC_DE from 'locales/de/smicalc.json'
import TRANSLATION_SCHOOLTECH_DE from 'locales/de/schooltech.json'
import TRANSLATION_SURVEYS_DE from 'locales/de/surveys.json'
import TRANSLATION_SCHOOL_NUMBERS_DE from 'locales/de/schoolnumbers.json'
import TRANSLATION_RECHTLICHES_DE from 'locales/de/rechtliches.json'
import TRANSLATION_PHISHING_DE from 'locales/de/phishing.json'
import TRANSLATION_CHATS_DE from 'locales/de/chats.json'
import TRANSLATION_UNTERSTUETZUNG_DE from 'locales/de/unterstuetzung.json'
import TRANSLATION_PAGETITLES_DE from 'locales/de/pagetitles.json'
import TRANSLATION_AMPELSYSTEM_DE from 'locales/de/ampelsystem.json'
import TRANSLATION_TWINE_DE from 'locales/de/twine.json'
import TRANSLATION_AUDIODRIVE_DE from 'locales/de/audiodrive.json'
import TRANSLATION_REDIRECTER_DE from 'locales/de/redirecter.json'

// FR
import TRANSLATION_PORTAL_FR from 'locales/fr/core.json'
import TRANSLATION_AMPELFLYER_FR from 'locales/fr/ampelflyer.json'
import TRANSLATION_INTERNETANSCHLUSS_FR from 'locales/fr/internetanschluss.json'
import TRANSLATION_SMICALC_FR from 'locales/fr/smicalc.json'
import TRANSLATION_SCHOOLTECH_FR from 'locales/fr/schooltech.json'
import TRANSLATION_CHATS_FR from 'locales/fr/chats.json'
import TRANSLATION_UNTERSTUETZUNG_FR from 'locales/fr/unterstuetzung.json'
import TRANSLATION_PAGETITLES_FR from 'locales/fr/pagetitles.json'
import TRANSLATION_AMPELSYSTEM_FR from 'locales/fr/ampelsystem.json'


const DE = {
 translation:{
  core:TRANSLATION_PORTAL_DE,
  ampelflyer:TRANSLATION_AMPELFLYER_DE,
  internetanschluss:TRANSLATION_INTERNETANSCHLUSS_DE,
  catalog:TRANSLATION_CATALOG_DE,
  fakewebsites:TRANSLATION_FAKEWEBSITES_DE,
  smicalc:TRANSLATION_SMICALC_DE,
  schooltech:TRANSLATION_SCHOOLTECH_DE,
  surveys:TRANSLATION_SURVEYS_DE,
  schoolnumbers:TRANSLATION_SCHOOL_NUMBERS_DE,
  rechtliches:TRANSLATION_RECHTLICHES_DE,
  phishing:TRANSLATION_PHISHING_DE,
  chats:TRANSLATION_CHATS_DE,
  unterstuetzung:TRANSLATION_UNTERSTUETZUNG_DE,
  pagetitles:TRANSLATION_PAGETITLES_DE,
  ampelsystem:TRANSLATION_AMPELSYSTEM_DE,
  twine:TRANSLATION_TWINE_DE,
  audiodrive:TRANSLATION_AUDIODRIVE_DE,
  redirecter:TRANSLATION_REDIRECTER_DE
 }
}
const FR = {
 translation:{
   core:TRANSLATION_PORTAL_DE,
   ampelflyer:TRANSLATION_AMPELFLYER_DE,
   internetanschluss:TRANSLATION_INTERNETANSCHLUSS_DE,
   catalog:TRANSLATION_CATALOG_DE,
   fakewebsites:TRANSLATION_FAKEWEBSITES_DE,
   smicalc:TRANSLATION_SMICALC_DE,
   schooltech:TRANSLATION_SCHOOLTECH_DE,
   surveys:TRANSLATION_SURVEYS_DE,
   schoolnumbers:TRANSLATION_SCHOOL_NUMBERS_DE,
   rechtliches:TRANSLATION_RECHTLICHES_DE,
   phishing:TRANSLATION_PHISHING_DE,
   chats:TRANSLATION_CHATS_FR,
   unterstuetzung:TRANSLATION_UNTERSTUETZUNG_DE,
   pagetitles:TRANSLATION_PAGETITLES_DE,
   ampelsystem:TRANSLATION_AMPELSYSTEM_FR,
   twine:TRANSLATION_TWINE_DE,
   audiodrive:TRANSLATION_AUDIODRIVE_DE,
   redirecter:TRANSLATION_REDIRECTER_DE
 }
}

const resources = {
  de:DE,
  fr:FR
}


i18next
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
  resources,
  saveMissing: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
 })



export default i18next
