import { createContext } from 'react'
import { decorate, observable, computed, toJS } from 'mobx'


class Player {
  playerRef = 0
  setPlayerRef = (pr) => this.playerRef = pr
  playing = false
  togglePlay = () => {
    this.playing = !this.playing
  }
  play = () => this.playing = true
  stop = () => this.playing = false
  playNewTrackAtIndex = (index) => {
    this.playing = false
    this.setTrackIndex(index)
    this.playing = true
  }
  list = []
  setList = (tracks) => {
    const split = tracks.split(',')
    this.list = split.map(track => {
      track = track.split(';')
      const id = track[0]
      const name = track[1]

      return { url:this.convertId(id), name }
    })
  }

  convertId = (id) => { return "https://docs.google.com/uc?export=open&id="+id }

  trackIndex = 0
  setTrackIndex = (index) => this.trackIndex = index
  prev = () => {
    this.setTrackIndex( this.trackIndex -1 < 0 ? this.list.length - 1 : this.trackIndex - 1 )
  }
  next = () => {
    this.setTrackIndex( this.trackIndex + 1 >= this.list.length ? 0 : this.trackIndex + 1 )
  }

  duration = 0
  setDuration = (seconds) => this.duration = seconds

  get durationFormated () {
    return this.duration ? new Date(this.duration * 1000).toISOString().substr(11, 8) : 0
  }
  playedSeconds = 0
  setPlayedSeconds = (playedSeconds) => this.playedSeconds = playedSeconds
  seekTo = (amount) => {
    this.playerRef.seekTo(amount,'seconds')
  }

  get hasMultipleSongs () { return this.list.length > 1 }
}

class Organizer {
 files = []
 setFiles = (files) => this.files = files
 toggleFile = (id) => this.files = this.files.map(file => {
   if (file.id === id) {
    file.checked = !file.checked
   }
   return file
 })
 getCheckedTracks = () => {
   let temp = []
   this.files.map(file => {
     if (file.checked) {
       const filename = file.name.split('.').slice(0, -1).join('.');
       temp.push(file.id+';'+filename)
     }
   })
   return temp
 }


 lastDriveLink = ''
 setLastDriveLink = (link) => this.lastDriveLink = link

 current = null
 setCurrent = (id) => this.current = id
 playing = false
 play = () => this.playing = true
 stop = () => this.playing = false
 togglePlay = () => this.playing = !this.playing

 BREAKPOINT_MIN_WIDTH = 370
 BREAKPOINT_MIN_HEIGHT = 95
 BREAKPOINTS = {
   custom:{ width:this.BREAKPOINT_MIN_WIDTH, height:this.BREAKPOINT_MIN_HEIGHT },
   smartphone:{ width:375, height: 500 },
   tablet:{ width:1024, height: 768}
 }
 breakpoint = this.BREAKPOINTS.custom
 setBreakpoint = (bp) => this.breakpoint = bp
 setCustomBreakpointWidth = (width) => {
   this.setBreakpoint({ width, height:this.breakpoint.height })
 }
 setCustomBreakpointHeight = (height) => {
   this.setBreakpoint({ height, width:this.breakpoint.width })
 }

}



class Data {
 player = new Player()
 organizer = new Organizer()

 resetApp = () => {
   this.player = new Player()
   this.organizer = new Organizer()
 }
}

decorate(Player,{
  list:observable,
  playing:observable,
  hasMultipleSongs:computed,
  duration:observable,
  durationFormated:computed,
  playedSeconds:observable,
  trackIndex:observable,
  playerRef:observable
})

decorate(Organizer,{
  files:observable,
  current:observable,
  playing:observable,
  breakpoint:observable,
  lastDriveLink:observable
})

decorate(Data,{
 player:observable,
 organizer:observable,
 resetApp:observable
})


const DataStore = createContext(new Data())
export default DataStore
