import React, { useContext,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import DataStore from 'core/stores/data'
import UiStore from 'core/stores/ui'

import {
  Container,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { amber } from '@material-ui/core/colors'
import { Drafts,Close } from '@material-ui/icons'

import { IamNotWorkingInASchoolOrKindergardenAndWantToParticipate } from 'shared/components/location'


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    height:'90vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column'
  },
  container:{
   maxWidth:345,
   textAlign:'center'
  },
  card:{
   marginBottom:theme.spacing(2)
 },
 pendingButton:{
   backgroundColor:amber[500]
 },
 foreignerText:{
   marginTop:theme.spacing(3)
 }
}));


const FreshStart = observer(() => {
  const classes = useStyles()
  const { user } = useContext(DataStore)
  const { location } = useContext(UiStore)
  const { t } = useTranslation()

  useEffect(() => {
    user.getPendingRequests()
  },[user])

  return (
   <div className={classes.root}>
    <div className={classes.container}>

     <Card className={classes.card}>
      <CardActionArea>
       <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
         {t('core.dashboard.freshStart.welcome')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {t('core.dashboard.freshStart.firstStep')}
        </Typography>
       </CardContent>
      </CardActionArea>
     </Card>

     <Button
      fullWidth
      variant="contained"
      color="primary"
      onClick={location.handleOpen}
      > {t('core.dashboard.freshStart.addLocation')}</Button>

     {user.pendings.length > 0 && <Pendings />}

     <div className={classes.foreignerText}>
      <Typography variant="caption">
       {t('core.dashboard.freshStart.foreigner.one')}
       <Link href="#" onClick={location.handleOpenForeignerDialog}>
        {t('core.dashboard.freshStart.foreigner.two')}
       </Link>
      </Typography>
      <IamNotWorkingInASchoolOrKindergardenAndWantToParticipate />
     </div>

    </div>
   </div>
  )
})

const Pendings = observer(() => {
  const { location } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  return (
   <>
    <Button
     fullWidth
     variant="contained"
     className={classes.pendingButton}
     onClick={location.handleOpenPendingDialog}>
     {t('core.dashboard.freshStart.pendings')}
    </Button>
    <PendingsDialog />
   </>
  )
})

export const PendingsDialog = observer(() => {
  const { location,notifier } = useContext(UiStore)
  const { t } = useTranslation()
  const classes = useStyles()
  const { user } = useContext(DataStore)

  console.log(location.openPendingDialog);

  const cancelInviteRequest = (erzKey) => {
    user.cancelInviteRequest(
      erzKey,
      () => {
        location.handleClosePendingDialog()
        user.getPendingRequests(
          () => { notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)  }
        )
      },
      () => {
        location.handleClosePendingDialog()
        notifier.push(t('core.notifier.4xx'),notifier.variants.ERROR)
      }
    )
  }

  return (
    <Dialog open={location.openPendingDialog} onClose={location.handleClosePendingDialog} maxWidth={"md"}>
     <Container component="main" maxWidth="md">
      <List
       component="nav"
       aria-label={t('core.dashboard.freshStart.pendings')}
       >
       {user.pendings.map((p,i) => {
         const address = p.strasse + ', ' + p.plz + ' ' + p.standort
         const style = i + 1 < user.pendings.length ? {borderBottom:'1px solid #ddd'} : {}
          return <ListItem key={"pending-item-"+i} style={style}>
           <ListItemIcon>
            <Drafts />
           </ListItemIcon>
           <ListItemText primary={p.name} secondary={address}/>
           <ListItemSecondaryAction>
            <Tooltip placement="right" title={t('core.dashboard.freshStart.cancelInvite')}>
             <IconButton edge="end" aria-label="del-pending" onClick={()=>{cancelInviteRequest(p.erz_key)}}>
              <Close />
             </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
          </ListItem>
       })}
      </List>
     </Container>
    </Dialog>
  )
})


export default FreshStart
