import React, { useContext, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import DataStore from 'core/stores/data'

import {
  Typography
} from '@material-ui/core'

import IMG_ADVICE from 'media/img/advice.png'

const useStyles = makeStyles(theme => ({
  image: {
    background: '#fff',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center center',
    maxWidth:300,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
  }
}));


const Advice = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { kibsmeta } = useContext(DataStore)


  return (
    <section id="advice" className="digital-experience-area bg-fff5ee ptb-100">
      <div className="container-fluid">
        <div className="section-title">
          <h2>{t('core.landingpage.advice.title')}</h2>
          <div className="bar" />
          <div className="subscribe-content">
           <h1>{t('core.landingpage.advice.intro')}</h1>
           <div className="digital-experience-image" style={{textAlign:'left'}}>
            <img src={IMG_ADVICE} alt="image" className={classes.image}/>
           </div>
           <Typography variant="h6" component="p">{t('core.landingpage.advice.text')}</Typography>
           <Typography variant="h6" component="p" dangerouslySetInnerHTML={{ __html:t('core.landingpage.advice.text2')  }} />
           <Typography variant="h6" component="p">{t('core.landingpage.advice.text3')}</Typography>
          </div>
        </div>
      </div>
    </section>
 )
})

export default Advice
