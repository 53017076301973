import React, { useEffect,useState,useCallback,useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const container = {
  width:'100%'
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width:'100%'
}

const activeStyle = {
 borderColor: '#2196f3'
}

const acceptStyle = {
 borderColor: '#00e676'
}

const rejectStyle = {
 borderColor: '#ff1744'
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  maxWidth: '100%',
  height: '100%'
};


const KibsDropzone = (props) => {
 const { t } = useTranslation()
 let {
   accept,
   maxSize,
   multiple,
   maxFiles,
   upload,
   onFiles,
   showThumbnails
 } = props

 let path = null, success = null, fail = null
 if (upload) {
   let { path, success, fail } = upload
 }

 accept = accept ? accept : 'image/*'
 maxSize = maxSize ? maxSize : 2
 maxFiles = maxFiles ? maxFiles : 10
 if (!multiple) maxFiles = 1
 showThumbnails = showThumbnails === false ? false : true

 const [files, setFiles] = useState([]);
 const onDrop = useCallback(acceptedFiles => {
  setFiles(acceptedFiles.map(file => Object.assign(file, {
   preview: URL.createObjectURL(file)})
  ))
 },[])

 useEffect(() => {
   if (onFiles) {
     onFiles(files)
   }
   else if (upload) {
     uploadFn()
   }
 },[files])


 const uploadFn = useCallback(
   () => {
    const formData = new FormData()
    files.each((file,i) => formData.append('file_'+i,file))

    axios.post(path, formData, {
     headers: {
      'Content-Type': 'multipart/form-data'
     }
    })
    .then(() => {
      if (success) success(); else return
    })
    .catch(() => {
      if (fail) fail(); else return
    })
   }
 )

 const {
  getRootProps,
  getInputProps,
  isDragActive,
  isDragAccept,
  isDragReject
 } = useDropzone({
  onDrop:onDrop,
  accept:accept
 })

 const style = useMemo(() => ({
  ...baseStyle,
  ...(isDragActive ? activeStyle : {}),
  ...(isDragAccept ? acceptStyle : {}),
  ...(isDragReject ? rejectStyle : {})
 }), [
  isDragActive,
  isDragReject
 ])

 const thumbs = files.map(file => (
  <div style={thumb} key={file.name}>
    <div style={thumbInner}>
      <img
        src={file.preview}
        style={img}
      />
    </div>
  </div>
 ));

 useEffect(() => () => {
   // Make sure to revoke the data uris to avoid memory leaks
   files.forEach(file => URL.revokeObjectURL(file.preview));
 }, [files]);

 return (
   <section style={container}>
     <div {...getRootProps({className: 'dropzone',style})}>
       <input {...getInputProps()} />
       <p>{t('core.upload.dragNDrop')}</p>
     </div>
     {showThumbnails &&
       <aside style={thumbsContainer}>
         {thumbs}
       </aside>
     }
   </section>
 )
}


export default KibsDropzone
