class WindowObject {

  constructor () {
    if (!window.kibsportal) {
      this.init()
    }
  }

 init () {
   let kibs = {
     routes:{
       VISIBILITY_PUBLIC:'public',
       VISIBILITY_PRIVATE:'private',
       lastRouteVisibility:'public',
       lastRoute:'',
       setVisibility:(visibility) => {
         // dont set last visibility at login page to public, because its overrides the real visibility --> all is redirected to login if authorization fails
         if(/login/.test(window.location.pathname)){
          return
        } else {
         window.kibsportal.routes.lastRouteVisibility = visibility
         window.kibsportal.routes.lastRoute = window.location.pathname
        }
      },
      isPrivate:() => {
        return window.kibsportal.routes.lastRouteVisibility === window.kibsportal.routes.VISIBILITY_PRIVATE
      }
     }
   }

   window.kibsportal = kibs
 }
}


const WO = new WindowObject()
export default WO
