
import React, {useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'


const Testimonials = observer(() => {
  return (
    <section id="discord" className="testimonial-area ptb-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 offset-lg-6">
            <div className="testimonial-content">
              <span>Abweschlung</span>
              <h2>soll hier sein</h2>
              <div className="bar" />
            </div>
          </div>
        </div>
        <div className="testimonial-slider owl-carousel owl-theme">
          <div className="testimonial-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonial-image" />
              </div>
              <div className="col-lg-6">
                <div className="testimonial-inner-content">
                  <div className="icon">
                    <i className="flaticon-left-quotes-sign" />
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                  <div className="info-text">
                    <h4>Morris Jacket</h4>
                    <span>Web Developer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonial-image image-two" />
              </div>
              <div className="col-lg-6">
                <div className="testimonial-inner-content">
                  <div className="icon">
                    <i className="flaticon-left-quotes-sign" />
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                  <div className="info-text">
                    <h4>Mahindra jhon</h4>
                    <span>App Developer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-item">
            <div className="row">
              <div className="col-lg-6">
                <div className="testimonial-image image-three" />
              </div>
              <div className="col-lg-6">
                <div className="testimonial-inner-content">
                  <div className="icon">
                    <i className="flaticon-left-quotes-sign" />
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                  <div className="info-text">
                    <h4>Lee Munroe</h4>
                    <span>Web Designer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Testimonials
