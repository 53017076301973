import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'
import ls from 'local-storage'
import Ajax from 'api/ajax'

class Data {
 code
 currentStep = 0
 MAX_STEPS = 10
 LIVE_UPDATE_EVERY_SECONDS = 10
 url = ''
 frameUrl
 thirdparty = {}
 magic = null
 selectedElements = []
 redirectUrl
 redirectOptions = {
   SAME_URL:1,
   CUSTOM_URL:2,
   RESOLVE_PHISHING:3
 }
 selectedRedirectOption = this.redirectOptions.SAME_URL
 end
 name = ''
 liveData
 CACHE_NAME = 'latestphishingsetups'
 DISABLE_TRACKING_PARAM = 'disabletracking'
 cache
 pin

 reset = () => {
   this.currentStep = 0
   this.code = null
   this.url = ''
   this.frameUrl = null
   this.magic = null
   this.selectedElements = []
   this.redirectUrl = null
   this.end = null
   this.name = ''
   this.liveData = null
   this.pin = null
 }

 next = (step) => {
   if (step) {
     if (step < this.MAX_STEPS) this.currentStep = step
  } else {
    if (this.currentStep + 1 < this.MAX_STEPS) this.currentStep++
  }
 }
 prev = () => {
   if (this.currentStep - 1 > 0) this.currentStep--
 }
 setCode = (code) => this.code = code
 setName = (name) => this.name = name
 setEnd = (end) => this.end = end
 setRedirectOption = (option) => {
   this.selectedRedirectOption = option
   if (this.selectedRedirectOption === this.redirectOptions.SAME_URL) {
     this.setRedirectUrl(this.magic.url)
   }
   else if (this.selectedRedirectOption === this.redirectOptions.CUSTOM_URL) {
     this.setRedirectUrl('')
   }
   else if (this.selectedRedirectOption === this.redirectOptions.RESOLVE_PHISHING) {
     this.setRedirectUrl('//portal.kibs.ch/phishing/sensibilisierung')
   }
 }
 setPin = (pin) => this.pin = pin
 setLiveData = (data) => this.liveData = data
 setMagic = (magic) => this.magic = magic
 setRedirectUrl = (url) => this.redirectUrl = url
 setFrameUrl = (url) => {
   if (url.indexOf('?') === -1) {
     this.frameUrl = url + '?' + this.DISABLE_TRACKING_PARAM
   } else {
     this.frameUrl = url + '&' + this.DISABLE_TRACKING_PARAM
   }
 }
 setURL = (url) => this.url = url
 setGoogle = () => {
   this.setURL('google')
   this.setFrameUrl(this.thirdparty.googleReplica)
 }
 setO365 = () => {
   this.setURL('microsoft')
   this.setFrameUrl(this.thirdparty.o365Replica)
 }
 get isGoogle () {
   return this.url === 'google' || (this.magic ? this.magic.isGoogle : false)
 }
 get isMicrosoft () {
   return this.url === 'microsoft' || (this.magic ? this.magic.isMicrosoft : false)
 }
 get lastPhishingSite () {
   return this.magic ? this.magic.url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0] : null
 }
 get staticPage () {
   return this.frameUrl ? this.frameUrl.replace('disabletracking','') : ''
 }

 loadlastWebsite = () => {
   this.setURL(this.magic.index)
   this.setFrameUrl(this.magic.gdrive)
 }

 postMessage = (msg) => {
   const iframe = document.getElementById('crazy-quest-frame').contentWindow
   iframe.postMessage(msg,"*")
 }

 handleSelectedElement = (data,cbEveryStep,cbDone) => {
   this.selectedElements.push(data)
   if (this.selectedElements.length === 1) {
    this.postMessage('stopSelect')
    cbDone()
  } else {
   cbEveryStep()
  }
 }

 initialChecks = (loadMonitoring) => {
   const params = this.getAllUrlParams()
   if (params.code) {
    this.setCode(params.code)
    loadMonitoring()
   }
   this.cache = ls.get(this.CACHE_NAME)
 }

 injectLiveData = (code,url,pin,staticPage,cb) => {
   this.setFrameUrl(staticPage)
   this.setURL(url)
   this.setPin(pin)
   cb()
 }

 loadWebsite = (cb) => {
  Ajax.phishingDelete(
    {code:this.code},
    () => {
      this.setMagic(null)

      Ajax.phishingMagic(
        { url: this.url },
        (res) => {
          if (res && res.data && res.data.success) {
            this.setFrameUrl(res.data.magic.beforeSetup)
            this.setMagic(res.data.magic)
            this.setRedirectUrl(res.data.magic.url)
            this.setCode(res.data.magic.tempName)
          } else {

          }
          cb()
        },
        () => {}
      )
    },
    () => {}
  )
 }

 addScripts = (cbSuccess,cbFail) => {
   Ajax.phishingAddScripts(
     {
       submitIdentifier:this.selectedElements[0],
       redirect:this.redirectUrl,
       code:this.code
     },
     cbSuccess,
     cbFail
   )
 }

 setup = (cbSuccess,cbFail) => {
   Ajax.phishingSetup(
     {
       code:this.code,
       name:this.name,
       end:this.end
     },
     (res) => {
       const success = res && res.data && res.data.success
       if (success) {
         this.setLiveData(res.data.liveData)
         this.setPin(res.data.liveData.abortPin)
         this.setFrameUrl(res.data.liveData.static)
         this.cacheFn(this.code,res.data.liveData.abortPin,this.name,res.data.liveData.static)
       }
       cbSuccess(success)
     },
     cbFail
   )
 }

 cacheFn = (code,abortPin,name,staticPage) => {
   const latest = ls.get(this.CACHE_NAME) || []
   latest.push({ code, abortPin, name, staticPage })
   ls.set(this.CACHE_NAME,latest)
 }

 removeFromCache = (code) => {
  const latest = ls.get(this.CACHE_NAME) || []
  for (let i = 0; i < latest.length; i++) {
    if (latest[i].code == code) {
      latest.splice(i,1)
    }
  }
  ls.set(this.CACHE_NAME,latest)
  this.cache = latest
 }

 live = (cbSuccess,cbFail) => {
   Ajax.phishingLive(
     { code: this.code },
     (res) => {
       const success = res && res.data && res.data.success
       if (success) {
         this.setLiveData(res.data.liveData)
         cbSuccess(success,res.data.liveData)
       }
     },
     cbFail
   )
 }

 delete = (cbSuccess,cbFail) => {
   Ajax.phishingDelete(
     { code: this.code },
     cbSuccess,
     cbFail
   )
 }

 abort = (cbSuccess,cbFail) => {
   if (this.code && this.pin) {
     Ajax.phishingAbort(
       { code: this.code, pin: this.pin },
       (res) => {
         this.removeFromCache(this.code)
         this.reset()
         cbSuccess()
       },
       cbFail
     )
   }
 }

 init = () => {
   window.alert = null
   Ajax.phishingThirdparty(
     (res) => {
       this.thirdparty = res.data.thirdparty
     },
     (err) => { console.error(err) }
   )
 }

 getAllUrlParams = (url) => {
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  var obj = {};
  if (queryString) {
    queryString = queryString.split('#')[0];
    var arr = queryString.split('&');
    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split('=');
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
      paramName = paramName.toLowerCase();
      //if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
      if (paramName.match(/\[(\d+)?\]$/)) {
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];
        if (paramName.match(/\[\d+\]$/)) {
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
 }

}

decorate(Data,{
 code:observable,
 currentStep:observable,
 url:observable,
 thirdparty:observable,
 frameUrl:observable,
 magic:observable,
 lastPhishingSite:computed,
 selectedElements:observable,
 redirectUrl:observable,
 selectedRedirectOption:observable,
 end:observable,
 name:observable,
 liveData:observable,
 isGoogle:computed,
 isMicrosoft:computed,
 cache:observable,
 pin:observable,
 staticPage:computed
})




const DataStore = createContext(new Data())
export default DataStore
