import React, { useContext,useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import UiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'

import {
  Button,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Link,
  InputAdornment,
  MenuItem
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { PersonAdd,Close,Send } from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
 root:{
   position:'relative',
   display:'flex',
   flexDirection:'column',
   justifyContent:'center',
   alignItems:'center',
   textAlign:'center',
   padding:theme.spacing(3),
 },
 personAddIcon:{
   fontSize:'3rem',
   color:'#ccc',
   marginBottom:theme.spacing(2)
 },
 contentText:{
   marginBottom:theme.spacing(2)
 },
 titleButton:{
  marginBottom:theme.spacing(3)
 },
 dialog:{
   mindWidth:320
 },
 title:{
   width:'95%'
 },
 close:{
   top: 8,
   color: '#9e9e9e',
   right: 8,
   position: 'absolute'
 }
}))

const Invite = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { inviteUI } = useContext(UiStore)

  const open = () => {
    inviteUI.goToPageOne()
    inviteUI.handleOpen()
  }

  return (
    <div className={classes.root}>
     <PersonAdd className={classes.personAddIcon}/>
     <Typography variant="" className={classes.contentText}> {t('core.dashboard.invite.content')} </Typography>
     <Button onClick={open} variant="outlined" color="primary" className={classes.titleButton}>{t('core.dashboard.invite.title')}</Button>
     <InviteDialog />
    </div>
  )
})

const InviteDialog = observer(() => {
  const { inviteUI } = useContext(UiStore)
  const { t } = useTranslation()
  const { schoolPage } = useContext(DataStore)
  const classes = useStyles()
  const school = schoolPage.school

  const pageOneTitle = t('core.dashboard.invite.to') + ' ' + school.name
  const pageTwoTitle = t('core.dashboard.invite.editLink')

  return (
    <Dialog className={classes.dialog} aria-labelledby="invite-link-gen" open={inviteUI.open} onClose={inviteUI.handleClose} maxWidth="md">
     <DialogTitle className={classes.title}>
      {inviteUI.isPageOne && pageOneTitle}
      {inviteUI.isPageTwo && pageTwoTitle}
      <IconButton className={classes.close} onClick={inviteUI.handleClose}>
       <Close />
      </IconButton>
     </DialogTitle>
     <DialogContent>
      {inviteUI.isPageOne && <SendingComponent />}
      {inviteUI.isPageTwo && <OptionsComponent />}
     </DialogContent>

    </Dialog>
  )
})

const SendingComponent = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { inviteUI,notifier } = useContext(UiStore)

  const { schoolPage } = useContext(DataStore)
  const { school } = schoolPage
  const { invite } = school

  const onCopy = () => {
    notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
  }
  const onChangeMailRecipients = (e) => {
    invite.setRecipients(e.target.value)
  }
  const sendInviteLink = () => {
    invite.sendInviteLink(
      () => {
        inviteUI.handleClose()
        notifier.push(t('core.notifier.200'),notifier.variants.SUCCESS)
      },
      () => {
        notifier.push(t('core.notifier.500'),notifier.variants.ERROR)
      }
    )
  }

  return (
   <div>
    <TextField
     fullWidth
     label={t('core.dashboard.invite.mails')}
     helperText={t('core.dashboard.invite.viaMail')}
     variant="filled"
     onChange={onChangeMailRecipients}
     value={invite.recipients}
     InputProps={{
      endAdornment: <InputAdornment position="end">
       <Button edge="end" variant="contained" color="primary" onClick={sendInviteLink}>
         {t('core.general.sendIt')}
       </Button>
      </InputAdornment>
     }}
    />
    <TextField
     fullWidth
     value={invite.sharedInviteLink}
     helperText={
      <>
       {t('core.dashboard.invite.viaLink')}{" "}
       <Link to="#" onClick={inviteUI.goToPageTwo}>{t('core.dashboard.invite.editLink')}</Link>
      </>
     }
     variant="filled"
     InputProps={{
      endAdornment: <InputAdornment position="end">
       <CopyToClipboard
        text={invite.sharedInviteLink}
        onCopy={onCopy}>
        <Button edge="end" variant="contained" color="primary">
         {t('core.general.copy')}
        </Button>
       </CopyToClipboard>
      </InputAdornment>
     }}
    />




   </div>
  )
})

const OptionsComponent = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { inviteUI } = useContext(UiStore)
  const { schoolPage } = useContext(DataStore)
  const { school } = schoolPage
  const { invite } = school

  const onChangeExpiresAfter = (e) => {
    invite.setExpiresAfter(e.target.value)
  }
  const onChangeMaxNumberOfUses = (e) => {
   invite.setMaxNumberOfUses(e.target.value)
  }
  const generateLink = () => {
    invite.generateInviteLink(
      () => {
        inviteUI.goToPageOne()
      },
      () => {}
    )
  }

  return (
   <div>
    <TextField
     value={invite.expiresAfter}
     fullWidth
     label={t('core.dashboard.invite.expiresAfter')}
     variant="filled"
     onChange={onChangeExpiresAfter}
     select>
      <MenuItem value={invite.expiresAfterPossibilities.MINUTES_5}>{invite.expiresAfterPossibilities.M5.value} {t('core.dashboard.invite.minutes')}</MenuItem>
      <MenuItem value={invite.expiresAfterPossibilities.MINUTES_30}>{invite.expiresAfterPossibilities.M30.value} {t('core.dashboard.invite.minutes')}</MenuItem>
      <MenuItem value={invite.expiresAfterPossibilities.HOURS_1}>{invite.expiresAfterPossibilities.H1.value} {t('core.dashboard.invite.hour')}</MenuItem>
      <MenuItem value={invite.expiresAfterPossibilities.HOURS_6}>{invite.expiresAfterPossibilities.H6.value} {t('core.dashboard.invite.hours')}</MenuItem>
      <MenuItem value={invite.expiresAfterPossibilities.HOURS_12}>{invite.expiresAfterPossibilities.H12.value} {t('core.dashboard.invite.hours')}</MenuItem>
      <MenuItem value={invite.expiresAfterPossibilities.DAY_1}>{invite.expiresAfterPossibilities.D1.value} {t('core.dashboard.invite.day')}</MenuItem>
    </TextField>

    <TextField
     value={invite.maxNumberOfUses}
     fullWidth
     label={t('core.dashboard.invite.maxNumberOfUses')}
     variant="filled"
     onChange={onChangeMaxNumberOfUses}
     select>
     <MenuItem value={invite.maxNumberOfUsesPossibilities.TIMES_UNLIMITED}>{t('core.dashboard.invite.unlimited')}</MenuItem>
     <MenuItem value={invite.maxNumberOfUsesPossibilities.TIMES_1}>{invite.maxNumberOfUsesPossibilities.TIMES_1} {t('core.dashboard.invite.times')}</MenuItem>
     <MenuItem value={invite.maxNumberOfUsesPossibilities.TIMES_5}>{invite.maxNumberOfUsesPossibilities.TIMES_5} {t('core.dashboard.invite.times')}</MenuItem>
     <MenuItem value={invite.maxNumberOfUsesPossibilities.TIMES_10}>{invite.maxNumberOfUsesPossibilities.TIMES_10} {t('core.dashboard.invite.times')}</MenuItem>
     <MenuItem value={invite.maxNumberOfUsesPossibilities.TIMES_25}>{invite.maxNumberOfUsesPossibilities.TIMES_25} {t('core.dashboard.invite.times')}</MenuItem>
    </TextField>

    <DialogActions>
      <Button color="secondary" onClick={inviteUI.goToPageOne}>
        {t('core.general.cancel')}
      </Button>
      <Button color="primary" autoFocus onClick={generateLink}>
        {t('core.dashboard.invite.generateLink')}
      </Button>
    </DialogActions>

   </div>
  )
})

export default Invite
