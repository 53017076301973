import React, { useEffect,useContext,Suspense } from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import PrivateRoute from 'PrivateRoute'
import PublicRoute from 'PublicRoute'
// DO LAZY imports on Routes to support code splitting for bundler --> smaller packages
//
//
// const About = lazy(() => import('./routes/About'));

import SignIn   from 'shared/components/login/index'
import SignUp   from 'shared/components/register/index'
import ResetPw  from 'shared/components/resetpw/index'
import ResetPwHash from 'shared/components/resetpw/resetPWHash'
import Loading  from 'shared/components/loading/index'
import Notifier from 'shared/components/snackbar/index'
import { OAuthReceiver } from 'shared/components/login/oauth'

import Header from 'shared/components/header'
import Footer from 'shared/components/footer'
import ScrollToTop from 'shared/components/backtotoproute'

import {
  ErrorPage404,ErrorPage403
} from 'shared/components/errorpages/index'

// Core
import Dashboard from 'core/dashboard/index'
import AdminBackend from 'core/admin/index'
import SchoolPage from 'core/dashboard/SchoolPage'
import InviteRoutine from 'core/dashboard/InviteRoutine'
import Rechtliches from 'core/rechtliches/index'
import Tagungen from 'core/tagungen/index'
import NewsTimeline from 'core/news/timeline'
// Datenschutz
import Datenschutz from 'services/datenschutz/index'
// Ampelflyer
import Ampelflyer from 'services/ampelflyer/index'
import Pdf from 'services/ampelflyer/pdf'
// Ampelsystem
import Ampelsystem from 'services/ampelsystem/index'
// InternetAnschluss
import InternetAnschluss from 'services/internetanschluss/index'
// SimpleCatalog
import { SimpleCatalog } from 'services/simplecatalog/index'
import { CatalogSuggestion } from 'services/simplecatalog/Catalog'
// FakeWebsites
import FakeWebsites from 'services/fakewebsites/index'
// Phishing
import Phishing from 'services/phishing/index'
import PhishingSensibilisierung from 'services/phishing/sensibilisierung'
import PhishingInfo from 'services/phishing/info'
// Chats
import Chats from 'services/chats/index'
// Redirecter
import Redirecter from 'services/redirecter/index'
// Unterstuetzung
import Unterstuetzung from 'services/unterstuetzung/index'
import SAI from 'core/sai/index'
// Twine
import Twine from 'services/twine/index'
import TwineEmbed from 'services/twine/embed'
// Sonic Pi
import SonicPi from 'services/sonicpi/index'
// Bookmarklet
import Bookmarklet from 'services/bookmarklet/index'
// Audiodrive
import AudioDrive from 'services/audiodrive/index'
import AudioDrivePlayer from 'services/audiodrive/Player'
// Kurts Abschied SMI Tagung 2022, instagramlike

import Kompass from 'core/kompass/index'

import LandingPage from 'core/landingpage/index'
import LandingPageONE from 'core/landingpageONE/index'
import * as serviceWorker from './serviceWorker'
import { Router, Switch, Route } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
//import '@fortawesome/fontawesome-pro/css/all.min.css'

import { makeStyles,useTheme,ThemeProvider } from '@material-ui/core/styles'
import { useWindowWidth } from 'shared/hooks/hooks'
import i18next from './i18n'
import GlobalUiStore from 'core/stores/ui'
import DataStore from 'core/stores/data'
import history from 'history.js'
import './windowObject'
import './index.css'


const useStyles = makeStyles(theme => ({
'@global': {
  'html':{
    scrollBehavior:'smooth'
  },
  'body': {
    background:'red'
  },
  'html':{
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      fontSize: 18
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 20
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 24
    }
  }
 }
}))


const Main = observer((props) => {
  const globalUi = useContext(GlobalUiStore)
  const theme = useTheme()
  const windowWidth = useWindowWidth()
  const { t } = useTranslation()

  useEffect(() => {
    if (globalUi.withLayout) {
      document.body.style.paddingTop = (globalUi.withLayout ? 64 : 0) + 'px'
      if (windowWidth <= theme.breakpoints.values.sm) {
        document.body.style.paddingTop = (globalUi.withLayout ? 56 : 0) + 'px'
      }
    }
  },[windowWidth,window.location])

  return (
   <Suspense fallback={<Loading />}>
    <ThemeProvider theme={globalUi.themer.current}>

      <CssBaseline />
      <Notifier />
      <Loading />

      <Router history={history}>
       <Header />
       <ScrollToTop />
       <Switch>
        // core
        <PublicRoute exact path="/" component={LandingPageONE} />
        <PublicRoute path="/login/google/success" component={OAuthReceiver} />
        <PublicRoute path="/login/o365/success" component={OAuthReceiver} />
        <PublicRoute path="/login" component={SignIn} />
        <PublicRoute path="/signup" component={SignUp} />
        <PublicRoute path="/resetpw/:hash" component={ResetPwHash} />
        <PublicRoute path="/resetpw" component={ResetPw} />
        <PrivateRoute path="/dashboard/schoolpage/:schoolid" component={SchoolPage}/>
        <PublicRoute path="/dashboard" component={Dashboard} />
        <PublicRoute path="/aktuelles" component={NewsTimeline} title={t('pagetitles.news')}/>
        <PrivateRoute path="/tagungen" component={Tagungen} />
        <PrivateRoute path="/admin" component={AdminBackend} />
        // Rechtliche Hinweise
        <PublicRoute path="/rechtliches" component={Rechtliches} />
        // Datenschutz
        <PublicRoute path="/datenschutz/ampelsystem/index.php" component={Ampelsystem} title={t('pagetitles.ampelsystem')}/>
        <PublicRoute path="/datenschutz/ampelflyer/pdf" component={Pdf} />
        <PublicRoute path="/datenschutz/ampelsystem" component={Ampelsystem} title={t('pagetitles.ampelsystem')}/>
        <PublicRoute path="/datenschutz" component={Ampelsystem} title={t('pagetitles.ampelsystem')}/>
        <PrivateRoute path="/dashboard/schoolpage/:schoolid/ampelflyer" component={Ampelflyer} />
        // InternetAnschluss
        <PublicRoute path="/internetanschluss" component={InternetAnschluss} title={t('pagetitles.internetanschluss')}/>
        // simplecatalog
        <PublicRoute path="/catalog/:theme/suggestion" component={CatalogSuggestion} />
        <PublicRoute path="/catalog/:theme" component={SimpleCatalog} />
        // Fakewebsites
        <PublicRoute path="/fakewebsites" component={FakeWebsites} title={t('pagetitles.fakewebsites')}/>
        // Phishing
        <PublicRoute path="/phishing/info" component={PhishingInfo} title={t('pagetitles.phishing')}/>
        <PublicRoute path="/phishing/sensibilisierung" component={PhishingSensibilisierung} title={t('pagetitles.phishing')}/>
        <PublicRoute path="/phishing" component={Phishing} title={t('pagetitles.phishing')}/>
        // Chats
        <PublicRoute path="/chats" component={Chats} title={t('pagetitles.chats')}/>
        // SAI
        <PublicRoute path="/sai" component={SAI} title={t('pagetitles.sai')} />
        // Kompass
        <PublicRoute path="/kompass" component={Kompass} title={t('pagetitles.kompass')} />
        // Unterstützung
        <PublicRoute path="/unterstützung" component={Unterstuetzung} title={t('pagetitles.unterstuetzung')}/>
        <PublicRoute path="/Unterstützung" component={Unterstuetzung} title={t('pagetitles.unterstuetzung')}/>
        <PublicRoute path="/Unterstuetzung" component={Unterstuetzung} title={t('pagetitles.unterstuetzung')}/>
        <PublicRoute path="/unterstuetzung" component={Unterstuetzung} title={t('pagetitles.unterstuetzung')}/>
        <PublicRoute path="/factsheets*" component={Unterstuetzung} />
        // Twine
        <PublicRoute path="/twine/embed" component={TwineEmbed} />
        <PublicRoute path="/twine" component={Twine} />
        // Sonic Pi
        <PublicRoute path="/sonicpi" component={SonicPi} />
        // Audiodrive
        <PublicRoute path="/audiodrive/:tracks" component={AudioDrivePlayer} />
        <PublicRoute path="/audiodrive" component={AudioDrive} />
        // Redirecter
        <PublicRoute path="/redirecter" component={Redirecter} />
        // Bookmarklet
        <PublicRoute path="/bookmarklet" component={Bookmarklet} />
        // Inviteroutine
        <PublicRoute path="/join/:code" component={InviteRoutine} />

        <PublicRoute path="/forbidden" component={ErrorPage403} />
        <PublicRoute component={ErrorPage404} />
       </Switch>
       <Footer />
      </Router>
    </ThemeProvider>
   </Suspense>
  )
})



ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
