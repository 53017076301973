import React, {useEffect} from 'react'

import { makeStyles,useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core/'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  sticky:{
    top: 64,
    zIndex: 10,
    position: 'sticky',
    backgroundColor: 'inherit',
    [theme.breakpoints.only('xs')]: {
      top:56
    },
  },
  tabStyle:{
    color:theme.palette.primary.main,
    '&:hover':{
      color:theme.palette.primary.dark,
      textDecoration:'none',
      outline:'none'
    }
  },
  appBar:{
    background:'#fff'
  }
}))

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Navigation = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()



  return (
   <div className={classes.sticky}>
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="navigation-kibs"
        >
          <Tab className={classes.tabStyle} href="/#helpdesk" label={t('core.landingpage.helpdesk.title')} />
          <Tab className={classes.tabStyle} href="/#advice" label={t('core.landingpage.advice.title')} />
          <Tab className={classes.tabStyle} href="/#services" label={t('core.landingpage.services')} />
          <Tab className={classes.tabStyle} href="/#aktuelles" label={t('core.landingpage.news')} />
          <Tab className={classes.tabStyle} href="/#discord" label={t('core.landingpage.discord.app')} />
        </Tabs>
      </AppBar>

    </div>
   </div>
  );
})

export default Navigation
